import React, { useCallback, useEffect, useContext }  from 'react';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';

import { MessageContext } from '../crmContext/MessageContext';

const CrmMessageToolbar = () => {
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const {
    searchInputText,
    messageStatus,
    messageFilter,
    messageSort
  } = MessageState;

  useEffect(() => {
    const searchBlock = document.getElementsByClassName('btn_search')[0];
    const clear = document.getElementsByClassName('clearSearch')[0];

    if(searchInputText.length > 0) {
      searchBlock.classList.add('in_search');
      clear.style.display = 'block';
    } else {
      searchBlock.classList.remove('in_search');
      clear.style.display = 'none';
    }
  }, [ searchInputText ]);

  const resizeScreen = useCallback(() => {
    const title = document.getElementsByClassName('title')[0];
    const searchInput = document.getElementsByClassName('searchInput')[0];
    const searchBox = document.getElementsByClassName('crmMessage_toolbar_right')[0];
    const sendIcon = document.getElementsByClassName('btn_send')[0];
    const closeSearch = document.getElementsByClassName('closeSearch')[0];

    let w_w = window.innerWidth;

    if(w_w > 768) {
      title.style.display = 'block';
      sendIcon.style.display = 'flex';
      searchInput.style.display = 'block';
      closeSearch.style.display = 'none';
      searchBox.style.width = 'auto';
    } else {
      if(closeSearch.style.display === 'inline-block') {
        searchInput.style.display = 'block';
      } else {
        if(searchInput.value.length > 0) {
          showSearch();
        } else {
          searchInput.style.display = 'none';
        }
      }
    }
	}, []);

  useEffect(() => {
    window.addEventListener('resize', resizeScreen);
    return () => {
      window.removeEventListener('resize', resizeScreen);
    }
  }, [ resizeScreen ]);

  const handleSearch = (e) => {
    const searchInputText = e.target.value;
    
    if(searchInputText.length > 0) {
      MessageStateDispatch({ type: 'setSearchInputText', searchInputText });
    } else {
      cancelSearch();
    }
  };

  const handleSubmitSearch = () => {
    if(searchInputText.length > 0) {
      MessageStateDispatch({ type: 'setSearchRes' });
      MessageStateDispatch({ type: 'setSearchResSort', messageSort });
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: true });
      MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
      MessageStateDispatch({ type: 'setStatusMessagesNum' });
    }
  };

  const handleSmallSizeSearchAction = () => {
    if(searchInputText.length > 0) {
      handleSubmitSearch();
    } else {
      showSearch();
    }
  };

  const showSearch = () => {
    const title = document.getElementsByClassName('title')[0];
    const searchInput = document.getElementsByClassName('searchInput')[0];
    const searchBlock = document.getElementsByClassName('crmMessage_toolbar_right')[0];
    const sendIcon = document.getElementsByClassName('btn_send')[0];
    const closeSearch = document.getElementsByClassName('closeSearch')[0];

    searchInput.style.display = 'flex';
    searchInput.focus();
    searchBlock.style.width = '100%';
    title.style.display = 'none';
    sendIcon.style.display = 'none';
    closeSearch.style.display = 'inline-block';
  };

  const closeSearch = () => {
    const title = document.getElementsByClassName('title')[0];
    const searchInput = document.getElementsByClassName('searchInput')[0];
    const searchBlock = document.getElementsByClassName('crmMessage_toolbar_right')[0];
    const sendIcon = document.getElementsByClassName('btn_send')[0];
    const closeSearch = document.getElementsByClassName('closeSearch')[0];

    searchInput.style.display = 'none';
    searchBlock.style.width = 'auto';
    title.style.display = 'block';
    sendIcon.style.display = 'flex';
    closeSearch.style.display = 'none';

    cancelSearch();
  };

  const cancelSearch = () => {
    MessageStateDispatch({ type: 'setMessageSort', messageSort });
    MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: false });
    MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
    MessageStateDispatch({ type: 'setSearchInputText', searchInputText: '' });
    MessageStateDispatch({ type: 'setStatusMessagesNum' });
  }

  const selectMessagePath = useCallback(() => {
		MessageStateDispatch({ type: 'setSelectMessagePath' });
	}, [ MessageStateDispatch ]);

  return (
    <div className="crmMessage_bar crmMessage_toolbar">
      <h2 className="title">訊息</h2>
      <div className="crmMessage_toolbar_right">
        <button className="btn_search">
          <SearchOutlined
            className="search_large"
            onClick={() => handleSubmitSearch()}
          />
          <SearchOutlined
            className="search_small"
            onClick={() => handleSmallSizeSearchAction()}
          />
          <input
            type="text"
            className="searchInput"
            value={searchInputText}
            placeholder="搜尋訊息標題或內文"
            onChange={(e) => handleSearch(e)}
            onKeyPress={(e) => e.key === 'Enter' && handleSubmitSearch()}
          />
          <img
            className="clearSearch"
            src={require('../../../images/crm/cancel_icon.svg')}
            onClick={() => cancelSearch()}
            alt=''
          />
          <CloseOutlined
            onClick={() => closeSearch()}
            className="closeSearch"
          />
        </button>
        <button
          className="btn_send"
          onClick={() => selectMessagePath()}
        >
          <span>傳送訊息</span>
          <img className="send_white_icon" src={require('../../../images/crm/sendMessage_icon.svg')} alt='' />
          <img className="send_icon" src={require('../../../images/crm/sendMessage_test_icon.svg')} alt='' />
        </button>
      </div>
    </div>
  )
}

export default CrmMessageToolbar;
