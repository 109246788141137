import React, { useCallback, useState, useEffect, useReducer }  from 'react';
import { Link } from 'react-router';
import { MenuOutlined } from '@ant-design/icons'

import CrmMessageAdd from './crmMessageAdd/CrmMessageAdd';
import CrmConfirmMessage from './crmMessageAdd/CrmConfirmMessage';

import { MessageContext, MessageReducer, messageState } from './crmContext/MessageContext';
import { MessageAddStateContext, MessageAddReducer, messageAddInitState } from './crmContext/MessageAddContext';

import '../../sass/crm_layout.scss';
import '../../sass/crm_message.scss';
import './crmMessagePopup/crmMessagePopup.scss';

const Crm = (props) => {
  const [ MessageState, MessageStateDispatch ] = useReducer(MessageReducer, messageState);
	const { mode } = MessageState;
	const [ MessageAddState, MessageAddDispatch ] = useReducer(MessageAddReducer, messageAddInitState);
	const { isSaved } = MessageAddState;

	const [ currentPath, setCurrentPath ] = useState(window.location.pathname.replace('/dashboard/', ''));

	const routerWillLeave = useCallback(
		() => {
			if(mode === 'addMessage' || mode === 'confirmMessage') {
				if(!isSaved) return '退出將放棄傳送訊息，確定退出嗎？';
			};
		},
		[ mode, isSaved ]
	);

	useEffect(() => {
		props.router.setRouteLeaveHook(props.route, routerWillLeave);
	}, [ props, routerWillLeave ]);

	useEffect(() => {
		let current_path = window.location.pathname.replace('/dashboard/', '');

		if(current_path === 'customers') {
			MessageStateDispatch({ type: 'switchMode', mode: 'customerList', editType: ''});
		} else {
			MessageStateDispatch({ type: 'switchMode', mode: 'messageList', editType: ''});
		}

		setCurrentPath(current_path);
	}, [ props ]);

	useEffect(() => {
		const asideBar = document.getElementsByClassName('crm_aside')[0];
		const container = document.getElementsByClassName('crm_main')[0];
		const msgNav = document.getElementsByClassName('crm_message_nav')[0];

		if(mode === 'messageList' || mode === 'customerList') {
			asideBar.style.display = 'flex';
			asideBar.style.top = '111px';
			container.style.top = '111px';
			container.style.left = '82px';
			container.style.height = 'calc(100vh - 56px - 55px)';
			container.style.width = 'calc(100% - 82px)';
			msgNav.style.display = 'flex';
		}	else if (mode === 'addMessage' || mode === 'confirmMessage') {
			container.style.top = '56px';
			container.style.left = '0';
			msgNav.style.display = 'none';
			container.style.height = 'calc(100vh - 56px)';
		} 
	}, [ mode ]);

	const renderActiveMode = useCallback(
		() => {
			if(mode === 'addMessage') {
				return <CrmMessageAdd />;
			} else if (mode === 'confirmMessage') {
				return <CrmConfirmMessage />
			} else {
				return props.children;
			}
		},
		[ mode, props ]
	);

	const switchModeHideMask = () => {
		const menu = document.getElementsByClassName('crm_menu')[0];
		const show = document.getElementsByClassName('show_menu')[0];
		const mask = document.getElementsByClassName('crm_menu_mask')[0];
		const nav = document.getElementsByClassName('crm_message_nav')[0];

		if(show) {
			menu.classList.remove('show_menu');
			mask.style.display = 'none';
			nav.style.border = '1px solid #E8E8E8';
		} 
	};

	const toggleMenu = () => {
		const menu = document.getElementsByClassName('crm_menu')[0];
		const show = document.getElementsByClassName('show_menu')[0];
		const mask = document.getElementsByClassName('crm_menu_mask')[0];
		const nav = document.getElementsByClassName('crm_message_nav')[0];

		if(show) {
			menu.classList.remove('show_menu');
			mask.style.display = 'none';
			nav.style.border = '1px solid #E8E8E8';
		} else {
			menu.classList.add('show_menu');
			mask.style.display = 'block';
			nav.style.border = 'none';
		}
	};

	const closeMenu = () => {
		const menu = document.getElementsByClassName('crm_menu')[0];
		const mask = document.getElementsByClassName('crm_menu_mask')[0];
		const nav = document.getElementsByClassName('crm_message_nav')[0];

		menu.classList.remove('show_menu');
		mask.style.display = 'none';
		nav.style.border = '1px solid #E8E8E8';
	};
  
	return (
		<MessageContext.Provider value={{ MessageState, MessageStateDispatch }}>
			<MessageAddStateContext.Provider value={{ MessageAddState, MessageAddDispatch }}>
				<div className="crmWrap" style={{ height: window.innerHeight }}>
					<div className="crm">
						<div className="crm_message_nav">
							<MenuOutlined onClick={() => toggleMenu()} />
							<img src={require("../../images/component/crm_logo.svg")} alt="" />
							顧客關係管理
						</div>

						<div className="crm_menu">
							<Link to={{pathname: '/dashboard/customers'}}>
								<button
									className={currentPath === 'customers' ? 'active': ''}
									onClick={() => switchModeHideMask()}
								>
									<div className="group_icon"></div>
									<div>顧客列表</div>
								</button>
							</Link>
							<Link to={{pathname: '/dashboard/messages'}}>
								<button
									className={currentPath === 'messages' ? 'active': ''}
									onClick={() => switchModeHideMask()}
									>
									<div className="message_icon"></div>
									<div>訊息</div>
								</button>
							</Link>
						</div>
						<div className="crm_menu_mask" onClick={() => closeMenu()}></div>

						<aside className="crm_aside">
							<Link to={{pathname: '/dashboard/customers'}}>
								<button
									className={currentPath === 'customers' ? 'active': ''}
								>
									<div className="group_icon"></div>
									<div>顧客列表</div>
								</button>
							</Link>
							<Link to={{pathname: '/dashboard/messages'}}>
								<button
									className={currentPath === 'messages' ? 'active': ''}
									>
									<div className="message_icon"></div>
									<div>訊息</div>
								</button>
							</Link>
						</aside>
						<div className="crm_main">
							<div className="crm_container">
								{ renderActiveMode() }
							</div>
						</div>
					</div>
				</div>
			</MessageAddStateContext.Provider>
		</MessageContext.Provider>
	);
};

export default Crm;
