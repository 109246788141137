import React, { useCallback, useState, useEffect, useContext, Fragment, useRef }  from 'react';
import { browserHistory } from 'react-router'
import { SearchOutlined } from '@ant-design/icons';

import { CustomerContext } from '../crmContext/CustomerContext';
import { MessageContext } from '../crmContext/MessageContext';

import { searchCustomer, filterCustomersCSV, getCustomersCSV } from '../api/CrmCustomerApi';
import useDebounce from '../utils/useDebounce';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';

const currentSearchInputWidth = 248;

const CrmToolbar = () => {
  const auth = useSelector((state) => state.auth);
  const { shop } = auth;
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
    searchCustomerText,
    searchCustomerMode,
    customerFilterNum,
    showCustomerFilterBar,
    filterCustomersParams
  } = CustomerState;
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const {
    mode,
    searchInputText,
    messageStatus,
    messageFilter,
    messageSort
  } = MessageState;

  const [ currentWindowWidth, setCurrentWindowWidth ] = useState(window.innerWidth);
  const [ searchCustomerResult, setSearchCustomerResult ] = useState([]);
  const [ isSearching, setIsSearching ] = useState(false);
  const [ searchValue, setSearchValue ] = useState('');
  const [ showSearchSelect, setShowSearchSelect ] = useState(false);
  const [ searchWidth, setSearchWidth ] = useState(currentSearchInputWidth);
  const [ isComposing, setIsComposing ] = useState(false);
  const [ focusedId, setFocusedId ] = useState(null);
  const [ isDownloading, setIsDownloading ] = useState(false);

  const searchInputRef = useRef(null);
  const searchBlockRef = useRef(null);

  const debounceSearchValue = useDebounce(searchValue);

  useEffect(() => {
    if(mode === 'messageList') setSearchValue(searchInputText);
    if(mode === 'customerList') setSearchValue(searchCustomerText);

    // 當切換模式，會關閉搜尋
    cancelSearch();
  }, [ mode ])

  useEffect(() => {
    if(!searchCustomerMode) {
      setSearchValue('');
      setSearchCustomerResult([])
    }
  }, [ searchCustomerMode ])

  useEffect(() => {
    if (currentWindowWidth < 768 && isSearching) {
      searchInputRef.current.focus();
    }
  }, [currentWindowWidth, isSearching]);

  useEffect(() => {
    if(showCustomerFilterBar) cancelSearch();
  }, [ showCustomerFilterBar ]);

  const resizeScreen = useCallback(() => {
    let w_w = window.innerWidth;
    let inputWidth = searchBlockRef.current.offsetWidth;

    setCurrentWindowWidth(w_w);
    setSearchWidth(inputWidth < currentSearchInputWidth ? currentSearchInputWidth : inputWidth);
	}, []);

  useEffect(() => {
    let inputWidth = searchBlockRef.current.offsetWidth;
    setSearchWidth(inputWidth < currentSearchInputWidth ? currentSearchInputWidth : inputWidth);

    window.addEventListener('resize', resizeScreen);
    return () => {
      window.removeEventListener('resize', resizeScreen);
    }
  }, [ resizeScreen, searchCustomerMode ]);

  useEffect(() => {
    let limit = 6;
    if (debounceSearchValue) handleDebounceSearch(limit);
  }, [ debounceSearchValue ]);

  useEffect(() => {
    const handleClick = (event) => {
      const isClickOnInput = event.target.classList.contains('searchInput');
  
      const isClickOnSelectOption = event.target.classList.contains('select_option');
      const isClickOnResultOption = event.target.classList.contains('result_option');

      if(mode !== 'customerList') return;
  
      if (isClickOnInput && searchValue) {
        setShowSearchSelect(true);
      } else if (isClickOnSelectOption || isClickOnResultOption) {
        setShowSearchSelect(true);

        setTimeout(() => {
          setShowSearchSelect(false);
        }, 0);
      } else {
        setShowSearchSelect(false);
      }
    };
  
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ debounceSearchValue, searchValue, mode ]);

  const handleDebounceSearch = async(limit) => {
    if(mode === 'customerList') {
      const query = {
        query: debounceSearchValue
      };

      if(limit) query.limit = limit;

      try {
        const { data } = await searchCustomer(query);

        if(!limit) {
          CustomerStateDispatch({
            type: 'setSearchCustomerResult',
            searchCustomers: data.customers,
            searchCustomerMode: true,
            listPage: 1,
            listCount: data.meta.size
          });
        } else {
          setSearchCustomerResult(data.customers);
          setShowSearchSelect(true);
        }
      } catch(err) {
        console.log('search customer err ----------', err)
      }
    }
  };

  const handleSearchValue = (e) => {
    const value = e.target.value;

    if(mode === 'customerList') {
      CustomerStateDispatch({ type: 'setSearchCustomerText', searchCustomerText: value });
    } else {
      MessageStateDispatch({ type: 'setSearchInputText', searchInputText: value });
    }

    if(value.trim().length === 0 && !searchCustomerMode) {
      cancelSearch();
      setShowSearchSelect(false);
    }

    setIsSearching(true);
    setSearchValue(value)
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedId(null);
  }, []);

  const handleKeyDown = (e) => {
    const { key } = e;
    let nextIndexCount = 0;

    if(searchValue.trim().length === 0 || searchCustomerResult.length === 0) return;

    if (key === "ArrowDown") {
      nextIndexCount = (searchCustomerResult.findIndex(item => item.id === focusedId) + 1) % searchCustomerResult.length;
      setFocusedId(searchCustomerResult[nextIndexCount].id)
    }

    if (key === "ArrowUp") {
      nextIndexCount = (searchCustomerResult.findIndex(item => item.id === focusedId) + searchCustomerResult.length - 1) % searchCustomerResult.length;
      setFocusedId(searchCustomerResult[nextIndexCount].id)
    }

    if (key === "Escape") {
      resetSearchComplete();
    }

    if (!isComposing && key === "Enter") {
      if(focusedId) return toCustomerPage(focusedId);

      handleToSearchList();
      setShowSearchSelect(false);
      setIsSearching(false);
    }
  };

  const handleKeyPressEnter = (e) => {
    if (e.key === 'Enter' && searchValue.trim().length !== 0 && !isComposing) {
      MessageStateDispatch({ type: 'setSearchRes' });
      MessageStateDispatch({ type: 'setSearchResSort', messageSort });
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: true });
      MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
      MessageStateDispatch({ type: 'setStatusMessagesNum' });
    }
  };

  const handleExpendSearch = () => {
    if(currentWindowWidth <= 768) {
      setIsSearching(true);

      setTimeout(() => {
        let inputWidth = searchBlockRef.current.offsetWidth;
        setSearchWidth(inputWidth);
      }, 0);
    }
  };

  const cancelSearch = () => {
    // customer
    setSearchCustomerResult([]);
    CustomerStateDispatch({ type: 'setSearchCustomerText', searchCustomerText: '' });
    CustomerStateDispatch({
      type: 'setSearchCustomerResult',
      searchCustomers: [],
      searchCustomerMode: false,
      listPage: 1,
      listCount: null
    });
    // message
    MessageStateDispatch({ type: 'setMessageSort', messageSort });
    MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: false });
    MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
    MessageStateDispatch({ type: 'setSearchInputText', searchInputText: '' });
    MessageStateDispatch({ type: 'setStatusMessagesNum' });

    setIsSearching(false);
    setSearchValue('');
  };

  const handleCreateCustomer = () => {
    CustomerStateDispatch({ type: 'setAddCustomerModal', show: true });
    CustomerStateDispatch({ type: 'cleanCustomerInfo' });
  }

  const renderTitle = () => {
    if(mode === 'customerList') {
      if(searchCustomerMode) {
        return '搜尋結果';
      } else {
        return '顧客列表';
      }
    } else {
      return '訊息';
    }
  };

  const handleToSearchList = () => {
    let limit = null;

    resetSearchComplete();
    handleDebounceSearch(limit);
  };

  const clearSearch = () => {
    setSearchValue('');
    setIsSearching(false);
  };

  const toCustomerPage = (id) => {
    browserHistory.push(window.location.pathname + '/' + id);
    resetSearchComplete();
  };

  const renderSearchCustomerResult = () => {
    return (
      <div
        className='search_customers_select'
        style={{ width: searchWidth }}
      >
        {
          searchCustomerResult.map((customer) => {
            return (
              <div
                key={customer.id}
                className='select_option'
                style={{
                  backgroundColor:
                    customer.id === focusedId ? "rgba(240, 240, 240, 0.5)" : "",
                }}
                onClick={() => toCustomerPage(customer.id)}
              >
                <div className='s_name'>{customer.last_name}</div>
                <div className='s_phone'>{customer.phone_number}</div>
                <div  className='s_note'>{customer.note}</div>
              </div>
            )
          })
        }
        {
          searchCustomerResult.length > 0
          ? <div
              className='result_option'
              onClick={handleToSearchList}
            >
              <SearchOutlined className="search_icon" />
              搜尋與「{searchCustomerText}」相符的所有結果
            </div>
          : <div className='result_option result_option_empty'>
              <SearchOutlined className="search_icon" />
              沒有與「{searchCustomerText}」相符的結果
            </div>
        }
      </div>
    )
  }

  const renderSearch = () => {
    const placeholderText = mode === 'customerList' ? '搜尋顧客' : '搜尋訊息標題或內文';
    let searchStyle = '';

    if(mode === 'customerList') {
      if(searchValue.length !== 0) {
        searchStyle = 'in_search_customer';
      } else {
        searchStyle = 'search_customer';
      }
    }

    return (
      <div className='block_search'>
        <div
          ref={searchBlockRef}
          className={`${searchStyle} btn_search`}
        >
          <SearchOutlined
            className="search_icon"
            onClick={mode === 'customerList' && showCustomerFilterBar || customerFilterNum !== 0 ? null: handleExpendSearch}
          />
          <input
            type="text"
            inputMode="search"
            ref={searchInputRef}
            className="searchInput"
            value={searchValue}
            disabled={mode === 'customerList' && showCustomerFilterBar || customerFilterNum !== 0}
            placeholder={placeholderText}
            onChange={(e) => handleSearchValue(e)}
            onCompositionStart={() => setIsComposing(true)}
            onCompositionEnd={() => setIsComposing(false)}
            onKeyDown={mode === 'customerList' ? handleKeyDown : handleKeyPressEnter}
          />
          <img
            className={
              (mode === 'messageList' || mode === 'customerList') &&
              searchValue.length > 0 ? 'clearSearch in_search' : 'clearSearch'
            }
            src={require('../../../images/crm/cancel_icon.svg')}
            onClick={clearSearch}
          />
        </div>
        {showSearchSelect && renderSearchCustomerResult()}
      </div>
    )
  };

  const renderAction = () => {
    if(mode === 'customerList') {
      const handleCustomersCSV = async () => {
        try {
          setIsDownloading(true);
          const response = await getCustomersCSV();

          const url = URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
          const a = document.createElement('a');
          const date = moment().format('YYYYMMDDhhmm');
          a.href = url;
          a.download = `menushop_customers_${shop.name}_${date}.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading CSV:', error);
        } finally {
          setIsDownloading(false);
        }
      };

      const handleFilterCustomersCSV = async (params) => {
        try {
          setIsDownloading(true);
          const response = await filterCustomersCSV(params);

          const url = URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8;' }));
          const a = document.createElement('a');
          const date = moment().format('YYYYMMDDhhmm');
          a.href = url;
          a.download = `menushop_customers_${shop.name}_${date}.csv`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading CSV:', error);
        } finally {
          setIsDownloading(false);
        }
      };

      const handleExport = async() => {
        if (isDownloading) return;
        if (Object.keys(filterCustomersParams).length === 0) {
          handleCustomersCSV();
        } else {
          handleFilterCustomersCSV(filterCustomersParams);
        }
      };

      return (
        <Fragment>
          <button className="btn_export" onClick={handleExport} disabled={isDownloading}>
            {
              isDownloading ?
              <LoadingOutlined /> :
              <img src={require('../../../images/crm/export_icon.svg')} />
            }
          </button>
          {/* <a href={window.domain + "/dashboard/customers.csv"} download>
          </a> */}
          <button
            className='btn_add'
            onClick={handleCreateCustomer}
          >
            <img src={require('../../../images/crm/plus_icon_white.svg')} />
            <span>新增顧客</span>
          </button>
        </Fragment>
      )
    } else {
      return (
        <button
          className="btn_send"
          onClick={() => MessageStateDispatch({ type: 'setSelectMessagePath' })}
        >
          <span>傳送訊息</span>
          <img className="send_white_icon" src={require('../../../images/crm/sendMessage_icon.svg')} alt='' />
          <img className="send_icon" src={require('../../../images/crm/sendMessage_test_icon.svg')} alt='' />
        </button>
      )
    }
  };

  const searchStyle = () => (searchCustomerMode ? 'endSearching' : isSearching ? 'isSearching' : '');

  return (
    <div
      className={`crmToolbar crmMessage_toolbar ${searchStyle()}`}
    >
      <h2 className='title'>{ renderTitle() }</h2>
      <div className="crmMessage_toolbar_right">
        { renderSearch() }
        { renderAction() }
      </div>
    </div>
  )
}

export default CrmToolbar;