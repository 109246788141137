import React from 'react';
import { Modal } from 'antd';
import { browserHistory } from 'react-router';

import { deleteCrmCustomer } from '../../api/CrmCustomerApi';
import './crmCustomerRecordModal.scss';

const CrmCustomerDeleteModal = ({
  id,
  setShowCustomerDeleteModal,
  handleEditCustomer
}) => {
  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      await deleteCrmCustomer(id);
      const url = window.location.pathname;
      const parent_url = url.split('/').slice(0, -1).join('/');
      
      handleEditCustomer();
      browserHistory.push(parent_url);
      window.app.alert.setMessage('已刪除顧客', "done");
    } catch(error) {
      console.log("delete customer error -----", error)
			window.app.alert.setMessage(error.response.message, "error");
    }
  };

  return (
    <Modal
      title="確定刪除嗎？"
      visible
      centered
      closable={false}
      className="crmCustomerDeleteModal"
      footer={
        <React.Fragment>
          <button
            className='btn_cancel'
            onClick={() => setShowCustomerDeleteModal(false)}
          >
            取消
          </button>
          <button
            className='btn_confirm'
            onClick={(e) => handleSubmit(e)}
          >
            刪除
          </button>
        </React.Fragment>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      刪除後將不會保存顧客資料；已被封鎖的電話與單筆預約記錄則仍會留存於封鎖名單和預約系統中。確定刪除嗎？
    </Modal>
  );
};

export default CrmCustomerDeleteModal;