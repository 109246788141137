import React from 'react'
import Customer from './Customer'
var createReactClass = require('create-react-class');
export default createReactClass({
  render() {
    var customers = this.props.data.map(function(customer) {
      return (
        <Customer key={customer.id} 
                  data={customer} />
      )
    });
    return (
      <tbody>
        {customers}
      </tbody>
    );
  }
});
