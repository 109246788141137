import React from 'react'
import $ from 'jquery'
var createReactClass = require('create-react-class');
export default createReactClass({
  handleDismiss() {
    $(this.refs.modal).modal('toggle');
  },
  handleSunbmit(e) {
    e.preventDefault();
    var data = {record: Object.assign({}, this.props.data, { value: this.refs.data.value })};
    var url = this.props.url + '/' + this.props.data.id;
    this.props.onRecordSubmit(data, 'PUT', url);
    this.handleDismiss();
  },
  componentDidUpdate(prevProps) {
    if(prevProps.data.id !== this.props.data.id){
      this.refs.data.value = this.props.data.value
    }

  },
  render() {
    return (
      <div className="modal fade edithistory" ref="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              <h4 className="modal-title">編輯紀錄</h4>
            </div>
            <form onSubmit={this.handleSunbmit}>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xs-5">
                      <div className="form-group">
                        <label>{this.props.data.created_at}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <textarea className="text optional form-control" 
                                  ref='data'
                                  name="value" 
                                  onChange={this.props.onRecordChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-color-white btn-style4" data-dismiss="modal">取消</button>
                <input type="submit" className="btn btn-color-black btn-style4" value="儲存" />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
});
