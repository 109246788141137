import React, { useState, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import $ from 'jquery';
import ReactDOMServer from 'react-dom/server';
import { browserHistory } from 'react-router';
import { Link } from 'react-router';

import BookingSystemApi from '../../booking_settings/BookingSystemApi';
import { WarningTemplate } from '../../new_module/NewModuleComponent/NewModuleWarning';

import { seatAlertTitle, bookingStatus } from '../../new_module/utils/data';

const BookingAPI = new BookingSystemApi();
export default (props) => {
	const { booking, bookings, status, serviceTimeOptionEnabled } = props;

	const openVisitRecord = (e) => {
		const visitRecordMenu = 'visitRecordMenu_'
		const visitRecord = document.getElementById("visitRecord")
		const showVisitRecordMenu = document.querySelector(".showVisitRecordMenu")
		const currentVisitRecordMenu = document.getElementById(visitRecordMenu + booking.active_code)
	
		if(e.target !== visitRecord && e.target !== currentVisitRecordMenu) {
			if(showVisitRecordMenu) {
				showVisitRecordMenu.classList.remove("showVisitRecordMenu")
			}
		}
	}

	useEffect(() => {
		// 點擊空白處關閉 visitRecordMenu
		document.addEventListener('click', openVisitRecord);

		return () => {
			document.removeEventListener('click', openVisitRecord);
		}
	}, [])

	const renderBookingSource = () => {
		if (booking.source === 'online') {
			if (booking.source_url_t === 'google') {
				return <span className="bookingSourceIcon bookingSourceIcon-google" />;
			} else if (booking.source_url_t === 'facebook') {
				return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
			} else if (booking.source_url_t === 'instagram') {
				return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
			} else if (booking.source_url_t === 'menuApp') {
				return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
			} else if (booking.source_url_t === 'other') {
				return <span className="bookingSourceIcon bookingSourceIcon-other" />;
			}
		}

		if(booking.source === 'google_booking' && booking.source_url_t === 'google_reservation') {
			return <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />;
		}
	};

	const renderDiningTime = () => {
		let diningTimeContent = booking.service_time_str;
		let diningTime = booking.dining_time;
		let diningTime_hour = Math.floor(parseInt(diningTime, 10) / 60);
		let diningTime_min = Math.floor(parseInt(diningTime, 10) % 60);

		if(serviceTimeOptionEnabled && booking.source !== 'offline') {
			return (
				<div className="diningTime">
					<div className="diningTime_time">{diningTimeContent}</div>
					<div className="diningTime_time">({diningTime_hour} 小時 {diningTime_min} 分)</div>
				</div>
			)
		} else {
			return (
				<div className="diningTime">
					<div className="diningTime_time">({diningTime_hour} 小時 {diningTime_min} 分)</div>
				</div>
			)
		};
	};

	const renderStandbyIndex = () => {
		const { standbyIndex, activeBookingListStatusTab } = props;

		if(activeBookingListStatusTab === 'show') {
			return(
				<div className="standby_tag">順位{standbyIndex + 1}</div>
			)
		}
	};

	const renderDeposit = () => {
		let tooltip;

		if (booking.deposit_required === true) {
			if (booking.deposit_paid) {
				if (booking.last_pay_info) {
					let pay_info = booking.last_pay_info.result.data;
					if (pay_info.PaymentType === 'CREDIT') {
						tooltip = ReactDOMServer.renderToStaticMarkup(
							<div style={{ lineHeight: '1.8' }}>
								付款狀態：
								<span style={{ color: '#3AA177' }}>已付款</span> ({pay_info.PayTime})<br />
								金額：{booking.deposit}
								<br />
								付款方式：信用卡
								<br />
								卡號：{pay_info.Card6No.slice(0, 4)} - {pay_info.Card6No.slice(4)}** - **** -{' '}
								{pay_info.Card4No}
								<br />
								智付通訂單編號：{booking.order_no}
							</div>
						);
					} else {
						tooltip = ReactDOMServer.renderToStaticMarkup(
							<div style={{ lineHeight: '1.8' }}>
								付款狀態：
								<span style={{ color: '#3AA177' }}>已付款</span> ({pay_info.PayTime})<br />
								金額：{booking.deposit}
								<br />
								付款方式：超商
								<br />
								條碼：{pay_info.CodeNo}
								<br />
								智付通訂單編號：{booking.order_no}
							</div>
						);
					}
				}
				return (
					<div tabIndex="0" role="button" data-toggle="popover" data-content={tooltip}>
						<div className="pay-info">$ {booking.deposit}</div>
					</div>
				);
			} else {
				tooltip = ReactDOMServer.renderToStaticMarkup(
					<div style={{ lineHeight: '1.8' }}>
						付款狀態：未付款 <br />
						金額：{booking.deposit}
						<br />
						付款期限：
						{ booking.deposit_due_date ? moment(booking.deposit_due_date).format('YYYY/MM/DD HH:mm:ss') : '' }
						<br />
					</div>
				);
				return (
					<div tabIndex="0" role="button" data-toggle="popover" data-content={tooltip}>
						<div className="pay-info unpaid">$ {booking.deposit}</div>
					</div>
				);
			}
		}
	};

	const renderCustomerNote = () => {
		const note = booking.customer_info.note,
			tags = booking.customer_info.tags;

		if (note.length !== 0 || tags.length !== 0) {
			return (
				<span className="customer_tag">
					<span className="fileIcon" />
					<p className="dropdown-menu">
						{tags.length !== 0 && (
							<span style={{ fontSize: '0', display: 'block', marginBottom: 5 }}>
								{tags.map((tag, index) => {
									return <span key={index} className={`c-tag ${tag}`} />;
								})}
							</span>
						)}
						{note.length !== 0 && <span>{note}</span>}
					</p>
				</span>
			);
		}
	};

	const renderVisitRecordTotalTimes = () => {
		let VisitRecordTotalTimes = 0
		Object.keys(booking.customer_info.booking_records).map((branch) => {
			return VisitRecordTotalTimes += booking.customer_info.booking_records[branch]
		})

		return VisitRecordTotalTimes
	}

	const showVisitRecordMenu = (e) => {
		const visitRecordMenu = 'visitRecordMenu_'
		const currentVisitRecordMenu = document.querySelector('.showVisitRecordMenu')
		const nextVisitRecordMenu = document.getElementById(visitRecordMenu + booking.active_code)
		
		if (currentVisitRecordMenu) {
			currentVisitRecordMenu.classList.remove("showVisitRecordMenu")

			if(currentVisitRecordMenu !== nextVisitRecordMenu) {
				nextVisitRecordMenu.classList.add("showVisitRecordMenu")
			}
		} else {
			nextVisitRecordMenu.classList.toggle("showVisitRecordMenu")
		}

		e.nativeEvent.stopImmediatePropagation();
	}

	const renderLastVisitRecord = () => {
		const booking_date = booking.customer_info.last_booking.booking_datetime.substr(0, 10)
		const booking_week = booking.customer_info.last_booking.booking_datetime.substr(10, 3)
		const booking_time = booking.customer_info.last_booking.booking_datetime.substr(14, 5)
		return (
			<div className="visitRecordMenu_cell1">
				<div className="visitRecordMenu_title">最近一次到店紀錄：</div>
				<div>{booking.customer_info.last_booking.branch}</div>
				<div className="lastVisitRecord">
					<div className="lastVisitRecord_date">{booking_date} {booking_week}</div>
					<div className="lastVisitRecord_time">{booking_time}</div>
				</div>
			</div>
		)
	}

	const renderBranchVisitRecord = () => {
		return (
			<div className="visitRecordMenu_cell2">
				<div className="visitRecordMenu_title">所有分店紀錄：</div>
					{
						Object.keys(booking.customer_info.booking_records).map((branch, index) => {
							return (
							<div className="branchVisitRecord" key={index}>
								<div className="branchVisitRecord_Name">{branch}</div>
								<div className="branchVisitRecord＿times">{booking.customer_info.booking_records[branch]}</div>
							</div>
							)
						})
					}
		</div>
		)
	}

	const branchVisitRecordStyle = () => {
		const statusBookings = bookings.filter((booking) => booking.status === status)

		if(booking !== statusBookings[statusBookings.length - 1]) {
			return "res-visitRecordMenu"
		} else {
			return "res-visitRecordMenu-mobile-last" }
	}

	const renderSeatingBlock = () => {
		return JSON.parse(booking.seating_json).map((s, i) => {
			return (
				<span className="tagBlock seatingBlock" key={i}>
					{s.table_number}
				</span>
			);
		});
	};

	const renderServiceTagBlock = () => {
		const { customQ } = props;
		let serviceTagBlock = [],
			quantityBlock = [];
		let serviceMark = {};

		const existQuestion = customQ.map((q) => q.id.toString());

		try {
			serviceMark = JSON.parse(booking['customized_questions']);
		} catch (e) {
			serviceMark = {};
		}

		const keys = Object.keys(serviceMark);

		customQ.forEach((q) => {
			keys.forEach((el) => {
				if (
					q['id'].toString() === el &&
					typeof serviceMark[el] === 'object' &&
					existQuestion.indexOf(el) !== -1
				) {
					let content = JSON.parse(q.content);
					let ansObj = serviceMark[el];

					Object.keys(ansObj).forEach((index) => {
						let optionAnsw = {};
						if (content[index] && ansObj[index] === -1) {
							serviceTagBlock.push(content[index].name);
						} else if (content[index] && ansObj[index] !== -1 && ansObj[index] !== 0) {
							optionAnsw['name'] = content[index].name;
							optionAnsw['value'] = ansObj[index];
							quantityBlock.push(optionAnsw);
						}
					});
				}
			});
		});

		return (
			<div>
				{serviceTagBlock.map((ele, index) => (
					<span className="tagBlock serviceTagBlock" key={index}>
						{ele}
					</span>
				))}
				{quantityBlock.map((ele, index) => (
					<span className="tagBlock serviceTagBlock" key={index}>
						{ele.name}x{ele.value}
					</span>
				))}
			</div>
		);
	};

	const renderQuestionsBlock = () => {
		const { customQ } = props;
		let question = {};
		let keys = [];
		let questionsBlock = [];
		const existQuestion = customQ.map((q) => q.id.toString());

		try {
			question = JSON.parse(booking['customized_questions']);
		} catch (e) {
			question = {};
		}

		keys = Object.keys(question);

		customQ.forEach((q) => {
			keys.forEach((el) => {
				if (
					q['id'].toString() === el &&
					q['question_type'] === 'question' &&
					existQuestion.indexOf(el) !== -1 &&
					question[el] !== ''
				) {
					questionsBlock.push(question[q.id]);
				}
			});
		});

		if (questionsBlock.length !== 0) {
			return (
				<ol className="res-questionBlock">
					{questionsBlock.map((ele, index) => {
						return <li key={index}>{ele}</li>;
					})}
				</ol>
			);
		}
	};

	const renderMemo = () => {
		if (booking['memo'] !== null && booking['memo'].trim() !== '') {
			return (
				<div className="customerMemo">
					<div
						style={{
							display: 'inline-block',
							verticalAlign: 'top',
							textAlign: 'center',
							width: '20px'
						}}
					>
						<img
							style={{
								width: '14px',
								margin: '3px 0 0 5px',
								verticalAlign: 'top'
							}}
							src={require('../../../images/info.svg')}
							alt=""
						/>
					</div>
					<div
						style={{
							display: 'inline-block',
							color: '#555',
							paddingLeft: '5px',
							whiteSpace: 'normal',
							fontSize: '12px'
						}}
					>
						{booking['memo']}
					</div>
				</div>
			);
		}
	};

	const renderShopMemo = () => {
		if (booking['shop_memo'] != null && booking['shop_memo'].trim() !== '') {
			return <div className="res-shopMemo">{booking['shop_memo']}</div>;
		}
	};

	const renderShortCutBtn = () => {
		const today = moment().format('YYYY-MM-DD');
		const queryDate = moment(new Date(browserHistory.getCurrentLocation().query.date)).format('YYYY-MM-DD');

		if (queryDate === today) {
			return <ShortCutBtn booking={booking} handleUpdateStatus={handleUpdateStatus} />;
		}
	};

	const toggleList = (e) => {
		const w_w = window.screen.width;
		const $btn = $(e.target);
		const $toggleBlock = $btn
				.parents('.listViewRow-bottom')
				.siblings('.listViewRow-top')
				.children('.listViewRow-layer:eq(1)')
				.children('.listViewRow-top-cell4')
				.children('.toggleBlock'),
			$toggleBloclVisible = $toggleBlock.is(':visible');
		const $status = $btn.parents('.listViewRow-bottom-left').siblings('.listViewRow-bottom-right').children('div'),
			$statusVisible = $status.is(':visible');

		if (w_w <= 768) {
			if (!$toggleBloclVisible && !$statusVisible) {
				$toggleBlock.slideDown();
				setTimeout(() => {
					$status.slideDown();
				}, 300);
				$btn.removeClass('toggleBtn-down').addClass('toggleBtn-up');
			} else {
				$toggleBlock.slideUp();
				$status.slideUp();
				$btn.removeClass('toggleBtn-up').addClass('toggleBtn-down');
			}
		} else {
			if (!$toggleBloclVisible) {
				$toggleBlock.slideDown();
				$btn.removeClass('toggleBtn-down').addClass('toggleBtn-up');
			} else {
				$toggleBlock.slideUp();
				$btn.removeClass('toggleBtn-up').addClass('toggleBtn-down');
			}
		}
	};

    const handleUpdateStatus = (new_status)=>{
        const showTime = moment().format().slice(11, 16);
        props.setStatus(booking, new_status, props.updateData, showTime);
        // renderStatus();
    }

	const renderStatus = () => {
		let statusClass = '';
		const date = moment(new Date(browserHistory.getCurrentLocation().query.date)).format('YYYY-MM-DD');
		const today = moment().format('YYYY-MM-DD');

		if (booking.status) {
			switch (booking['status']) {
				case 'unconfirmed':
					statusClass = 'res-pedding';
					break;
				case 'confirmed':
					statusClass = 'res-success';
					break;
				case 'cancel':
					statusClass = 'res-cancel';
					break;
				case 'finish':
					statusClass = 'res-completed';
					break;
				case 'no_show':
					statusClass = 'res-noShow';
					break;
				case 'show':
					statusClass = 'res-arrived';
					break;
				case 'seated':
					statusClass = 'res-seated';
					break;
				default:
					statusClass = '';
			}
		}

		return (
			<div className="res-status">
				<button className={statusClass} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					● <span style={{ color: '#333' }}>{booking['status_t']}</span>
				</button>

				<BookingStatus
					booking={booking}
					date={date}
					isToday={date === today}
					handleUpdateStatus={handleUpdateStatus}
				/>
			</div>
		);
	};

	// console.log('booking---', booking);
	return (
		<div className="listViewRow" id={booking.active_code}>
			<div className="listViewRow-top">
				<div className="listViewRow-layer">
					<div className="listViewRow-top-cell listViewRow-top-cell1">
						<div>
							<span className="res-date">{moment(booking.booking_datetime).format('HH:mm')}</span>
							{renderBookingSource()}
							{renderDiningTime()}
							{renderStandbyIndex()}
						</div>
						{renderDeposit()}
					</div>
					<div className="listViewRow-top-cell listViewRow-top-cell2">
						{booking['customer_id'] !== null ? (
							<div>
								<div className="res-name">
									{booking.on_site ? <span className="onSiteTag">現場</span> : null}
									<Link
										to={
											props.booking.customer_id !== '' ? (
												`/dashboard/customers/${booking.customer_id}`
											) : (
												window.location.pathname
											)
										}
									>
										<span>{booking.last_name}</span>
										{booking.gender_desc.length !== 0 && <span> {booking.gender_desc}</span>}
									</Link>
									{renderCustomerNote()}
								</div>
							</div>
						) : (
							<div className="res-name">
								{booking.on_site ? <span className="onSiteTag">現場</span> : null}
								<span>{booking.last_name}</span>
								{booking.gender_desc.length !== 0 && <span> {booking.gender_desc}</span>}
							</div>
						)}
						<div>
							{booking.phone_number !== '' ? (
								<a className="res-phoneIcon" href={'tel:' + booking.phone_number}>
									<img src={require('../../../images/telephone.svg')} alt="" />
								</a>
							) : (
								''
							)}

							<p className="res-phone">{booking.phone_number}</p>

							{booking.phone_number !== '' && booking.customer_id === null ? (
								<p className="rsv-addCrmWrap">
									<button onClick={() => props.showAddCustomerModal(booking)} className="rsv-addCrm">
										儲存顧客
									</button>
								</p>
							) : null}
							{booking.customer_id !== null && JSON.stringify(booking.customer_info.booking_records) !== "{}" && JSON.stringify(booking.customer_info.last_booking) !== "{}" ? (
								<div id="visitRecord" className="visitRecord">
									<div id="visitRecordAllTimes" className="res-visitRecord" onClick={showVisitRecordMenu}>到店次數 {renderVisitRecordTotalTimes()} 次</div>
									<div id={'visitRecordMenu_' + booking.active_code} className={branchVisitRecordStyle()} onClick={(e)=> e.nativeEvent.stopImmediatePropagation()}>
										{renderLastVisitRecord()}
										{renderBranchVisitRecord()}
									</div>
								</div>
							): null}
						</div>
					</div>
					<div className="listViewRow-top-cell listViewRow-top-cell3">
						<span className="res-attendance">{booking.attendance}</span>
						<span>人</span>
					</div>
				</div>

				<div className="listViewRow-layer">
					<div className="listViewRow-top-cell listViewRow-top-cell4">
						{booking.source !== 'offline' && booking.space_group_selection_t !== '' ? (
							<div className="seatArea">
								<span>{booking.space_group_selection_t}</span>
							</div>
						) : null}
						<div>{renderSeatingBlock()}</div>

						<div className="toggleBlock">
							{renderServiceTagBlock()}
							{renderQuestionsBlock()}
							{renderMemo()}
							{renderShopMemo()}
						</div>
					</div>
					{renderShortCutBtn()}
				</div>
			</div>
			<div className="listViewRow-bottom">
				<div className="listViewRow-bottom-left">
					<p>
						<span className="toggleBtn toggleBtn-up" onClick={(e) => toggleList(e)} />
						<span>
							{booking.source_t} ｜
							<span className="bookingList_updateTime">
								{booking.updated_at !== booking.created_at ? (
									`最後更新: ${new moment(booking.updated_at).format('YYYY/MM/DD HH:mm')} ｜ `
								) : null}
							</span>
							建立: {new moment(booking.created_at).format('YYYY/MM/DD HH:mm')} ｜ 查詢代碼:{' '}
							{booking.active_code}
						</span>
					</p>
				</div>
				<div className="listViewRow-bottom-right">
					<div>
						{booking.status !== 'cancel' && booking.status !== 'no_show' && booking.status !== 'finish' ? (
							renderStatus()
						) : null}
						{booking.status !== 'cancel' && booking.status !== 'no_show' && booking.status !== 'finish' ? (
							<div className="rsv-bar" />
						) : null}
						<button
							className="rsv-editBtn"
							onClick={() => props.showNewAddBookingModal(booking, 'editor')}
						>
							<img src={require('../../../images/edit.svg')} alt="" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const Portal = ({ children }) => createPortal(children, document.getElementById('modalPortal'));

const ShortCutBtn = ({ booking, handleUpdateStatus }) => {
	const today = moment().format('YYYY/MM/DD');
	const pre = moment(new Date(booking.booking_datetime)).add(-60, 'm').format('YYYY/MM/DD HH:mm');
	const tomorrow = moment(new Date(today)).add(1, 'd').format('YYYY/MM/DD HH:mm');
	const [ hideStatusBtn, setHideStatusBtn ] = useState(true);
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);

	const findStatus = useCallback(
		() => {
			const currentTime = moment();

			if (currentTime.isBefore(new Date(pre))) {
				//當天時間>預約60分鐘
				// console.log('----預約>60分鐘----');
				setHideStatusBtn(true);
			} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
				//當天時間<=預約60分鐘
				// console.log('----預約前60分鐘----');
				setHideStatusBtn(false);
			}
		},
		[ pre, tomorrow ]
	);

	useInterval(() => {
		findStatus();
	}, 1000);

	const seatedWarning = useCallback(
		() => {
			BookingAPI.checkSeated(booking.id).then((data) => {
				if (data.overlay_table.length !== 0) {
					setShowWarning(true);
					setWarningTales(data.overlay_table);
				} else {
					handleUpdateStatus('seated');
				}
			});
		},
		[ booking, handleUpdateStatus ]
	);

	const cancelWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
	};

	const confirmWarning = () => {
		handleUpdateStatus('seated');
		setShowWarning(false);
		setWarningTales([]);
	};

	const statusBtnRes = statusBtn(booking, handleUpdateStatus, seatedWarning);

	const rednerBtn = useCallback(
		() => {
			if (!hideStatusBtn) {
				return statusBtnRes.stautsBtn;
			} else {
				return null;
			}
		},
		[ hideStatusBtn, statusBtnRes ]
	);

	return (
		<React.Fragment>
			{rednerBtn()}
			{showWarning && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title={seatAlertTitle}
					>
						<p>
							桌次
							{warningTales.map((table) => {
								return (
									<span key={table} className="tableName">
										{table}
									</span>
								);
							})}
							有尚未結束的預約
						</p>
						<p>是否確定入座？</p>
					</WarningTemplate>
				</Portal>
			)}
		</React.Fragment>
	);
};

const statusBtn = (booking, handleUpdateStatus, seatedWarning) => {
	let stautsBtn = null;
	const newStatusTime = JSON.parse(booking.status_time);

	switch (booking.status) {
		case 'confirmed':
			stautsBtn = (
				<div className="listViewRow-top-cell listViewRow-top-cell5">
					<div className="shortCutBtnReverse">
						<div className="btn showBtn" onClick={() => handleUpdateStatus('show')}>
							報到
						</div>
						<div className="btn seatedBtn" onClick={() => handleUpdateStatus('seated')}>
							入座
						</div>
					</div>
				</div>
			);
			break;
		case 'show':
			stautsBtn = (
				<div className="listViewRow-top-cell listViewRow-top-cell5">
					<div>
						<div className="btn seatedBtn" onClick={() => seatedWarning()}>
							入座
						</div>
						<p className="showTime">報到時間: {newStatusTime.show}</p>
					</div>
				</div>
			);
			break;
		case 'seated':
			stautsBtn = (
				<div className="listViewRow-top-cell listViewRow-top-cell5">
					<div>
						<div className="btn checkBtn" onClick={() => handleUpdateStatus('finish')}>
							結帳
						</div>
						<p className="showTime">入座時間: {newStatusTime.seated}</p>
					</div>
				</div>
			);

			break;
		case 'finish':
			stautsBtn = (
				<div className="listViewRow-top-cell listViewRow-top-cell5">
					<div className="btn doneBtn">
						{newStatusTime.finish}
						<span>完成消費</span>
					</div>
				</div>
			);
			break;
		case 'no_show':
			stautsBtn = (
				<div className="listViewRow-top-cell listViewRow-top-cell5">
					<div className="btn noShowBtnTxt">未到店</div>
				</div>
			);
			break;

		default:
			stautsBtn = null;
			break;
	}

	return {
		stautsBtn
	};
};

const BookingStatus = ({ handleUpdateStatus, booking, isToday, date }) => {
	const [ currentTime, setCurrentTime ] = useState(moment());
	const today = moment().format('YYYY/MM/DD');
	const pre = moment(new Date(booking.booking_datetime)).add(-60, 'm').format('YYYY/MM/DD HH:mm');
	const tomorrow = moment(new Date(today)).add(1, 'd').format('YYYY/MM/DD HH:mm');

	const [ delay, setDelay ] = useState(isToday ? 1000 : null);
	const [ bookingStatusBtn, setBookingStatusBtn ] = useState(3); //1: 前一天 2:當天時間>預約60分鐘 3:當天時間<=預約60分鐘
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);

	const findStatus = useCallback(
		() => {
			if (currentTime.isAfter(new Date(date), 'date')) {
				//過去: 前一天
				//待確認 確認預約 取消預約 完成預約
				//不顯示 finish show
				setBookingStatusBtn(1);
			} else if (currentTime.isBefore(new Date(pre))) {
				//當天時間>預約60分鐘
				//待確認 確認預約 已到店 取消預約
				setBookingStatusBtn(2);
			} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
				//當天時間<=預約60分鐘
				//待確認 確認預約 已到店 取消預約 未到店 已入座 完成消費
				setBookingStatusBtn(3);
			}
		},
		[ currentTime, date, pre, tomorrow ]
	);

	useEffect(
		() => {
			if (isToday) {
				setDelay(1000);
			} else {
				setDelay(null);
			}
			findStatus();
		},
		[ isToday, findStatus ]
	);

	useInterval(() => {
		setCurrentTime(moment());
	}, delay);

	const seatedWarning = useCallback(
		() => {
			BookingAPI.checkSeated(booking.id).then((data) => {
				if (data.overlay_table.length !== 0) {
					setShowWarning(true);
					setWarningTales(data.overlay_table);
				} else {
					handleUpdateStatus('seated');
				}
			});
		},
		[ booking, handleUpdateStatus ]
	);

	const cancelWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
	};

	const confirmWarning = () => {
		handleUpdateStatus('seated');
		setShowWarning(false);
		setWarningTales([]);
	};

	const renderStatus = () => {
		return (
			<ul className="dropdown-menu fix-dropdown-menu" aria-labelledby="status">
				{bookingStatus.map((t) => {
					if (bookingStatusBtn === 1) {
						if (t.status === 'show' || t.status === 'no_show' || t.status === 'seated') {
							return null;
						}
					} else if (bookingStatusBtn === 2) {
						if (t.status === 'no_show' || t.status === 'seated' || t.status === 'finish') {
							return null;
						}
					}

					if (t.status === 'seated') {
						return (
							<li key={t.status} className={`${t.status + 'Status'}`} onClick={() => seatedWarning()}>
								<span>{t.status_t}</span>
							</li>
						);
					} else {
						return (
							<li
								key={t.status}
								className={`${t.status + 'Status'}`}
								onClick={() => handleUpdateStatus(t.status)}
							>
								<span>{t.status_t}</span>
							</li>
						);
					}
				})}
			</ul>
		);
	};

	return (
		<React.Fragment>
			{renderStatus()}
			{showWarning && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title={seatAlertTitle}
					>
						<p>
							桌次
							{warningTales.map((table) => {
								return (
									<span key={table} className="tableName">
										{table}
									</span>
								);
							})}
							有尚未結束的預約
						</p>
						<p>是否確定入座？</p>
					</WarningTemplate>
				</Portal>
			)}
		</React.Fragment>
	);
};

function useInterval(callback, delay) {
	const savedCallback = React.useRef();

	// 保存新回调
	useEffect(() => {
		savedCallback.current = callback;
	});

	// 建立 interval
	useEffect(
		() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				return () => {
					clearInterval(id);
				};
			}
		},
		[ delay ]
	);
}
