import React from 'react'
import $ from 'jquery'
import { Link } from 'react-router'
var createReactClass = require('create-react-class');
export default createReactClass({
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  },
  renderTag() {
    return (
      this.props.data.customer_tags.map(function(tag) {
        return(
          <div className="menu-tag"
                key={tag.id}
                data-toggle="tooltip"
                data-placement="top"
                title={tag.description}>
            <img src={tag.image} alt="" />
          </div>
        );
      })
    );
  },
  renderIfNoteExists() {
    if(this.props.data.note!=='') {
      return (
        <div className="menu-tag" 
             data-toggle="tooltip"
             data-placement="top"
             title='備註'>
          <img src={require('../../images/tag_notes.png')} alt=""/>
        </div>
        )
    } else {
      return <div />
    }
  },
	render() {
	  return(
	    <tr>
        <td />
        <td className="c-name">
          <Link to={window.location.pathname + '/' + this.props.data.id}>{this.props.data.full_name}</Link>
        </td>
        <td className="cGender">
          {this.props.data.gender_display}
        </td>
        <td className="cPhone">
          {this.props.data.phone_number}
        </td>
        <td>
          { this.renderIfNoteExists() }
          { this.props.data.customer_tags.map(function(tag) {
              return (
                <div className="menu-tag"
                      key={tag.id}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={tag.description}>
                  <img src={tag.image} alt=""/>
                </div>
              );
            })
          }
        </td>
        <td>
          <Link to={window.location.pathname + '/' + this.props.data.id}
              className="btn btn-color-white btn-style3" 
              role="button">
            <i className="fa fa-info-circle" /> 顧客紀錄
          </Link>
        </td>
      </tr>
	  );
	}
});
