import React, { useEffect, useState, useCallback, useRef } from 'react';
import _ from 'lodash';

const TableInput = ({ groups, tables, addTable, removeAddTable }) => {
	const [ tableName, setTableName ] = useState('');
	const inputRef = useRef();

	const checkTable = useCallback(
		(e) => {
			if (e.key === 'Enter' || e === 'submit') {
				const flattenTableNumbers = _.flatten(groups.map((group) => group.table_numbers));
				const tablesNumber = tables.filter((t) => t.available === true).map((table) => table.table_number);
				const concatTableNumbers = _.concat(flattenTableNumbers, tablesNumber);

				const findIndex = concatTableNumbers.indexOf(tableName.trim());

				if (tableName.trim() === '') {
					return window.app.alert.setMessage('桌子名稱不可空白', 'error');
				}

				if (findIndex !== -1) {
					return window.app.alert.setMessage('桌子名稱重複', 'error');
				}

				addTable(tableName);
				setTableName('');
			}
		},
		[ groups, tables, tableName, addTable ]
	);

	useEffect(() => {
		if (inputRef.current) inputRef.current.focus();

		return () => {
			inputRef.current = null;
		};
	}, []);

	const remove = () => {
		setTableName('');
		removeAddTable();
	};

	return (
		<div className="tableInput">
			<div>
				<input
					ref={inputRef}
					autoFocus={true}
					className="newTableInput"
					type="text"
					value={tableName}
					onKeyUp={(e) => checkTable(e)}
					onChange={(e) => setTableName(e.target.value.trim())}
				/>
			</div>

			<button className="addBtn" name="submit" onClick={() => checkTable('submit')}>
				新增
			</button>
			<button className="cancelBtn" onClick={remove}>
				<i className="fa fa-times" aria-hidden="true" />
			</button>
		</div>
	);
};

export default TableInput;
