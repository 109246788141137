import React, { useEffect, useContext, Fragment }  from 'react';
import _ from 'lodash';
import { browserHistory } from "react-router";
import { Pagination, Select } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import CustomerList from './crmCustomer/CrmCustomerList';
import CrmFollower from './crmCustomer/CrmFollower';

import CustomerCreateModal from './crmCustomer/crmCustomerPopup/CrmCustomerEditorModal';

import { CustomerContext } from './crmContext/CustomerContext';

import '../../sass/crm_customers.scss';

import { getMenuUserInfo } from '../shops/menu_user/MenuUserApi';
import { getCrmCustomerPage, getCrmCustomerCount, searchCustomer, filterCustomers } from './api/CrmCustomerApi';
import { filterFollowers } from './api/CrmMessageMenuTalkApi';
import MenuCouponApi from '../menu_coupon/MenuCouponApi';
const menuCouponAPI = new MenuCouponApi();

const { Option } = Select;
const pageSize = [10, 20, 30, 40, 50];

const CrmCustomerBox = () => {
	const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
		loading,
		customerStatus,
		searchCustomerMode,
		filterCustomerMode,
    page,
    per,
    count,
		listPage,
		listCount,
		filterCustomersParams,
		searchCustomerText,
		showAddCustomerModal,
  } = CustomerState;

	useEffect(() => {
		const customerItem = document.getElementsByClassName('crmCustomerItem')[0];

		if(customerItem) {
			customerItem.scrollIntoView({
				behavior: 'smooth',
				block: 'end'
			});
		};
	}, [ listPage ]);

  useEffect(() => {
    let pageNum = _.cloneDeep(page);
		let pageSizeNum = _.cloneDeep(per);

		if(window.location.search) {
			window.location.search.split("&").forEach(
				function (q) {
					if (q.search("page") >= 0) {
						let p = parseInt(q.split("=").pop(), 10);
						if (p > 0) {
							pageNum = p;
							CustomerStateDispatch({ type: 'setListPage', page: p })
						}
					}

					if (q.search("per") >= 0) {
						let e = parseInt(q.split("=").pop(), 10);
						if (e > 0) {
							pageSizeNum = e;
							CustomerStateDispatch({ type: 'setListPageSize', per: e })
						}
					}
				}
			);
		} else {
			pageNum = 1
			pageSizeNum = 10;
			CustomerStateDispatch({ type: 'setListPage', page: pageNum })
			CustomerStateDispatch({ type: 'setListPageSize', per: pageSizeNum })
		}

    load(pageNum, pageSizeNum);
  },[ window.location.search ]);

	useEffect(() => {
		getMenuUserBindStatus();
		getShop();
		getFollowersLength();
	}, []);

	const getMenuUserBindStatus = async () => {
		try {
			const { data } = await getMenuUserInfo();

			if(data.menu_user.bind_menu) {
        CustomerStateDispatch({ type: 'getMenuUserBindStatus', menuUserBindStatus: true, menuUserAvatar: data.menu_user.thumbnailURL });
			} else {
        CustomerStateDispatch({ type: 'getMenuUserBindStatus', menuUserBindStatus: false, menuUserAvatar: '' });
			}
		} catch(err) {
			console.log('get menu user bind status err ----------', err);
      CustomerStateDispatch({ type: 'getMenuUserBindStatus', menuUserBindStatus: false, menuUserAvatar: '' });
		}
	};

	const getShop = async () => {
    try {
      const data = await menuCouponAPI.getShops();
      CustomerStateDispatch({
				type: 'getShopName',
				shopName: data.shop.display_name,
				shopLogo: data.shop.logo
			});
    } catch (err) {
      console.log('get shop name err-----', err);
    }
  };

	const getFollowersLength = async () => {
    try {
      const { data } = await filterFollowers();
      CustomerStateDispatch({ type: 'getFollowersLength', followers: data.followers.length })
    } catch(err) {
      console.log('get followers length err', err);
    }
  };

  const load = async (page, per) => {
		const params = { page, per };

		try {
			CustomerStateDispatch({ type: 'setLoading', loading: true })

			const { data } = await getCrmCustomerPage(params);
			const res = await getCrmCustomerCount();

			CustomerStateDispatch({
        type: 'setLoadCustomers',
        pageCustomers: data.customers,
        page_num: Math.ceil(data.meta.size / per)
      })

			CustomerStateDispatch({ type: 'setCustomerCount', count: res.data.count })
			CustomerStateDispatch({ type: 'setLoading', loading: false })

		} catch(err) {
			console.log('get customer err ----------', err)
		}
  };

  const pageClick = (page) => {
		const handleSearchListPage = async() => {
			const query = {
        query: searchCustomerText,
				page: page,
				per: 10
      };
			const { data } = await searchCustomer(query);
			CustomerStateDispatch({
				type: 'setSearchCustomerResult',
				searchCustomers: data.customers,
				searchCustomerMode: true,
				listPage: page,
				listCount: data.meta.size
			});
		};

		const handleFilterListPage = async() => {
			let newParams = Object.assign({}, filterCustomersParams);
			newParams.page = page;
			newParams.per = 10;
			const { data } = await filterCustomers(newParams);
      CustomerStateDispatch({
        type: 'setFilterCustomerResult',
        filterCustomers: data.customers,
        filterCustomerMode: true,
        listPage: page,
        listCount: data.meta.size
      });
		};

		if(searchCustomerMode) {
			return handleSearchListPage();
		};
		if(filterCustomerMode) {
			return handleFilterListPage();
		};

    CustomerStateDispatch({ type: 'setListPage', page })
    browserHistory.push(
      `${window.location.pathname}?page=${page}&per=${per}`
    );
  };

	const pageSizeClick = (per) => {
		CustomerStateDispatch({ type: 'setListPageSize', per })
		browserHistory.push(
      `${window.location.pathname}?page=${page}&per=${per}`
    );
	};

	const renderList = () => {
		if(loading) {
      return (
        <div className="crmCustomerLoading">
          <LoadingOutlined style={{ fontSize: 30 }} />
          <p>Loading ...</p>
        </div>
      );
    };

		if(customerStatus === 'customers') {
			const pages = () => {
				if(searchCustomerMode || filterCustomerMode) return listPage;
				return page;
			};

			const counts = () => {
				if(searchCustomerMode || filterCustomerMode) return listCount;
				return count;
			};

			const pagePer = () => {
				if(searchCustomerMode || filterCustomerMode) return 10;
				return per;
			};

			return (
				<Fragment>
					<CustomerList />
					<div className={`crmCustomerBox_footer ${searchCustomerMode ? 'footer-search' : ''} ${filterCustomerMode ? 'footer-filter' : ''}`}>
						{
							(!searchCustomerMode && !filterCustomerMode && count !== 0) 
							? <Select
									className='crm_page_select'
									bordered={false}
									value={per}
									onChange={(value) => pageSizeClick(value)}
								>
									{pageSize.map((option, index) => {
										return(
											<Option
												key={index}
												value={option}
											>
												{option} 筆/頁
											</Option>
										)
									})}
								</Select>
							: <div className='footer-mode-placeholder' />
						}
						{
							(
								(!searchCustomerMode && !filterCustomerMode && count !== 0) ||
								(searchCustomerMode && listCount !== 0) ||
								(filterCustomerMode && listCount !== 0)
							) &&
							<Pagination
								className="crm_page_picker"
								current={pages()}
								total={counts()}
								pageSize={pagePer()}
								showSizeChanger={false}
								showPrevNextJumpers={false}
								locale={{ items_per_page: "筆/頁" }}
								onChange={pageClick}
								itemRender={(_, type, originalElement) => {
									if (type === "prev") {
										return <span>上一頁</span>;
									}
									if (type === "next") {
										return <span>下一頁</span>;
									}
									return originalElement;
								}}
							/>
						}
						{
							(!searchCustomerMode && !filterCustomerMode && count !== 0) &&
							<div className='crmCustomer_count'>顧客總數：{count}</div>
						}
						{
							searchCustomerMode && listCount !== 0 &&
							<div className='crmCustomer_count'>搜尋結果：{listCount}</div>
						}
					</div>
				</Fragment>
			)
		} else {
			return <CrmFollower />
		}
	};

  return (
    <div className='crmCustomerBox_container'>
			{ renderList() }

			{ showAddCustomerModal && <CustomerCreateModal load={load} /> }
    </div>
  )
}

export default CrmCustomerBox;
