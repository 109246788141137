export const messageStatusTab = {
	sent: '寄件備份',
	draft: '草稿',
	scheduling: '排程訊息'
};

export const messageSortOption = {
	latest: '從新到舊',
	oldest: '從舊到新'
};

export const messageFilterOption = {
	all: '全部',
	sms: '簡訊',
	menuTalk: 'MENU 私訊'
};

export const customerStatusTab = {
	customers: '店＋顧客',
	followers: 'MENU粉絲'
};

export const customerSortOption = {
	latest: '從新到舊',
	oldest: '從舊到新'
};

export const bookingStatusTab = {
	all: '預約次數',
	online: '線上預約',
	offline: '自建預約',
	line_up: '排隊入座',
	no_show: '未到店',
	cancel: '取消預約'
}

export const queueStatusTab = {
	all: '全部',
	waiting: '等候中',
	seated: '已報到',
	canceled: '已取消',
	passed: '過號'
}

export const bookingRecordStatus = {
	effective: '有效預約',
	no_show: '未到店',
	cancel: '取消預約'
}

export const queueRecordStatus = {
	seated: '已報到',
	canceled: '已取消',
	passed: '過號',
	inactive: '失效'
}

export const weekDay = [ '星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六' ];

export const weekDay_s = [ '日', '一', '二', '三', '四', '五', '六' ];