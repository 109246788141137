import React from 'react'
import Record from './Record'
var createReactClass = require('create-react-class');
export default createReactClass({
  render() {
    var onEditClick = this.props.onEditClick;
    var records = this.props.data.map(function(record) {
      return (
        <Record key={record.id} 
                data={record} 
                onEditClick={onEditClick} />
      )
    });
    return (
      <div className="menu-section">
        <div className="menu-panel">
          <table className="table table-hover table-style">
            <thead>
              <tr>
                <th colSpan={3}>紀錄</th>
              </tr>
            </thead>
            <tbody>
              {records}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
});

