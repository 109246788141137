/* eslint-disable */
import React, { useEffect } from "react";
import { Link } from "react-router";

import $ from "jquery";
import PageNav from './PageNav';
import PageFooter from './PageFooter';
import PageSlogan from './PageSlogan';
import ScrollReveal from "../../libs/vendor/scrollreveal.min";
import { SVGSource, SVG } from './Img'

import '../../sass/pageIndex.scss';

const PageIndex = () => {
  useEffect(() => {
    $(document).ready(() => {
      $(".nonloop").owlCarousel({
        center: false,
        items: 2,
        autoWidth: true,
        loop: false,
        dots: true,
        margin: 0,
        responsive: {
          1024: {
            items: 3,
            dots: true,
            autoWidth: false
          }
        }
      });
    });

    window.sr = ScrollReveal();
    sr.reveal(".section_booking .s_title", {
      distance: "50px",
      origin: "bottom",
      duration: 500,
      delay: 300
    });

    sr.reveal("#ipad_1", {
      scale: 0.9,
      opacity: 0,
      distance: "100px",
      origin: "right",
      duration: 500,
      delay: 500
    });

    sr.reveal(".section_feature .s4_box .main_img", {
      scale: 1,
      opacity: 0,
      distance: "100px",
      origin: "right",
      duration: 400,
      delay: 200
    });

    sr.reveal(
      ".section_use .content_block",
      {
        scale: 0.9,
        opacity: 0,
        distance: "20px",
        origin: "right",
        duration: 500,
        delay: 300
      },
      200
    );

    sr.reveal(
      ".section_pricing .feature_system",
      {
        scale: 0.9,
        opacity: 0,
        distance: "20px",
        origin: "top",
        duration: 500,
        delay: 300
      },
      200
    );

    $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $("html, body").animate(
              {
                scrollTop: target.offset().top
              },
              1000,
              function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                  // Checking if the target was focused
                  return false;
                } else {
                  $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              }
            );
          }
        }
      })
  }, [])

  useEffect(() => {
    scrollToAnchor();
  }, []);

  const scrollToAnchor = () => {
    const { hash } = window.location;

    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="pageIndex">
      <SVGSource />
      <PageNav />

      <div className="section_header">
        <div className="container">
          <div className="s_title">
            店家大小事
            <br />
            交給
            <img src={require("../../images/pageIdx/logo2.png")} />
          </div>
          <div className="s_desc">
            專為店家設計的線上管理系統
            <br />
            解決預約、顧客紀錄和現場帶位問題
          </div>
          <div className="s_action">
          <Link to="/contact">
            <button type="button" className="btn_primary">立即試用</button>
          </Link>
          <Link to="/case">
            <button type="button" className="btn_secondary">應用場景</button>
          </Link>
          </div>
        </div>
      </div>

      <div className="section_slogan">
        協助您輕鬆管理店家大小事，
        <br />
        是我們最在意的事。
      </div>

      <div className="section_booking">
        <div className="bg">
          <div className="container">
            <div className="s_title">
              從預約訂位到社群曝光，
              <br />
              交給專業。
            </div>
            <img id="ipad_1" src="https://image.ibb.co/mxbf9b/Bitmap.png" />
          </div>
        </div>
      </div>

      <div id="feature" className="section_feature">
        <div className="container">
          <div className="row row_booking">
            <div className="col-sm-6">
              <div className="s4_box">
                <img
                  className="holder"
                  src={require("../../images/pageIdx/1x1.png")}
                />
                <div className="img_container">
                  <img
                    className="main_img"
                    src={require("../../images/pageIdx/booking_and_google_reservation.gif")}
                    style={{ height: "81%" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="s4_box2">
                <img
                  className="holder"
                  src={require("../../images/pageIdx/1x1.png")}
                />
                <div className="content">
                  <div className="box_title">預約系統</div>
                  <div className="box_desc">
                    除了專屬預約網頁，我們也提供透過<span>Google預訂功能</span>，隨時隨地輕鬆預約，節省人力與時間成本。
                  </div>
                  <div className="box_desc">
                    <div className="feature_link">
                      <Link to="/booking_system">
                        了解詳情<span className="icon_arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row_crm">
            <div className="col-sm-6">
              <div className="s4_box2">
                <img
                  className="holder"
                  src={require("../../images/pageIdx/1x1.png")}
                />
                <div className="content">
                  <div className="box_title">顧客管理系統</div>
                  <div className="box_desc">
                    輕鬆記錄顧客喜好，了解你的回訪顧客，
                    <br />
                    預約 No show、黑名單皆可一併記錄。
                    <div className="feature_link">
                      <Link to="/crm_system">
                        了解詳情<span className="icon_arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="s4_box">
                <img
                  className="holder"
                  src={require("../../images/pageIdx/1x1.png")}
                />
                <div className="img_container">
                  <img
                    className="main_img"
                    src={require("../../images/pageIdx/s42.svg")}
                    style={{ height: "65%" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row row_menu">
            <div className="col-sm-6">
              <div className="s4_box">
                <img
                  className="holder"
                  src={require("../../images/pageIdx/1x1.png")}
                />
                <div className="img_container">
                  <img
                    className="main_img"
                    src={require("../../images/pageIdx/s43.jpg")}
                    style={{ height: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="s4_box2">
                <img
                  className="holder"
                  src={require("../../images/pageIdx/1x1.png")}
                />
                <div className="content">
                  <div className="box_title">MENU美食誌</div>
                  <div className="box_desc">
                    黏著度超群的美食社群APP，
                    <br />
                    免費建立官方帳號，發布店家消息與特色菜單。
                    <div className="feature_link">
                      <Link to="/menutaiwan">
                        了解詳情<span className="icon_arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_use container_center">
        <div>
          <h1>
            不只餐廳，<br />
            也可以靈活應用在不同產業
          </h1>
          <div className="content">
            <div className="content_block">
              <div className="content_img">
                <img src={require('../../images/pageIdx/use_queue.png')} alt="" />
              </div>
              <div className="c_title">現場候位叫號</div>
              <div className="c_content">一鍵登記候位，輕鬆管理現場秩序，<br />提升顧客體驗</div>
              <Link to="/queue_system">
                了解詳情<span className="icon_arrow" />
              </Link>
            </div>
            <div className="content_block">
              <div className="content_img">
                <img src={require('../../images/pageIdx/use_marketing.png')} alt="" />
              </div>
              <div className="c_title">輕鬆行銷推播</div>
              <div className="c_content">結合社群，提供優惠券、簡訊、私訊推廣功能</div>
              <Link to="/menu_coupon">
                了解詳情<span className="icon_arrow" />
              </Link>
            </div>
            <div className="content_block">
              <div className="content_img">
                <img src={require('../../images/pageIdx/use_login.png')} alt="" />
              </div>
              <div className="c_title">免額外安裝設備</div>
              <div className="c_content">網頁式管理系統，只需一台行動裝置就可輕鬆登入</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_comment">
        <h1>合作夥伴好評推薦</h1>
        <div className="comment nonloop owl-carousel owl-theme">
          <div className="comment_block item">
            <div className="comment_block_info">
              <div className="shopName">赤富士日式燒肉</div>
              <div className="name">周老闆娘</div>
            </div>
            <div className="comment_block_content content_overflow">
              <div>
                翻遍各種訂位系統商後終於讓我遇見了Menu店+！不但擁有超高CP值，直覺式的操作讓員工更易上手，省去人力成本也大幅降低手寫訂位的出錯率；客人也能方便找到自己想要預約的時間，不需在電話中反覆詢問，增加了客人的來店意願。每月花小額費用就能擁有如此方便的系統，感謝MENU店+ 拯救了我們接電話接到快哭的員工，也方便我們經營者可以遠端即時了解來客量及店內狀況，更重要的是能夠使店內業績更好！
              </div>
            </div>
          </div>
          <div className="comment_block item">
            <div className="comment_block_info">
              <div className="shopName">黑RURU CAFE</div>
              <div className="name">行銷王專員</div>
            </div>
            <div className="comment_block_content">
              在使用上很容易上手，且業務人員也會從旁協助教導，從確定建立時間至開始使用不到一週，能幫我們快速解決網路訂位問題，後台與前台界面都非常清晰，透過線上教學讓現場服務人員也能快速熟練系統，在設定上也很符合需求，是一個完善的訂位系統。
            </div>
          </div>
          <div className="comment_block item">
            <div className="comment_block_info">
              <div className="shopName">R9 Café</div>
              <div className="name">楊店長</div>
            </div>
            <div className="comment_block_content">
              餐廳一直都有在用線上訂位系統，但原本只由老闆一個人操作，現場人員有時候忙的時候還是用手寫相關的預約記錄。現在統一使用線上訂位系統，覺得很方便，不管是對客人或是對我們員工來說，都節省了不少時間！
            </div>
          </div>
        </div>
      </div>

      <div id="pricing" className="section_pricing">
        <div className="container">
          <div className="s_title">
            不限顧客組數！<br />
            用越多，賺越多！
          </div>
          <div className="block">
            <div className="box_price">
              <div className="content">
                <div className="title">新戶限時半價</div>
                <div className="price">
                  <span className="price_discount">600</span>
                  元/月
                  <span className="price_original">原價1200元</span>
                </div>
                <hr />
                <ul>
                  <li><span className="icon_feat icon_check"/>預約組數無上限</li>
                  <li><span className="icon_feat icon_check"/>開通所有功能，無需加購</li>
                  <li><span className="icon_feat icon_check"/>無硬體要求，免設定費</li>
                  <li><span className="icon_feat icon_check"/>專人一對一教學快速上線</li>
                </ul>
                <Link to="/contact">
                  <button type="button" className="btn_primary">申請免費試用</button>
                </Link>
              </div>
              <div className="note">
                <ol>
                  <li>此為一年期方案</li>
                  <li>於 2024/10/31 前完成簽約，享整年優惠價</li>
                </ol>
              </div>
            </div>
            <div className="feature_system">
              <div className="ss_title">MENU店+ 特色功能</div>
              <div className="list">
                <ul className="feature_system_left">
                  <li><span className="icon_feat icon_booking" />線上預約、排隊系統</li>
                  <li><span className="icon_feat icon_money" />線上預收訂金</li>
                  <li><span className="icon_feat icon_google_reservation" />透過Google地圖預約</li>
                  <li><span className="icon_feat icon_line" />免費LINE通知</li>
                </ul>
                <ul className="feature_system_right">
                  <li><span className="icon_feat icon_crm" />顧客關係管理</li>
                  <li><span className="icon_feat icon_block" />奧客電話封鎖</li>
                  <li><span className="icon_feat icon_coupon" />電子優惠券</li>
                  <li><span className="icon_feat icon_note" />內部公告管理</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PageSlogan />
      <PageFooter />
    </div>
  );
}

export default PageIndex;