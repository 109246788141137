import React from 'react';
import { Modal } from 'antd';
import { updateCrmBlockedList } from '../../api/CrmSettingsApi';

function BlockActionModal({
  phone_number,
  isBlocked,
  setIsBlocked,
  load,
  setShowBlockActionModal
}) {
  let title = isBlocked ? '確定解除封鎖嗎？' : '確定加入封鎖名單嗎？';
  let content = isBlocked 
  ? '解除後擁有此組號碼的顧客都將同時被解除封鎖。確定解除封鎖嗎？'
  : '被封鎖的號碼將無法進行線上預約，同時擁有此組號碼的顧客都將一併被封鎖。確定封鎖嗎？';

  const handleSubmit = async() => {
    try {
      await updateCrmBlockedList({
        phone_numbers: [phone_number],
        operation: isBlocked ? 'delete' : 'add'
      })

      if(isBlocked) {
        setIsBlocked(false);
        window.app.alert.setMessage('已將號碼從封鎖名單中移除', 'done');
      } else {
        setIsBlocked(true);
        window.app.alert.setMessage('已將號碼加入封鎖名單', 'done');
      }
  
      setShowBlockActionModal(false);
      load();
    } catch(error) {
      console.log('customer info update blocked status error', error);
      window.app.alert.setMessage('請稍後再試', 'error');
    }
  };

  return (
    <Modal
      title={title}
      visible
      centered
      closable={false}
      className="confirmBlockedCustomerModal"
      footer={
        <React.Fragment>
          <button
            className='btn_cancel'
            onClick={() => setShowBlockActionModal(false)}
          >
            取消
          </button>
          <button
            className='btn_confirm'
            onClick={handleSubmit}
          >
            確認
          </button>
        </React.Fragment>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      {content}
    </Modal>
  );
}

export default BlockActionModal