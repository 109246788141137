import React, { Fragment, useContext } from 'react';
import { browserHistory } from 'react-router';
import { Modal } from 'antd';

import { MessageContext } from '../../crmContext/MessageContext';
import { MessageAddStateContext } from '../../crmContext/MessageAddContext';

const ReturnCheckModal = () => {
  const { MessageStateDispatch } = useContext(MessageContext);
  const { MessageAddDispatch } = useContext(MessageAddStateContext);

  const returnToMsgList = () => {
    browserHistory.push({
      pathname: '/dashboard/messages'
    });

    MessageStateDispatch({ type: 'switchMode', mode: 'messageList', editType: ''});
    MessageAddDispatch({ type: 'setIsSaved', isSaved: true });
    MessageAddDispatch({ type: 'cancelPopup' });
    MessageAddDispatch({ type: 'cleanMessageSetting' });
  };

  const cancelPopup = () => {
    MessageAddDispatch({ type: 'cancelPopup' });
  };

  return (
    <Modal
      title="確定退出嗎？"
      visible
      centered
      className="returnCheckModal"
      onCancel={cancelPopup}
      footer={
        <Fragment>
          <button
            className='btn_cancel'
            onClick={() => cancelPopup()}
          >
            取消
          </button>
          <button
            className='btn_confirm'
            onClick={() => returnToMsgList()}
          >
            確認
          </button>
        </Fragment>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      退出將放棄傳送訊息，確定退出嗎？
    </Modal>
  );
};

export default ReturnCheckModal;