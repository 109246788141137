import React, { Fragment, useCallback, useEffect, useContext }  from 'react';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';

import { MessageContext } from '../../crmContext/MessageContext';
import { MessageAddStateContext } from '../../crmContext/MessageAddContext';

import BuyMsgPointsModal from './crmMessageAddPopup/BuyMsgPointsModal';
import Loading from '../crmMessageAdd/CrmSubmitLoading';

import { createCrmSms, updateCrmSms } from '../../api/CrmMessageSmsApi';

const CrmConfirmMessage = () => {
  const { MessageStateDispatch } = useContext(MessageContext);
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const {
    isLoading,
    points,
    allCustomers,
    selectCustomer,
    messageSetting,
    showBuyMsgPointsModal
  } = MessageAddState;
  const {
    id,
    status,
    title,
    content,
    scheduleTime,
    filterTag,
    customers,
    filterConditions,
    oneSmsPoint,
    estimatedMsgPoints
  } = messageSetting;
  const {
    dateAfter,
    dateBefore,
    bookingMin,
    bookingMax,
    gender,
    tags
  } = filterConditions;

  const switchMode = useCallback(() => {
		MessageStateDispatch({ type: 'switchMode', mode: 'addMessage', editType: 'sms' });
	}, [ MessageStateDispatch ]);

  const saveDraft = async () => {
    const crmSmsContent = {
      title: title.trim() !== '' ? title.trim() : '未命名的簡訊',
      content: content.trim(),
      filter_tag: filterTag,
      schedule_time: scheduleTime,
      customers: customers,
      status: 'draft',
      filter_conditions: {
        date_after: dateAfter ? dateAfter : null,
        date_before: dateBefore ? dateBefore : null,
        booking_min: bookingMin ? bookingMin : null,
        booking_max: bookingMax ? bookingMax : null,
        gender: gender ? gender : null,
        customer_tags: tags.length !== 0 ? tags : null
      },
      estimated_msg_points: estimatedMsgPoints
    };

    if(id === null) {
      try {
        await createCrmSms(crmSmsContent);
  
        // 儲存進入草稿
        MessageAddDispatch({ type: 'cleanMessageSetting' });
        MessageStateDispatch({ type: 'switchMode', mode: 'messageList' });
        MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: 'sent' });
        MessageStateDispatch({ type: 'setMessageNotification', notificationType: 'draft', msgType: 'sms' });
      } catch(err) {
        console.log('save crm sms message to draft err', err);
      }
    } else {
      try {
        await updateCrmSms(id, crmSmsContent);
  
        // 儲存進入草稿
        MessageAddDispatch({ type: 'cleanMessageSetting' });
        MessageStateDispatch({ type: 'switchMode', mode: 'messageList' });
        MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: 'sent' });
        MessageStateDispatch({ type: 'setMessageNotification', notificationType: 'draft', msgType: 'sms' });
      } catch(err) {
        console.log('save crm sms message to draft err', err);
      }
    }
  };

  const submit = async () => {
    MessageAddDispatch({ type: 'setIsLoading', isLoading: true });

    const crmSmsContent = {
      title: title.trim(),
      content: content.trim(),
      filter_tag: filterTag,
      schedule_time: scheduleTime,
      customers: customers,
      status: status,
      filter_conditions: {
        date_after: dateAfter ? dateAfter : null,
        date_before: dateBefore ? dateBefore : null,
        booking_min: bookingMin ? bookingMin : null,
        booking_max: bookingMax ? bookingMax : null,
        gender: gender ? gender : null,
        customer_tags: tags.length !== 0 ? tags : null
      },
      estimated_msg_points: estimatedMsgPoints
    };

    if(estimatedMsgPoints > points) {
      return MessageAddDispatch({ type: 'showBuyMsgPointsModal', buyMsgPointsType: 'notEnough' });
    };

    if(id === null) {
      try {
        await createCrmSms(crmSmsContent);

        MessageAddDispatch({ type: 'setIsLoading', isLoading: false });

        // 儲存進入排程
        if(status === 'real_time') {
          MessageStateDispatch({ type: 'setMessageNotification', notificationType: 'sent', msgType: 'sms' });
        } else if(status === 'scheduled') {
          MessageStateDispatch({ type: 'setMessageNotification', notificationType: 'scheduling', msgType: 'sms' });
        }
        MessageAddDispatch({ type: 'cleanMessageSetting' });
        MessageStateDispatch({ type: 'switchMode', mode: 'messageList' });
        MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: 'sent' });
      } catch(err) {
        console.log('create crm sms message err', err);

        MessageAddDispatch({ type: 'setIsLoading', isLoading: false });
        window.app.alert.setMessage('請稍後再試', 'error');
      }
    } else {
      try {
        await updateCrmSms(id, crmSmsContent);
  
        MessageAddDispatch({ type: 'setIsLoading', isLoading: false });

        // 儲存進入排程
        if(status === 'real_time') {
          MessageStateDispatch({ type: 'setMessageNotification', notificationType: 'sent', msgType: 'sms' });
        } else if(status === 'scheduled') {
          MessageStateDispatch({ type: 'setMessageNotification', notificationType: 'scheduling', msgType: 'sms' });
        }
        MessageAddDispatch({ type: 'cleanMessageSetting' });
        MessageStateDispatch({ type: 'switchMode', mode: 'messageList' });
        MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: 'sent' });
      } catch(err) {
        console.log('update crm sms message err', err);

        MessageAddDispatch({ type: 'setIsLoading', isLoading: false });
        window.app.alert.setMessage('請稍後再試', 'error');
      }
    }
  };

  const buyMsgPoints = () => {
    MessageAddDispatch({ type: 'showBuyMsgPointsModal', buyMsgPointsType: 'buyPoints' });
  };

  const renderMessageInfo = () => {
    let contentLength = content.trim().length;

    return (
      <Fragment>
        <h5>標題</h5>
        <div className="text" style={{ marginBottom: '24px' }}>{ title }</div>
        <h5>內文</h5>
        <div
          className="text"
          style={{ marginBottom: '24px', whiteSpace: 'pre-line' }}
        >
          { content }
        </div>
        <div className="count">{contentLength} 字</div>
      </Fragment>
    )
  };

  const renderSendObject = () => {
    let customer = '';

    if(allCustomers === selectCustomer) {
      customer = '全部顧客';
    } else {
      filterTag === '' ? customer = `篩選顧客` : customer = `篩選顧客 (${filterTag})`
    };

    return (
      <Fragment>
        <h5>傳送對象</h5>
        <div className="sendObject">
          <div className="text">{ customer }</div>
          <div className="text">{ customers.length }名</div>
        </div>
      </Fragment>
    )
  };

  const renderScheduleTime = () => {
    const newScheduleTime = moment(new Date(scheduleTime)).format('YYYY年MM月DD日 HH:mm');

    if(status === 'scheduled') {
      return (
        <Fragment>
          <h5>傳送時間</h5>
          <div className="text">{ newScheduleTime }</div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <h5>傳送時間</h5>
          <div className="text">立即傳送</div>
        </Fragment>
      )
    }
  };

  const renderSendDetail = () => {
    return (
      <Fragment>
        <h4>傳送詳情</h4>
        <div className="detail_text" style={{ marginBottom: '8px' }}>
          <div className="text">1封簡訊</div>
          <div className="number">{oneSmsPoint}點</div>
        </div>
        <div className="detail_text">
          <div className="text">傳送顧客</div>
          <div className="number">{customers.length}名</div>
        </div>
        <hr />
        <div className="detail_sum">
          <div className="text">預計扣點</div>
          <div className="number">{estimatedMsgPoints}點</div>
        </div>
        <div className="notice" style={{ marginTop: '16px', marginBottom: '24px' }}>
          總扣點額度將依最終成功發送簡訊數量為準。
        </div>
        <button className="btn_send" onClick={() => submit()}>
          <div className="send_icon"></div>
          確定傳送
        </button>
        <div className="notice">將在指定時間為您發送簡訊。</div>
        <button  className="btn_draft" onClick={() => saveDraft()}>
          <div className="draft_icon"></div>
          儲存草稿
        </button>
      </Fragment>
    )
  };

  const renderPoint = () => {
    return (
      <Fragment>
        <h4>您目前的點數</h4>
        <div className="point_num">{points}點</div>
        <button 
          className="btn_buyPoint"
          onClick={() => buyMsgPoints()}
        >
          購買簡訊點數
        </button>
        <div className="notice">購買足額點數即可完成傳送簡訊。</div>
      </Fragment>
    )
  };
  
  return (
    <Fragment>
      <div className="return_bar" onClick={() => switchMode()}>
        <LeftOutlined />
        返回編輯
      </div>
      <div className="crmConfirmMessage_main">
        <div
          className="return"
        >
          <div onClick={() => switchMode()}>
            <LeftOutlined />
            返回編輯
          </div>
        </div>

        <div className="content">
          <div className="block message">
            <h3>
              <img src={require('../../../../images/crm/sendMessage_test_icon.svg')} alt='' />
              您將發送的簡訊
            </h3>
            { renderMessageInfo() }
            <hr />
            { renderSendObject() }
            <hr />
            { renderScheduleTime() }
          </div>

          <div className="detail">
            <div className="block send">
              { renderSendDetail() }
            </div>
            <div className="block point">
              { renderPoint() }
            </div>
          </div>
        </div>

        { isLoading && <Loading /> }
        { showBuyMsgPointsModal ? <BuyMsgPointsModal /> : null }
      </div>
    </Fragment>
  )
}

export default CrmConfirmMessage;
