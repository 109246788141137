import React, { useState, useEffect, useRef } from "react";
import { browserHistory } from "react-router";
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons'

import { weekDay } from '../../utils/data';

const queueStatus = {
  waiting: '等候中',
  seated: '已報到',
  canceled: '已經取消', // 已取消
  passed: '過號',
  inactive: '失效'
};

export const CrmCustomerBookingRecord = ({ data, customQ }) => {
  const toList = () => {
    let date = moment(new Date(data.date)).format("YYYY-MM-DD");
    let activeCode = data.active_code;
    let status = data.status;

    browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
        date: date,
        mode: "list"
      },
      state:{
        activeCode: activeCode
      }
    })
    
    setTimeout(() => {
      document.getElementById(status).click();
    }, 300);
  };

  const handleContentExpend = () => {
    const card = document.getElementsByClassName(`${'bookingRecordCard_' + data.active_code}`)[0];

    card.classList.toggle('open');
  };

  const renderDate = () => {
    let day = new Date(data.booking_datetime).getDay();
    let date = moment(data.booking_datetime).format('YYYY年MM月DD日')

    return (
			<div className="date">{date}<span>{weekDay[day]}</span></div>
    )
  };

  const renderTime = () => {
    let bookingTime = moment(data.booking_datetime);
    let bookingTimeFormat = bookingTime.format('HH:mm');
    let diningTime = bookingTime.add(data.dining_time, 'minutes');
    let diningTimeFormat = diningTime.format('HH:mm');

    return (
      <div className="time">
        <div>
          {bookingTimeFormat}
          <span className='time_divider'>-</span>
          {diningTimeFormat}
        </div>
        {(data.status === 'no_show' || data.status === 'cancel') &&
          <span className={`booking_status ${data.status}`}>{data.status_t}</span>
        }
      </div>
    )
  };

  const renderSpaceGroup = () => {
    if(data.source === 'online' && data.space_group_selection_t !== '') {
      return <div className="seatingBlock">{data.space_group_selection_t}</div>
    } 
  };

  const renderSeatingBlock = () => {
		return JSON.parse(data.seating_json).map((s, i) => {
			return (
				<span className="seatingBlock" key={i}>
					{s.table_number}
				</span>
			);
		});
	};

  const renderServiceTagBlock = () => {
		let serviceTagBlock = [],
			quantityBlock = [];
		let serviceMark = {};

		const existQuestion = customQ.map((q) => q.id.toString());

		try {
			serviceMark = JSON.parse(data['customized_questions']);
		} catch (e) {
			serviceMark = {};
		}

		const keys = Object.keys(serviceMark);

		customQ.forEach((q) => {
			keys.forEach((el) => {
				if (
					q['id'].toString() === el &&
					typeof serviceMark[el] === 'object' &&
					existQuestion.indexOf(el) !== -1
				) {
					let content = JSON.parse(q.content);
					let ansObj = serviceMark[el];

					Object.keys(ansObj).forEach((index) => {
						let optionAns = {};
						if (content[index] && ansObj[index] === -1) {
							serviceTagBlock.push(content[index].name);
						} else if (content[index] && ansObj[index] !== -1 && ansObj[index] !== 0) {
							optionAns['name'] = content[index].name;
							optionAns['value'] = ansObj[index];
							quantityBlock.push(optionAns);
						}
					});
				}
			});
		});

		return (
			<div>
				{serviceTagBlock.map((ele, index) => (
					<span className="serviceTagBlock" key={index}>
						{ele}
					</span>
				))}
				{quantityBlock.map((ele, index) => (
					<span className="serviceTagBlock" key={index}>
						{ele.name}x{ele.value}
					</span>
				))}
			</div>
		);
	};

	const renderQuestionsBlock = () => {
		let question = {};
		let keys = [];
		let questionsBlock = [];
		const existQuestion = customQ.map((q) => q.id.toString());

		try {
			question = JSON.parse(data['customized_questions']);
		} catch (e) {
			question = {};
		}

		keys = Object.keys(question);

		customQ.forEach((q) => {
			keys.forEach((el) => {
				if (
					q['id'].toString() === el &&
					q['question_type'] === 'question' &&
					existQuestion.indexOf(el) !== -1 &&
					question[el] !== ''
				) {
					questionsBlock.push(question[q.id]);
				}
			});
		});

		if (questionsBlock.length !== 0) {
			return (
				<ol className="res-questionBlock">
					{questionsBlock.map((ele, index) => {
            if(ele.length > 0) return <li key={index}>{ele}</li>;
						// return <li key={index}>{ele}</li>;
					})}
				</ol>
			);
		}
	};

  const renderCustomerMemoBlock = () => {
    if(data.memo) {
      return `${data.memo}`;
    } else {
      return '無';
    }
  };

  const renderShopMemoBlock = () => {
    if(data.shop_memo) {
      return `${data.shop_memo}`;
    } else {
      return '無';
    }
  };

  const renderDepositBlock = () => {
    if(data.deposit_mode && data.deposit && data.deposit !== 0) {
      return `預付款項：${data.deposit}元`;
    } else {
      return '無預付款項';
    }
  };

	const renderBookingSource = () => {
		if (data.source === 'online') {
			if (data.source_url_t === 'google') {
				return <span className="bookingSourceIcon bookingSourceIcon-google" />;
			} else if (data.source_url_t === 'facebook') {
				return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
			} else if (data.source_url_t === 'instagram') {
				return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
			} else if (data.source_url_t === 'menuApp') {
				return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
			} else if (data.source_url_t === 'other') {
				return <span className="bookingSourceIcon bookingSourceIcon-other" />;
			} else if (data.source_url_t === 'google_reservation') {
				return <span className="bookingSourceIcon bookingSourceIcon-other" />;
			} 
		}
	};

  return (
    <div
      key={data.id}
      className={`bookingRecordCard ${'bookingRecordCard_' + data.active_code}`}
    >
      <div className="header" onClick={() => handleContentExpend()}>
        <div className="dateTime">
          {renderDate()}
          {renderTime()}
        </div>
        <div className="source_m">
          <div className="attendance">
            <span>{data.attendance}</span>
            <div className="account_icon" />
          </div>
          <div className="status">
            {data.source_t}預約
            {renderBookingSource()}
          </div>
        </div>
        <div className="check" onClick={() => toList()}><span>至預約系統</span>查看</div>
        <div className="arrow" />
      </div>
      <div className="content">
        <div className="question">
          {renderSpaceGroup()}
          {renderSeatingBlock()}

          {/* 訂金 */}
          <div className="deposit">
            <div className="deposit_tag">
              <img src={require('../../../../images/crm/money_icon.svg')} alt="" />
            </div>
            <div className="deposit_content">
              {renderDepositBlock()}
            </div>
          </div>

          {/* 問題 */}
          {renderServiceTagBlock()}
          {renderQuestionsBlock()}
        </div>
        <div className="memo">
        <div className="note">
          <div className="note_tag">顧客備註</div>
            <div className="note_content">
              {renderCustomerMemoBlock()}
            </div>
          </div>
          <div className="note">
            <div className="note_tag">商家備註</div>
            <div className="note_content">
              {renderShopMemoBlock()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CrmCustomerQueueRecord = ({ data }) => {
  const statusStyle = Object.keys(queueStatus).find(key => queueStatus[key] === data.status_t);

  const handleContentExpend = () => {
    const card = document.getElementsByClassName(`${'queueRecordCard_' + data.id}`)[0];

    card.classList.toggle('open');
  };

  const renderDate = () => {
    let day = new Date(data.created_at).getDay();
    let date = moment(data.created_at).format('YYYY年MM月DD日')

    return (
      <div className="date">{date}<span>{weekDay[day]}</span></div>
    )
  };

  const renderTime = () => {
    let queueTime = moment(data.created_at).format('HH:mm');

    return (
      <div className="time">
        <div>
          {queueTime}
          <span className="text">登記排隊</span>
        </div>
        <div className={`status ${statusStyle}`}>{data.status_t}</div>
      </div>
    )
  };

  const renderWaitingTime = (status) => {
    const startTime = moment(data.created_at);
    const endTime = moment(data.updated_at);
    const minutesDiff = endTime.diff(startTime, 'minutes');

    if(status === queueStatus['waiting']) {
      return `${moment().diff(startTime, 'minutes')}`;
    } else {
      return minutesDiff;
    }
  };

  return (
    <div
      key={data.id}
      className={`queueRecordCard ${'queueRecordCard_' + data.id}`}
    >
      <div className="header" onClick={() => handleContentExpend()}>
        <div className="dateTime">
          {renderDate()}
          {renderTime()}
        </div>
        <div className="group_block">
          <div className="attendance">
            <span>{data.people_count}</span>
            <div className="account_icon" />
          </div>
          <div className="group">{data.group_name}</div>
        </div>
        <div className="arrow" />
      </div>
      <div className="content">
        <div className="waiting_number">
          <span>{data.source_t}</span>{data.waiting_number}號
        </div>
        <div className="waiting_time">
          等待時間
          <span>
            {renderWaitingTime(data.status_t)}分鐘
          </span>
        </div>
        <div className="memo">
          <div className="tag">顧客備註</div>
          <div>{data.memo ? data.memo : '無'}</div>
        </div>
        <div className="memo">
          <div className="tag">店家備註</div>
          <div>{data.shop_memo ? data.shop_memo : '無'}</div>
        </div>
      </div>
    </div>
  )
}

export const CrmCustomerRecord = ({ data, setShowRecordEditorModal, setSelectRecord }) => {
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ isOverflown, setIsOverflown ] = useState(false);
  const textRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const { current: textElement } = textRef;

      if (textElement) {
        const isOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight || data.value.includes('\n');

        setIsOverflown(isOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [ data.value ]);

  const handleContentExpend = () => {
    const card = document.getElementsByClassName(`${'customerRecordCard_' + data.created_at}`)[0];

    card.classList.toggle('open');
    setIsExpanded(true);
  };

  const renderDate = () => {
    let day = new Date(data.created_at).getDay();
    let date = moment(data.created_at).format('YYYY年MM月DD日')

    return (
			<div className="date">{date}<span>{weekDay[day]}</span></div>
    )
  };

  const renderContent = () => {
    if(isExpanded) {
      return data.value;
    } else {
      let dot = isOverflown && data.value.includes('\n') ? '...' : ''
      return data.value.split('\n')[0] + dot;
    }
  }

  const handleEditCustomer = () => {
    setShowRecordEditorModal(true);
    setSelectRecord(data);
  };

  return (
    <div
      key={data.created_at} // 待修正
      className={`customerRecordCard ${'customerRecordCard_' + data.created_at}`}
    >
      <div className="header">
        <div className="dateTime">
          <div className="title">{renderDate()}</div>
          <div
            className="edit"
            onClick={handleEditCustomer}
          >
            <EditOutlined style={{ fontSize: '20px' }} />
          </div>
        </div>
        <div className="content" ref={contentRef}>
          <div className="content_txt">
            <div className='txt' ref={textRef}>{renderContent()}</div>
            {isOverflown && !isExpanded && !data.value.includes('\n') ? '...' : ''}
          </div>
          {isOverflown && !isExpanded && (
            <div className="more" onClick={handleContentExpend}>更多</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default {
  CrmCustomerBookingRecord,
  CrmCustomerQueueRecord,
  CrmCustomerRecord
};