import React from 'react';
import { Modal } from 'antd';

const ConfirmBlockedCustomerModal = ({
  type,
  isBlocked,
  setShowConfirmBlockedListModal,
  submit
}) => {
  let content = type === 'create' ? '新增' : '儲存';

  const handleSubmit = () => {
    setShowConfirmBlockedListModal(false);
    submit();
  };

  return (
    <Modal
      title={`確定${content}嗎？`}
      visible
      centered
      closable={false}
      className="confirmBlockedCustomerModal"
      footer={
        <React.Fragment>
          <button
            className='btn_cancel'
            onClick={() => setShowConfirmBlockedListModal(false)}
          >
            取消
          </button>
          <button
            className='btn_confirm'
            onClick={handleSubmit}
          >
            確認
          </button>
        </React.Fragment>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      {
        isBlocked
        ? '此筆顧客的原號碼仍會保留在封鎖名單中，若要將號碼解除封鎖可至封鎖名單進行編輯。確定儲存變更嗎？'
        : `此筆顧客的號碼已被加入封鎖名單。確定${content}嗎？`
      }
    </Modal>
  );
}

export default ConfirmBlockedCustomerModal;