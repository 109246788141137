import React, { Fragment, useCallback, useContext } from 'react';
import { Modal } from 'antd';

import { MessageContext } from '../../crmContext/MessageContext';

import { getCrmMessagesSms, deleteCrmSms, cancelCrmSmsSchedule } from '../../api/CrmMessageSmsApi';
import { getCrmMessagesMenuTalk, deleteCrmMenuTalk, cancelCrmMenuTalkSchedule } from '../../api/CrmMessageMenuTalkApi';


const DeleteMessagePopup = () => {
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { deleteType, messageId, messageType } = MessageState;

  const getCrmMessageList = async () => {
    try {
      const smsMessagesResult = await getCrmMessagesSms(),
        smsMessages = smsMessagesResult.data.crm_sms_body;
      const menuTalkMessagesResult = await getCrmMessagesMenuTalk(),
        menuTalkMessages = menuTalkMessagesResult.data.crm_menu_body;

      for(let i = 0; i < smsMessages.length; i++) {
        smsMessages[i].type = 'sms';
      };

      for(let j = 0; j < menuTalkMessages.length; j++) {
        menuTalkMessages[j].type = 'menuTalk';
      };

      const allMessages = smsMessages.concat(menuTalkMessages);

      MessageStateDispatch({ type: 'setInitMessages', originMessages: allMessages });
      MessageStateDispatch({ type: 'setStatusMessagesNum' });
    } catch (err) {
      console.log(err)
    }
  };

  const cancelSmsSchedule = async () => {
    if(messageType === 'sms') {
      try {
        await cancelCrmSmsSchedule(messageId);
        getCrmMessageList();
        cancelPopup();
      } catch(err) {
        console.log('cancel crm sms schedule err ----------', err);
      }
    } else {
      try {
        await cancelCrmMenuTalkSchedule(messageId);
        getCrmMessageList();
        cancelPopup();
      } catch(err) {
        console.log('cancel crm menuTalk schedule err ----------', err);
      }
    }
  };

  const deleteSms = async () => {
    if(messageType === 'sms') {
      try {
        await deleteCrmSms(messageId);
        getCrmMessageList();
        cancelPopup();
      } catch(err) {
        console.log('delete crm sms err ----------', err);
      }
    } else {
      try {
        await deleteCrmMenuTalk(messageId);
        getCrmMessageList();
        cancelPopup();
      } catch(err) {
        console.log('delete crm menuTalk err ----------', err);
      }
    }
  };

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: 'cancelPopup' });
	}, [ MessageStateDispatch ]);

  const renderDeleteBtn = () => {
    if(deleteType === 'delete') {
      return (
        <button
          className="btn_confirm"
          onClick={() => deleteSms()}
        >
          刪除
        </button>
      );
    } else {
      return (
        <button
          className="btn_confirm"
          onClick={() => cancelSmsSchedule()}
        >
          取消排程
        </button>
      );
    }
  };

  const renderContent = () => {
    if(deleteType === 'delete') {
      return <div>刪除後將不會保留訊息內容，確定刪除嗎？</div>;
    } else {
      return <div>取消後將放棄排程，您的訊息也不會被儲存。確定取消嗎？</div>;
    }
  };

  return (
    <Modal
      title={ deleteType === 'delete' ? '確定刪除嗎？' : '確定取消排程嗎？' }
      visible
      centered
      className="deleteMessagePopup"
      onCancel={cancelPopup}
      footer={
        <Fragment>
          <button
            className="btn_cancel"
            onClick={() => cancelPopup()}
          >
            取消
          </button>
          { renderDeleteBtn() }
        </Fragment>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      { renderContent() }
    </Modal>
  );
};

export default DeleteMessagePopup;