import React, { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';

import BookingStatus from './BookingStatus';
import { WarningTemplate } from '../../new_module/NewModuleComponent/NewModuleWarning';

import useInterval from '../utils/useInterval';
import {seatAlertTitle, time } from '../../new_module/utils/data';
import BookingSystemApi from '../../booking_settings/BookingSystemApi';

const API = new BookingSystemApi();
const customerTagsMap = {
	vip: 'VIP',
	egg_vegan: '蛋奶素',
	vegan: '素食',
	not_friendly: '奧客'
};

export default ({
	editBooking,
	setStatus,
	goBookingInfo,
	timerPaused,
	selectedBookingTime,
	date,
	bookingListInfoBookings
}) => {
	const [ currentTimestamp, setCurrentTimestamp ] = useState(moment());
	const [ intervalTime, setIntervalTime ] = useState(1000);
	const [ selectedTime, setSelectedTime ] = useState(selectedBookingTime);
	const [ bookingList, setBookingList ] = useState(bookingListInfoBookings);
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);
	const [ seatedBooking, setSeatedBooking ] = useState({});

	React.useEffect(
		() => {
			setSelectedTime(selectedBookingTime);
			setCurrentTimestamp(moment(new Date(date + ' ' + time[selectedTime])));
			if (!timerPaused) {
				setIntervalTime(1000);
			} else if (timerPaused) {
				setIntervalTime(null);
			}
		},
		[ selectedBookingTime, selectedTime, date, timerPaused ]
	);

	React.useEffect(
		() => {
			setBookingList(bookingListInfoBookings);
		},
		[ bookingListInfoBookings ]
	);

	const renderCurrentBookingSource = useCallback(
		() => {
			let { currentBooking } = bookingList;

			if (currentBooking.source === 'online') {
				if (currentBooking.source_url_t === 'google') {
					return <span className="bookingSourceIcon bookingSourceIcon-google" />;
				} else if (currentBooking.source_url_t === 'facebook') {
					return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
				} else if (currentBooking.source_url_t === 'instagram') {
					return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
				} else if (currentBooking.source_url_t === 'menuApp') {
					return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
				} else if (currentBooking.source_url_t === 'other') {
					return <span className="bookingSourceIcon bookingSourceIcon-other" />;
				}
			}

			if(currentBooking.source === 'google_booking' && currentBooking.source_url_t === 'google_reservation') {
				return <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />;
			}
		},
		[ bookingList ]
	);

	const renderNextBookingSource = useCallback(
		() => {
			let { nextBooking } = bookingList;

			if (nextBooking.source === 'online') {
				if (nextBooking.source_url_t === 'google') {
					return <span className="bookingSourceIcon bookingSourceIcon-google" />;
				} else if (nextBooking.source_url_t === 'facebook') {
					return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
				} else if (nextBooking.source_url_t === 'instagram') {
					return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
				} else if (nextBooking.source_url_t === 'menuApp') {
					return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
				} else if (nextBooking.source_url_t === 'other') {
					return <span className="bookingSourceIcon bookingSourceIcon-other" />;
				}
			}

			if(nextBooking.source === 'google_booking' && nextBooking.source_url_t === 'google_reservation') {
				return <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />;
			}
		},
		[ bookingList ]
	);

	const renderNextNextBookingSource = useCallback(
		() => {
			let { nextNextBooking } = bookingList;

			if (nextNextBooking.source === 'online') {
				if (nextNextBooking.source_url_t === 'google') {
					return <span className="bookingSourceIcon bookingSourceIcon-google" />;
				} else if (nextNextBooking.source_url_t === 'facebook') {
					return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
				} else if (nextNextBooking.source_url_t === 'instagram') {
					return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
				} else if (nextNextBooking.source_url_t === 'menuApp') {
					return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
				} else if (nextNextBooking.source_url_t === 'other') {
					return <span className="bookingSourceIcon bookingSourceIcon-other" />;
				}
			}

			if(nextNextBooking.source === 'google_booking' && nextNextBooking.source_url_t === 'google_reservation') {
				return <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />;
			}
		},
		[ bookingList ]
	);

	useInterval(() => {
		setCurrentTimestamp(moment());
	}, intervalTime);

	const seatedWarning = useCallback(
		(booking) => {
			API.checkSeated(booking.id).then((data) => {
				if (data.overlay_table.length !== 0) {
					setShowWarning(true);
					setWarningTales(data.overlay_table);
					setSeatedBooking(booking);
				} else {
					setStatus(booking, 'seated');
				}
			});
		},
		[ setStatus ]
	);

	const cancelWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
		setSeatedBooking({});
	};

	const confirmWarning = () => {
		setStatus(seatedBooking, 'seated');
		setShowWarning(false);
		setWarningTales([]);
		setSeatedBooking({});
	};

	const renderCurrentBooking = () => {
		if (bookingList.currentBooking.hasOwnProperty('id')) {
			let { currentBooking } = bookingList;
			let currentStatusTxt = currentBooking.status_t;
			let currentStatus = currentBooking.status,
				bookingTime = currentBooking.time,
				name = currentBooking.last_name + ' ' + currentBooking.gender_desc,
				attendance = currentBooking.attendance;
			let statusBtnRes = statusBtn(currentBooking, setStatus, seatedWarning);
			let bookingListTitleColor = 'bookingList__title-unconfirmed';

			let diningTime = JSON.parse(currentBooking.dining_time);
			let bookingTimestamp = moment(currentBooking.booking_datetime);
			let duration = moment.duration(currentTimestamp.diff(bookingTimestamp));
			let durationHour = duration.hours(),
				durationMin = duration.minutes(),
				durationDiff = duration.asMinutes(); //已用餐幾分鐘
			let diningStatus = durationDiff > diningTime - 30 ? 'diningTime-alert' : 'diningTime-normal';
			let tags = currentBooking.customer_info.tags,
				note = currentBooking.customer_info.note;

			switch (currentStatus) {
				case 'confirmed':
					bookingListTitleColor = 'bookingList__title-confirmed';
					break;
				case 'show':
					bookingListTitleColor = 'bookingList__title-show';
					break;
				case 'seated':
					bookingListTitleColor = 'bookingList__title-seated';
					break;
				default:
					bookingListTitleColor = 'bookingList__title-unconfirmed';
					break;
			}

			return (
				<section className="bookingList bookingList-current">
					<h6 className={bookingListTitleColor}>{currentStatusTxt}</h6>

					{durationDiff < diningTime && (
						<p className={`diningTime ${diningStatus}`}>
							(已用餐<span>{durationHour}</span>小時<span>{durationMin}</span>分鐘)
						</p>
					)}
					<div className="bookingList__info">
						<div className="bookingList__info__cell">{renderCurrentBookingSource()}</div>
						<div className="bookingList__info__cell">{bookingTime}</div>
						<div className="bookingList__info__cell">
							{renderCurrentBookingOnsiteTag()}
							{name}
						</div>
						<div className="bookingList__info__cell">
							<span>{attendance}</span>位
						</div>
						<div className="bookingList__info__cell">
							<i className="editIcon" onClick={() => editBooking(currentBooking)} />
						</div>
						<div className="bookingList__info__cell">
							<i className="go" onClick={() => goBookingInfo(currentBooking.id)} />
						</div>
					</div>
					<div className="customerInfo">
						{tags.map((tag, index) => {
							return <span key={index} className='c-tag'>{customerTagsMap[tag]}</span>;
						})}
						<span>{note}</span>
					</div>
					<div className="bookingList__status">
						<div className="statusBtn">
							{statusBtnRes.stautsBtnTime}
							{statusBtnRes.stautsBtn}
						</div>
					</div>
					<BookingStatus booking={currentBooking} setStatus={setStatus} seatedWarning={seatedWarning} />
				</section>
			);
		}
		return null;
	};

	const renderNextBooking = () => {
		if (bookingList.nextBooking.hasOwnProperty('id')) {
			let { nextBooking } = bookingList;
			let nextBookingTime = nextBooking.time,
				nextName = nextBooking.last_name + ' ' + nextBooking.gender_desc,
				naxtAttendance = nextBooking.attendance;
			let tags = nextBooking.customer_info.tags,
				note = nextBooking.customer_info.note;

			return (
				<section className="bookingList bookingList-next">
					<h6>下一筆預約</h6>
					<div className="bookingList__info">
						<div className="bookingList__info__cell">{renderNextBookingSource()}</div>
						<div className="bookingList__info__cell">{nextBookingTime}</div>
						<div className="bookingList__info__cell">
							{renderNextBookingOnsiteTag()}
							{nextName}
						</div>
						<div className="bookingList__info__cell">
							<span>{naxtAttendance}</span>位
						</div>
						<div className="bookingList__info__cell">
							<i className="editIcon" onClick={() => editBooking(nextBooking)} />
						</div>
						<div className="bookingList__info__cell">
							<i className="go" onClick={() => goBookingInfo(nextBooking.id)} />
						</div>
					</div>
					<div className="customerInfo">
						{tags.map((tag, index) => {
							return <span key={index} className='c-tag'>{customerTagsMap[tag]}</span>;
						})}
						<span>{note}</span>
					</div>
					<BookingStatus booking={nextBooking} setStatus={setStatus} seatedWarning={seatedWarning} />
				</section>
			);
		}

		return null;
	};

	const renderNextNextBooking = () => {
		if (bookingList.nextNextBooking.hasOwnProperty('id')) {
			let { nextNextBooking } = bookingList;
			let nextNextBookingTime = nextNextBooking.time,
				nextNextName = nextNextBooking.last_name + ' ' + nextNextBooking.gender_desc,
				naxtNextAttendance = nextNextBooking.attendance;
			let tags = nextNextBooking.customer_info.tags,
				note = nextNextBooking.customer_info.note;
			return (
				<section className="bookingList bookingList-next">
					<h6>下下一筆預約</h6>
					<div className="bookingList__info">
						<div className="bookingList__info__cell">{renderNextNextBookingSource()}</div>
						<div className="bookingList__info__cell">{nextNextBookingTime}</div>
						<div className="bookingList__info__cell">
							{renderNextNextBookingOnsiteTag()}
							{nextNextName}
						</div>
						<div className="bookingList__info__cell">
							<span>{naxtNextAttendance}</span>位
						</div>
						<div className="bookingList__info__cell">
							<i className="editIcon" onClick={() => editBooking(nextNextBooking)} />
						</div>
						<div className="bookingList__info__cell">
							<i className="go" onClick={() => goBookingInfo(nextNextBooking.id)} />
						</div>
					</div>
					<div className="customerInfo">
						{tags.map((tag, index) => {
							return <span key={index} className='c-tag'>{customerTagsMap[tag]}</span>;
						})}
						<span>{note}</span>
					</div>
					<BookingStatus booking={nextNextBooking} setStatus={setStatus} seatedWarning={seatedWarning} />
				</section>
			);
		}

		return null;
	};

	const renderEmptyBooking = () => {
		if (
			!bookingList.currentBooking.hasOwnProperty('id') &&
			!bookingList.nextBooking.hasOwnProperty('id') &&
			!bookingList.nextNextBooking.hasOwnProperty('id')
		) {
			return (
				<section className="bookingList bookingList-empty">
					<span>尚無預約</span>
				</section>
			);
		}
		return null;
	};

	const renderCurrentBookingOnsiteTag = () => {
		if (bookingList.currentBooking.on_site) {
			return <span className="onSiteTag">現場</span>;
		}
	};

	const renderNextBookingOnsiteTag = () => {
		// console.log('bookingList.nextBooking----', bookingList.nextBooking);
		if (bookingList.nextBooking.on_site) {
			return <span className="onSiteTag">現場</span>;
		}
	};

	const renderNextNextBookingOnsiteTag = () => {
		if (bookingList.nextNextBooking.on_site) {
			return <span className="onSiteTag">現場</span>;
		}
	};

	return (
		<div className="tables_bookingList__wrap hide">
			<div className="tables_bookingList__card">
				{renderCurrentBooking()}
				{renderNextBooking()}
				{renderNextNextBooking()}
				{renderEmptyBooking()}
			</div>
			{showWarning && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title={seatAlertTitle}
					>
						<p>
							桌次
							{warningTales.map((table) => {
								return (
									<span key={table} className="tableName">
										{table}
									</span>
								);
							})}
							有尚未結束的預約
						</p>
						<p>是否確定入座？</p>
					</WarningTemplate>
				</Portal>
			)}
		</div>
	);
};

const Portal = ({ children }) => {
	return createPortal(children, document.getElementById('modalPortal'));
};

const statusBtn = (booking, setStatus, seatedWarning) => {
	let statusTime = JSON.parse(booking.status_time);
	let stautsBtn = null,
		stautsBtnTime = null;
	let garyBackground = '';

	switch (booking.status) {
		case 'confirmed':
			stautsBtn = (
				<React.Fragment>
					<button className="btn noShowBtn" onClick={() => setStatus(booking, 'no_show')}>
						未到店
					</button>
					<button className="btn showBtn" onClick={() => setStatus(booking, 'show')}>
						已到店
					</button>
				</React.Fragment>
			);
			break;
		case 'show':
			stautsBtn = (
				<button className="btn seatedBtn" onClick={() => seatedWarning(booking)}>
					入座
				</button>
			);
			stautsBtnTime = <span className="stautsBtnTime">報到時間: {statusTime['show']}</span>;
			break;
		case 'seated':
			stautsBtn = (
				<button className="btn checkBtn" onClick={() => setStatus(booking, 'finish')}>
					結帳
				</button>
			);
			stautsBtnTime = <span className="stautsBtnTime">入座時間: {statusTime['seated']}</span>;

			break;
		case 'finish':
			stautsBtn = <span className="doneBtn">{statusTime['finish']}完成消費</span>;
			break;
		case 'no_show':
			garyBackground = 'garyBackground';
			stautsBtn = <span className="noShowBtnTxt">{statusTime['no_show']}未到店</span>;
			break;
		case 'cancel':
			garyBackground = 'garyBackground';
			stautsBtn = <span className="cancelBtnTxt">{statusTime['cancel']}取消預約</span>;
			break;
		default:
			stautsBtn = (
				<React.Fragment>
					<button className="btn cancelBtn" onClick={() => setStatus(booking, 'cancel')}>
						取消預約
					</button>
					<button className="btn confirmedBtn" onClick={() => setStatus(booking, 'confirmed')}>
						確認預約
					</button>
				</React.Fragment>
			);
			stautsBtnTime = null;
			break;
	}

	return {
		stautsBtn,
		stautsBtnTime,
		garyBackground
	};
};
