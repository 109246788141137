import React, { useState, useEffect, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LoadingOutlined } from '@ant-design/icons'

import QueuePanelAPI from '../../queue_system/api/QueuePanelAPI';

import QueueActionButton from './QueueActionButton';
import { QueueAttendanceNumPad, QueuePhoneNumPad } from '../NewModuleComponent/NewModuleTools';
import { moveCanvasClass, moveBackCanvasClass } from '../NewModuleComponent/NewModuleTools';

//reducer
import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';

const API = new QueuePanelAPI();
const reg = /^\d+$/;
const NewModuleQueueGroup = ({
	mode,
	closeQueueGroupPanel,
	queueGroup,
	createQueueRecord,
	timeChecking,
	updateQueueSystem,
	setShowQueueGroupPanel
}) => {
	const { setUuid } = useContext(CommomSettingContext);

	const [ moduleQueueGroup, setModuleQueueGroup ] = useState(queueGroup);
	const [ queueGroupStep, setQueueGroupStep ] = useState(1); //1選組別 2填資料 3完成
	const [ seletedGroup, setSeletedGroup ] = useState({});
	const [ finishDate, setFinishDate ] = useState({});

	const [ attendance, setAttendance ] = useState('');
	const [ name, setName ] = useState('');
	const [ tel, setTel ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ gender, setGender ] = useState('male');
	const [ sameTel, setSameTel ] = useState(false);
	const [ addDisabled, setAddBtnDisabled ] = useState(false);

	let isNextButtonAvailable = false;

	useEffect(
		() => {
			if (mode !== 'tablemap') return;
			moveCanvasClass();
			return () => {
				if (mode !== 'tablemap') return;
				moveBackCanvasClass();
			};
		},
		[ mode ]
	);

	useEffect(
		() => {
			setModuleQueueGroup(queueGroup);
		},
		[ queueGroup ]
	);

	const setAttendanceFunc = (attendance) => {
		if (reg.test(attendance) || attendance === '') {
			setAttendance(attendance);
		} else {
			window.app.alert.setMessage('請輸入數字', 'error');
		}
	};

	const setTelFunc = (phone) => {
		if (reg.test(phone) || phone === '') {
			setTel(phone);
		} else {
			window.app.alert.setMessage('請輸入數字', 'error');
		}
	};

	const getInLine = () => {
		//uuid
		const eventId = uuidv4();
		setUuid(eventId);
		setAddBtnDisabled(true);

		API.createQueueRecord({
			queue_group_id: seletedGroup.id,
			name,
			phone: tel,
			gender,
			people_count: attendance,
			email,
			event_id: eventId
		})
			.then((data) => {
				if (typeof data.status === 'string') {
					setFinishDate(data);
					clearInfos();
					setQueueGroupStep(3);
					setAddBtnDisabled(false);
					createQueueRecord();
				}
			})
			.catch((err) => {
				if (err.status === 409) {
					setSameTel(true);
				}
			});
	};

	const clearInfos = () => {
		setAttendance('');
		setName('');
		setTel('');
		setEmail('');
		setGender('male');
		setSameTel(false);
	};

	const toPrevStep = () => {
		clearInfos();
		setQueueGroupStep(1);
	};

	if (name.trim() !== '' && attendance.trim() !== '' && tel.trim() !== '') {
		isNextButtonAvailable = true;
	} else {
		isNextButtonAvailable = false;
	}

	const renderQueueGroupPanelBody = () => {
		if (queueGroupStep === 1) {
			return renderQueueGroupCard();
		} else if (queueGroupStep === 2) {
			return (
				<div className="queueGroupPanel">
					{ addDisabled && <div className='status_loading'><LoadingOutlined /></div> }
					<div className="queueGroupPanel__header">
						<span>填寫資料</span>

						<div className="stepGroup">
							<button className="stepGroup-preStep" onClick={() => toPrevStep()}>
								上一步
							</button>
							<button
								className="stepGroup-check"
								disabled={!isNextButtonAvailable || addDisabled}
								onClick={() => getInLine()}
							>
								排隊
							</button>
						</div>
					</div>
					<div className="queueGroupPanel__body ">
						<QueueGroupCardInfo
							seletedGroup={seletedGroup}
							attendance={attendance}
							name={name}
							tel={tel}
							email={email}
							gender={gender}
							setAttendance={setAttendanceFunc}
							setName={setName}
							setTel={setTelFunc}
							setEmail={setEmail}
							setGender={setGender}
							sameTel={sameTel}
						/>
					</div>
				</div>
			);
		} else if (queueGroupStep === 3) {
			return (
				<QueueFinishCard
					timeChecking={timeChecking}
					finishDate={finishDate}
					closeQueueGroupPanel={closeQueueGroupPanel}
					toPrevStep={toPrevStep}
				/>
			);
		}
	};

	const renderQueueGroupCard = () => {
		return (
			<div className="queueGroupPanel">
				<div className="queueGroupPanel__header">
					<span>開始排隊</span>

					<QueueActionButton updateQueueSystem={updateQueueSystem} setShowQueueGroupPanel={setShowQueueGroupPanel} />
				</div>
				<div className="queueGroupPanel__body groupCardBody">
					<div className="queueGroupList">
						{moduleQueueGroup.map((group, index) => {
							return <QueueGroupCard key={index} group={group} toStep2={toStep2} />;
						})}
					</div>
				</div>
			</div>
		);
	};

	const toStep2 = (group) => {
		setSeletedGroup(group);
		setQueueGroupStep(2);
	};

	return (
		<div className="NewModuleQueueGroup">
			<div className="NewModuleQueueGroupLayout" onClick={() => closeQueueGroupPanel()} />
			<div className="NewModuleQueueGroupWrap">
				<button className="NewModuleQueueGroup__close" onClick={() => closeQueueGroupPanel()} />
				{renderQueueGroupPanelBody()}
			</div>
		</div>
	);
};

const QueueGroupCard = ({ group, toStep2 }) => {
	const renderPausedIcon = () => {
		return <i className="queueGroupCard-pause" />;
	};
	return (
		<div className="queueGroupCard" onClick={() => toStep2(group)}>
			{group.status === 'pause' && renderPausedIcon()}
			<div className="queueGroupCard__top">
				<span>{group.title}</span>
			</div>
			<div className="queueGroupCard__bottom">
				<span>等候組數 {group.current_waiting_groups}</span>
			</div>
		</div>
	);
};

const QueueGroupCardInfo = ({
	seletedGroup,
	attendance,
	setAttendance,
	name,
	setName,
	tel,
	setTel,
	email,
	setEmail,
	gender,
	setGender,
	sameTel
}) => {
	const [ sameTelStyle, setSameTelStyle ] = useState(sameTel);
	const [ showAttendanceNumpad, setShowAttendanceNumpad ] = useState(false);
	const [ showPhoneNumpad, setShowPhoneNumpad ] = useState(false);
	const attendanceNumpadRef = React.createRef();
	const phoneNumpadRef = React.createRef();
	const attendanceInput = useRef();
	const phoneInput = useRef();

	useEffect(() => {
		const windowWidth = window.innerWidth;
		if (windowWidth <= 600) {
			// <600 使用原生鍵盤
			attendanceInput.current.removeAttribute('readOnly');
			phoneInput.current.removeAttribute('readOnly');
		} else {
			// >600 不要原生鍵盤 && 不出現numpad
			attendanceInput.current.setAttribute('readonly', true);
			phoneInput.current.setAttribute('readonly', true);
		}
	}, []);

	useEffect(
		() => {
			setSameTelStyle(sameTel);
		},
		[ sameTel ]
	);

	const renderGender = () => {
		let male_active = gender === 'male' ? 'active' : '';
		let female_active = gender === 'female' ? 'active' : '';

		return (
			<div className="genderPicker">
				<span className={male_active} onClick={() => setGender('male')}>
					先生
				</span>
				<span className={female_active} onClick={() => setGender('female')}>
					小姐
				</span>
			</div>
		);
	};

	const handleAttendanceKeyin = (e) => {
		let attendanceArray = attendance.toString().split('');

		if (e === 'delete') {
			attendanceArray.pop();
		} else if (e === 'clear') {
			attendanceArray = [];
		} else {
			attendanceArray.push(e.toString());
		}

		const newAttendance = attendanceArray.join('');
		handleAttendance(newAttendance);
	};

	const handleAttendance = (attendance) => {
		setAttendance(attendance);
	};

	useEffect(
		() => {
			if (attendanceNumpadRef.current) {
				attendanceNumpadRef.current.focus();
			}
		},
		[ attendanceNumpadRef ]
	);

	useEffect(
		() => {
			if (phoneNumpadRef.current) {
				phoneNumpadRef.current.focus();
			}
		},
		[ phoneNumpadRef ]
	);

	const handleInputFocus = (input) => {
		if (input === 'attendance') {
			setShowAttendanceNumpad(true);
		} else if (input === 'phone') {
			setShowPhoneNumpad(true);
		}
	};

	const handlePhoneKeyin = (e) => {
		let phoneArray = tel.toString().split('');

		if (e === 'delete') {
			phoneArray.pop();
		} else if (e === 'clear') {
			phoneArray = [];
		} else {
			phoneArray.push(e.toString());
		}

		const newPhoneArray = phoneArray.join('');
		handlePhone(newPhoneArray);
	};

	const handlePhone = (phone) => {
		setTel(phone);
	};

	return (
		<div className="queueGroupCardInfo">
			<div className="queueGroupCardInfo__left">
				<div className="queueGroupCard">
					<div className="queueGroupCard__top">
						<span>{seletedGroup.title}</span>
					</div>
					<div className="queueGroupCard__bottom">
						<span>等候組數 {seletedGroup.current_waiting_groups}</span>
					</div>
				</div>
			</div>
			<div className="queueGroupCardInfo__right">
				<div className="queueGroupCardInfo__form">
					<div className="queueGroupCardInfo__form__row">
						<input
							ref={attendanceInput}
							type="tel"
							placeholder={`*${seletedGroup.min_people_count}-${seletedGroup.max_people_count}人`}
							value={attendance}
							onFocus={() => handleInputFocus('attendance')}
							onChange={(e) => handleAttendance(e.target.value)}
						/>

						{showAttendanceNumpad && (
							<QueueAttendanceNumPad
								ref={attendanceNumpadRef}
								handleKeyin={handleAttendanceKeyin}
								numpadBlur={() => setShowAttendanceNumpad(false)}
							/>
						)}
					</div>
					<div className="queueGroupCardInfo__form__row queueGroupCardInfo__form__row-name">
						<div className="nameInput">
							<input
								type="text"
								placeholder="*姓名"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						{renderGender()}
					</div>
					<div className="queueGroupCardInfo__form__row">
						<div className={`queueGroupCardInfo__form__row-tel ${sameTelStyle}`}>
							<input
								ref={phoneInput}
								type="tel"
								placeholder="*手機號碼"
								value={tel}
								onFocus={() => handleInputFocus('phone')}
								onChange={(e) => handlePhone(e.target.value)}
							/>
							{sameTelStyle && <span>重複的手機號碼</span>}
						</div>

						{showPhoneNumpad && (
							<QueuePhoneNumPad
								ref={phoneNumpadRef}
								handleKeyin={handlePhoneKeyin}
								numpadBlur={() => setShowPhoneNumpad(false)}
							/>
						)}
					</div>
					<div className="queueGroupCardInfo__form__row">
						<input
							type="text"
							placeholder="電子信箱"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const QueueFinishCard = ({ finishDate, closeQueueGroupPanel, toPrevStep, timeChecking }) => {
	return (
		<div className="queueFinishCardWrap">
			<div className="queueFinishCardAlert">成功加入排隊</div>
			<div className="queueFinishCard">
				<div className="queueFinishCard__group">{finishDate.group_name}</div>
				<div className="queueFinishCard__number">{finishDate.waiting_number}</div>
				<div className="queueFinishCard__infos">
					<span>
						前面還有<span>{finishDate.waiting_groups}</span>組
					</span>
					<span>
						約<span>{getEstimatedWaitingTime(timeChecking, finishDate.waiting_groups)}分鐘</span>{' '}
					</span>
				</div>
				<div className="queueFinishCard__bthGroup">
					<div>
						<button className="queueFinishCard-toMain" onClick={() => closeQueueGroupPanel()}>
							回主畫面
						</button>
					</div>
					<div>
						<button className="queueFinishCard-oneMore" onClick={() => toPrevStep()}>
							再排一位
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const getEstimatedWaitingTime = (time_checking, groupCount) => {
	let timeCheckingConditions = [];
	try {
		timeCheckingConditions = JSON.parse(time_checking);
	} catch (e) {
		// console.log('Parse condition failed');
	}

	if (Array.isArray(timeCheckingConditions) && timeCheckingConditions.length > 1) {
		timeCheckingConditions.sort((first, second) => {
			return first.count - second.count;
		});
	}

	let estimatedWaitingTime = 0;

	for (let i = 0; i < timeCheckingConditions.length; i++) {
		const element = timeCheckingConditions[i];
		if (groupCount <= element.count) {
			if (i === 0) {
				if (groupCount === element.count) {
					estimatedWaitingTime = element.time;
				} else {
					estimatedWaitingTime = '少於 ' + element.time;
				}
			} else {
				const prevElement = timeCheckingConditions[i - 1];
				estimatedWaitingTime = prevElement.time;
			}
			break;
		} else {
			const prevElement = timeCheckingConditions[i];
			estimatedWaitingTime = prevElement.time;
		}
	}

	return estimatedWaitingTime;
};

export default NewModuleQueueGroup;
