import React, { useCallback, useState, useEffect, useReducer }  from 'react';
import { Link, browserHistory } from 'react-router';
import { MenuOutlined, CloseCircleFilled } from '@ant-design/icons'

import CrmMessageAdd from './crmMessage/crmMessageAdd/CrmMessageAdd';
import CrmConfirmMessage from './crmMessage/crmMessageAdd/CrmConfirmMessage';

import Toolbar from './commons/CrmToolbar';
import Tab from './commons/CrmTab';
import CustomerFilterBar from './commons/CrmCustomerFilterBar';

import { CustomerContext, CustomerReducer, customerState } from './crmContext/CustomerContext';
import { MessageContext, MessageReducer, messageState } from './crmContext/MessageContext';
import { MessageAddStateContext, MessageAddReducer, messageAddInitState } from './crmContext/MessageAddContext';

import '../../sass/crm_layout.scss';
import '../../sass/crm_message.scss';
import './crmSettings/crmSettings.scss';
import './crmMessage/crmMessagePopup/crmMessagePopup.scss';
import './crmCustomer/crmCustomerPopup/crmCustomerPopup.scss';

const Crm = (props) => {
	const [ CustomerState, CustomerStateDispatch ] = useReducer(CustomerReducer, customerState);
	const {
		showCustomerFilterBar,
		customerFilterNum,
		searchCustomerMode
	} = CustomerState;
  const [ MessageState, MessageStateDispatch ] = useReducer(MessageReducer, messageState);
	const { mode } = MessageState;

	const [ MessageAddState, MessageAddDispatch ] = useReducer(MessageAddReducer, messageAddInitState);
	const { isSaved } = MessageAddState;

	const [ currentPath, setCurrentPath ] = useState(window.location.pathname.replace('/dashboard/', ''));
	const [ modeStyle, setModeStyle ] = useState('');
	const [ menuStyle, setMenuStyle ] = useState(''); // mobile

	const routerWillLeave = useCallback(
		() => {
			if(mode === 'addMessage' || mode === 'confirmMessage') {
				if(!isSaved) return '退出將放棄傳送訊息，確定退出嗎？';
			};
		},
		[ mode, isSaved ]
	);

	useEffect(() => {
    const element = document.getElementsByClassName('crm_main')[0];
		element.scrollTo({
			top: 0,
		});
  }, [ props ])

	useEffect(() => {
		props.router.setRouteLeaveHook(props.route, routerWillLeave);
	}, [ props, routerWillLeave ]);

	useEffect(() => {
		let current_path = window.location.pathname.replace('/dashboard/', '');

		if(current_path === 'customers') {
			MessageStateDispatch({ type: 'switchMode', mode: 'customerList', editType: ''});
		} else if(current_path === 'messages') {
			MessageStateDispatch({ type: 'switchMode', mode: 'messageList', editType: ''});
		} else {
			MessageStateDispatch({ type: 'switchMode', mode: 'crmSettings', editType: ''});
		}
		setCurrentPath(current_path);
	}, [ props ]);

	useEffect(() => {
		const regCustomersPage = /^customers\//;
		const regCrmPage = /^crm\//;

		let newModeStyle = '';

		// 顧客個人頁 `customers/${customerId}` 和 CRM 封鎖、標記管理頁面除外
		if (regCustomersPage.test(currentPath) || regCrmPage.test(currentPath)) {
      newModeStyle = 'crm_mode_normal';
    } else if (mode === 'messageList' || mode === 'customerList') {
      newModeStyle = 'crm_mode_list';
    } else if (mode === 'addMessage' || mode === 'confirmMessage') {
      newModeStyle = 'crm_mode_message';
    }

		setModeStyle(newModeStyle);
		// 清除篩選
		resetCustomerFilter();
		CustomerStateDispatch({ type: 'setCustomerFilterBar', show: false });
	}, [ mode, currentPath ]);

	const toCRMHomepage = () => {
		if(searchCustomerMode) {
			return resetSearchMode();
		};

		browserHistory.push({
			pathname: '/dashboard/customers'
		});
	};

	const renderActiveMode = useCallback(
		() => {
			if(mode === 'addMessage') {
				return <CrmMessageAdd />;
			} else if (mode === 'confirmMessage') {
				return <CrmConfirmMessage />
			} else {
				return props.children;
			}
		},
		[ mode, props ]
	);

	const resetSearchMode = () => {
		if(!searchCustomerMode) return;

		CustomerStateDispatch({ type: 'setSearchCustomerText', searchCustomerText: '' });
    CustomerStateDispatch({
      type: 'setSearchCustomerResult',
      searchCustomers: [],
      searchCustomerMode: false,
			listPage: 1,
			listCount: null
    });
	}

	const toggleMenu = () => {
		if(menuStyle === 'show_menu') {
			setMenuStyle('');
		} else {
			setMenuStyle('show_menu');
		}
	};

	const closeMenu = (page) => {
		setMenuStyle('');

		if(page === 'customerList') resetSearchMode();
	};

	const resetCustomerFilter = () => {
		CustomerStateDispatch({ type: 'resetCustomerFilter'});
	};

	const renderResetCustomerFilterBar = () => {
		return (
			<div
				className={`crmCustomer_filter_reset ${showCustomerFilterBar ? 'sticky_lower' : ''}`}
			>
				<div onClick={resetCustomerFilter}>
					<CloseCircleFilled style={{ color: '#EF6868' }} />
					清除所有條件
				</div>
			</div> 
		)
	};

	return (
		<CustomerContext.Provider value={{ CustomerState, CustomerStateDispatch }}>
			<MessageContext.Provider value={{ MessageState, MessageStateDispatch }}>
				<MessageAddStateContext.Provider value={{ MessageAddState, MessageAddDispatch }}>
					<div className={`crmWrap ${modeStyle} ${menuStyle}`} style={{ height: window.innerHeight }}>
						<div className="crm">
							<div className="crm_message_nav">
								<MenuOutlined onClick={() => toggleMenu()} />
								<img
									src={require("../../images/component/crm_logo.svg")}
									alt=""
									onClick={toCRMHomepage}
								/>
								顧客關係管理
							</div>

							{/* for mobile */}
							<div className="crm_menu">
								<Link to={{pathname: '/dashboard/customers'}}>
									<button
										className={currentPath === 'customers' || currentPath.startsWith('customers') ? 'active': ''}
										onClick={() => closeMenu('customerList')}
									>
										<div className="group_icon"></div>
										<div>顧客列表</div>
									</button>
								</Link>
								<Link to={{pathname: '/dashboard/crm/blocked'}}>
									<button
										className={currentPath === 'crm/blocked' ? 'active': ''}
										onClick={() => closeMenu()}
									>
										<div className="blocked_icon" />
										<div>封鎖名單</div>
									</button>
								</Link>
								{/* <Link to={{pathname: '/dashboard/crm/tags'}}>
									<button
										className={currentPath === 'crm/tags' ? 'active': ''}
										onClick={() => closeMenu()}
									>
										<div className="tag_icon" />
										<div>標記管理</div>
									</button>
								</Link> */}
								<Link to={{pathname: '/dashboard/messages'}}>
									<button
										className={currentPath === 'messages' ? 'active': ''}
										onClick={() => closeMenu()}
									>
										<div className="message_icon" />
										<div>訊息</div>
									</button>
								</Link>
							</div>
							<div className="crm_menu_mask" onClick={() => closeMenu()} />

							<aside className="crm_aside">
								<Link to={{pathname: '/dashboard/customers'}}>
									<button
										className={currentPath === 'customers' || currentPath.startsWith('customers') ? 'active': ''}
										onClick={resetSearchMode}
									>
										<div className="group_icon" />
										<div>顧客列表</div>
									</button>
								</Link>
								<Link to={{pathname: '/dashboard/crm/blocked'}}>
									<button
										className={currentPath === 'crm/blocked' ? 'active': ''}
									>
										<div className="blocked_icon" />
										<div>封鎖名單</div>
									</button>
								</Link>
								{/* <Link to={{pathname: '/dashboard/crm/tags'}}>
									<button
										className={currentPath === 'crm/tags' ? 'active': ''}
									>
										<div className="tag_icon" />
										<div>標記管理</div>
									</button>
								</Link> */}
								<Link to={{pathname: '/dashboard/messages'}}>
									<button
										className={currentPath === 'messages' ? 'active': ''}
										>
										<div className="message_icon" />
										<div>訊息</div>
									</button>
								</Link>
							</aside>
							<div className="crm_main">
								<div className="crm_container">
									{ currentPath === 'customers' || currentPath === 'messages' ? <Toolbar /> : null }
									{ (currentPath === 'customers' && !searchCustomerMode) || currentPath === 'messages' ? <Tab /> : null }
									{ currentPath === 'customers' && showCustomerFilterBar ? <CustomerFilterBar /> : null }
									{ currentPath === 'customers' && customerFilterNum !== 0 ? renderResetCustomerFilterBar() : null }

									{ renderActiveMode() }
									</div>
								</div>
							</div>
						</div>
					</MessageAddStateContext.Provider>
				</MessageContext.Provider>
		</CustomerContext.Provider>
	);
};

export default Crm;
