import React, { useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import { Modal } from 'antd';

import { MessageContext } from '../../../crmContext/MessageContext';
import { MessageAddStateContext } from '../../../crmContext/MessageAddContext';

import EnableMenuCouponCard from '../EnableMenuCouponCard';

const InsertMenuCouponModal = () => {
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { couponLists, insertMenuCoupon, insertCoupons, messageSetting } = MessageAddState;
  const { content, cursorPosition, couponInfo, menuTalkContent } = messageSetting;
  const [ error, setError ] = useState(false);

  const confirm = useCallback(() => {
    if(couponLists.length === 0) {
      return MessageAddDispatch({ type: 'cancelPopup' });
    };

    if(insertMenuCoupon === 0 || insertMenuCoupon === undefined || insertMenuCoupon === null) {
      setError(true);
    } else {
      if(editType === 'sms') {
        const menuCouponUrl = `https://menutaiwan.com/tw/coupon/${insertMenuCoupon}`;
        const insertContent = 
          content.slice(0, cursorPosition) +
          menuCouponUrl +
          content.slice(cursorPosition);
        const newCursorPosition = cursorPosition + menuCouponUrl.length;
  
        MessageAddDispatch({ type: 'setMessageContent', content: insertContent });
        MessageAddDispatch({ type: 'setSmsContentCursorPosition', cursorPosition: newCursorPosition });
        MessageAddDispatch({ type: 'setInsertMenuCoupon', insertMenuCoupon: 0, couponInfo: {} });
        MessageAddDispatch({ type: 'cancelPopup' });
      } else if(editType === 'menuTalk' && couponInfo) {
        const newMenuTalkContent = _.cloneDeep(menuTalkContent);
        const newInsertCoupons = _.cloneDeep(insertCoupons);
        let coupon = {};

        coupon.type = 'voucher';
        coupon.voucherId = couponInfo.id;
        coupon.endDateTime = couponInfo.endDateTime;
        newMenuTalkContent.push(coupon);
        newInsertCoupons.push(coupon);

        MessageAddDispatch({ type: 'setMenuTalkContent', menuTalkContent: newMenuTalkContent });
        MessageAddDispatch({ type: 'setMenuTalkInsertCoupons', insertCoupons: newInsertCoupons });
        MessageAddDispatch({ type: 'setInsertMenuCoupon', insertMenuCoupon: 0, couponInfo: {} });
        MessageAddDispatch({ type: 'cancelPopup' });
      } else {
        setError(true);
      }
    }
	}, [ 
    MessageAddDispatch,
    insertMenuCoupon,
    couponLists,
    editType,
    content,
    couponInfo,
    cursorPosition,
    insertCoupons,
    menuTalkContent
  ]);

  const cancelPopup = useCallback(() => {
    MessageAddDispatch({ type: 'cancelPopup' });
    MessageAddDispatch({ type: 'setInsertMenuCoupon', insertMenuCoupon: 0 });
	}, [ MessageAddDispatch ]);

  const renderCoupon = () => {
    if(couponLists.length !== 0) {
      return couponLists.map((coupon, index) => {
        return (
          <EnableMenuCouponCard id={coupon.id} key={index} coupon={coupon} onlyView={false}>
            <EnableMenuCouponCard.Img thumbnailURL={coupon.thumbnailURL} />
            <EnableMenuCouponCard.Content coupon={coupon} />
          </EnableMenuCouponCard>
        )
      })
    } else {
      return <div className='coupons_empty'>尚無優惠券。</div>
    }
  };

  return (
    <Modal
      title="分享優惠券"
      visible
      centered
      className="insertMenuCouponModal"
      onCancel={cancelPopup}
      footer={
        <button onClick={() => confirm()}>確認</button>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      { error ? <div className="error">選擇要分享的優惠券。</div> : null }
      { renderCoupon() }
    </Modal>
  );
};

export default InsertMenuCouponModal;