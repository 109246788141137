import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Select, Space, DatePicker } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import {
  createCrmCustomer,
  updateCrmCustomer,
  updateCrmCustomerTags,
  getCrmCustomerTags
} from '../../api/CrmCustomerApi';
import useBlockedList from '../../utils/useBlockedList';
import ConfirmBlockedCustomerModal from './ConfirmBlockedCustomerModal';

import '../crmCustomerRecord/crmCustomerRecordModal.scss';

const defaultPhonePlaceholder = "0912345678";
const errorPhonePlaceholder = "請輸入號碼";

const CrmCustomerEditorModal = ({ load, selectedBooking, cancelPopup }) => {
  const [ data, setData] = useState({
    last_name: selectedBooking.last_name,
    gender: selectedBooking.gender,
    phone_number: selectedBooking.phone_number,
    // tele_number,
    email: selectedBooking.email,
    // note,
  })
  const [ displayChecked, setDisplayChecked ] = useState([]);
  const [ displayTags, setDisplayTags ] = useState([]);

  const [ blockedList, setBlockedList ] = useState([]);
  const [ blockedNumberError, setBlockedNumberError ] = useState(false);
  const [ showConfirmBlockedListModal, setShowConfirmBlockedListModal ] = useState(false);
  const [ showCancelEditModal, setShowCancelEditModal ] = useState(false);
  const [ isSaved, setIsSaved ] = useState(true);

  const customerNameRef = useRef(null);
  const phoneNumberRef = useRef(null);

  const { response } = useBlockedList();

  useEffect(() => {
    getTags();
  },[]);

  useEffect(() => {
    if(response !== null) {
      setBlockedList(response);

      if(response.includes(selectedBooking.phone_number)) {
        setBlockedNumberError(true);
        phoneNumberRef.current.classList.add('input_error_empty');
      }
    }
  }, [ response ]);

  const validateData = () => {
    const setError = (field, ref, newPlaceholder, message) => {
      error.push(field);
      ref.current.classList.add('input_error_empty');
      if (newPlaceholder) {
        ref.current.placeholder = newPlaceholder;
      }
      window.app.alert.setMessage(message, 'error');
    };
  
    const clearError = (field, ref, placeholder) => {
      const index = error.indexOf(field);
      if (index !== -1) error.splice(index, 1);
      ref.current.classList.remove('input_error_empty');
      if (placeholder) {
        ref.current.placeholder = placeholder;
      }
    };

    let error = [];

    if (data.last_name.trim() === '') {
      setError('last_name', customerNameRef, '', '姓名與手機號碼不得為空白');
    } else {
      clearError('last_name', customerNameRef, '');
    }
  
    if (data.phone_number.trim() === '') {
      setError('phone_number', phoneNumberRef, errorPhonePlaceholder, '姓名與手機號碼不得為空白');
    } else {
      clearError('phone_number', phoneNumberRef, defaultPhonePlaceholder);
    }
  
    if(error.length !== 0) return;
    if(blockedNumberError) return setShowConfirmBlockedListModal(true);

    submit();
  };

  const submit = async () => {
    try {
      await createCrmCustomer(data);
      cancelPopup();
      load();
      setIsSaved(true);
    } catch(error) {
      if(error.response.status === 422) {
        window.app.alert.setMessage('此顧客已存在於顧客列表中', 'error');
      } else {
        window.app.alert.setMessage(error.response.data.message, 'error');
      }
    }
  };

  const tagsSubmit = async (id) => {
    // const checkedTags = {
    //   checked: displayChecked,
    //   id: id
    // }

    try {
      // await updateCrmCustomerTags(checkedTags)
      //   .then( res => 
      //     window.app.alert.setMessage('已新增顧客', 'done')
      //   );

      window.app.alert.setMessage('已新增顧客', 'done')

      if (typeof(load) !== 'undefined') {
        load();
      }
    } catch(err) {
      window.app.alert.setMessage(err.response.data.message, 'error');
    }
  };

  const getTags = async () => {
    try {
      const { data } = await getCrmCustomerTags();
      let tagsOptions = [];

      data.customer_tags.map((tag) => {
        tagsOptions.push({
          label: tag.description,
          value: `${tag.id}`
        })
      })

      setDisplayTags(tagsOptions);
    } catch(err) {
      console.log('get tags err ----------', err);
    }
  };

  const handleInputChange = (e) => {
    const reg = /^\d+$/;
    const name = e.target.name;
    const value = e.target.value;
    const isNumericInput = value.trim().length > 0 ? reg.test(value.trim()) : true;

    let newData = _.cloneDeep(data);

    if(name === 'last_name') customerNameRef.current.classList.remove('input_error_empty');

    if ((name === 'phone_number' || name === 'company_tele') && !isNumericInput) {
      return;
    };

    if(name === 'phone_number') {
      const isBlockedNumber = blockedList.includes(value);

      if(isBlockedNumber) {
        setBlockedNumberError(true);
        phoneNumberRef.current.classList.add('input_error_empty');
      } else {
        setBlockedNumberError(false);
        phoneNumberRef.current.classList.remove('input_error_empty');
        phoneNumberRef.current.placeholder = defaultPhonePlaceholder;
      }
    }

    newData[name] = value;
    setData(newData);
    setIsSaved(false);
  };

  const renderDatePicker = () => {
    const disabledDate = (current) => {
      // Can not select days after today
      const time = moment().endOf('day').subtract(1, 'days');
      return current && current > time;
    };

    return (
      <div className="customer_datepicker">
        <DatePicker
          showToday={false}
					// defaultValue={
					// 	setting.start_date ? moment(setting.start_date, dateFormat) : null
					// }
          // value={moment('2020-12-31')}
          disabledDate={disabledDate}
					// onChange={handleDateChange}
					placeholder="YYYY-MM-DD"
				/>
      </div>
    )
  };

  const handleCancel = () => {
    if(isSaved) {
      cancelPopup()
    } else {
      setShowCancelEditModal(true);
    }
  }

  return (
    <Modal
      title='新增顧客'
      visible
      centered
      closable={false}
      className="crmCustomerEditorModal"
      footer={
        <div className='btn_action'>
          <div>
            <button
              type="button"
              className='btn_cancel'
              onClick={handleCancel}
            >
              取消
            </button>
            <button
              type="button"
              className='btn_confirm'
              onClick={validateData}
            >
              儲存
            </button>
          </div>
        </div>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className='wrap'>
        <div className='customer_basic'>
          <h4>新增顧客</h4>
          <div className='customer_row'>
            <div className='title'>姓名*</div>
            <input
              type="text"
              ref={customerNameRef}
              name='last_name'
              placeholder='請輸入姓名'
              value={data.last_name}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className='customer_row'>
            <div className='title'>性別</div>
            <select
              name='gender'
              value={data.gender}
              onChange={(e) => handleInputChange(e)}
              placeholder='選擇性別'
            >
              <option value="other">其他</option>
              <option value="male">男性</option>
              <option value="female">女性</option>
            </select>
          </div>
          <div className='customer_row'>
            <div className='title'>手機號碼*</div>
            <input
              type="text"
              ref={phoneNumberRef}
              name='phone_number'
              placeholder='0912345678'
              value={data.phone_number}
              onChange={(e) => handleInputChange(e)}
            />
            {blockedNumberError && <div className='text text_error'>此組號碼已被加入封鎖名單</div>}
          </div>
          <div className='customer_row'>
            <div className='title'>E-mail</div>
            <input
              type="text"
              name='email'
              placeholder='abc@example.com'
              value={data.email}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className='customer_row customer_row_checkbox'>
            <div className='title'>顧客標記</div>
            <label>
              <input type="checkbox" name="" id="" />
              <span>VIP</span>
            </label>
            <label>
              <input type="checkbox" name="" id="" />
              <span>奧客</span>
            </label>
            <label>
              <input type="checkbox" name="" id="" />
              <span>蛋奶素</span>
            </label>
            <label>
              <input type="checkbox" name="" id="" />
              <span>全素</span>
            </label>
          </div>
          <hr />
        </div>
        <div className='customer_advanced'>
          <h5>進階選填</h5>
          <div className='customer_row'>
            <div className='title'>生日</div>
            {renderDatePicker()}
          </div>
          <div className='customer_row'>
            <div className='title'>公司名稱</div>
            <input
              type="text"
              name='company'
              placeholder='MENU店+'
              onChange={(e) => handleInputChange(e)}
              value={data.company}
            />
          </div>
          <div className='customer_row'>
            <div className='title'>公司電話</div>
            <input
              type="text"
              name='company_tele'
              placeholder='0212345678'
              onChange={(e) => handleInputChange(e)}
              value={data.company_tele}
            />
          </div>
          <div className='customer_row'>
            <div className='title'>統編</div>
            <input
              type="text"
              name='company_id'
              placeholder='66668888'
              onChange={(e) => handleInputChange(e)}
              value={data.company_id || ''}
            />
          </div>
          <div className='customer_row'>
            <div className='title'>顧客筆記</div>
            <textarea
              name='note'
              placeholder='您可以輸入顧客的用餐喜好或是個人特徵，例如：海鮮過敏/需兒童椅'
              onChange={(e) => handleInputChange(e)}
              value={data.note}
            />
          </div>
        </div>
      </div>

      { showCancelEditModal &&
        <CustomerEditCancelPopup
          setShowCancelEditModal={setShowCancelEditModal}
          cancelPopup={cancelPopup}
        />
      }
      { showConfirmBlockedListModal &&
        <ConfirmBlockedCustomerModal
          type='create'
          setShowConfirmBlockedListModal={setShowConfirmBlockedListModal}
          submit={submit}
        />
      }
    </Modal>
  );
};

const CustomerEditCancelPopup = ({ setShowCancelEditModal, cancelPopup }) => {
  const continueEdit = () => {
    setShowCancelEditModal(false);
  };

  const handleSubmit = () => {
    setShowCancelEditModal(false);
    cancelPopup();
  };

  return (
    <Modal
      title='放棄新增嗎？'
      visible
      centered
      closable={false}
      className="customerEditCancelPopup"
      footer={
        <React.Fragment>
          <button
            className='btn_cancel'
            onClick={continueEdit}
          >
            取消
          </button>
          <button
            className='btn_confirm'
            onClick={handleSubmit}
          >
            確認
          </button>
        </React.Fragment>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      退出將不會保留顧客資料。確定退出嗎？
    </Modal>
  );
};

export default CrmCustomerEditorModal;