import React, { useState, useEffect, useCallback, useContext } from 'react';
import $ from 'jquery';
import { Modal } from 'antd';

import { config } from '../../../../utils/config';
import {handleError} from '../../../../libs/handler';

import { MessageAddStateContext } from '../../crmContext/MessageAddContext';

const BuyMsgPointsModal = () => {
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { points, buyMsgPointsType, messageSetting } = MessageAddState;
  const { content, customers } = messageSetting;

  const [ buyPoints, setBuyPoints ] = useState('');
  const [ error, setError ] = useState(false);

  useEffect(() => {
    const phoneInput = document.getElementsByClassName('msgInput')[0];

    if(error) {
      phoneInput.style.border = '1px solid #EF6868';
    } else {
      phoneInput.style.border = '1px solid #C8C8C8';
    }
  }, [ error ]);

  const handleSelectPoints = (e) => {
    const value = e.target.value;

    $('#apForm').html('');

    if(value < 100 || isNaN(value)){ 
      return setBuyPoints(undefined);
    };

    $.ajax({url: window.domain + '/dashboard/booking_settings/buy_msg_point', 
          method: 'POST',
          data: {amount: value},
          xhrFields: { withCredentials: true }})
      .done(function(data){        
        $('#apForm').html('');
        //debugger
        for(let k in data){ 
          if(data.hasOwnProperty(k))
            $('#apForm').append('<input name="' + k + '" type="hidden" value="' + data[k] + '">')
        }
        setBuyPoints(data);
      })
      .fail(function(xhr){
        handleError(xhr);
      });
  };

  const submitBuyMsgPoints = () => {
    if(buyPoints !== undefined && buyPoints !== "") {
      document.getElementById('apForm').submit();
      setBuyPoints('');
    } else {
      setError(true);
    }
  };

  const cancelPopup = useCallback(() => {
    MessageAddDispatch({ type: 'cancelPopup' });
	}, [ MessageAddDispatch ]);

  const renderContent = () => {
    let letters = Math.ceil(content.length / 75);
    let costPoints = letters * customers.length;
    let owePoints = costPoints - points;

    if(buyMsgPointsType === 'notEnough') {
      return (
        <div className="text">
          您目前簡訊剩餘點數{points}點，還差{owePoints}點。您可在下方選擇購買的點數額度。
        </div>
      )
    } else {
      return (
        <div className="text">
          於下方選擇購買的點數額度。
        </div>
      )
    }
  }

  return (
    <Modal
      title={buyMsgPointsType === 'notEnough' ? '點數不足' : '購買簡訊點數'}
      visible
      centered
      className="buyMsgPointsModal"
      onCancel={cancelPopup}
      footer={
        <button onClick={() => submitBuyMsgPoints()}>購買點數</button>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      { renderContent() }

      <div className="buyMsgPointsModal_buy">
        <h5>選擇購買簡訊點數</h5>
        <div className="selectBox">
          <select
            className="form-control input-lg msgInput"
            onChange={(e) => {handleSelectPoints(e)}}
          >
            <option value="" disabled selected>請選擇欲購買項目</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={300}>300</option>
            <option value={400}>400</option>
            <option value={500}>500</option>
          </select>
        </div>
        { error ? <div className="error_text">請選擇購買簡訊點數額度。</div> : null}
      </div>

      <form
        action={config.allpay_checkout}
        id="apForm"
        method="post"
        target="_blank"
      ></form>
    </Modal>
  );
};

export default BuyMsgPointsModal;