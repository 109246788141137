import _ from 'lodash';
import {
	ADDBOOKING,
	EDITBOOKING,
	NEWADDBOOKING_FETCHSTART,
	NEWADDBOOKING_FETCHSUCCESS,
	NEWADDBOOKING_FETCHFAILURE,
	NEWADDBOOKING_GETCQ,
	NEWADDBOOKING_HANDLETAGCHANGE,
	NEWADDBOOKING_HANDLEQUESTIONCHANGE,
	NEWADDBOOKING_HANDLEQUANTITYCHANGE,
	NEWADDBOOKING_HANDLECUSTOMERINFOCHANGE,
	NEWADDBOOKING_HANDLEPHONEKEYIN,
	NEWADDBOOKING_TYPEHEADDATA,
	NEWADDBOOKING_PHONENUMPADVISIBLE,
	NEWADDBOOKING_TOGGLECUSTOMERMEMO,
	NEWADDBOOKING_TOGGLEONSITE,
	NEWADDBOOKING_SETTABLECONDITION,
	NEWADDBOOKING_ATTENDANCENUMPADVISIBLE,
	NEWADDBOOKING_HANDLEATTENDANCECHANGE,
	NEWADDBOOKING_BOOKINGATTENDACNE,
	NEWADDBOOKING_SENDTABLETIME,
	NEWADDBOOKING_TOGGLEDATEPICKER,
	NEWADDBOOKING_TIMECLICK,
	NEWADDBOOKING_CHOOSEDATE,
	NEWADDBOOKING_DATEPICKERSUBMIT,
	NEWADDBOOKING_GETTABLE,
	NEWADDBOOKING_TOGGLETABLEPICKER,
	NEWADDBOOKING_RESETBOOKINGTIME,
	NEWADDBOOKING_TABLEPICKERSUBMIT,
	NEWADDBOOKING_RESETTABLEPICKER,
	NEWADDBOOKING_SERVICETIMECHANGE,
	NEWADDBOOKING_UPDATEDATA,
	NEWADDBOOKING_SEARCHING,
	NEWADDBOOKING_HIDEONSPOT,
	NEWADDBOOKING_SETCUSTOMERINFO,
	NEWADDBOOKING_RESETTABLECONDITION,
	NEWADDBOOKING_RESETDEFAULT_SEATCONDITION,
	NEWADDBOOKING_RESETDEFAULT_UI,
	NEWADDBOOKING_RESETDEFAULT_CUSTOMERQ,
	NEWADDBOOKING_RESETDEFAULT_CUSTOMERDETAIL,
	NEWADDBOOKING_SETTINGBOOKINGTIME,
	NEWADDBOOKING_EDIT_SEAT_STATE,
	NEWADDBOOKING_EDIT_FETCHSUCCESS,
	NEWADDBOOKING_HANDLEDEPOSITDETAILCHANGE,
	NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONERROR,
	NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONRESEND
} from '../actions/addBookingAction';

const dateReg = /-/g;
const OUTCOMETYPE_1 = '請選擇座位',
	OUTCOMETYPE_2 = '已選座位',
	OUTCOMETYPE_3 = '該時段找不到可安排的座位';

const bookingTimeTxt_1 = '請先選擇預約人數',
	bookingTimeTxt_2 = '請選擇預約時間',
	bookingTimeTxt_3 = '無可預約的時間';

const SEARCHINGTXT = '搜尋中';

const init_addModal = {
	modalTitle: '新增'
};

const init_addBookingComponentUI = {
	isHiddenCustomerMemo: true,
	isHiddenDatepicker: true,
	isHiddenTablePicker: true,
	isHiddenAttendanceNumpad: true,
	isHiddenOnSpotCustomer: false
};

const init_tableCondition = {
	bookingAttend: '',
	bookingTimeRes: bookingTimeTxt_1,
	bookingResult: '請選擇預約時間',
	severTime_hour: 0,
	severTime_min: 0,
	bookingTime: {
		date: '',
		hour: '',
		min: ''
	},
	loadingTime: false,
	autoTable: false,
	bookingAttendChange: false,
	bookingAttendFirstClick: false,
	tablePickerInit: false,
	newSelected: {
		id: null,
		bookingDate: '',
		time: '',
		dining_time: 0
	},
	outcomeType: OUTCOMETYPE_1,
	tempBookingTime: {
		date: '',
		hour: '',
		min: ''
	},
	tempBookingTimeRes: '',
	tempBookingResult: '',
	filterAvailableTables: {},
	tempFilterAvailableTables: {},
	filterCombinationTables: {},
	tables: [],
	seating: [],
	initSeating: [],
	chosenTableGroup: [],
	availableTables: [],
	availableTime: {},
	defaultTableGroupTxt: 'all'
};

const init_cutomerDetail = {
	customerInfo: {
		phone: '',
		name: '',
		gender: '',
		email: ''
	},
	shopNote: '',
	customerMemo: '',
	customerTag: [],
	customerNote: '',
	onSite: false,
	isHiddenPhoneNumpad: true,
	depositDetail: { // 發送設定
		depositMode: false,
		deposit: '',
		paid: false,
		notification: {
			email: false,
			sms: false
		},
		notificationStatus: { // 發送狀態
			email: null,
			sms: false
		},
		notificationError: false
	}
};

const init_cutomerQ = {
	customQ: [],
	customQAns: {}
};

export function NewAddBooking_ModalSetting(state = init_addModal, action) {
	switch (action.type) {
		case ADDBOOKING:
			return Object.assign({}, state, {
				...state,
				modalTitle: '新增'
			});
		case EDITBOOKING:
			return Object.assign({}, state, {
				...state,
				modalTitle: '修改'
			});
		default:
			return state;
	}
}

export function NewAddBooking_CustomerInfo(state = init_cutomerDetail, action) {
	switch (action.type) {
		case NEWADDBOOKING_HANDLECUSTOMERINFOCHANGE:
			return handleCustomerInfo(state, action);
		case NEWADDBOOKING_HANDLEPHONEKEYIN:
			return handlePhoneKeyIn(state, action);
		case NEWADDBOOKING_TYPEHEADDATA:
			return Object.assign({}, state, {
				...state,
				customerInfo: {
					phone: action.data.phone_number,
					name: action.data.last_name,
					gender: action.data.gender,
					email: action.data.email
				},
				customerTag: action.data.customer_tags,
				customerNote: action.data.note
			});
		case NEWADDBOOKING_PHONENUMPADVISIBLE:
			return Object.assign({}, state, {
				...state,
				isHiddenPhoneNumpad: action.status
			});
		case NEWADDBOOKING_TOGGLEONSITE:
			return Object.assign({}, state, {
				...state,
				onSite: !state.onSite,
				customerInfo: {
					...state.customerInfo,
					name: '現場顧客'
				}
			});
		case NEWADDBOOKING_HANDLEDEPOSITDETAILCHANGE:
			return handleDepositDetail(state, action);
		case NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONERROR:
			return handleDepositNotificationError(state, action);
		case NEWADDBOOKING_HANDLEDEPOSITNOTIFICATIONRESEND:
			return handleDepositNotificationResend(state, action);
		case NEWADDBOOKING_SETCUSTOMERINFO:
			return setCustomerDetail(state, action);
		case NEWADDBOOKING_RESETDEFAULT_CUSTOMERDETAIL:
			return Object.assign({}, state, {
				customerInfo: {
					phone: '',
					name: '',
					gender: '',
					email: ''
				},
				shopNote: '',
				customerMemo: '',
				customerTag: [],
				customerNote: '',
				onSite: false,
				isHiddenPhoneNumpad: true,
				depositDetail: {
					depositMode: false,
					deposit: '',
					paid: false,
					notification: {
						email: false,
						sms: false
					},
					notificationStatus: {
						email: null,
						sms: false
					},
					notificationError: false
				}
			});
		default:
			return state;
	}
}

export function NewAddBooking_SeatCondition(state = init_tableCondition, action) {
	switch (action.type) {
		case NEWADDBOOKING_FETCHSTART:
			return Object.assign({}, state, {
				...state,
				bookingAttendChange: false,
				loadingTime: true
			});
		case NEWADDBOOKING_FETCHSUCCESS:
			return getTableTime(state, action);
		case NEWADDBOOKING_FETCHFAILURE:
			return state;
		case NEWADDBOOKING_SETTABLECONDITION:
			return setTableCondition(state, action);
		case NEWADDBOOKING_HANDLEATTENDANCECHANGE:
			return Object.assign({}, state, {
				...state,
				bookingAttendChange: action.attendanceChange
			});
		case NEWADDBOOKING_BOOKINGATTENDACNE:
			return Object.assign({}, state, {
				...state,
				bookingAttend: action.attendance
			});
		case NEWADDBOOKING_SENDTABLETIME:
			return tableTime(state);
		case NEWADDBOOKING_TIMECLICK:
			return clickTime(state, action);
		case NEWADDBOOKING_CHOOSEDATE:
			return Object.assign({}, state, {
				...state,
				loadingTime: true,
				tempBookingTime: {
					...state.tempBookingTime,
					date: action.date
				}
			});
		case NEWADDBOOKING_DATEPICKERSUBMIT:
			return datepicker(state);
		case NEWADDBOOKING_GETTABLE:
			return getTable(state, action);
		case NEWADDBOOKING_RESETBOOKINGTIME:
			return Object.assign({}, state, {
				...state,
				filterAvailableTables: state.tempFilterAvailableTables,
				bookingTimeRes: state.tempBookingTimeRes,
				tempBookingTime: {
					date: state.bookingTime.date,
					hour: state.bookingTime.hour,
					min: state.bookingTime.min
				}
			});
		case NEWADDBOOKING_TABLEPICKERSUBMIT:
			return tableSubmit(state, action);
		case NEWADDBOOKING_RESETTABLEPICKER:
			return resetTable(state);
		case NEWADDBOOKING_SERVICETIMECHANGE:
			return serviceTimeChange(state, action);
		case NEWADDBOOKING_UPDATEDATA:
			return updateDataTableCondition(state, action);
		case NEWADDBOOKING_SETTINGBOOKINGTIME:
			return Object.assign({}, state, {
				...state,
				bookingTime: {
					date: action.bookingTime_date.slice(0, 10),
					hour: action.bookingTime_date.slice(11, 13),
					min: action.bookingTime_date.slice(14)
				},
				tempBookingTime: {
					date: action.bookingTime_date.slice(0, 10),
					hour: action.bookingTime_date.slice(11, 13),
					min: action.bookingTime_date.slice(14)
				},
				bookingTimeRes: action.bookingTime_date,
				tempBookingTimeRes: action.bookingTime_date
			});
		case NEWADDBOOKING_SEARCHING:
			return Object.assign({}, state, {
				...state,
				bookingResult: SEARCHINGTXT
			});
		case NEWADDBOOKING_RESETTABLECONDITION:
			return Object.assign({}, state, {
				initSeating: [],
				seating: [],
				chosenTableGroup: [],
				loadingTime: true
			});
		case NEWADDBOOKING_EDIT_SEAT_STATE:
			return editSeatState(state);
		case NEWADDBOOKING_EDIT_FETCHSUCCESS:
			return getEditState(state, action)
		case NEWADDBOOKING_RESETDEFAULT_SEATCONDITION:
			return Object.assign({}, state, {
				bookingAttend: '',
				bookingTimeRes: bookingTimeTxt_1,
				bookingResult: '請選擇預約時間',
				severTime_hour: 0,
				severTime_min: 0,
				bookingTime: {
					date: '',
					hour: '',
					min: ''
				},
				loadingTime: false,
				autoTable: false,
				bookingAttendChange: false,
				tablePickerInit: false,
				newSelected: {
					id: null,
					bookingDate: '',
					time: '',
					dining_time: 0
				},
				outcomeType: OUTCOMETYPE_1,
				tempBookingTime: {
					date: '',
					hour: '',
					min: ''
				},
				tempBookingTimeRes: '',
				tempBookingResult: '',
				filterAvailableTables: {},
				tempFilterAvailableTables: {},
				filterCombinationTables: {},
				// tempFilterCombinationTables: {},
				tables: [],
				seating: [],
				initSeating: [],
				chosenTableGroup: [],
				availableTables: [],
				availableTime: {},
				defaultTableGroupTxt: 'all'
			});
		default:
			return state;
	}
}

export function NewAddBooking_UI(state = init_addBookingComponentUI, action) {
	switch (action.type) {
		case NEWADDBOOKING_TOGGLECUSTOMERMEMO:
			return Object.assign({}, state, {
				...state,
				isHiddenCustomerMemo: action.status
			});
		case NEWADDBOOKING_ATTENDANCENUMPADVISIBLE:
			return Object.assign({}, state, {
				...state,
				isHiddenAttendanceNumpad: action.status
			});
		case NEWADDBOOKING_TOGGLEDATEPICKER:
			return Object.assign({}, state, {
				...state,
				isHiddenDatepicker: action.status
			});
		case NEWADDBOOKING_TOGGLETABLEPICKER:
			return Object.assign({}, state, {
				...state,
				isHiddenTablePicker: action.status
			});
		case NEWADDBOOKING_HIDEONSPOT:
			return Object.assign({}, state, {
				...state,
				isHiddenOnSpotCustomer: true
			});
		case NEWADDBOOKING_RESETDEFAULT_UI:
			return Object.assign({}, state, {
				isHiddenCustomerMemo: true,
				isHiddenDatepicker: true,
				isHiddenTablePicker: true,
				isHiddenAttendanceNumpad: true,
				isHiddenOnSpotCustomer: false
			});
		default:
			return state;
	}
}

export function NewAddBooking_Question(state = init_cutomerQ, action) {
	switch (action.type) {
		case NEWADDBOOKING_GETCQ:
			return Object.assign({}, state, {
				customQ: action.customQ,
				customQAns: action.customQAns
			});
		case NEWADDBOOKING_HANDLETAGCHANGE:
			return handleTag(state, action);
		case NEWADDBOOKING_HANDLEQUESTIONCHANGE:
			return handleQuestion(state, action);
		case NEWADDBOOKING_HANDLEQUANTITYCHANGE:
			return handleQuantity(state, action);
		case NEWADDBOOKING_RESETDEFAULT_CUSTOMERQ:
			return Object.assign({}, state, {
				customQAns: {}
			});
		default:
			return state;
	}
}

function editSeatState(state) {
	let newState = Object.assign({}, state);

	let filterTime = newState.bookingTime.date + ' ' + newState.bookingTime.hour + ':' + newState.bookingTime.min;

	newState['bookingResult'] = JSON.parse(JSON.stringify(newState.tempBookingResult));
	newState['bookingTimeRes'] = filterTime;
	return newState;
}

function handleCustomerInfo(state, action) {
	let newState = Object.assign({}, state);
	let { e, infoType, addType } = action;
	let txt = addType === 'customer' ? e : e.target.value;

	if (infoType === 'phone') {
		newState.customerInfo['phone'] = txt;
		newState.customerTag = [];
	} else if (infoType === 'shopNote') {
		newState['shopNote'] = txt;
	} else {
		newState.customerInfo[infoType] = txt;
	}

	return newState;
}

function handlePhoneKeyIn(state, action) {
	let newState = Object.assign({}, state);
	let { e } = action;

	let phoneArray = newState.customerInfo.phone.toString().split('');

	if (e === 'delete') {
		phoneArray.pop();
	} else if (e === 'clear') {
		phoneArray = [];
	} else {
		phoneArray.push(e.toString());
	}

	newState.customerInfo['phone'] = phoneArray.join('');
	newState.customerTag = [];
	newState.customerNote = '';

	return newState;
}

function handleTag(customQState, action) {
	let { id, multiple, optionId } = action;
	let newCustomQState = Object.assign({}, customQState);
	let { customQAns } = newCustomQState;
	if (multiple) {
		//複選
		if (customQAns[id]) {
			if (customQAns[id][optionId]) {
				delete customQAns[id][optionId];
			} else {
				customQAns[id][optionId] = -1;
			}
		} else {
			customQAns[id] = {};
			customQAns[id][optionId] = -1;
		}
	} else {
		//單選
		if (customQAns[id] && customQAns[id][optionId] === -1) {
			customQAns[id] = {};
		} else {
			customQAns[id] = {};
			customQAns[id][optionId] = -1;
		}
	}

	return newCustomQState;
}

function handleQuestion(customQState, action) {
	let newCustomQState = Object.assign({}, customQState);
	let { customQAns } = newCustomQState;
	let { id, e } = action;

	customQAns[id] = e.target.value;

	return newCustomQState;
}

function handleQuantity(customQState, action) {
	let newCustomQState = Object.assign({}, customQState);
	let { customQAns } = newCustomQState;
	let { id, e, multiple, optionId } = action;
	let value = parseInt(e.target.value, 10);

	if (multiple) {
		//複選
		if (customQAns[id]) {
			if (value === 0) {
				delete customQAns[id][optionId];
			} else {
				customQAns[id][optionId] = value;
			}
		} else {
			customQAns[id] = {};
			customQAns[id][optionId] = value;
		}
	} else {
		//單選
		customQAns[id] = {};
		customQAns[id][optionId] = value;
	}

	return newCustomQState;
}

function clickTime(tableConditionState, action) {
	let newTableConditionState = Object.assign({}, tableConditionState);
	let { tempBookingTime, availableTime } = newTableConditionState;
	let { timeType, time } = action;

	tempBookingTime[action.timeType] = time;

	if (timeType === 'hour' && availableTime[time]) {
		tempBookingTime['min'] = availableTime[time][0];
	};

	if (timeType === 'hour' && !availableTime[time]) {
		tempBookingTime['min'] = '00';
	};

	return newTableConditionState;
}

function tableTime(tableConditionState) {
	let { bookingAttend, bookingAttendChange } = tableConditionState;
	let newTableConditionState = Object.assign({}, tableConditionState);

	if (bookingAttend === '') {
		newTableConditionState.bookingTimeRes = bookingTimeTxt_1;
		newTableConditionState.bookingResult = bookingTimeTxt_2;
		newTableConditionState.seating = [];
		newTableConditionState.initSeating = [];
	} else if (bookingAttend !== '' && bookingAttendChange) {
		newTableConditionState.bookingTimeRes = bookingTimeTxt_2;
		newTableConditionState.bookingResult = bookingTimeTxt_2;
		// newTableConditionState.seating = [];
		// newTableConditionState.initSeating = [];
		newTableConditionState.loadingTime = true;
	}

	return newTableConditionState;
}

//seatingRes 安排結果(文字顯示)
//seating 已選的座位(若修改座位會被改變): 影響座位顯示, 判斷座位是否還在
//initSeating 暫時儲存原本已選的座位
//defaultTableGroupTxt Tablepicker要顯示哪個區域 (for Tablepicker)
//availableTables 該時段有空位的桌子 (for Tablepicker)
//chosenTableGroup 所選擇桌子所屬的區域


function getEditState(tableCondition, action){
	let newTableConditon = Object.assign({}, tableCondition);
	let { availableTime, tempBookingTime, bookingTime } = newTableConditon;
	let { data } = action;

	availableTime = {};
	let filterTime = bookingTime.date + ' ' + bookingTime.hour + ':' + bookingTime.min;


	Object.keys(data.available_tables_by_time).forEach((item) => {
		if (data.available_tables_by_time[item].length !== 0) {
			let hour_idx = item.substring(11, 13),
				min_idex = item.substring(14, 16);

			if (availableTime.hasOwnProperty(hour_idx) && availableTime[hour_idx].indexOf(min_idex) === -1) {
				availableTime[hour_idx].push(min_idex);
			} else {
				availableTime[hour_idx] = [];
				availableTime[hour_idx].push(min_idex);
			}
		}
	});

	if (availableTime[tempBookingTime.hour]) {
		let minIndex = availableTime[tempBookingTime.hour].indexOf(tempBookingTime.min);
		if (minIndex === -1) {
			tempBookingTime['hour'] = '';
			tempBookingTime['min'] = '';
		}
	}

	newTableConditon['filterAvailableTables'] = data.available_tables_by_time;
	newTableConditon['filterCombinationTables'] = data.combinations_by_time;
	newTableConditon['tables'] = data.tables;
	newTableConditon['availableTime'] = availableTime;
	newTableConditon['loadingTime'] = false;
	newTableConditon['tempBookingTime'] = tempBookingTime;
	newTableConditon['tempBookingTimeRes'] =
		tempBookingTime.date + ' ' + tempBookingTime.hour + ' ' + tempBookingTime.min;

	return editFilterAvailableTable(newTableConditon, data.available_tables_by_time[filterTime]);
}

//選定預約人數
function getTableTime(tableCondition, action) {
	let newTableConditon = Object.assign({}, tableCondition);
	let { availableTime, tempBookingTime, newSelected, bookingTime } = newTableConditon;
	let { data } = action;

	availableTime = {};

	Object.keys(data.available_tables_by_time).forEach((item) => {
		if (data.available_tables_by_time[item].length !== 0) {
			let hour_idx = item.substring(11, 13),
				min_idex = item.substring(14, 16);

			if (availableTime.hasOwnProperty(hour_idx) && availableTime[hour_idx].indexOf(min_idex) === -1) {
				availableTime[hour_idx].push(min_idex);
			} else {
				availableTime[hour_idx] = [];
				availableTime[hour_idx].push(min_idex);
			}
		}
	});

	if (!availableTime[tempBookingTime.hour]) {
		tempBookingTime['hour'] = '';
		tempBookingTime['min'] = '';
	} else if (availableTime[tempBookingTime.hour]) {
		let minIndex = availableTime[tempBookingTime.hour].indexOf(tempBookingTime.min);
		if (minIndex === -1) {
			tempBookingTime['hour'] = '';
			tempBookingTime['min'] = '';
		}
	}

	newTableConditon['filterAvailableTables'] = data.available_tables_by_time;
	newTableConditon['filterCombinationTables'] = data.combinations_by_time;
	newTableConditon['tables'] = data.tables;
	newTableConditon['availableTime'] = availableTime;
	newTableConditon['loadingTime'] = false;
	newTableConditon['tempBookingTime'] = tempBookingTime;

	if (Object.keys(availableTime).length === 0) {
		newTableConditon['tempBookingTimeRes'] = `${tempBookingTime.date}${bookingTimeTxt_3}`;
		newTableConditon['bookingTimeRes'] = `${tempBookingTime.date}${bookingTimeTxt_3}`;
		newTableConditon['bookingResult'] = tempBookingTime.date + '找不到可安排的座位';
		newTableConditon['outcomeType'] = OUTCOMETYPE_3;
		newTableConditon['seating'] = [];
		newTableConditon['initSeating'] = [];
		newTableConditon['chosenTableGroup'] = [];
		newTableConditon['defaultTableGroupTxt'] = 'all';

		// newTableConditon['tempFilterCombinationTables'] = data.combinations_by_time;
		newTableConditon['tempFilterAvailableTables'] = data.available_tables_by_time;

		return newTableConditon;
	} else if (action.time === 'time' && Object.keys(availableTime).length !== 0) {
		return newTableConditon;
	} else if (Object.keys(availableTime).length !== 0) {
		// newTableConditon['tempFilterCombinationTables'] = data.combinations_by_time;
		newTableConditon['tempFilterAvailableTables'] = data.available_tables_by_time;

		if (newSelected.id !== null) {
			//修改
			return getTable(newTableConditon);
		} else {
			//新增
			// newTableConditon["outcomeType"] = OUTCOMETYPE_1;

			if (bookingTime.hour === '' && bookingTime.min === '') {
				//沒選時間
				newTableConditon['bookingTimeRes'] = bookingTimeTxt_2;
				newTableConditon['tempBookingTimeRes'] = bookingTimeTxt_2;
				newTableConditon['bookingResult'] = bookingTimeTxt_2;

				return newTableConditon;
			} else if (bookingTime.hour !== '' && bookingTime.min !== '') {
				if (
					availableTime[bookingTime.hour] &&
					availableTime[bookingTime.hour].indexOf(bookingTime.min) !== -1
				) {
					return getTable(newTableConditon);
				} else {
					newTableConditon['bookingTimeRes'] = bookingTimeTxt_2;
					newTableConditon['tempBookingTimeRes'] = bookingTimeTxt_2;
					newTableConditon['bookingResult'] = bookingTimeTxt_2;
					newTableConditon['bookingTime'].hour = '';
					newTableConditon['bookingTime'].min = '';
					newTableConditon['tempBookingTime'].hour = '';
					newTableConditon['tempBookingTime'].min = '';

					return newTableConditon;
				}
			}
		}
	}
}

//預約時間確認、點選現場客戶自動填入時間
function getTable(newTableConditon) {
	let {
		bookingTime,
		tempBookingTime,
		filterCombinationTables,
		filterAvailableTables,
		autoTable,
		newSelected,
		seating,
		tables,
		availableTime
	} = newTableConditon;

	let filterTime = bookingTime.date + ' ' + bookingTime.hour + ':' + bookingTime.min;

	newTableConditon['availableTables'] = [];

	if (newSelected.id !== null) {
		//修改

		console.log("seating----", seating)

		let res;
		//檢查座位是否還在：比較table_setting_id & idx
		for (let i = 0; i < seating.length; i++) {
			res = tables.findIndex((seat) => {
				return (
					seating[i].idx === seat.idx &&
					seating[i].table_setting_id === seat.table_setting_id
				);
			});

			if (res === -1) {
				break;
			}
		}

		//一打開modal的座位顯示
		if (res === -1 || res === undefined || res === null) {
			//原本座位已從座位設定中移除
			//座位清空
			newTableConditon['seating'] = [];
			newTableConditon['initSeating'] = [];
			newTableConditon['chosenTableGroup'] = [];
			newTableConditon['defaultTableGroupTxt'] = 'all';
			newTableConditon['tablePickerInit'] = false;

			// newTableConditon['outcomeType'] = bookingTimeTxt_1;

			newTableConditon['bookingTimeRes'] = filterTime;
			newTableConditon['tempBookingTimeRes'] = filterTime;
			newTableConditon['bookingResult'] = OUTCOMETYPE_1;
			newTableConditon['tempBookingResult'] = OUTCOMETYPE_1;

			return filterAvailableTable(newTableConditon, filterAvailableTables[filterTime]);
		} else {
			//座位還在座位設定中

			newTableConditon['tablePickerInit'] = false;

			newTableConditon['bookingTimeRes'] = filterTime;
			newTableConditon['tempBookingTimeRes'] = filterTime;
			newTableConditon['outcomeType'] = OUTCOMETYPE_2;

			return filterAvailableTable(newTableConditon, filterAvailableTables[filterTime]);
		}
	} else {
		//新增
		// newTableConditon['availableTables'] = [];

		if (autoTable) {
			if (filterCombinationTables[filterTime]) {
				newTableConditon['bookingResult'] = OUTCOMETYPE_1;
				newTableConditon['tempBookingResult'] = OUTCOMETYPE_1;

				let atutoRes = autoTableFunc(
					newTableConditon,
					filterTime,
					filterAvailableTables[filterTime],
					filterCombinationTables[filterTime]
				);

				return filterAvailableTable(atutoRes, filterAvailableTables[filterTime]);
			} else {
				//沒組合
				newTableConditon['seating'] = [];
				newTableConditon['initSeating'] = [];
				newTableConditon['bookingResult'] = OUTCOMETYPE_1;
				newTableConditon['tempBookingResult'] = OUTCOMETYPE_1;
				newTableConditon['chosenTableGroup'] = [];
				newTableConditon['defaultTableGroupTxt'] = 'all';
				newTableConditon['bookingTimeRes'] = filterTime;

				return filterAvailableTable(newTableConditon, filterAvailableTables[filterTime]);
			}
		} else {
			//新增沒有自動配位依據filterAvailableTables (available_tables_by_time)
			//若選擇了"現場客戶" 須判斷時間是否在availableTime裡

			if (filterAvailableTables[filterTime].length !== 0) {
				//還有空位
				newTableConditon['bookingTimeRes'] = filterTime;

				return filterAvailableTable(
					newTableConditon,
					filterAvailableTables[filterTime]
					// filterCombinationTables[filterTime]
				);
			} else {
				//沒有空位

				newTableConditon['seating'] = [];
				newTableConditon['initSeating'] = [];
				newTableConditon['bookingResult'] = OUTCOMETYPE_3;
				newTableConditon['tempBookingResult'] = OUTCOMETYPE_3;
				newTableConditon['outcomeType'] = OUTCOMETYPE_3;
				newTableConditon['chosenTableGroup'] = [];
				newTableConditon['defaultTableGroupTxt'] = 'all';

				return newTableConditon;
			}
		}
	}
}

//檢查座位是否還在availableTables裡
function editFilterAvailableTable(newTableConditon, availableTableIndex) {
	let { availableTables, tables, seating, chosenTableGroup, tempBookingResult } = newTableConditon;
	// availableTables 該時段空位
	// availableTableIndex 該時段空桌序號;

	availableTableIndex.map((id) => {
		availableTables.push(tables[id]);
		return availableTables;
	});

	if (seating.length !== 0) {
		//已經選擇座位：檢查座位是否在availableTables
		let checkGroupTables = [];

		availableTables.map((table) => {
			chosenTableGroup.map((group) => {
				if (table.group === group) checkGroupTables.push(table.table_number);
				return null;
			});
			return null;
		});

		let res = null;
		for (let i = 0; i < seating.length; i++) {
			res = checkGroupTables.indexOf(seating[i].table_number);
			if (res === -1) break;
		}

		if (res === -1) {
			//座位不在
			newTableConditon['chosenTableGroup'] = [];
			newTableConditon['defaultTableGroupTxt'] = 'all';
		} else {
			newTableConditon['bookingResult'] = tempBookingResult;
		}
	} else {
		newTableConditon['chosenTableGroup'] = [];
		newTableConditon['defaultTableGroupTxt'] = 'all';
	}

	newTableConditon['tablePickerInit'] = true;

	return newTableConditon;
}

//檢查座位是否還在availableTables裡
function filterAvailableTable(newTableConditon, availableTableIndex) {
	let { availableTables, tables, seating, chosenTableGroup, tempBookingResult } = newTableConditon;
	// availableTables 該時段空位
	// availableTableIndex 該時段空桌序號;

	availableTableIndex.map((id) => {
		availableTables.push(tables[id]);
		return availableTables;
	});

	if (seating.length !== 0) {
		//已經選擇座位：檢查座位是否在availableTables
		let checkGroupTables = [];

		availableTables.map((table) => {
			chosenTableGroup.map((group) => {
				if (table.group === group) checkGroupTables.push(table.table_number);
				return null;
			});
			return null;
		});

		let res = null;
		for (let i = 0; i < seating.length; i++) {
			res = checkGroupTables.indexOf(seating[i].table_number);
			if (res === -1) break;
		}

		if (res === -1) {
			//座位不在
			seating = [];
			newTableConditon['seating'] = [];
			newTableConditon['initSeating'] = [];
			newTableConditon['bookingResult'] = OUTCOMETYPE_1;
			newTableConditon['tempBookingResult'] = OUTCOMETYPE_1;
			newTableConditon['outcomeType'] = OUTCOMETYPE_1;
			newTableConditon['chosenTableGroup'] = [];
			newTableConditon['defaultTableGroupTxt'] = 'all';
		} else {
			newTableConditon['bookingResult'] = tempBookingResult;
		}
	} else {
		newTableConditon['chosenTableGroup'] = [];
		newTableConditon['defaultTableGroupTxt'] = 'all';

		newTableConditon['bookingResult'] = OUTCOMETYPE_1;
		newTableConditon['tempBookingResult'] = OUTCOMETYPE_1;
		newTableConditon['outcomeType'] = OUTCOMETYPE_1;
	}

	newTableConditon['tablePickerInit'] = true;

	return newTableConditon;
}

function autoTableFunc(newTableConditon, filterTime, filterAvailableTables, filterCombinationTables) {
	let { seating, tables } = newTableConditon;
	let combination = JSON.parse(JSON.stringify(filterCombinationTables)),
		combinationLength = combination.length;
	let available = JSON.parse(JSON.stringify(filterAvailableTables));
	let availableTablesIndex = available.map((tableIndex) => {
		return tables[tableIndex];
	});
	let tableGroup = []; // 可選的區域桌子
	let tableInfo = {}; // {10-10: 2} 10-10人桌可選兩張
	let groupName = ''; // 選中的區域名稱

	seating = [];

	for (let i = 0; i < combinationLength; i++) {
		let name = combination[i][0].group;
		let hasGroup = availableTablesIndex
			.map((t) => {
				return t.group;
			})
			.indexOf(name);

		if (hasGroup !== -1) {
			groupName = name;
			tableGroup = availableTablesIndex.filter((t) => {
				return t.group === name;
			});

			for (let j = 0; j < combination[i].length; j++) {
				let peopleRange = combination[i][j].min_seat + '-' + combination[i][j].max_seat;
				if (!tableInfo.hasOwnProperty(peopleRange)) {
					tableInfo[peopleRange] = 1;
				} else {
					tableInfo[peopleRange] += 1;
				}
			}

			break;
		}
	}

	tableGroup.map((table) => {
		let tableRange = table.min_seat + '-' + table.max_seat;

		if (tableInfo[tableRange] && tableInfo[tableRange] > 0) {
			seating.push(table);
			tableInfo[tableRange] -= 1;
		}
		return seating;
	});

	newTableConditon['bookingTimeRes'] = filterTime;
	newTableConditon['outcomeType'] = OUTCOMETYPE_2;
	newTableConditon['bookingResult'] =
		'【' +
		groupName +
		'】' +
		seating.map((t) => {
			return ' ' + t.table_number;
		});
	newTableConditon['tempBookingResult'] =
		'【' +
		groupName +
		'】' +
		seating.map((t) => {
			return ' ' + t.table_number;
		});
	newTableConditon['chosenTableGroup'] = [ groupName ];
	newTableConditon['defaultTableGroupTxt'] = groupName;
	newTableConditon['seating'] = seating;
	newTableConditon['initSeating'] = JSON.parse(JSON.stringify(seating));

	return newTableConditon;
}

function datepicker(tableCondition) {
	let newTableCondition = Object.assign({}, tableCondition);
	let { bookingTime, tempBookingTime, seating, initSeating } = newTableCondition;

	bookingTime['hour'] = tempBookingTime.hour;
	bookingTime['min'] = tempBookingTime.min;
	bookingTime['date'] = tempBookingTime.date;

	seating.map((t) => delete t['compared']);
	initSeating.map((t) => delete t['compared']);

	newTableCondition['bookingResult'] = SEARCHINGTXT;
	newTableCondition['bookingTimeRes'] = tempBookingTime.date + ' ' + tempBookingTime.hour + ' ' + tempBookingTime.min;
	newTableCondition['tempBookingTimeRes'] =
		tempBookingTime.date + ' ' + tempBookingTime.hour + ' ' + tempBookingTime.min;
	newTableCondition['bookingTime'] = bookingTime;
	newTableCondition['tablePickerInit'] = true;

	return newTableCondition;
}

function tableSubmit(tableCondition, action) {
	let newTableCondition = Object.assign({}, tableCondition);
	let { tempSeating, chosenGroup } = action;
	let bookingRes = {};

	tempSeating.map((seat) => {
		if (!bookingRes.hasOwnProperty(seat.group)) {
			bookingRes[seat.group] = [];
			bookingRes[seat.group].push(seat.table_number);
		} else {
			bookingRes[seat.group].push(seat.table_number);
		}

		return null;
	});

	newTableCondition['bookingResult'] = bookingRes;
	newTableCondition['tempBookingResult'] = bookingRes;
	newTableCondition['chosenTableGroup'] = chosenGroup;
	newTableCondition['defaultTableGroupTxt'] = 'all';
	newTableCondition['outcomeType'] = OUTCOMETYPE_2;
	newTableCondition['seating'] = JSON.parse(JSON.stringify(tempSeating));
	newTableCondition['initSeating'] = JSON.parse(JSON.stringify(tempSeating));

	return newTableCondition;
}

//關閉tablepicker
function resetTable(tableCondition) {
	let newTableCondition = Object.assign({}, tableCondition);
	let { outcomeType, initSeating } = newTableCondition;

	if (outcomeType === OUTCOMETYPE_1 || outcomeType === OUTCOMETYPE_3) {
		newTableCondition['initSeating'] = [];
		newTableCondition['seating'] = [];

		return newTableCondition;
	} else if (outcomeType === OUTCOMETYPE_2) {
		newTableCondition['seating'] = JSON.parse(JSON.stringify(initSeating));

		return newTableCondition;
	}
}

function serviceTimeChange(tableCondition, action) {
	let newTableCondition = Object.assign({}, tableCondition);
	let { bookingAttend } = newTableCondition;
	let { t } = action;
	let time = parseInt(t.target.value, 10);

	if (action.serviceType === 'hour') {
		newTableCondition['severTime_hour'] = time;
	} else if (action.serviceType === 'min') {
		newTableCondition['severTime_min'] = time;
	}

	if (bookingAttend !== '') {
		newTableCondition['bookingResult'] = SEARCHINGTXT;
		newTableCondition['bookingTimeRes'] = SEARCHINGTXT;
	}


	return newTableCondition;
}

function updateDataTableCondition(tableCondition, action) {
	let newTableCondition = Object.assign({}, tableCondition);
	let { dining_time, time, seating_json, date, attendance } = action.editBooking;

	let severTime_hour = Math.floor(parseInt(dining_time, 10) / 60),
		severTime_min = parseInt(dining_time, 10) % 60;
	let new_hour = time.slice(0, 2),
		new_min = time.slice(3);
	let selectedSeated = JSON.parse(seating_json).map((seat) => {
		delete seat['compared'];
		return seat;
	});
	let selectedSeatedGroup = selectedSeated.map((seat) => {
		return seat.group;
	});
	let seatingGroup = {};

	selectedSeated.map((seat) => {
		if (!seatingGroup.hasOwnProperty(seat.group)) {
			seatingGroup[seat.group] = [];
			seatingGroup[seat.group].push(seat.table_number);
		} else {
			seatingGroup[seat.group].push(seat.table_number);
		}

		return null;
	});


	newTableCondition = {
		...newTableCondition,
		newSelected: {
			id: action.editBooking.id,
			bookingDate: date,
			time: action.time,
			dining_time: dining_time
		},
		bookingTime: {
			date: date,
			hour: new_hour,
			min: new_min
		},
		tempBookingTime: {
			date: date,
			hour: new_hour,
			min: new_min
		},
		bookingTimeRes: SEARCHINGTXT,
		bookingAttend: attendance.toString(),
		severTime_hour,
		severTime_min,
		tempBookingResult: seatingGroup,
		seating: selectedSeated,
		initSeating: JSON.parse(JSON.stringify(selectedSeated)),
		chosenTableGroup: _.uniq(selectedSeatedGroup),
		defaultTableGroupTxt: 'all'
	};

	return newTableCondition;
}

function setCustomerDetail(state, action) {
	if (!action.editBooking) {
		let { booking_deposit } = action.smsNotificationSettings;
		let { booking_created } = action.emailNotificationSettings;

		return Object.assign({}, state, {
			...state,
			customerInfo: {
				phone: '',
				name: '',
				gender: '',
				email: ''
			},
			shopNote: '',
			customerMemo: '',
			customerTag: [],
			customerNote: '',
			onSite: false,
			isHiddenPhoneNumpad: true,
			depositDetail: {
				depositMode: false,
				deposit: '',
				paid: false,
				notification: {
					email: booking_created ? booking_created.enabled : false,
					sms: booking_deposit ? booking_deposit.offline : false
				},
				notificationStatus: {
					email: null,
					sms: false
				},
				notificationError: false
			}
		});
	} else {
		let { phone_number, last_name, gender, email, shop_memo, memo, on_site, customer_info, deposit, deposit_mode, deposit_paid, sms_notified, mail_status, deposit_mode_settings } = action.editBooking;
		let depositModeSettings = {};

		let despositOff = {
			notification: {
				email: false,
				sms: false
			}
		}

		if(deposit_mode_settings) {
			// 預約系統
			if(deposit_mode_settings.notification) {
				depositModeSettings = deposit_mode_settings;
			} else {
				// 排隊預約系統
				if(JSON.stringify(deposit_mode_settings) === '{}') {
					depositModeSettings = despositOff;
				} else {
					depositModeSettings = JSON.parse(deposit_mode_settings);
				}
			}
		} else {
			depositModeSettings = despositOff;
		}

		return Object.assign({}, state, {
			...state,
			customerInfo: {
				phone: phone_number,
				name: last_name,
				gender: gender,
				email: email
			},
			customerTag: customer_info.tags,
			customerNote: customer_info.note,
			shopNote: shop_memo,
			customerMemo: memo,
			onSite: on_site,
			depositDetail: {
				depositMode: deposit_mode,
				deposit,
				paid: deposit_paid,
				notification: {
					email: depositModeSettings.notification ? depositModeSettings.notification.email : false,
					sms: depositModeSettings.notification ? depositModeSettings.notification.sms : false
				},
				notificationStatus: {
					email: mail_status,
					sms: sms_notified
				},
				notificationError: false
			}
		});
	}
}

function setTableCondition(state, action) {
	return Object.assign({}, state, {
		...state,
		bookingAttend: '',
		bookingTimeRes: bookingTimeTxt_1,
		bookingResult: '請選擇預約時間',
		severTime_hour: Math.floor(parseInt(action.serviceTime, 10) / 60),
		severTime_min: parseInt(action.serviceTime, 10) % 60,
		bookingTime: {
			date: action.date.replace(dateReg, '/'),
			hour: '',
			min: ''
		},
		autoTable: action.autoTable,
		loadingTime: false,
		bookingAttendChange: false,
		tablePickerInit: false,
		newSelected: {
			id: null,
			bookingDate: '',
			time: '',
			dining_time: 0
		},
		outcomeType: OUTCOMETYPE_1,
		tempBookingTime: {
			date: action.date.replace(dateReg, '/'),
			hour: '',
			min: ''
		},
		tempBookingTimeRes: '',
		tempBookingResult: '',
		filterAvailableTables: {},
		filterCombinationTables: {},
		tables: [],
		seating: [],
		initSeating: [],
		chosenTableGroup: '',
		availableTables: [],
		availableTime: {},
		defaultTableGroupTxt: 'all'
	});
}

function handleDepositDetail(state, action) {
	let newState = Object.assign({}, state);
	let { e, detailType } = action;
	let value = detailType !== 'deposit' ? '' : e.target.value;

	if(detailType === 'disabled') {
		newState.depositDetail['depositMode'] = false;
	} else if (detailType === 'depositMode') {
		newState.depositDetail['depositMode'] = !newState.depositDetail['depositMode'];
	} else if (detailType === 'deposit') {
		newState.depositDetail['deposit'] = value;
	} else if(detailType === 'sms_notification') {
		newState.depositDetail.notification['sms'] = !newState.depositDetail.notification['sms'];
	} else {
		newState.depositDetail.notification['email'] = !newState.depositDetail.notification['email'];
	}

	return newState;
}

function handleDepositNotificationError(state, action) {
	let newState = Object.assign({}, state);
	let { e } = action;

	newState.depositDetail['notificationError'] = e;

	return newState;
}

function handleDepositNotificationResend(state, action) {
	let newState = Object.assign({}, state);
	let { e } = action;

	// send 發送完成
	if(e === 'email') {
		newState.depositDetail.notificationStatus['email'] = 'send';
	} else {
		newState.depositDetail.notificationStatus['sms'] = 'send';
	}

	return newState;
}