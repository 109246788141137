import React from 'react';
import { Modal } from 'antd';
import { config } from '../../utils/config'

import './shopFormBindModal.scss';

export default ({ select, logo, visible, cancelSelect, onSync }) => {
  const renderSelectPhotos = () => {
    if(select.objectId !== '' && select.photos) {
      if(select.photos.length > 0) {
        let photos = select.photos.map((photo) => {
          return (
            <div className="photo col-md-4 col-xs-4" key={photo.object_id}>
              <a
                href={config.photo_domain + '/' + photo.object_id}
                target='_blank'>
                <img src={photo.url} style={{width: '100%'}} alt="" />
              </a>
            </div>
          )
        })

        return (
          <div className="photos">
            { photos }
          </div>
        )
      } else {
        return <div className='text-center'><h5>此店家目前沒有照片</h5></div>
      };
    } else {
      return <div/>
    };
  };

	return (
		<Modal
			destroyOnClose
			centered
			visible={visible}
			wrapClassName="shopBindModal"
      title="確認同步店家"
      footer={
        <button onClick={() => onSync(select.objectId)}>與MENU同步</button>
      }
      onCancel={cancelSelect}
      transitionName="ant-move-down"
		>
			<div className="shopBindModalBody">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="info">
          <div className="info_block">
            <div className="info_block_title">店家名稱</div>
            <div className="info_block_content">{ select.name ? select.name : '-' }</div>
          </div>
          <div className="info_block">
            <div className="info_block_title">分店名稱（選填）</div>
            <div className="info_block_content">{ select.branch ? select.branch : '-' }</div>
          </div>
          <div className="info_block">
            <div className="info_block_title">聯絡電話</div>
            <div className="info_block_content">{ select.PhoneNumber ? select.PhoneNumber : '-' }</div>
          </div>
          <div className="info_block">
            <div className="info_block_title">店家地址</div>
            <div className="info_block_content">{ select.address ? select.address : '-' }</div>
          </div>
          <div className="info_block">
            <div className="info_block_title">店家網址</div>
            <div className="info_block_content">
              { select.objectId ? 
                (<a href={config.restaurant_domain + '/' + select.objectId} target='_blank' style={{color: '#676767'}}>
                    { config.restaurant_domain + '/' + select.objectId }
                </a>) : '-' 
              }
            </div>
          </div>
          <div className="info_block">
            <div className="info_block_title">照片數</div>
            <div className="info_block_content">{ select.photoCount ? select.photoCount : '0' }</div>
          </div>
        </div>
        { renderSelectPhotos() }
      </div>
		</Modal>
	);
};
