import React from 'react'
import $ from 'jquery'
var createReactClass = require('create-react-class');
export default createReactClass({
  handleDismiss() {
    $(this.refs.modal).modal('toggle');
  },
  handleSunbmit(e) {
    e.preventDefault();
    var data = {customer_id: this.props.data.id, record: this.state.record};
    this.props.onRecordSubmit(data, 'POST', this.props.url);
    this.handleDismiss();
    this.clearData();
  },
  getInitialState() {
    return { record: { value: '' } };
  },
  handleInputChange(e) {
    var new_state = this.state.record;
    new_state[e.target.name] = e.target.value;
    this.setState({record: new_state});
  },
  clearData() {
    this.setState({ record: { value: '' } } );
  },
  render() {
    var d = new Date();
    var m = d.getMonth()+1; m = (m<10) ? ('0'+m) : m;
    var now = d.getFullYear() + '-' + m + '-' + d.getDate();
    return (
      <div className="modal fade newrecorditem" ref="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              <h4 className="modal-title">新增記錄</h4>
            </div>
            <form onSubmit={this.handleSunbmit} >
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xs-5">
                      <div className="form-group">
                        <label>{now}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <textarea className="text optional form-control"
                                  name="value"
                                  value={this.state.record.value}
                                  onChange={this.handleInputChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-color-white btn-style4" data-dismiss="modal">取消</button>
                <input type="submit" className="btn btn-color-black btn-style4" value='儲存' />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
});
