import React, { Component } from "react";
import QueuePanelAPI from "./api/QueuePanelAPI";
import QueueSystemAPI from "../queue_system_settings/QueueSystemAPI";
import { getEstimatedWaitingTime } from "./QueuePanelHelper";
import { Carousel } from "antd";
import QRCode from "qrcode.react";

import { handleWebsocketConnection } from "../../libs/handler";
import { handleWebsocketEventRegistry } from "../../libs/handler";
import { config } from "../../utils/config";

import { queueDisplayLists_ikea } from "../../shopLists/queueDisplayLists_ikea";

import "antd/dist/antd.css";

import "../../sass/customize_ikea.scss";

import MenuCouponApi from "../menu_coupon/MenuCouponApi";
const menuCouponAPI = new MenuCouponApi();

export default class QueueRTDashboard extends Component {
  state = {
    groupTypes: [],
    timeCheckingCondition: "",
    displayName: "",
    custom_ikea: false,
  };

  debounced(func) {
    let timer;
    if (timer) clearTimeout(timer);
    return (e) => {
      timer = setTimeout(func, 200, e);
    };
  }

  componentDidMount() {
    this.API = new QueuePanelAPI();
    this.systemAPI = new QueueSystemAPI();
    this.refreshCurrentContent();

    window.addEventListener("resize", this.debounced(this.detectWindow));

    handleWebsocketConnection();

    handleWebsocketEventRegistry(
      "sync_frontend",
      function (data) {
        this.refreshCurrentContent();
      }.bind(this)
    );

    //開啟&關閉&暫停排隊
    handleWebsocketEventRegistry(
      "sync_queue_groups",
      function () {
        this.refreshCurrentContent();
      }.bind(this)
    );

    this.detectWindow();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.detectWindow);
  }

  detectWindow() {
    let w_w = window.innerWidth,
      w_h = window.innerHeight;
    let container = document.getElementsByClassName("container-black")[0],
      container_padding_top = parseInt(
        window
          .getComputedStyle(container, null)
          .getPropertyValue("padding-top"),
        10
      ),
      container_padding_bottom = parseInt(
        window
          .getComputedStyle(container, null)
          .getPropertyValue("padding-bottom"),
        10
      ),
      container_padding_height = parseInt(
        container_padding_top + container_padding_bottom,
        10
      );
    let display_group = document.getElementsByClassName("queue-display-group");
    let header_height =
      document.getElementsByClassName("queue-display-header")[0].offsetHeight +
      14;
    let footerHeight = document.getElementsByClassName(
        "queue-display-footer"
      )[0].offsetHeight,
      qrcodeHeight = document.getElementsByClassName("queue-display-qrcode")[0]
        .offsetHeight,
      offset_Height = qrcodeHeight - footerHeight + 30;
    let all_height =
      footerHeight + offset_Height + header_height + container_padding_height;
    let portrait_group_height = Math.floor((w_h - all_height) * 0.3);
    let portrait_marginBottomHeight =
      (w_h - all_height - portrait_group_height * 3) / 3;
    let landscape_group_height = w_h - all_height - 20;

    if (w_w < w_h) {
      for (let i = 0; i < display_group.length; i++) {
        display_group[i].style.height = portrait_group_height - 5 + "px";
        display_group[i].style.marginBottom =
          portrait_marginBottomHeight + "px";

        if (w_h >= 1120) {
          display_group[i].classList.remove("portrait__layout");
        } else {
          display_group[i].classList.add("portrait__layout");
        }
      }
    } else {
      for (let i = 0; i < display_group.length; i++) {
        display_group[i].style.height = landscape_group_height + "px";
        display_group[i].classList.remove("portrait__layout");
      }
    }
  }

  refreshCurrentContent() {
    this.API.getListedQueueGroup()
      .then((data) => {
        if (data !== undefined && data["queue_groups"].length > 0) {
          this.setState(
            {
              groupTypes: data["queue_groups"],
              currentStartTime: data["start_time"]
                ? "開始時間:" + data["start_time"]
                : "",
            },
            () => this.detectWindow()
          );
        }

        return this.systemAPI.getQueueSettings();
      })
      .then((data) => {
        this.setState(
          {
            timeCheckingCondition: data["time_checking"],
            displayName: data["display_name"],
            live_page_url: data["live_page_url"],
          },
          () => this.detectWindow()
        );

        return menuCouponAPI.getShops();
      })
      .then((data) => {
        const custom_ikea = queueDisplayLists_ikea.indexOf(data.shop.id);

        if (custom_ikea !== -1) {
          this.setState({ custom_ikea: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    let groupContents = [];
    let scrollGroupContents = [];
    let showEstimateTime =
      this.state.timeCheckingCondition !== undefined &&
      this.state.timeCheckingCondition !== "[]";
    const allPausedOrClosed = this.state.groupTypes.every(
        (groupType) => groupType.status === "pause" || groupType.status === "end"
      );
    
    this.state.groupTypes.forEach((groupType, index) => {
      // groupType.status
      // pause, end
      let subGroupContent;
      let createNewPage = false;

      if (
        (groupType.auto_pause || groupType.status === "pause") &&
        groupType.current_waiting_groups === 0
      ) {
        return;
      }

      if (
        groupContents.length > 0 &&
        groupContents[groupContents.length - 1].length < 3
      ) {
        subGroupContent = groupContents[groupContents.length - 1];
      } else {
        createNewPage = true;
        subGroupContent = [];
      }

      if ((index + 1) % 3 === 0) {
        switch (groupType.status) {
          case "start":
            subGroupContent.push(
              <div key={index} className="queue-display-group last">
                <h2 className="queue-display-group-name">{groupType.title}</h2>
                <div>
                  <p className="queue-display-group-content-num">
                    {groupType.last_seated_number > -1
                      ? groupType.last_seated_number
                      : "尚未叫號"}
                  </p>
                </div>
                <div className="queue-display-group-content">
                  <p className="queue-display-group-content-count">
                    {groupType.current_waiting_groups} 組等候
                    {showEstimateTime
                      ? "，約 " +
                        getEstimatedWaitingTime(
                          this.state.timeCheckingCondition,
                          groupType.current_waiting_groups
                        ) +
                        " 分鐘"
                      : ""}
                  </p>
                </div>
              </div>
            );
            break;
          case "pause":
            if (groupType.auto_pause === true) {
              subGroupContent.push(
                <div key={index} className="queue-display-group last">
                  <h2 className="queue-display-group-name">
                    {groupType.title}
                  </h2>
                  <div>
                    <p className="queue-display-group-content-num">
                      {groupType.last_seated_number > -1
                        ? groupType.last_seated_number
                        : "尚未叫號"}
                    </p>
                  </div>
                  <div className="queue-display-group-content">
                    <p className="queue-display-group-content-count">
                      {groupType.current_waiting_groups} 組等候
                      {showEstimateTime
                        ? "，約 " +
                          getEstimatedWaitingTime(
                            this.state.timeCheckingCondition,
                            groupType.current_waiting_groups
                          ) +
                          " 分鐘"
                        : ""}
                    </p>
                  </div>
                </div>
              );
            } else {
              subGroupContent.push(
                <div key={index} className="queue-display-group last">
                  <h2 className="queue-display-group-name">
                    {groupType.title}
                  </h2>
                  <div>
                    <p className="queue-display-group-content-num">
                      {groupType.last_seated_number > -1
                        ? groupType.last_seated_number
                        : "尚未叫號"}
                    </p>
                  </div>
                  <div className="queue-display-group-content">
                    <p className="queue-display-group-content-count">
                      {groupType.current_waiting_groups} 組等候
                      {showEstimateTime
                        ? "，約 " +
                          getEstimatedWaitingTime(
                            this.state.timeCheckingCondition,
                            groupType.current_waiting_groups
                          ) +
                          " 分鐘"
                        : ""}
                    </p>
                  </div>
                </div>
              );
            }
            break;
          case "end":
            subGroupContent.push(
              <div key={index} className="queue-display-group last unavailable">
                <h2 className="queue-display-group-name">
                  {groupType.title} (未開放)
                </h2>
                <div>
                  <p className="queue-display-group-content-num">
                    {groupType.last_seated_number > -1
                      ? groupType.last_seated_number
                      : "尚未叫號"}
                  </p>
                </div>
                <div className="queue-display-group-content">
                  <p className="queue-display-group-content-count">
                    {groupType.current_waiting_groups} 組等候
                    {showEstimateTime
                      ? "，約 " +
                        getEstimatedWaitingTime(
                          this.state.timeCheckingCondition,
                          groupType.current_waiting_groups
                        ) +
                        " 分鐘"
                      : ""}
                  </p>
                </div>
              </div>
            );
            break;
          default:
            console.log(" ");
            break;
        }
      } else {
        switch (groupType.status) {
          case "start":
            subGroupContent.push(
              <div key={index} className="queue-display-group">
                <h2 className="queue-display-group-name">{groupType.title}</h2>
                <div>
                  <p className="queue-display-group-content-num">
                    {groupType.last_seated_number > -1
                      ? groupType.last_seated_number
                      : "尚未叫號"}
                  </p>
                </div>
                <div className="queue-display-group-content">
                  <p className="queue-display-group-content-count">
                    {groupType.current_waiting_groups} 組等候
                    {showEstimateTime
                      ? "，約 " +
                        getEstimatedWaitingTime(
                          this.state.timeCheckingCondition,
                          groupType.current_waiting_groups
                        ) +
                        " 分鐘"
                      : ""}
                  </p>
                </div>
              </div>
            );
            break;
          case "pause":
            if (groupType.auto_pause === true) {
              subGroupContent.push(
                <div key={index} className="queue-display-group">
                  <h2 className="queue-display-group-name">
                    {groupType.title}
                  </h2>
                  <div>
                    <p className="queue-display-group-content-num">
                      {groupType.last_seated_number > -1
                        ? groupType.last_seated_number
                        : "尚未叫號"}
                    </p>
                  </div>
                  <div className="queue-display-group-content">
                    <p className="queue-display-group-content-count">
                      {groupType.current_waiting_groups} 組等候
                      {showEstimateTime
                        ? "，約 " +
                          getEstimatedWaitingTime(
                            this.state.timeCheckingCondition,
                            groupType.current_waiting_groups
                          ) +
                          " 分鐘"
                        : ""}
                    </p>
                  </div>
                </div>
              );
            } else {
              subGroupContent.push(
                <div key={index} className="queue-display-group">
                  <h2 className="queue-display-group-name">
                    {groupType.title}
                  </h2>
                  <div>
                    <p className="queue-display-group-content-num">
                      {groupType.last_seated_number > -1
                        ? groupType.last_seated_number
                        : "尚未叫號"}
                    </p>
                  </div>
                  <div className="queue-display-group-content">
                    <p className="queue-display-group-content-count">
                      {groupType.current_waiting_groups} 組等候
                      {showEstimateTime
                        ? "，約 " +
                          getEstimatedWaitingTime(
                            this.state.timeCheckingCondition,
                            groupType.current_waiting_groups
                          ) +
                          " 分鐘"
                        : ""}
                    </p>
                  </div>
                </div>
              );
            }
            break;
          case "end":
            subGroupContent.push(
              <div key={index} className="queue-display-group unavailable">
                <h2 className="queue-display-group-name">
                  {groupType.title} (未開放)
                </h2>
                <div>
                  <p className="queue-display-group-content-num">
                    {groupType.last_seated_number > -1
                      ? groupType.last_seated_number
                      : "尚未叫號"}
                  </p>
                </div>
                <div className="queue-display-group-content">
                  <p className="queue-display-group-content-count">
                    {groupType.current_waiting_groups} 組等候
                    {showEstimateTime
                      ? "，約 " +
                        getEstimatedWaitingTime(
                          this.state.timeCheckingCondition,
                          groupType.current_waiting_groups
                        ) +
                        " 分鐘"
                      : ""}
                  </p>
                </div>
              </div>
            );
            break;
          default:
            console.log(" ");
            break;
        }
      }

      if (groupContents.length > 0 && !createNewPage) {
        groupContents[groupContents.length - 1] = subGroupContent;
      } else {
        groupContents.push(subGroupContent);
      }
    });

    groupContents.forEach((element, index) => {
      scrollGroupContents.push(<div key={index}>{element}</div>);
    });

    return (
      <div
        style={{ background: "#4F4F5B" }}
        className={this.state.custom_ikea ? "custom-ikea" : ""}
      >
        <div className="container container-shadow-dark container-black">
          <h3
            className="queue-display-header"
            onClick={() => toggleFullScreen()}
          >
            排隊動態
          </h3>
          {this.state.custom_ikea ? (
            <div className="ikea-content">
              <div className="ikea-queue">{scrollGroupContents}</div>
              <div className="ikea-ad">
                <Carousel autoplaySpeed={15000} autoplay>
                  <img
                    src={require("../../images/ikea/ikea_system_1.jpg")}
                    alt=""
                  />
                  <img
                    src={require("../../images/ikea/ikea_system_2.jpg")}
                    alt=""
                  />
                  <img
                    src={require("../../images/ikea/ikea_system_3.jpg")}
                    alt=""
                  />
                  <img
                    src={require("../../images/ikea/ikea_system_4.jpg")}
                    alt=""
                  />
                  <img
                    src={require("../../images/ikea/ikea_system_5.jpg")}
                    alt=""
                  />
                </Carousel>
              </div>
            </div>
          ) : (
            <Carousel style={{ height: "600px" }} autoplay>
              {scrollGroupContents}
            </Carousel>
          )}
          <div className="queue-display-footer">
            <div className="queue-display-qrcode">
              <div>
                <QRCode
                  size={200}
                  value={
                    config.queue_domain + "/live/" + this.state.live_page_url
                  }
                />
              </div>
            </div>
            <h2>{this.state.displayName}</h2>
            {allPausedOrClosed ? (
              <h3 className="error">暫停開放新的排隊登記</h3>
            ) : (
              <h3>掃描 QR Code 線上登記排隊</h3>
            )}
            {this.state.custom_ikea ? (
              <div className="ikea_logo">
                <img src={require("../../images/IKEA_logo.svg")} alt="ikea" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function toggleFullScreen() {
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}
