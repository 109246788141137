import React, { Fragment } from "react";
import { Modal } from 'antd';
import './customAntdPopup.scss';

const CheckPopup = ({
  title,
  content,
  submit,
  cancel
}) => {
  return (
    <Modal
      title={title}
      visible
      width={374}
      centered
      className="common_ant_modal check_popup"
      footer={
        <>
          <button
            className="btn_cancel"
            onClick={cancel}
          >
            取消
          </button>
          <button
            className="btn_save"
            onClick={submit}
          >
            確定
          </button>
        </>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {content}
    </Modal>
  )
}

export default CheckPopup;