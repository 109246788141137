import React, { Fragment, useContext } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import MenuCouponCard from '../EnableMenuCouponCard';

import { MessageAddStateContext } from '../../../crmContext/MessageAddContext';


const CrmMenuTalkItem = ({ children }) => {
	return <section>{React.Children.map(children, (child) => child)}</section>;
};

const MenuTalkHeader = ({ index, item, deleteMENUContent }) => {
  const { MessageAddState } = useContext(MessageAddStateContext);
  const { errorCoupon } = MessageAddState;

  const renderTitle = () => {
    if(item.type === 'text') {
      return '文字';
    } else if(item.type === 'new_image' || item.type === 'image') {
      return '圖片';
    } else if(item.type === 'voucher') {
      if(errorCoupon.length > 0) {
        // 優惠券有效期限是否在排程時間內
        const findIndex = errorCoupon.map((coupon) => coupon.voucherId).indexOf(item.voucherId);
        
        if(findIndex !== -1) {
          return (
            <Fragment>
              優惠券
              <span className='error_text' style={{ marginLeft: '8px' }}>
                *此券結束日未超過排程日期，建議先存為草稿至優惠券區更改結束日期
              </span>
            </Fragment>
          )
        } else {
          return '優惠券';
        }
      } else {
        return '優惠券';
      }
    }
  }


  return (
    <div className="edit_block_header edit_content_header">
      <div>{ renderTitle() }</div>
      <CloseOutlined
        onClick={() => deleteMENUContent(index, item)}
      />
    </div>
  )
}

const MenuTalkContent = ({ index, item, couponLists, handleMENUContent }) => {
  const renderContent = () => {
    if(item.type === 'text') {
      return (
        <div className="edit_block_content" style={{ padding: '16px' }}>
          <textarea
            id={`text_${index}`}
            value={item.text}
            onChange={(e) => handleMENUContent(index, e)}
            placeholder="請輸入欲傳送文字"
          />
        </div>
      )
    } else if(item.type === 'new_image' || item.type === 'image') {
      return (
        <div className="edit_block_content">
          <img src={item.url} alt="" />
        </div>
      )
    } else if(item.type === 'voucher') {
      let voucherInfo = ''
      const couponIndex = couponLists.map((coupon) => coupon.id).indexOf(item.voucherId);
      voucherInfo = couponLists[couponIndex];
      
      return (
        <div className="edit_block_content edit_block_content_coupon">
          <MenuCouponCard
            id={`view_${item.voucherId}`}
            key={index}
            coupon={voucherInfo}
            onlyView={true}
          >
            <MenuCouponCard.Img thumbnailURL={voucherInfo.thumbnailURL} />
            <MenuCouponCard.Content coupon={voucherInfo} />
          </MenuCouponCard>
        </div>
      )
    }
  }

  return (
    <Fragment>
      { renderContent() }
    </Fragment>
  )
}

CrmMenuTalkItem.Header = MenuTalkHeader;
CrmMenuTalkItem.Content = MenuTalkContent;

export default CrmMenuTalkItem;
