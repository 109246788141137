import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { CustomerContext } from '../../crmContext/CustomerContext';
import { Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons'

import BlockActionModal from "../crmCustomerPopup/BlockActionModal";

const CrmCustomerRecordInfo = ({
  data,
  load,
  setIsHiddenNewBookingAddView,
  setShowRecordEditorModal
}) => {
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const [ isBlocked, setIsBlocked ] = useState(false);
  const [ showBlockActionModal, setShowBlockActionModal ] = useState(false);
  const [ placeholderHeight, setPlaceholderHeight ] = useState(0);
  const [ nameBlockWidth, setNameBlockWidth ] = useState(0);

  const { loading } = CustomerState;

  const nameRef = useRef(null);
  const infoBlockRef = useRef(null);

  const resizeScreen = useCallback(() => {
    if(nameRef.current) {
      setPlaceholderHeight(nameRef.current.offsetHeight);
    }
    if(infoBlockRef.current) {
      setNameBlockWidth(infoBlockRef.current.offsetWidth - 1);
    }
	}, [ data.last_name ]);

  useEffect(() => {
    resizeScreen();

    window.addEventListener('resize', resizeScreen);
    return () => {
      window.removeEventListener('resize', resizeScreen);
    }
  }, [ resizeScreen, window.innerWidth ]);

  useEffect(() => {
    setIsBlocked(data.blacklisted);
  }, [data]);

  const handleEditCustomer = () => {
    if(loading) return;
    CustomerStateDispatch({ type: 'setAddCustomerModal', show: true });
  };

  const handleBlockActionModal = () => {
    setShowBlockActionModal(!showBlockActionModal)
  };

  const renderGender = () => {
    if(data.gender === 'male') return '先生';
    if(data.gender === 'female') return '小姐';
    if(data.gender === 'other') return '其他';
  };

  const renderTags = () => {
    return (
      data.customer_tags.map(function(tag) {
        return (
          <div
            key={tag.id}
            className='c_tag'
          >
            {tag.description}
          </div>
        );
      })
    )
  };

  return (
    <div className="crmCustomerRecordInfo" ref={infoBlockRef}>
      <div className="c_row c_name_row customer_name" ref={nameRef} style={{ width: `${nameBlockWidth}px` }}>
        <div className="c_name">
          { data.last_name }
        </div>
        <div className="c_edit" onClick={handleEditCustomer}>
          <EditOutlined style={{ fontSize: '24px' }} />
        </div>
      </div>
      <div className="customer_name_placeholder" style={{ height: `${placeholderHeight}px` }} />
      <div className="customer_contact">
        <div className="c_row" style={{ alignItems: 'flex-end' }}>
          <div className="c_row c_tag_row">
            <div className="c_gender">{renderGender()}</div>
            {isBlocked || data.customer_tags.length !== 0 ? <div className="c_divider" /> : null}
            <div className="c_tags">
              {isBlocked && <div className="c_tag c_blocked">封鎖</div>}
              {renderTags()}
            </div>
          </div>
          <div className="c_action">
              <button
                type="button"
                onClick={() => setShowRecordEditorModal(true)}
                disabled={loading}
              >
                新增紀錄
              </button>
              <button
                type="button"
                onClick={() => setIsHiddenNewBookingAddView(false)}
                disabled={loading}
              >
                新增預約
              </button>
          </div>
        </div>

        <hr />

        <div className="c_profile">
          <div className="c_profile_row">
            <div className="c_profile_title">手機號碼</div>
            <div className="c_profile_content">{data.phone_number}</div>
            {
              data.phone_verification &&
              <Tooltip
                placement="bottom"
                title='已認證電話'
                trigger='hover'
                color='#333'
                arrowPointAtCenter
                overlayClassName='c_item_tooltip'
                getPopupContainer={triggerNode => triggerNode.parentNode} // scroll position fixed
              >
                <div className='c_verified'>
                  <img src={require('../../../../images/crm/verified_icon.svg')} alt="" />
                </div>
              </Tooltip>
            }
            <button
              className="btn_outline"
              onClick={handleBlockActionModal}
            >
              {isBlocked ? '解除封鎖': '封鎖號碼'}
            </button>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">Email</div>
            <div className="c_profile_content">{data.email ? data.email : ''}</div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">生日</div>
            <div className="c_profile_content">{data.birthday ? data.birthday  : ''}</div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">公司名稱</div>
            <div className="c_profile_content">{data.company ? data.company  : ''}</div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">公司電話</div>
            <div className="c_profile_content">{data.company_tele ? data.company_tele  : ''}</div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title">統編</div>
            <div className="c_profile_content">{data.company_id ? data.company_id  : ''}</div>
          </div>
          <div className="c_profile_row">
            <div className="c_profile_title" style={{ alignSelf: "flex-start" }}>顧客筆記</div>
            <div className="c_profile_content">{data.note ? data.note : ''}</div>
          </div>
        </div>

        <div className="c_created">
          <div className="c_created_block">
            <div>
              <div className="c_created_title">建立者</div>
              <div className="c_created_content">{data.user_create ? data.user_create : '自動建立'}</div>
            </div>
            <div>
              <div className="c_created_title">資料建立時間</div>
              <div className="c_created_content">{data.user_created_at}</div>
            </div>
          </div>
          <div className="c_created_block">
            <div>
              <div className="c_created_title">修改者</div>
              <div className="c_created_content">{data.user_update ? data.user_update : '自動建立'}</div>
            </div>
            <div>
              <div className="c_created_title">上次修改時間</div>
              <div className="c_created_content">{data.user_updated_at}</div>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      {
        showBlockActionModal &&
        <BlockActionModal
          phone_number={data.phone_number}
          isBlocked={isBlocked}
          load={load}
          setIsBlocked={setIsBlocked}
          setShowBlockActionModal={setShowBlockActionModal}
        />
      }
    </div>
  )
}

export default CrmCustomerRecordInfo;