import React, { Fragment, useCallback, useContext, useEffect }  from 'react';

import {
  customerSortOption,
  messageFilterOption,
  messageSortOption,
  messageStatusTab
} from '../utils/data';

import { MessageContext } from '../crmContext/MessageContext';
import { CustomerContext } from '../crmContext/CustomerContext';

import { Select } from 'antd';
const { Option } = Select;

const CrmTab = () => {
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
    customerSort,
    showCustomerFilterBar,
    customerFilterNum,
    filterCustomers,
    listCount
  } = CustomerState;
	const { MessageState, MessageStateDispatch } = useContext(MessageContext);
	const {
    mode,
    messageFilter,
    messageSort,
    messageStatus,
    statusMessages,
    searchInputText,
    searchMode
  } = MessageState;

  useEffect(() => {
    MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: 'sent', searchMode: false });
  }, [ mode ]);

  const handleMessageFilter = useCallback((value) => {
    let index = Object.values(messageFilterOption).indexOf(value);
    let filter = Object.keys(messageFilterOption)[index];

    if (filter === messageFilter) return;

    if(searchInputText.length > 0 && searchMode) {
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: true });
    } else {
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: false });
    }

		MessageStateDispatch({ type: 'setMessageFilter', messageFilter: filter });
    MessageStateDispatch({ type: 'setStatusMessagesNum' });
	}, [ messageFilter, messageStatus, searchInputText, searchMode, MessageStateDispatch ]);

  const handleMessageSort = useCallback((value) => {
    let index = Object.values(messageSortOption).indexOf(value);
    let sort = Object.keys(messageSortOption)[index];

		if (sort === messageSort) return;

    if(searchInputText.length > 0 && searchMode) {
      MessageStateDispatch({ type: 'setSearchResSort', messageSort: sort });
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: true });
    } else {
      MessageStateDispatch({ type: 'setMessageSort', messageSort: sort });
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: false });
    }

    MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
	}, [ messageSort, messageStatus, messageFilter, searchInputText, searchMode, MessageStateDispatch ]);

  const handleMessageType = useCallback((value) => {
		if (value === messageStatus) return;

    if(searchInputText.length > 0 && searchMode) {
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: value, searchMode: true });
    } else {
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: value, searchMode: false });
    }

    MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
	}, [ messageStatus, messageFilter, searchInputText, searchMode, MessageStateDispatch ]);

  const handleCustomerFilterBar = () => {
    let value = !showCustomerFilterBar;

    CustomerStateDispatch({ type: 'setCustomerFilterBar', show: value });
    CustomerStateDispatch({ type: 'setSearchCustomerText', searchCustomerText: '' });
    CustomerStateDispatch({
      type: 'setSearchCustomerResult',
      searchCustomers: [],
      searchCustomerMode: false,
      listPage: 1,
			listCount: null
    });
  }

  // const handleCustomerSort = (value) => {
  //   let index = Object.values(customerSortOption).indexOf(value);
  //   let sort = Object.keys(customerSortOption)[index];

	// 	if (sort === customerSort) return;

  //   // if(searchCustomerText.length > 0 && searchCustomerMode) {
  //   //   CustomerStateDispatch({ type: 'setSearchResSort', customerSort: sort });
  //   // } else {
  //   //   CustomerStateDispatch({ type: 'setCustomerSort', customerSort: sort });
  //   // }

  //   // 補 filter

  //   CustomerStateDispatch({ type: 'setCustomerSort', customerSort: sort });
  // }

  const renderTab = () => {
    if(mode === 'customerList') {
      return (
        <div className='crmMessage_bar crmMessage_tab crmCustomer_tab'>
          <div className='block_filter'>
            <button
              className={`btn_filter ${showCustomerFilterBar ? 'active' : ''}`}
              onClick={handleCustomerFilterBar}
            >
              {
                customerFilterNum !== 0 ? 
                <span className='btn_filter_num'>{customerFilterNum}</span> :
                <img src={require('../../../images/crm/filter_icon.svg')} />
              }
              <span className='btn_filter_txt'>篩選</span>
            </button>

            { customerFilterNum !== 0 ? <span className='res_filter'>篩選結果：{listCount}名顧客</span> : null }
          </div>
        </div>
      )
    } else {
      return (
        <div className="crmMessage_bar crmMessage_tab">
          <div className='msgSelect'>
            <Select
              bordered={false}
              defaultValue={messageFilterOption[messageFilter]}
              onChange={(value) => handleMessageFilter(value)}
              getPopupContainer={triggerNode => triggerNode.parentElement}
            >
              {Object.keys(messageFilterOption).map((option, index) => {
                return(
                  <Option
                    key={index}
                    value={messageFilterOption[option]}
                  >
                    {messageFilterOption[option]}
                  </Option>
                )
              })}
            </Select>
          </div>
    
          <div className="crmMessage_type_tab">
            {Object.keys(messageStatusTab).map((option, index) => {
              return (
                <div
                  key={index}
                  className={option === messageStatus ? 'active' : ''}
                  onClick={() => handleMessageType(option)}
                >
                  {messageStatusTab[option]} {statusMessages[index] !== 0 ? statusMessages[index] : ''}
                </div>
              );
            })}
          </div>
    
          <div className='msgSelect'>
            <Select
              bordered={false}
              defaultValue={messageSortOption[messageSort]}
              onChange={(value) => handleMessageSort(value)}
              getPopupContainer={triggerNode => triggerNode.parentElement}
            >
              {Object.keys(messageSortOption).map((option, index) => {
                return(
                  <Option
                    key={index}
                    value={messageSortOption[option]}
                  >
                    {messageSortOption[option]}
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
      )
    }
  };

  return (
    <Fragment>
      { renderTab() }
    </Fragment>
  )
}

export default CrmTab;
