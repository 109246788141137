import React from 'react'
var createReactClass = require('create-react-class');
export default createReactClass({
  render() {
    var calls = this.props.data.map(function(call) {
      return (
        <tr key={call.id}>
          <td style={{width: '200px'}}>{call.called_at}</td>
          <td>{call.ring_on_at+' 來電'}</td>
          <td>
          </td>
        </tr>
      )
    });
    return (
      <div className="menu-section">
        <div className="menu-panel">
          <table className="table table-hover table-style">
            <thead>
              <tr>
                <th colSpan={3}>來電</th>
              </tr>
            </thead>
            <tbody>
              {calls}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
});

