import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

import BookingCalendar from './BookingCalendar/BookingCalendar';
import BookingListView from './BookingList/BookingListView';
import BookingTableView from './BookingTable/BookingTableView';
import CrmCustomerCreateModal from '../crm/crmCustomer/crmCustomerPopup/CrmCustomerCreateModal';
import NewAddBookingView from '../AddNewBooking/NewAddBooking';

import AnnouncementListModal from '../announcement/announcementPopup/AnnouncementListModal';
import AddAnnouncementModal from '../announcement/announcementPopup/AnnouncementAddModal';
import AnnouncementCheckModal from '../announcement/announcementPopup/AnnouncementDeleteModal';
import AnnouncementCancelModal from '../announcement/announcementPopup/AnnouncementCancelModal';

import { handleError } from '../../libs/handler';
import { handleWebsocketEventRegistry, cancelWebsocketSubscription } from '../../libs/handler';

import {
	handleAnnouncementListModal,
	updateDayAnnouncements,
	announcementFetchMonth,
	resetAnnouncementList
} from '../../actions/announcementAction';

import BookingSystemApi from '../booking_settings/BookingSystemApi';

const Link = require('react-router').Link;

const filterTimeOption = [
	'00:00',
	'01:00',
	'02:00',
	'03:00',
	'04:00',
	'05:00',
	'06:00',
	'07:00',
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00 PM',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
	'22:00',
	'23:00',
	'23:59'
];
const colors = [ '#EE6352', '#59CD90', '#3FA7D6', '#FAC05E', '#F79D84', '#A26EEA', '#F7AFFF', '#464646' ];
const QUESTION = 'question';

class BookingPanel extends Component {
	constructor(props) {
		super(props);
		if (this.props.location.query.mode == null || this.props.location.query.date == null) {
			browserHistory.push({
				pathname: '/dashboard/bookings',
				query: {
					date: new moment().format('YYYY-MM-DD'),
					mode: 'calendar'
				}
			});
		}
		var mode = this.props.location.query.mode;
		if (mode === undefined) mode = 'list';
		var queryDate = this.props.location.query.date;

		const isToday = moment().format('YYYY-MM-DD');
		const shortCutBtnBool = queryDate === isToday ? true : false;

		this.state = {
			bookings: [],
			filter: { date: queryDate },
			tlFilter: {},
			selectedBooking: {
				phone_number: ''
			},
			month: new moment(),
			monthCount: {},
			monthEffectiveCount: {},
			monthUnconfirmedCount: {},
			monthIneffectiveCount: {},
			monthAttendance: {},
			mode: mode,
			changeDateListenerInit: false,
			warnings: [],
			tables: [],
			disableDates: [],
			isDisableDateTxt: '',
			gov_holidaysData: [],
			timeFilterTxt: (
				<span>
					<i className="glyphicon glyphicon-filter" />
					全日{' '}
				</span>
			),
			fStartTime: '請選擇',
			fEndTime: '請選擇',
			tempFStartTime: '',
			tempFEndTime: '',
			initBookings: [],
			reRender: false,
			isToday: isToday,
			show: shortCutBtnBool,
			newSelected: {},
			isHiddenNewBookingAddView: true,
			modalType: 'addNew',
			diningTime: 0,
			serviceTimeOptionEnabled: false,
			customQ: [],
			customQAns: {},
			tempCustomQAns: {},
			autoTable: false,
			tableMapSetting: false,
			loading: false,
			activeBookingListStatusTab: 'confirmed', // 預設：confirmed 確認預約
			spg_hash_iv: null,
			spg_hash_key: null,
			spg_merchant_id: null,
			credit_enabled: '',
			showAddCustomerModal: false
		};

		this.isDisableDate = this.isDisableDate.bind(this);
		this.getHolidays = this.getHolidays.bind(this);
		this.handleFStartTime = this.handleFStartTime.bind(this);
		this.handleFEndTime = this.handleFEndTime.bind(this);
		this.handleAllTime = this.handleAllTime.bind(this);
		this.handleFtimeCancel = this.handleFtimeCancel.bind(this);
		this.handleTimeFilter = this.handleTimeFilter.bind(this);
		this.renderFilterTime = this.renderFilterTime.bind(this);
		this.renderBookingViewInfoTxt = this.renderBookingViewInfoTxt.bind(this);
		this.renderTableMapIcon = this.renderTableMapIcon.bind(this);
		this.handleCloseFtimeDropdown = this.handleCloseFtimeDropdown.bind(this);
		this.isToday = this.isToday.bind(this);
		this.handleResetStatusTimeRange = this.handleResetStatusTimeRange.bind(this);
		this.handleBookingListStatusTab = this.handleBookingListStatusTab.bind(this);
	}

	componentDidUpdate(prevProps) {
		let prev_mode = prevProps.location.query.mode,
			prev_date = prevProps.location.query.date;
		let history_query_mode = this.props.location.query.mode,
			history_query_date = this.props.location.query.date;
		// let { mode, filter } = this.state;

		if (
			prev_mode !== history_query_mode ||
			prev_date !== history_query_date
			// mode !== history_query_mode ||
			// filter.date !== history_query_date
		) {
			// filter['date'] = history_query_date;
			this.setState({ mode: history_query_mode, filter: { date: history_query_date } }, () => {
				this.setState({ bookings: [], loading: true });
				this.updateData();
				this._setupDateInput();
				this.isToday(history_query_date);

				// this.resize();
			});
		}
	}

	componentDidMount() {
		this.API = new BookingSystemApi();

		this.getHolidays();
		this.updateData();
		this._setupDateInput();
		this.getCustomQuestion();
		this.getCreditEnabled();

		// window.addEventListener('resize', this.resize);
		// this.resize();

		handleWebsocketEventRegistry(
			'notify',
			function(data) {
				setTimeout(
					function() {
						this.updateData();
					}.bind(this),
					3000
				);
			}.bind(this)
		);

		handleWebsocketEventRegistry(
			'refresh_booking_list',
			function() {
				setTimeout(
					function() {
						this.updateData();
					}.bind(this),
					3000
				);
			}.bind(this)
		);

		handleWebsocketEventRegistry(
			'refresh_booking_list_status_changed',
			function(data) {
				setTimeout(() => {
					// let new_bookings = [...this.state.bookings];
					// let booking_id = data.booking.booking.id;
					// let booking_index = this.state.bookings.map(booking=> {return booking.id}).indexOf(booking_id);
					// new_bookings.splice(booking_index, 1, data.booking.booking);
					// this.setState({bookings: new_bookings})
					this.getBookings();
				}, 3000);
			}.bind(this)
		);

		// 公告: 建立/更新/過期/刪除 -> update 該月公告
		handleWebsocketEventRegistry(
			'sync_announcements',
			function() {
				setTimeout(
					function() {
						this.props.updateDayAnnouncements(this.props.location.query.date);
						this.props.announcementFetchMonth(moment(this.props.location.query.date).format('YYYY-MM'));
					}.bind(this),
					3000
				);
			}.bind(this)
		);

		this.API
			.getBookingSetting()
			.then((data) => {
				this.setState(data);
				this.setState({
					init: true,
					disableDates: data.disable_dates,
					diningTime: data.dining_time,
					autoTable: data.auto_table_distribution,
					tableMapSetting: data.canvas_enabled,
					serviceTimeOptionEnabled: data.service_time_option_enabled,
					spg_hash_iv: data.spg_hash_iv,
					spg_hash_key: data.spg_hash_key,
					spg_merchant_id: data.spg_merchant_id
				});
				this.isDisableDate(this.props.location.query.date);
			})
			.catch((error) => {
				handleError(error);
			});

		$(document).on('click', '.dropdown-menu.filters, .dropdown-menu.timeFilterContent', (e) => {
			e.stopPropagation();
			if (e.target.tagName === 'INPUT') this.updateTlfilter(e);
		});

		// $(this.refs.dateInput).on('changeDate', (e) => {
		// 	// let getDate = e.target.firstChild.getAttribute('value');

		// 	// console.log("getDate----", getDate)

		// 	// this.isToday();

		// 	// this.setState({
		// 	// 	bookings: [],
		// 	// 	initBookings: []
		// 	// });
		// });
	}

	componentWillUnmount() {
		cancelWebsocketSubscription([ 'notify' ]);
		cancelWebsocketSubscription([ 'refresh_booking_list_status_changed' ]);
		cancelWebsocketSubscription([ 'sync_announcements' ]);

		this.props.resetAnnouncementList();
		// window.removeEventListener('resize', this.resize);
	}

	// resize = () => {
	// 	let chair = document.getElementsByClassName('chair')[0];
	// 	let w_w = window.innerWidth;

	// 	if (chair) {
	// 		if (w_w >= 500) {
	// 			chair.style.display = 'block';
	// 		} else {
	// 			chair.style.display = 'none';
	// 		}
	// 	}
	// };

	getCreditEnabled = () => {
		$.ajax({url: window.domain + '/dashboard/booking_settings/spg_data',
      method: 'GET',
      xhrFields: { withCredentials: true }})
    .done(function(data){
      this.setState({ credit_enabled: data.credit_enabled });
    }.bind(this))
    .fail(function(xhr){
      handleError(xhr);
    });
	};

	getCustomQuestion = () => {
		let { customQAns } = this.state;
		this.API.getQuestions().then((data) => {
			data.map((q) => {
				if (q.question_type === QUESTION) {
					customQAns[q.id] = '';
				} else {
					customQAns[q.id] = {};
				}
				return true;
			});
			this.setState({
				customQ: data,
				customQAns,
				tempCustomQAns: JSON.stringify(customQAns)
			});
		});
	};

	getBookings = (queryDate) => {
		// this.setState({ bookings: [], loading: true });
		if (queryDate === undefined) queryDate = this.props.location.query.date;
		// window.app.alert.setMessage('讀取中..', 'done');

		this.API
			.getBookingsByDate(queryDate)
			.then((data) => {
				// setTimeout(() => {
				// 	window.app.alert.setMessage('', 'done');
				// }, 500);

				data.forEach((b) => {
					b.seatings = JSON.parse(b.seating_json);
				});
				this.setState({
					bookings: data,
					initBookings: data,
					loading: false
				});
				this.handleResetStatusTimeRange();
			})
			.catch((error) => {
				handleError(error);
			});
	};

	getMonthEffectiveCount = () => {
		var month = moment(this.props.location.query.date, 'YYYY-MM-DD').format('YYYY/MM');

		this.API
			.getMonthStatus(month, 'effective')
			.then((data) => {
				var totalEffective = 0;
				for (var key in data) {
					totalEffective += data[key];
				}
				this.setState({
					monthEffectiveCount: data,
					monthTotalEffectiveCount: totalEffective
				});
			})
			.catch((error) => {
				handleError(error);
			});
	};

	getMonthUnconfirmedCount = () => {
		var month = moment(this.props.location.query.date, 'YYYY-MM-DD').format('YYYY/MM');

		this.API
			.getMonthStatus(month, 'unconfirmed')
			.then((data) => {
				this.setState({
					monthUnconfirmedCount: data
				});
			})
			.catch((error) => {
				handleError(error);
			});
	};

	getMonthIneffectiveCount = () => {
		var month = moment(this.props.location.query.date, 'YYYY-MM-DD').format('YYYY/MM');

		this.API
			.getMonthStatus(month, 'ineffective')
			.then((data) => {
				var totalIneffective = 0;
				for (var key in data) {
					totalIneffective += data[key];
				}
				this.setState({
					monthIneffectiveCount: data,
					monthTotalIneffectiveCount: totalIneffective
				});
			})
			.catch((error) => {
				handleError(error);
			});
	};

	getMonthAttendance = () => {
		var month = moment(this.props.location.query.date, 'YYYY-MM-DD').format('YYYY/MM');

		this.API
			.getMonthStatus(month, 'attendance')
			.then((data) => {
				this.setState({
					monthAttendance: data
				});
				var totalAttendance = 0;
				for (var key in data) {
					totalAttendance += data[key];
				}
				this.setState({
					monthAttendance: data,
					monthTotalAttendance: totalAttendance
				});
			})
			.catch((error) => {
				handleError(error);
			});
	};

	updateData = () => {
		let mode = this.props.location.query.mode;
		if (mode === 'calendar') {
			this.getMonthEffectiveCount();
			this.getMonthUnconfirmedCount();
			this.getMonthIneffectiveCount();
			this.getMonthAttendance();

			this.props.updateDayAnnouncements(this.props.location.query.date);
			this.props.announcementFetchMonth(moment(this.props.location.query.date).format('YYYY-MM'));
		} else {
			this.getBookings();
			this.getTimeline();

			this.props.updateDayAnnouncements(this.props.location.query.date);
		}
	};

	getTimeline = () => {
		this.setState({ warnings: [] });
		let date = new moment(this.props.location.query.date).format('YYYY/MM/DD');

		this.API
			.getTimeLine(date)
			.then((data) => {
				// let tables = _.sortBy(data.tables, [ 'group' ]);
				let groups = _.uniq(data.tables.map((m) => m.group));
				if (_.isEmpty(this.state.tlFilter)) {
					let filter = {};
					groups.forEach((g) => (filter[g] = true));
					this.setState({
						tlFilter: filter
					});
				}

				this.setState({
					tables: data.tables,
					warnings: data.warnings
				});
			})
			.catch((error) => {
				handleError(error);
				this.setState({
					tables: null,
					warnings: []
				});
			});
	};

	updateTlfilter = (e) => {
		let { tlFilter } = this.state;
		tlFilter[e.target.value] = e.target.checked;
		this.setState({ tlFilter });
	};

	updateAvailableTable = () => {
		this.refs.availableTableList.getAvailableTable(this.props.location.query.date);
	};

	showAddCustomerModal = (booking) => {
		this.setState({
			selectedBooking: booking,
			showAddCustomerModal: true
		});
	};

	setStatus = (booking, newStatus, callback, status_time) => {
		let customerName = booking.last_name,
			customerGender = booking.gender_desc;
		let { date } = this.props.location.query;

		const newState = fakeNewStatus({
			bookings: this.state.initBookings,
			tables: this.state.tables,
			booking,
			newStatus
		});

		this.setState({
			initBookings: newState.newBookings,
			bookings: newState.newBookings,
			tables: newState.newTables
		});

		if (newStatus === 'confirmed') {
			window.app.alert.setMessage(customerName + customerGender + ' 確認預約', 'done');
		} else if (newStatus === 'no_show') {
			window.app.alert.setMessage(customerName + customerGender + ' 未到店', 'done');
		} else if (newStatus === 'show') {
			window.app.alert.setMessage(customerName + customerGender + ' 已到店', 'done');
		} else if (newStatus === 'seated') {
			window.app.alert.setMessage(customerName + customerGender + ' 已入座', 'done');
		} else if (newStatus === 'finish') {
			window.app.alert.setMessage(customerName + customerGender + ' 完成消費', 'done');
		} else {
			window.app.alert.setMessage('更新狀態成功', 'done');
		}

		this.API
			.setBookingStatus(booking.id, newStatus, date, status_time)
			.then((data) => {
				// this.setState({
				// 	bookings: data,
				// 	initBookings: data
				// });
				this.handleResetStatusTimeRange();

				if (callback && typeof callback === 'function') callback();
			})
			.catch((error) => {
				handleError(error);
				window.app.alert.setMessage('請重新再試', 'error');
			});
	};
	addCustomerCallback = () => {
		this.getBookings();
	};
	// updateFilterDate = date => {
	// 	this.setState({ filter: { date: date } });
	// };
	changeViewMode = (mode) => {
		this.setState({ mode: mode }, () => {
			browserHistory.push({
				pathname: '/dashboard/bookings',
				query: {
					date: this.props.location.query.date,
					mode: mode
				}
			});
			// this._setupDateInput();
			this.isDisableDate(this.props.location.query.date);
			// this.setState({activeBookingListStatusTab: 'confirmed'})
		});
	};

	handleBookingListStatusTab = (status) => {
		this.setState({activeBookingListStatusTab: status})
	}

	renderBookingView = () => {
		switch (this.state.mode) {
			case 'list':
				return (
					<BookingListView
						scrollToActiveCode={this.props.location.state}
						shortCutBtn={this.state.show}
						bookings={this.state.bookings}
						setStatus={this.setStatus}
						showAddCustomerModal={this.showAddCustomerModal}
						showNewAddBookingModal={this.showNewAddBookingModal}
						getBookings={this.getBookings}
						customQ={this.state.customQ}
						loading={this.state.loading}
						updateData={this.updateData}
						handleBookingListStatusTab={this.handleBookingListStatusTab}
						activeBookingListStatusTab={this.state.activeBookingListStatusTab}
						serviceTimeOptionEnabled={this.state.serviceTimeOptionEnabled}
					/>
				);
			case 'table':
				return (
					<BookingTableView
						showNewAddBookingModal={this.showNewAddBookingModal}
						diningTime={this.state.diningTime}
						isToday={this.state.show}
						setStatus={this.setStatus}
						date={moment(this.props.location.query.date, 'YYYY-MM-DD')}
						updateData={this.updateData}
						filter={this.state.tlFilter}
						timelineTables={this.state.tables}
						warnings={this.state.warnings}
						bookings={this.state.bookings}
					/>
				);
			case 'calendar':
			default:
				return (
					<BookingCalendar
						renderIsToday={this.isToday}
						renderDisableDate={this.isDisableDate}
						gov_holidaysData={this.state.gov_holidaysData}
						disableDates={this.state.disableDates}
						monthEffectiveCount={this.state.monthEffectiveCount}
						monthUnconfirmedCount={this.state.monthUnconfirmedCount}
						monthIneffectiveCount={this.state.monthIneffectiveCount}
						monthAttendance={this.state.monthAttendance}
						selected={moment(this.props.location.query.date, 'YYYY-MM-DD')}
						changeViewMode={this.changeViewMode}
						monthTotalEffectiveCount={this.state.monthTotalEffectiveCount}
						monthTotalAttendance={this.state.monthTotalAttendance}
					/>
				);
		}
	};

	calPrevious = () => {
		let search = window.location.search.substring(1);
		let params = JSON.parse(
			'{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
		);
		if (this.state.mode === 'calendar') {
			var month = moment(this.props.location.query.date, 'YYYY-MM-DD');
			month.add(-1, 'M');
			browserHistory.push({
				pathname: '/dashboard/bookings',
				query: {
					date: month.format('YYYY-MM-DD'),
					mode: 'calendar'
				}
			});
		} else {
			this.setState({ bookings: [], loading: true });

			var date = moment(this.props.location.query.date);
			date.add(-1, 'days');
			browserHistory.push({
				pathname: '/dashboard/bookings',
				query: {
					date: date.format('YYYY-MM-DD'),
					mode: params.mode
				}
			});
			this.isDisableDate(date.format('YYYY-MM-DD'));
		}
		this.setState({activeBookingListStatusTab: 'confirmed'});
	};

	calNext = () => {
		let search = window.location.search.substring(1);
		let params = JSON.parse(
			'{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
		);
		if (this.state.mode === 'calendar') {
			var month = moment(this.props.location.query.date, 'YYYY-MM-DD');
			month.add(1, 'M');
			browserHistory.push({
				pathname: '/dashboard/bookings',
				query: {
					date: month.format('YYYY-MM-DD'),
					mode: 'calendar'
				}
			});
		} else {
			this.setState({ bookings: [], loading: true });

			var date = moment(this.props.location.query.date);
			date.add(1, 'days');
			browserHistory.push({
				pathname: '/dashboard/bookings',
				query: {
					date: date.format('YYYY-MM-DD'),
					mode: params.mode
				}
			});
			this.isDisableDate(date.format('YYYY-MM-DD'));
		}
		this.setState({activeBookingListStatusTab: 'confirmed'});
	};

	calSelect = (day) => {
		this.setState({ selected: day.date });
		this.forceUpdate();
	};

	_setupDateInput = () => {
		if (this.state.mode === 'list' || this.state.mode === 'table') {
			$(this.refs.dateInput)
				.datepicker({
					format: 'yyyy-mm-dd',
					minViewMode: 0,
					maxViewMode: 0,
					todayBtn: 'linked',
					todayHighlight: true,
					language: 'zh-TW'
				})
				.on('show', (e) => {
					document.activeElement.blur();
				})
				.on('changeDate', (e) => {
					browserHistory.push({
						pathname: '/dashboard/bookings',
						query: {
							date: e.format(),
							mode: this.props.location.query.mode
						}
					});
					this.isDisableDate(e.format());
				});
		} else {
			$(this.refs.dateInput).datepicker('destroy');
		}
	};

	isDisableDate = (filterDate) => {
		let { disableDates } = this.state;
		let txt;
		disableDates.sort();

		for (let date = 0; date < disableDates.length; date++) {
			if (disableDates[date] === filterDate) {
				txt = '本日為不可預約日';
				this.setState({
					isDisableDateTxt: txt
				});
				return txt;
			} else {
				txt = '';
				this.setState({
					isDisableDateTxt: txt
				});
			}
		}
	};

	isToday(queryDate) {
		let { isToday } = this.state;
		if (queryDate === isToday) {
			this.setState({ show: true });
		} else {
			this.setState({ show: false });
		}
	}

	getHolidays() {
		this.API.holidays().then((data) => {
			this.setState({
				gov_holidaysData: data
			});
		});
	}

	handleFStartTime(e) {
		this.setState({
			fStartTime: e.target.value,
			tempFStartTime: e.target.value
		});
	}

	handleFEndTime(e) {
		let { fStartTime } = this.state;

		if (parseInt(fStartTime, 10) >= parseInt(e.target.value, 10)) {
			window.app.alert.setMessage('結束時間應大於開始時間', 'error');
		} else {
			this.setState({
				fEndTime: e.target.value,
				tempFEndTime: e.target.value
			});
		}
	}

	handleAllTime() {
		let { initBookings } = this.state;
		let newFilterBookings = JSON.parse(JSON.stringify(initBookings));
		let dropdownNode = this.refs.timeFilterBtn;

		dropdownNode.classList.remove('open');

		this.setState({
			timeFilterTxt: (
				<span>
					<i className="glyphicon glyphicon-filter" />
					全日{' '}
				</span>
			),
			bookings: newFilterBookings,
			fStartTime: '請選擇',
			fEndTime: '請選擇',
			tempFStartTime: '',
			tempFEndTime: ''
		});
	}

	handleFtimeCancel() {
		let dropdownNode = this.refs.timeFilterBtn;
		dropdownNode.classList.remove('open');

		this.setState({
			fStartTime: '請選擇',
			fEndTime: '請選擇'
		});
	}

	handleCloseFtimeDropdown(e) {
		let dropdownNode = this.refs.timeFilterBtn;

		e.stopPropagation();

		if (dropdownNode && dropdownNode.classList.contains('open')) {
			this.setState({
				fStartTime: '請選擇',
				fEndTime: '請選擇'
			});
		}
	}

	handleTimeFilter() {
		let { fStartTime, fEndTime, initBookings } = this.state;
		let newBookings = JSON.parse(JSON.stringify(initBookings));
		let filterNewBookingTime = [];
		let hour, min, filterTimeDisplay;
		let dropdownNode = this.refs.timeFilterBtn;

		if (isNaN(parseInt(fStartTime, 10)) || isNaN(parseInt(fEndTime, 10))) {
			window.app.alert.setMessage('請選擇起始時間', 'error');
		} else {
			filterTimeDisplay = fStartTime + '-' + fEndTime + ' ';

			newBookings.map((item) => {
				hour = parseInt(item.time.slice(0, 2), 10);
				min = parseInt(item.time.slice(3, 5), 10);

				if (hour >= parseInt(fStartTime, 10) && hour < parseInt(fEndTime, 10)) {
					filterNewBookingTime.push(item);
				} else if (hour === parseInt(fEndTime, 10) && min === 0) {
					filterNewBookingTime.push(item);
				}
				return filterNewBookingTime;
			});

			dropdownNode.classList.remove('open');
			this.setState({
				timeFilterTxt: filterTimeDisplay,
				bookings: filterNewBookingTime,
				fStartTime: '請選擇',
				fEndTime: '請選擇'
			});
		}
	}

	handleResetStatusTimeRange() {
		let { tempFStartTime, tempFEndTime, initBookings } = this.state;
		let newBookings = JSON.parse(JSON.stringify(initBookings));
		let hour, min;
		let filterNewBookingTime = [];

		if (tempFStartTime === '' || tempFEndTime === '') {
			this.setState({
				bookings: initBookings
			});
		} else {
			newBookings.map((item) => {
				hour = parseInt(item.time.slice(0, 2), 10);
				min = parseInt(item.time.slice(3, 5), 10);

				if (hour >= parseInt(tempFStartTime, 10) && hour < parseInt(tempFEndTime, 10)) {
					filterNewBookingTime.push(item);
				} else if (hour === parseInt(tempFEndTime, 10) && min === 0) {
					filterNewBookingTime.push(item);
				}
				return filterNewBookingTime;
			});

			this.setState({
				bookings: filterNewBookingTime
			});
		}
	}

	renderTableMapIcon() {
		let { tableMapSetting } = this.state;

		if (tableMapSetting) {
			return (
				<div className="chair" onClick={() => this.toTableMap()}>
					<img src={require('../../images/component/chair.svg')} alt="table map" />
				</div>
			);
		}
	}

	toTableMap = () => {
		let { bookings, customQ } = this.state;
		let { date } = this.props.location.query;

		browserHistory.push({
			pathname: '/dashboard/bookings/booking_table_map',
			query: {
				date
			},
			state: {
				bookings,
				customQ
			}
		});
	};

	renderFilterTime() {
		let { mode, timeFilterTxt, initBookings } = this.state;
		let { date } = this.props.location.query;

		let today = moment().format().slice(0, 10);

		if (mode === 'list' && today === date) {
			return (
				<div className="btn-group timeFilterBtn" ref="timeFilterBtn">
					<button
						type="button"
						className="btn btn-default btn-lg dropdown-toggle"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						style={{
							borderColor: '#676767'
						}}
					>
						{timeFilterTxt}
						<span className="caret" />
					</button>
					<div className="dropdown-menu timeFilterContent" onClick={(e) => e.stopPropagation()}>
						<div className="content-row">
							<div className="content-row-title">開始時間</div>
							<div className="content-row-select">
								<select value={this.state.fStartTime} onChange={(e) => this.handleFStartTime(e)}>
									<option value="none">請選擇</option>
									{filterTimeOption.map((item, index) => {
										return (
											<option key={index} value={index}>
												{item}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="content-row">
							<div className="content-row-title">結束時間</div>
							<div className="content-row-select">
								<select value={this.state.fEndTime} onChange={(e) => this.handleFEndTime(e)}>
									<option value="none">請選擇</option>
									{filterTimeOption.map((item, index) => {
										return (
											<option key={index} value={index}>
												{item}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<hr />
						<div className="btnRow">
							<div className="btn btn-default" onClick={() => this.handleAllTime()}>
								全日
							</div>
							<div className="btn btn-default" onClick={() => this.handleFtimeCancel()}>
								取消
							</div>
							<div
								className="btn btn-default ms-btn-green"
								id="ga-filterBtn"
								onClick={() => this.handleTimeFilter(initBookings)}
							>
								確定
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	renderBookingViewInfoTxt() {
		const { mode, bookings, isDisableDateTxt, show } = this.state;
		let confirmedCount = 0,
			showCount = 0,
			seatedCount = 0;

		bookings.forEach((item) => {
			if (item.status === 'confirmed') {
				confirmedCount += 1;
			} else if (item.status === 'show') {
				showCount += 1;
			} else if (item.status === 'seated') {
				seatedCount += 1;
			}
		});

		if (mode === 'list') {
			return (
				<div className="listInfoTxt expandWidth">
					<div>{show ? `確認預約：${confirmedCount}組，已到店：${showCount}組，已入座：${seatedCount}組` : ''}</div>
					<p className="isDisableDateStyle">{isDisableDateTxt}</p>
				</div>
			);
		}
	}

	showNewAddBookingModal = (booking, type) => {
		let bodyEle = document.getElementsByTagName('body');
		if (type === 'editor') {
			bodyEle[0].classList.add('modal-open');
			this.setState({
				isHiddenNewBookingAddView: false,
				newSelected: booking,
				modalType: 'editor'
			});
		} else {
			bodyEle[0].classList.add('modal-open');
			this.setState({
				isHiddenNewBookingAddView: false,
				modalType: 'addNew'
			});
		}
	};

	hideNewAddBookingModal = () => {
		let { tempCustomQAns } = this.state;
		let bodyEle = document.getElementsByTagName('body');
		bodyEle[0].classList.remove('modal-open');
		this.setState({
			isHiddenNewBookingAddView: true,
			customQAns: JSON.parse(tempCustomQAns)
		});
	};

	renderFilterBtn = () => {
		let tables = _.sortBy(this.state.tables, [ 'group' ]);
		let groups = _.uniq(tables.map((m) => m.group)).reduce((map, groupName, idx) => {
			map[groupName] = colors[idx % colors.length];
			return map;
		}, {});
		if (this.props.location.query.mode === 'table') {
			return (
				<div
					className="btn-group"
					id="tlFilter"
					style={{
						display: 'inline-block',
						padding: 0,
						marginRight: '10px'
					}}
				>
					<button
						type="button"
						className="btn btn-default ms-btn-lg dropdown-toggle"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						style={{
							borderColor: '#333333'
						}}
					>
						<i className="glyphicon glyphicon-filter" />
						篩選 <span className="caret" />
					</button>
					<ul className="dropdown-menu filters">
						{Object.keys(this.state.tlFilter).map((g, index) => {
							return (
								<li key={index}>
									<div className="checkbox">
										<label>
											<input type="checkbox" value={g} defaultChecked={this.state.tlFilter[g]} />
											{g}{' '}
											<i
												className="glyphicon glyphicon-stop"
												style={{
													color: groups[g]
												}}
											/>
										</label>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			);
		}
	};

	renderAnnouncementBtn = () => {
		const { dayAnnouncements, todayAnnouncements } = this.props.announcementListReducer;
		let isCurrentMonth = moment().month() === moment(this.props.location.query.date).month();

		if(this.state.mode === 'calendar') {
			// 當月
			if(isCurrentMonth) {
				return (
					<span
						className='announcement_month'
						onClick={() => this.props.handleAnnouncementListModal('month')}
					>
						{ todayAnnouncements.length === 0 ?
							<span className='announcement_month_none' /> :
							<span className='announcement_month_remind' />
						}
						<span className='txt'>本月公告</span>
					</span>
				)
			}
		} else {
			if(dayAnnouncements.length === 0) {
				return (
					<span
						className='announcement_month disabled'
					>
						<span className='announcement_day_none' />
						<span className='txt'>本日公告</span>
					</span>
				)
			} else {
				return (
					<span
						className='announcement_month'
						onClick={() => this.props.handleAnnouncementListModal('day', this.props.location.query.date)}
					>
						<span className='announcement_month_remind' />
						<span className='txt'>本日公告</span>
					</span>
				)
			}
		}
	};

	render() {
		var sms_point_notify = null;
		let { shop } = this.state;
		const {
			showAnnouncementListModal,
			showAnnouncementDeleteModal,
			showAnnouncementCancelModal
		} = this.props.announcementListReducer;
		const {
			showAddAnnouncementModal
		} = this.props.addAnnouncementReducer;

		if (this.state.shop !== undefined && shop.msg_point >= 0) {
			if (shop.msg_point <= 30 && shop.msg_point > 5) {
				sms_point_notify = (
					<span style={{ marginLeft: 5 }}>
						剩餘簡訊數
						<span style={msg_point_style.minPont}>{shop.msg_point}</span>點
						<span
							style={{ ...msg_point_style.normal, ...msg_point_style.cursor }}
							onClick={() => {
								browserHistory.push({
									pathname: '/dashboard/setting/sms'
								});
							}}
						>
							<i className="fa fa-hand-o-right" aria-hidden="true" />前往購買
						</span>
					</span>
				);
			} else if (shop.msg_point <= 5 && shop.msg_point >= 0) {
				sms_point_notify = (
					<span style={{ marginLeft: 5 }}>
						剩餘簡訊數
						<span style={msg_point_style.alertPoint}>{shop.msg_point}</span>點
						<span
							style={{ ...msg_point_style.normal, ...msg_point_style.cursor }}
							onClick={() => {
								browserHistory.push({
									pathname: '/dashboard/setting/sms'
								});
							}}
						>
							<i className="fa fa-hand-o-right" aria-hidden="true" />前往購買
						</span>
					</span>
				);
			} else {
				sms_point_notify = (
					<span style={{ marginLeft: 5 }}>
						剩餘簡訊數<span style={msg_point_style.normal}>{shop.msg_point}</span>點
					</span>
				);
			}
		}
		return (
			<div style={{ padding: 1 }} onClick={(e) => this.handleCloseFtimeDropdown(e)}>
				<div id="timeline" className="container container-shadow">
					<div id="calendar" />
					<div className="func-title fix-funcTitle booking-title">
						<div>
							<Link
								to={{
									pathname: '/dashboard/bookings',
									query: {
										date: new moment().format('YYYY-MM-DD'),
										mode: 'calendar'
									}
								}}
								onClick={()=>this.setState({activeBookingListStatusTab: 'confirmed'})
							}
							>
								<img src={require('../../images/component/f_reservation.svg')} alt="" />
								<h1>預約系統 </h1>
							</Link>
							<span className="msg_point">{sms_point_notify}</span>
						</div>
						<div className='booking_title_setting'>
							{ this.renderAnnouncementBtn() }
							<Link
								to={{
									pathname: '/dashboard/setting',
									state: { source: 'booking', mode: this.state.mode, date: this.state.filter.date }
								}}
							>
								<img
									className="booking-setting-btn"
									src={require('../../images/component/setting.svg')}
									alt=""
								/>
								<span>設定</span>
							</Link>
						</div>
					</div>
					<div className="main fix-main">
						<div id="bookingControlBar" className="form-inline fix-bookingControlBar expandWidth">
							<div className="fix-bookingControlBar-cell">
								<span>
									<button
										onClick={this.calPrevious}
										type="button"
										className="btn btn-default btn-circle btn-lg fix-datepickerBarBtn-left"
									>
										<i className="glyphicon glyphicon-triangle-left" />
									</button>
									<div className="bookingPanelDateInput">
										<div ref="dateInput" className="input-group date">
											<input
												type="text"
												className="form-control input-lg fix-datepickerBar"
												onChange={() => {
													// this.isToday();
													console.log('onChange');
												}}
												value={
													this.state.mode === 'calendar' ? (
														moment(this.props.location.query.date, 'YYYY-MM-DD').format(
															'YYYY/MM'
														)
													) : (
														this.props.location.query.date
													)
												}
											/>
											<span className="input-group-addon">
												<i className="fa fa-calendar" aria-hidden="true" />
											</span>
										</div>
									</div>
									<button
										onClick={this.calNext}
										type="button"
										className="btn btn-default btn-circle btn-lg fix-datepickerBarBtn-right"
									>
										<i className="glyphicon glyphicon-triangle-right" />
									</button>
								</span>
							</div>

							<div className="fix-bookingControlBar-cell subBookingControlBar">
								<div className="viewBtn">
									<div
										className={'' + this.state.mode === 'calendar' ? 'active' : ''}
										onClick={this.changeViewMode.bind(null, 'calendar')}
									>
										<img src={require('../../images/component/calendar.svg')} alt="" />
									</div>
									<div
										className={'' + this.state.mode === 'list' ? 'active' : ''}
										onClick={this.changeViewMode.bind(null, 'list')}
									>
										<img src={require('../../images/component/list.svg')} alt="" />
									</div>
									<div
										className={'' + this.state.mode === 'table' ? 'active' : ''}
										onClick={this.changeViewMode.bind(null, 'table')}
									>
										<img src={require('../../images/component/clock.svg')} alt="" />
									</div>
									{this.renderTableMapIcon()}
								</div>

								<div>
									{this.renderFilterTime()}
									{this.renderFilterBtn()}
									<a
										className="exportFileBtn"
										href={
											this.state.mode === 'calendar' ? (
												window.domain +
												'/dashboard/bookings.csv?yyyymm=' +
												moment(this.props.location.query.date, 'YYYY-MM-DD').format('YYYYMM')
											) : (
												window.domain +
												'/dashboard/bookings.csv?date=' +
												this.props.location.query.date
											)
										}
									>
										<button
											className="ms-btn-lg btn-color-white"
											style={{
												borderColor: '#676767'
											}}
										>
											<img
												src={require('../../images/component/icon_export.png')}
												style={{
													height: '17px',
													width: '14px'
												}}
												alt=""
											/>
											<span
												style={{
													verticalAlign: 'middle',
													marginLeft: '10px'
												}}
											>
												匯出
											</span>
										</button>
									</a>
									<button
										onClick={() => this.showNewAddBookingModal()}
										type="button"
										className="ms-btn-lg ms-btn-green"
									>
										<img
											className="fix-addBookingModalCrossImg"
											src={require('../../images/component/cross.png')}
											alt=""
										/>
										<span
											style={{
												verticalAlign: 'middle'
											}}
										>
											新增預約
										</span>
									</button>
								</div>
							</div>
						</div>
						{this.renderBookingView()}
					</div>
				</div>

				{ this.state.showAddCustomerModal &&
					<CrmCustomerCreateModal
						selectedBooking={this.state.selectedBooking}
						load={this.addCustomerCallback}
						cancelPopup={() => this.setState({showAddCustomerModal: false})}
					/>
				}


				{!this.state.isHiddenNewBookingAddView && (
					<NewAddBookingView
						autoTable={this.state.autoTable}
						hideNewAddBookingModal={this.hideNewAddBookingModal}
						updateData={this.updateData}
						modalType={this.state.modalType}
						date={this.props.location.query.date}
						diningTime={this.state.diningTime}
						newSelected={this.state.newSelected}
						customQAns={this.state.customQAns}
						customQ={this.state.customQ}
						msgPoint={shop.msg_point}
						smsNotificationSettings={this.state.sms_notification_settings}
						emailNotificationSettings={this.state.email_notification_settings}
						spg_hash_iv={this.state.spg_hash_iv}
						spg_hash_key={this.state.spg_hash_key}
						spg_merchant_id={this.state.spg_merchant_id}
						credit_enabled={this.state.credit_enabled}
					/>
				)}

				{ showAnnouncementListModal && 
					<AnnouncementListModal
						month={moment(this.props.location.query.date).month()}
					/>
				}

				{
					showAddAnnouncementModal &&
					<AddAnnouncementModal />
				}

				{ showAnnouncementDeleteModal &&
					<AnnouncementCheckModal/>
				}

				{ showAnnouncementCancelModal &&
					<AnnouncementCancelModal/>
				}

				<div id="modalPortal" />
			</div>
		);
	}
}

const msg_point_style = {
	minPont: {
		color: '#faa329',
		marginLeft: 5,
		marginRight: 5
	},
	alertPoint: {
		color: '#ce4949',
		marginLeft: 5,
		marginRight: 5
	},
	normal: {
		color: '#3fba87',
		marginLeft: 5,
		marginRight: 5
	},
	cursor: {
		cursor: 'pointer'
	}
};

const fakeNewStatus = ({ bookings, tables, booking, newStatus }) => {
	const newBookings = _.cloneDeep(bookings);
	const newTables = _.cloneDeep(tables);
	const parseStatusTime = JSON.parse(booking.status_time);
	const bookingStatusObj = {
		unconfirmed: '待確認',
		confirmed: '確認預約',
		show: '已到店',
		seated: '已入座',
		finish: '完成消費',
		no_show: '未到店',
		cancel: '取消預約'
	};

	if (newStatus === 'seated') {
		const bookingTime_min = moment().minute();
		const diff = 5 - bookingTime_min % 5;
		const seatedTime = moment().add(diff, 'm').format('HH:mm');
		parseStatusTime[newStatus] = seatedTime;
	} else {
		parseStatusTime[newStatus] = moment().format('HH:mm');
	}

	newBookings.forEach((findBooking) => {
		if (findBooking.id === booking.id) {
			findBooking.status = newStatus;
			findBooking.status_t = bookingStatusObj[newStatus];
			findBooking.status_time = JSON.stringify(parseStatusTime);
		}
	});

	newTables.forEach((table) => {
		table.timeline.forEach((findBooking) => {
			if (findBooking.id === booking.id) {
				findBooking.status = newStatus;
				findBooking.status_t = bookingStatusObj[newStatus];
				findBooking.status_time = JSON.stringify(parseStatusTime);
			}
		});
	});

	return { newBookings, newTables };
};

const mapStateToProps = (state) => ({
	announcementListReducer: state.announcementListReducer,
  addAnnouncementReducer: state.addAnnouncementReducer
});

const mapDispatchToProps = (dispatch) => ({
	handleAnnouncementListModal: bindActionCreators(handleAnnouncementListModal, dispatch),
	updateDayAnnouncements: bindActionCreators(updateDayAnnouncements, dispatch),
	announcementFetchMonth: bindActionCreators(announcementFetchMonth, dispatch),
	resetAnnouncementList: bindActionCreators(resetAnnouncementList, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingPanel);