import React, { Fragment ,useContext } from 'react';

import Datetime from 'react-datetime';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

import { MessageContext } from '../../crmContext/MessageContext';
import { MessageAddStateContext } from '../../crmContext/MessageAddContext';

const MessagePublishSection = ({ children }) => {
	return <section className="crmMessageAdd_block">{React.Children.map(children, (child) => child)}</section>;
};

const PublishSectionTitle = () => {
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState } = useContext(MessageAddStateContext);
  const { error } = MessageAddState;

  const errorText = editType === 'sms' ? '請選擇傳送簡訊排程時間。' : '請選擇傳送MENU私訊排程時間。';

  return (
    <Fragment>
      <h3>排程時間</h3>
      { error.includes('dateTime') ? <div className="error_text">{ errorText }</div> : null }
    </Fragment>
  );
};

const ScheduleTime = () => {
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { messageSetting } = MessageAddState;
  const { status, scheduleTime } = messageSetting;

	const handleMessageStatus = (type) => {
    MessageAddDispatch({ type: 'setMessageStatus', status: type });
	};

  const renderDateTime = () => {
    let inputProps = {
      placeholder: '自訂時間'
    };

    return (
      <div className="datetime">
        <Datetime
          inputProps={inputProps}
          value={scheduleTime}
          onChange={(datetime) => {
            MessageAddDispatch({ type: 'setScheduleTime', scheduleTime: datetime });
          }}
          dateFormat="YYYY-MM-DD"
          timeFormat="HH:mm"
          isValidDate={(currentDate) => {
            return currentDate.isAfter(moment().subtract(1, 'day'));
          }}
        />
        <CalendarOutlined />
      </div>
    )
  }

  return (
    <Fragment>
      <div className="crmMessageAdd_radio" style={{ marginTop: '24px' }}>
        <label>
          <input
            type="radio"
            name="sendTime"
            onChange={() => handleMessageStatus('real_time')}
            checked={status === 'real_time'}
          />
          立即傳送
        </label>
      </div>
      <div className="crmMessageAdd_radio" style={{ marginTop: '16px' }}>
        <label>
          <input
            type="radio"
            name="sendTime"
						onChange={() => handleMessageStatus('scheduled')}
						checked={status === 'scheduled'}
          />
          自訂時間
        </label>
      </div>
      { status === 'scheduled' ? renderDateTime() : null }
    </Fragment>
  )
}

MessagePublishSection.Title = PublishSectionTitle;
MessagePublishSection.Schedule = ScheduleTime;

export default MessagePublishSection;
