import React, { Fragment } from "react";
import { Modal } from 'antd';

const BlacklistWarning = ({ modalType = 'addNew', addBookingSubmit, cancelBlacklistWarning }) => {
  const content = modalType === 'editor' ? '確定修改此預約嗎？' : '是否仍欲新增此筆預約？';

  return (
    <Modal
      title={`確定${modalType === 'editor' ? '修改' : '新增'}嗎？`}
      visible
      centered
      className="blacklistWarningPopup"
      footer={
        <Fragment>
          <button
            className="btn_cancel"
            onClick={cancelBlacklistWarning}
          >
            取消
          </button>
          <button
            className="btn_save"
            onClick={addBookingSubmit}
          >
            確認
          </button>
        </Fragment>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      此筆電話號碼已列入封鎖名單，{content}
    </Modal>
  )
}

export default BlacklistWarning;