import _ from 'lodash';
import {
	BOOKINGSETTINGAREA_FETCHSUCCESS,
	BOOKINGSETTINGAREA_SWITCHSETTING,
	BOOKINGSETTINGAREA_CHAGEAREATITLE,
	BOOKINGSETTINGAREA_ADDNEWAREABLOCKOPTION,
	BOOKINGSETTINGAREA_OPENMODAL,
	BOOKINGSETTINGAREA_CLOSEMODAL,
	BOOKINGSETTINGAREA_CLEARSETTING,
	BOOKINGSETTINGAREA_SETAREAOPTION,
	BOOKINGSETTINGAREA_CANCELWARNING,
	BOOKINGSETTINGAREA_DELETEAREABLOCK,
	BOOKINGSETTINGAREA_EDITAREABLOCK,
	BOOKINGSETTINGAREA_CHANGEAREABLOCKTITLE,
	BOOKINGSETTINGAREA_CLICKTABLEGROUP,
	BOOKINGSETTINGAREA_INITMODALSTATE,
	BOOKINGSETTINGAREA_UPDATEMODALSTATE,
	BOOKINGSETTINGAREA_CLEARMODALSTATE,
	BOOKINGSETTINGAREA_SENDING,
	BOOKINGSETTINGAREA_DISABLESENDING,
	BOOKINGSETTINGAREA_UPDATEAREA,
	BOOKINGSETTINGAREA_SYNCAVAILABLETIME,
	BOOKINGSETTINGAREA_EDITAVAILABLETIME,
	BOOKINGSETTINGAREA_CHANGESETTINGWEEK,
	BOOKINGSETTINGAREA_CHANGESETTINGTIME,
	BOOKINGSETTINGAREA_CHANGEDISPLAYTIME,
	BOOKINGSETTINGAREA_CHANGEDININGTIMEFIXED,
	BOOKINGSETTINGAREA_CHANGEDININGTIMEUSERSETTING,
	BOOKINGSETTINGAREA_CANCELSETTINGEDIT,
	BOOKINGSETTINGAREA_SAVESETTINGEDIT,
	BOOKINGSETTINGAREA_TOGGLEPAYMENT,
	BOOKINGSETTINGAREA_CHANGEDEPOSITENABLE,
	BOOKINGSETTINGAREA_CHANGEDEPOSIT,
	BOOKINGSETTINGAREA_SYNCMODALPAYMENT,
	BOOKINGSETTINGAREA_CHANGEDEPOSITPEOPLE,
	BOOKINGSETTINGAREA_ERRORTIME,
	BOOKINGSETTINGAREA_ADDNEWWEEKSETTING,
	BOOKINGSETTINGAREA_CHANGEENABLE,
	BOOKINGSETTINGAREA_CHANGEFIXEDDININGTIME,
	BOOKINGSETTINGAREA_CHANGEDININGTIMEMODE,
	BOOKINGSETTINGAREA_ADDTIMERANGE,
	BOOKINGSETTINGAREA_ADDDININGTIMERANGE,
	BOOKINGSETTINGAREA_DININGTIMESHOWTIME,
	BOOKINGSETTINGAREA_DININGTIMENAMEUPDATE,
	BOOKINGSETTINGAREA_ADDTIME,
	BOOKINGSETTINGAREA_CHANGEINTERVALTIME,
	BOOKINGSETTINGAREA_CHANGEDININGTIMEINTERVALTIME,
	BOOKINGSETTINGAREA_REMOVETIME,
	BOOKINGSETTINGAREA_CHECKADD,
	BOOKINGSETTINGAREA_DELSETTING,
	BOOKINGSETTINGAREA_CHECKEDIT,
	BOOKINGSETTINGAREA_DELTIMERANGE,
	BOOKINGSETTINGAREA_DELDININGTIMERANGE,
	BOOKINGSETTINGAREA_GOOGLERESERVATIONREQUIREDCHECK
} from '../actions/bookingSettingAreaAction';
const weeksTxt = [ '星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六' ];

const bookingSettingAreaInitState = {
	isSaved: true,
	sending: false,
	isHiddenModal: true,
	isHiddenWarning: true,
	areaSetting: false,
	areaSettingTitle: '',
	type: '',
	space_groups: [],
	deleted_space_groups: [],
	tables: [],
	targetIndex: null,
	setting: {},
	depositPeopleRequired: 0,
	deposit: 0,
	serviceTimeOptionEnabled: false,
	google_reservation: false,
	showGoogleReservationRequiredCheckModal: false
};

const bookingSettingAreaModalInitState = {
	areaBlockTitle: '',
	areaBlockTables: [],
	areaBlockWeekSettings: [],
	setting: {},
	activeEditId: null,
	editMode: false,
	paymentSettings: {},
	errorTime: [],
	weekSettingType: '',
	systemDiningTime: 0,
	usedWeeks: [],
	spgMerchantId: ''
};

export function BookingSettingAreaReducer(state = bookingSettingAreaInitState, action) {
	let newState = _.cloneDeep(state);

	switch (action.type) {
		case BOOKINGSETTINGAREA_FETCHSUCCESS:
			return initSetting(state, action);
		case BOOKINGSETTINGAREA_SWITCHSETTING:
			return Object.assign({}, newState, {
				areaSetting: !newState.areaSetting,
				isSaved: false
			});
		case BOOKINGSETTINGAREA_CHAGEAREATITLE:
			return Object.assign({}, newState, {
				areaSettingTitle: action.txt,
				isSaved: false
			});
		case BOOKINGSETTINGAREA_ADDNEWAREABLOCKOPTION:
			return Object.assign({}, newState, {
				targetIndex: null
			});
		case BOOKINGSETTINGAREA_OPENMODAL:
			return Object.assign({}, newState, {
				setting: {},
				isHiddenModal: false
			});
		case BOOKINGSETTINGAREA_CLOSEMODAL:
			return Object.assign({}, newState, {
				setting: {},
				isHiddenModal: true
			});
		case BOOKINGSETTINGAREA_SETAREAOPTION:
			return Object.assign({}, newState, {
				type: action.clickType,
				targetIndex: action.id,
				isHiddenWarning: false
			});
		case BOOKINGSETTINGAREA_CANCELWARNING:
			return Object.assign({}, newState, {
				type: null,
				targetIndex: null,
				isHiddenWarning: true
			});
		case BOOKINGSETTINGAREA_DELETEAREABLOCK:
			return deleteAreaBlock(state);
		case BOOKINGSETTINGAREA_EDITAREABLOCK:
			return editAreaBlock(state);
		case BOOKINGSETTINGAREA_CLEARSETTING:
			return Object.assign({}, newState, {
				isSaved: true,
				sending: false,
				isHiddenModal: true,
				isHiddenWarning: true,
				areaSetting: false,
				areaSettingTitle: '',
				type: '',
				space_groups: [],
				deleted_space_groups: [],
				tables: [],
				targetIndex: null,
				setting: {}
			});
		case BOOKINGSETTINGAREA_UPDATEMODALSTATE:
			return updateAreaLists(state, action);
		case BOOKINGSETTINGAREA_SENDING:
			return Object.assign({}, newState, {
				sending: true
			});
		case BOOKINGSETTINGAREA_DISABLESENDING:
			return Object.assign({}, newState, {
				sending: false
			});
		case BOOKINGSETTINGAREA_UPDATEAREA:
			const newSpaceGroups = _.cloneDeep(action.res);

			newSpaceGroups.forEach((group) => {
				group.time_settings = JSON.parse(group.time_settings);
				group.payment_settings = JSON.parse(group.payment_settings);
			});

			return Object.assign({}, newState, {
				sending: false,
				isSaved: true,
				space_groups: newSpaceGroups
			});
		case BOOKINGSETTINGAREA_GOOGLERESERVATIONREQUIREDCHECK:
			return Object.assign({}, newState, {
				showGoogleReservationRequiredCheckModal: action.value
			});
		default:
			return state;
	}
}

export function BookingSettingAreaModalReducer(state = bookingSettingAreaModalInitState, action) {
	let newState = _.cloneDeep(state);

	switch (action.type) {
		case BOOKINGSETTINGAREA_INITMODALSTATE:
			return initEditAreaModalState(state, action);
		case BOOKINGSETTINGAREA_CHANGEAREABLOCKTITLE:
			return Object.assign({}, newState, {
				areaBlockTitle: action.txt
			});
		case BOOKINGSETTINGAREA_SYNCAVAILABLETIME:
			return Object.assign({}, newState, {
				systemDiningTime: action.systemDiningTime,
				spgMerchantId: action.spgMerchantId
			});
		case BOOKINGSETTINGAREA_CLICKTABLEGROUP:
			return clickAreaTableGroup(state, action);
		case BOOKINGSETTINGAREA_CLEARMODALSTATE:
			return Object.assign({}, newState, {
				areaBlockTitle: '',
				areaBlockTables: [],
				areaBlockWeekSettings: [],
				activeEditId: null,
				paymentSettings: {},
				errorTime: [],
				usedWeeks:[],
				weekSettingType: '',
				editMode: false
			});
		case BOOKINGSETTINGAREA_SYNCMODALPAYMENT:
			return syncModal(state, action);
		case BOOKINGSETTINGAREA_EDITAVAILABLETIME:
			return setEditSetting(state, action);
		case BOOKINGSETTINGAREA_CHANGESETTINGWEEK:
			return changeSettingWeek(state, action);
		case BOOKINGSETTINGAREA_CHANGESETTINGTIME:
			return changeSettingTime(state, action);
		case BOOKINGSETTINGAREA_CHANGEDISPLAYTIME:
			return changeSettingTimeRange(state, action);
		case BOOKINGSETTINGAREA_CHANGEDININGTIMEFIXED:
			return changeSettingDiningTimeFixed(state, action);
		case BOOKINGSETTINGAREA_CHANGEDININGTIMEUSERSETTING:
			return changeDiningTimeUserSetting(state, action);
		case BOOKINGSETTINGAREA_CANCELSETTINGEDIT:
			return Object.assign({}, newState, {
				activeEditId: null,
				setting: {},
				weekSettingType: '',
				editMode: false,
				errorTime: [],
				usedWeeks:[],
			});
		case BOOKINGSETTINGAREA_SAVESETTINGEDIT:
			return Object.assign({}, newState, {
				setting: {},
				weekSettingType: '',
				editMode: false,
				activeEditId: null,
				errorTime: [],
				usedWeeks: []
			});
		case BOOKINGSETTINGAREA_TOGGLEPAYMENT:
			return Object.assign({}, newState, {
				paymentSettings: {
					...newState.paymentSettings,
					active: !newState.paymentSettings.active
				}
			});
		case BOOKINGSETTINGAREA_CHANGEDEPOSITENABLE:
			return changeDepositEnable(state, action);
		case BOOKINGSETTINGAREA_CHANGEDEPOSIT:
			return changeDeposit(state, action);
		case BOOKINGSETTINGAREA_CHANGEDEPOSITPEOPLE:
			return changeDepositPeople(state, action);
		case BOOKINGSETTINGAREA_ERRORTIME:
			return Object.assign({}, newState, {
				errorTime: action.errorTime
			});
		case BOOKINGSETTINGAREA_ADDNEWWEEKSETTING:
			return addNewWeekSetting(state);
		case BOOKINGSETTINGAREA_CHANGEENABLE:
			return changeEnable(state, action);
		case BOOKINGSETTINGAREA_CHANGEFIXEDDININGTIME:
			return changeFixedDiningTime(state, action);
		case BOOKINGSETTINGAREA_CHANGEDININGTIMEMODE:
			return changeDiningTimeMode(state, action);
		case BOOKINGSETTINGAREA_ADDTIMERANGE:
			return addTimeRange(state, action);
		case BOOKINGSETTINGAREA_ADDDININGTIMERANGE:
			return addDiningTimeRange(state, action);
		case BOOKINGSETTINGAREA_DININGTIMESHOWTIME:
			return diningTimeShowTime(state, action);
		case BOOKINGSETTINGAREA_DININGTIMENAMEUPDATE:
			return updateDiningTimeName(state, action);
		case BOOKINGSETTINGAREA_ADDTIME:
			return addTime(state, action);
		case BOOKINGSETTINGAREA_CHANGEINTERVALTIME:
			return Object.assign({}, newState, {
				setting: {
					...newState.setting,
					booking_time_interval: action.time
				}
			});
		case BOOKINGSETTINGAREA_CHANGEDININGTIMEINTERVALTIME:
			return Object.assign({}, newState, {
				setting: {
					...newState.setting,
					service_time_setting: [{
						...newState.setting.service_time_setting[0],
						fixed: {
							...newState.setting.service_time_setting[0].fixed,
							unit: action.time
						}
					}]
				}
			});
		case BOOKINGSETTINGAREA_REMOVETIME:
			return removeTime(state, action);
		case BOOKINGSETTINGAREA_CHECKADD:
			return checkAdd(state);
		case BOOKINGSETTINGAREA_CHECKEDIT:
			return checkEdit(state);
		case BOOKINGSETTINGAREA_DELSETTING:
			return delSetting(state, action);
		case BOOKINGSETTINGAREA_DELTIMERANGE:
			return delTimeRange(state, action)
		case BOOKINGSETTINGAREA_DELDININGTIMERANGE:
			return delDiningTimeRange(state, action)
		default:
			return state;
	}
}

function initSetting(state, action) {
	const {
		tables,
		areaSetting,
		areaSettingTitle,
		space_groups,
		depositPeopleRequired,
		deposit,
		serviceTimeOptionEnabled,
		google_reservation
	} = action;
	let newState = _.cloneDeep(state);
	const spaceGroups = _.cloneDeep(space_groups);

	newState.tables = _.sortBy(tables, [ 'group', 'max_seat' ]);
	newState.areaSetting = areaSetting;
	newState.areaSettingTitle = areaSettingTitle;
	newState.space_groups = spaceGroups;
	newState.depositPeopleRequired = depositPeopleRequired;
	newState.deposit = deposit;
	newState.serviceTimeOptionEnabled = serviceTimeOptionEnabled;
	newState.google_reservation = google_reservation;

	return newState;
}

function deleteAreaBlock(state) {
	let newState = _.cloneDeep(state);
	const { space_groups, targetIndex, deleted_space_groups } = newState;

	deleted_space_groups.push(space_groups[targetIndex].id);
	space_groups.splice(targetIndex, 1);

	newState.targetIndex = null;
	newState.isHiddenWarning = true;
	newState.isSaved = false;

	return newState;
}

function editAreaBlock(state) {
	let newState = _.cloneDeep(state);
	const { space_groups, targetIndex } = newState;

	newState.setting = _.cloneDeep(space_groups[targetIndex]);
	newState.isHiddenModal = false;
	newState.isHiddenWarning = true;

	return newState;
}

function clickAreaTableGroup(state, action) {
	let newState = _.cloneDeep(state);
	const { table } = action;

	let { areaBlockTables } = newState;
	const hasId = _.includes(areaBlockTables, table.id);

	if (hasId) {
		_.remove(areaBlockTables, (tableID) => {
			return tableID === table.id;
		});
	} else {
		areaBlockTables.push(table.id);
	}

	return newState;
}

function initEditAreaModalState(state, action) {
	let newState = _.cloneDeep(state);
	const { setting, depositPeopleRequired, deposit } = action;
	const newSetting = _.cloneDeep(setting);
	let { name, table_settings, time_settings, payment_settings } = newSetting;

	if (Object.keys(payment_settings).length === 0) {
		payment_settings = {
			deposit_required: false,
			enable: 1,
			setting1: { deposit: deposit, deposit_people_required: depositPeopleRequired },
			setting2: 1000
		};
	}

	newState.areaBlockTitle = name;
	newState.areaBlockTables = table_settings;
	newState.areaBlockWeekSettings = time_settings;
	newState.paymentSettings = payment_settings;

	return newState;
}

function updateAreaLists(state, action) {
	let newState = _.cloneDeep(state);
	let { space_groups, targetIndex } = newState;
	const { areaBlockTables, areaBlockTitle, areaBlockWeekSettings, paymentSettings } = action;
	const newAreaBlockTables = _.cloneDeep(areaBlockTables);
	const newAreaBlockWeekSettings = _.cloneDeep(areaBlockWeekSettings);
	const newPayment = _.cloneDeep(paymentSettings)

	if (targetIndex === null) {
		//新增
		const newGroup = {
			id: '',
			name: areaBlockTitle,
			table_settings: newAreaBlockTables,
			time_settings: newAreaBlockWeekSettings,
			payment_settings: newPayment
		};

		space_groups.push(newGroup);
	} else {
		//修改
		space_groups[targetIndex].name = areaBlockTitle;
		space_groups[targetIndex].table_settings = newAreaBlockTables;
		space_groups[targetIndex].time_settings = newAreaBlockWeekSettings;
		space_groups[targetIndex].payment_settings = newPayment;
	}

	return newState;
}

function setEditSetting(state, action) {
	let newState = _.cloneDeep(state);
	const { areaBlockWeekSettings } = newState;
	const { index } = action;
	const editSetting = areaBlockWeekSettings[index];

	const newAreaBlockWeekSettings=_.cloneDeep(areaBlockWeekSettings)

	const usedWeeks = newAreaBlockWeekSettings.reduce((prevWeek, currentWeek, currentIndex) => {
		if(currentIndex !== index) prevWeek.push(currentWeek.week_days);
		return prevWeek;
	}, []);

	newState.activeEditId = index;
	newState.setting = _.cloneDeep(editSetting);
	newState.weekSettingType = 'edit';
	newState.editMode = true;
	newState.usedWeeks = _.uniq(_.flatten(usedWeeks));

	return newState;
}

function changeSettingWeek(state, action) {
	let newState = _.cloneDeep(state);
	const { setting } = newState;
	const { week } = action;
	const weekIndex = weeksTxt.indexOf(week);
	const findWeekIndex = setting.week_days.indexOf(weekIndex);

	if (findWeekIndex === -1) setting.week_days.push(weekIndex);
	if (findWeekIndex !== -1) setting.week_days.splice(findWeekIndex, 1);

	setting.week_days.sort((a, b) => a - b);

	return newState;
}

function changeSettingTime(state, action) {
	let newState = _.cloneDeep(state);
	const { time } = action;
	const { areaBlockWeekSettings, activeEditId } = newState;
	const setting = areaBlockWeekSettings[activeEditId].space_group_settings;
	const { setting3 } = setting.setting_temp;

	const timeIndex = setting3.indexOf(time);

	if (timeIndex === -1) setting3.push(time);
	if (timeIndex !== -1) setting3.splice(timeIndex, 1);

	return newState;
}

function changeSettingTimeRange(state, action) {
	let newState = _.cloneDeep(state);
	const { setting } = newState;
	const { displayTime, timeRangeIndex, timeIndex } = action;
	const { setting2 } = setting.setting_temp;

	setting2[timeRangeIndex][timeIndex] = displayTime;

	return newState;
}

function changeSettingDiningTimeFixed(state, action) {
	let newState = _.cloneDeep(state);
	const { setting } = newState;
	const { rangeType, timeType, time } = action;

	const diningTime_min = parseInt(setting.service_time_setting[0].fixed.min, 10);
	const diningTime_max = parseInt(setting.service_time_setting[0].fixed.max, 10);

	if (rangeType === 'min') {
		if (timeType === 'hour') {
			const min = diningTime_min % 60;
			setting.service_time_setting[0].fixed.min = parseInt(time, 10) * 60 + min;
		} else if (timeType === 'min') {
			const hour = Math.floor(diningTime_min / 60);
			setting.service_time_setting[0].fixed.min = parseInt(time, 10) + hour * 60;
		}
	} else if (rangeType === 'max') {
		if (timeType === 'hour') {
			const min = diningTime_max % 60;
			setting.service_time_setting[0].fixed.max = parseInt(time, 10) * 60 + min;
		} else if (timeType === 'min') {
			const hour = Math.floor(diningTime_max / 60);
			setting.service_time_setting[0].fixed.max = parseInt(time, 10) + hour * 60;
		}
	}
	return newState;
}

function changeDiningTimeUserSetting(state, action) {
	let newState = _.cloneDeep(state);
	const { setting } = newState;
	const { timeType, id, time } = action;

	const diningTime = parseInt(setting.service_time_setting[0].user_setting.service_time_setting[id].service_time, 10) ;

	if (timeType === 'hour') {
		const min = diningTime % 60;
		setting.service_time_setting[0].user_setting.service_time_setting[id].service_time = parseInt(time, 10) * 60 + min;
	} else if (timeType === 'min') {
		const hour = Math.floor(diningTime / 60);
		setting.service_time_setting[0].user_setting.service_time_setting[id].service_time = parseInt(time, 10) + hour * 60;
	}

	return newState;
}

function changeDepositEnable(state, action) {
	let newState = _.cloneDeep(state);
	const { paymentSettings } = newState;

	paymentSettings.enable = action.enable;

	return newState;
}

function changeDeposit(state, action) {
	let newState = _.cloneDeep(state);
	const { paymentSettings } = newState;
	const { enable, value } = action;

	if (enable === 1) paymentSettings.setting1.deposit = value;
	if (enable === 2) paymentSettings.setting2 = value;

	return newState;
}

function changeDepositPeople(state, action) {
	let newState = _.cloneDeep(state);
	const { paymentSettings } = newState;
	const { value } = action;

	paymentSettings.setting1.deposit_people_required = value;

	return newState;
}

function syncModal(state, action) {
	let newState = _.cloneDeep(state);
	const { paymentSystemSetting } = action;

	newState.paymentSettings = {
		active: false,
		enable: 1,
		setting1: {
			deposit: paymentSystemSetting.deposit,
			deposit_people_required: paymentSystemSetting.depositPeopleRequired
		},
		setting2: 1000
	};

	return newState;
}

function addNewWeekSetting(state) {
	let newState = _.cloneDeep(state);
	const { systemDiningTime, areaBlockWeekSettings } = newState;

	const settingRow = {
		week_days: [],
		dining_time: systemDiningTime,
		booking_time_interval: 30,
		setting_temp: {
			enable: 2,
			setting2: [ [ '00:00', '23:00' ] ],
			setting3: [ '00:00' ]
		},
		service_time_setting: [{
			mode: 'user_setting',
			user_setting: {
				show_name: true,
				service_time_setting: [{
					name: "選項名稱",
					service_time: systemDiningTime
				}]
			},
			fixed: {
				service_time: [],
				max: 240,
				min: 60,
				unit: 30
			}
		}]
	};

	const usedWeeks = areaBlockWeekSettings.reduce((prevWeek, currentWeek) => {
		prevWeek.push(currentWeek.week_days);
		return prevWeek;
	}, []);

	newState.setting = settingRow;
	newState.activeEditId = null;
	newState.editMode = true;
	newState.weekSettingType = 'add';
	newState.usedWeeks = _.uniq(_.flatten(usedWeeks));

	return newState;
}

function changeEnable(state, action) {
	let newState = _.cloneDeep(state);
	const { enable } = action;

	newState.setting.setting_temp.enable = enable;

	return newState;
}

function changeFixedDiningTime(state, action) {
	let newState = _.cloneDeep(state);
	const { setting } = newState;
	const { timeType, time } = action;
	const { dining_time } = setting;
	
	const diningTime = parseInt(dining_time, 10);
	
	if (timeType === 'hour') {
		const min = diningTime % 60;
		setting.dining_time = parseInt(time, 10) * 60 + min;
	} else if (timeType === 'min') {
		const hour = Math.floor(diningTime / 60);
		setting.dining_time = parseInt(time, 10) + hour * 60;
	}
	
	return newState;
}

function changeDiningTimeMode(state, action) {
	let newState = _.cloneDeep(state);
	const { mode } = action;

	newState.setting.service_time_setting[0].mode = mode;

	return newState;
}


function addTimeRange(state) {
	let newState = _.cloneDeep(state);

	const newTimeRange = [ '00:00', '23:00' ];

	newState.setting.setting_temp.setting2.push(newTimeRange);

	return newState;
}

function addDiningTimeRange(state) {
	let newState = _.cloneDeep(state);

	const newDiningTimeRange = {name: '選項名稱', service_time: '30'};

	newState.setting.service_time_setting[0].user_setting.service_time_setting.push(newDiningTimeRange);

	return newState;
}

function diningTimeShowTime(state) {
	let newState = _.cloneDeep(state);

	newState.setting.service_time_setting[0].user_setting.show_name = !state.setting.service_time_setting[0].user_setting.show_name

	return newState;
}

function updateDiningTimeName(state, action) {
	let newState = _.cloneDeep(state);
	const { id, name } = action;

	newState.setting.service_time_setting[0].user_setting.service_time_setting[id].name = name;

	return newState;
}

function addTime(state, action) {
	let newState = _.cloneDeep(state);
	const { time } = action;

	newState.setting.setting_temp.setting3.push(time);

	return newState;
}

function removeTime(state, action) {
	let newState = _.cloneDeep(state);
	let { setting3 } = newState.setting.setting_temp;
	const { time } = action;
	const findTimeIndex = setting3.indexOf(time);
	setting3.splice(findTimeIndex, 1);

	return newState;
}

function checkAdd(state) {
	let newState = _.cloneDeep(state);
	let { areaBlockWeekSettings, setting } = newState;

	areaBlockWeekSettings.push(setting);

	return newState;
}

function delSetting(state, action) {
	let newState = _.cloneDeep(state);
	const { index } = action;
	let { areaBlockWeekSettings } = newState;

	areaBlockWeekSettings.splice(index, 1);

	return newState;
}

function checkEdit(state) {
	let newState = _.cloneDeep(state);
	let { areaBlockWeekSettings, setting, activeEditId } = newState;

	areaBlockWeekSettings[activeEditId] = _.cloneDeep(setting);

	return newState;
}


function delTimeRange(state, action){
	let newState = _.cloneDeep(state);
	let { setting2 } = newState.setting.setting_temp;
	const {index}=action;

	setting2.splice(index, 1)

	return newState;
}

function delDiningTimeRange(state, action){
	let newState = _.cloneDeep(state);
	const { index }=action;

	newState.setting.service_time_setting[0].user_setting.service_time_setting.splice(index, 1)

	return newState;
}