/* eslint-disable */
///////////////////////////////////////////
// jquery and tether for bootstrap to use
// alternative is to link them in index.html
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-63016150-6'); //Unique Google Analytics tracking number
// global.ReactGA = ReactGA;
import jQuery from "jquery";
var $ = jQuery;
global.$ = global.jQuery = jQuery;
require("bootstrap/dist/js/bootstrap");

// import moment from 'moment';
// window.moment = moment;
//import './libs/vendor/typeahead.jquery'

//window.Tether = require("tether");
//require("bootstrap/dist/js/bootstrap");
/////////////////////////////////////////////

import React from "react";

global.React = React;
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, IndexRoute, IndexRedirect } from "react-router";

/////////////////////////////////////////////////////////////////////////
// browserHistory would be preferred over hashHistory, but browserHistory
// would require configuring the server. So we will use hashHistory here.
// Please change to browserHistory if you have your own backend server.
// import {browserHistory as history} from 'react-router';
import { browserHistory } from "react-router";

//////////////////////////////////////////////////////////////////////////

import configureStore from "./store/configureStore";
import connectToAlerts from "./utils/socketUtils";

// import App from "./containers/app/App";
// import Login from "./containers/login/Login";
// import RestrictPage from "./containers/misc/RestrictPage";
// import Home from "./containers/home/Home";
// import UsersPage from "./containers/user/UsersPage";
// import ReposPage from "./containers/repo/ReposPage";
// import About from "./containers/about/About";
import NotFound from "./containers/misc/NotFound";
import RestrictPage from "./containers/misc/RestrictPage";
import { config } from "./utils/config";
window.config = config;
window.domain = config.domain;
window.ws_domain = config.ws_domain;

import PageIndex from "./components/pages/PageIndex";
import PageBookingSystem from "./components/pages/PageBookingSystem";
import QueueSystem from "./components/pages/PageQueueSystem";
import PageCoupon from "./components/pages/PageCoupon";
import PageCRM from "./components/pages/PageCRM";
import PageAnnouncement from "./components/pages/PageAnnouncement";
import PageMENU from './components/pages/PageMENU';
// import PageContact from "./components/pages/PageContact";
import PageContact from "./components/pages/PageContact_new";
// import PageDone from "./components/pages/PageDone";
import PageCase from './components/pages/PageCase';
import PageDone from "./components/pages/PageDone_new";
import PageLoginBox from "./components/pages/PageLoginBox";
import PageUserLoginBox from "./components/pages/PageUserLoginBox";
import PageTOS from "./components/pages/PageTOS";
import PagePrivacyPolicy from "./components/pages/PagePrivacyPolicy";
import Navbar from "./components/commons/Navbar";
import DashboardBox from "./components/pages/DashboardBox";
import ShopBox from "./components/shops/ShopBox";
import UserPanel from "./components/users/UserPanel";
import UserProfileBox from "./components/users/UserProfileBox";
import UserChangePassword from "./components/users/UserChangePassword.js";

// CRM
import Crm from './components/crm/Crm'
import CrmMessageBox from './components/crm/CrmMessageBox';
import CrmCustomerBox from './components/crm/CrmCustomerBox';
// import CustomerRecordBox from './components/customer_records/CustomerRecordBox';
import CrmCustomerRecordBox from './components/crm/crmCustomer/CrmCustomerRecordBox';
import CrmBlockedList from './components/crm/crmSettings/CrmBlockedList';
import CrmSettingsTags from './components/crm/crmSettings/CrmSettingsTags';

import CallBox from "./components/calls/CallBox";
import ReportPanel from "./components/report/ReportPanel";
import AnnouncementPanel from "./components/announcement/AnnouncementPanel";

import AllSettingSideBar from "./components/all_setting/AllSettingSideBar";

import BookingPanel from "./components/bookings/BookingPanel";
import BookingTableMap from "./components/bookings/BookingTableMap/BookingTableMap";
import BookingSettingDateConfig from "./components/booking_settings/BookingSettingDateConfig";
import BookingSettingDateConfig2 from "./components/booking_settings/DateConfig/BookingSettingDateConfig2";
import BookingSettingSeats from "./components/booking_settings/Seats/BookingSettingSeats";
import BookingSettingArea from "./components/booking_settings/Area/BookingSettingArea";
import BookingSettingTableMap from "./components/booking_settings/TableMap/BookingSettingTableMap";
import TableMap from "./components/booking_settings/TableMap/TableMap";
import CustomQuestion from "./components/booking_settings/BookingCustomQuestions";
import BookingSettingMsg from "./components/booking_settings/BookingSettingMsg";
import BookingSettingAdvanced from "./components/booking_settings/BookingSettingAdvanced";
import BookingSettingPayment from "./components/booking_settings/BookingSettingPayment";
import BookingSettingCalendar from "./components/booking_settings/BookingSettingCalendar";
import BookingSettingGoogleReservation from './components/booking_settings/BookingSettingGoogleReservation.js';
import BookingSettingEnable from "./components/booking_settings/Enable/BookingSettingEnable";
import BsTest from "./components/booking_settings/BsTest/BsTest";
import BookingSeatsOrder from "./components/booking_settings/SeatOrder/BookingSeatsOrder";

import QueuePanel from "./components/queue_system/QueuePanel";
import QueueChart from "./components/queue_system/chart/QueueChart";
import QueueRTDashboard from "./components/queue_system/QueueRTDashboard";
import QueueSettingGroupType from "./components/queue_system_settings/QueueSettingGroupType";
import QueueSettingDisplay from "./components/queue_system_settings/QueueSettingDisplay";
import QueueSettingTime from "./components/queue_system_settings/QueueSettingTime";
import QueueSettingNotification from "./components/queue_system_settings/QueueSettingNotification";

//new module
import NewModuleEntry from "./components/new_module/NewModuleEntry";
import NewModule from "./components/new_module/NewModule";

//menu coupon
import MenuCouponEntry from "./components/menu_coupon/MenuCouponEntry";
import MenuCouponPanel from "./components/menu_coupon/MenuCouponPanel";

//menu user
import MenuUserBox             from './components/shops/menu_user/MenuUserBox';

//stylesheets
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "./stylesheets/settings.css";
import "./stylesheets/menuwebfont.css";
import "./stylesheets/crm.css";
import "./stylesheets/phonecall.css";
import "./stylesheets/reservation.css";
import "./stylesheets/menushop-index.css";
import "./stylesheets/menu.css";
import "./stylesheets/menushop-main.css";
import "./stylesheets/menushop-module.css";
import "./stylesheets/booking-calendar.css";
import "./stylesheets/menushop-general.css";
import "font-awesome/css/font-awesome.css";
import "./stylesheets/test.css";

import "./stylesheets/ms/general.css";
import "./stylesheets/queuePanel.css";
import "./stylesheets/queueChart.css";
import "./stylesheets/bookingListView.css";
import "./stylesheets/fix-navBar.css";
import "./stylesheets/timeDimension.css";
import "./stylesheets/announcementPanel.css"

import "./libs/vendor/bootstrap-datepicker.js";
import "./libs/vendor/bootstrap-datepicker.zh-TW.min";
import "./stylesheets/bootstrap-datepicker.css";
import "./libs/vendor/typeahead.jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "./libs/vendor/owl.carousel";
import "./stylesheets/booking-customQuestion.css";

//x-editable
require("../node_modules/x-editable/dist/bootstrap3-editable/js/bootstrap-editable");
//require('../node_modules/x-editable/dist/bootstrap3-editable/css/bootstrap-editable.css')

const store = configureStore();
connectToAlerts(store);

ReactDOM.render(
	<Provider store={store}>
		<Router
			history={browserHistory}
			onUpdate={() => {
				if (!window.location.pathname.startsWith("/dashboard"))
					window.scrollTo(0, 0);
			}}
		>
			<Route path="/" component={PageIndex} />
			<Route path="/booking_system" component={PageBookingSystem} />
			<Route path="/queue_system" component={QueueSystem} />
			<Route path="/menu_coupon" component={PageCoupon} />
			<Route path="/crm_system" component={PageCRM} />
			<Route path="/announcement_system" component={PageAnnouncement} />
			<Route path="/menutaiwan" component={PageMENU} />
			<Route path="/termsOfService" component={PageTOS} />
			<Route path="/privacyPolicy" component={PagePrivacyPolicy} />
			<Route path="/contact">
				<IndexRoute component={PageContact} />
				<Route path="/contact/done" component={PageDone} />
			</Route>
			<Route path="/case" component={PageCase} />
			<Route path="/login" component={PageLoginBox} />
			<Route path="/user_login" component={PageUserLoginBox} />
			<Route
				path="/dashboard/queue_system/display/:id"
				component={QueueRTDashboard}
			/>
			<Route component={RestrictPage}>
				<Route path="/dashboard" component={Navbar}>
					<IndexRoute component={DashboardBox} />
					<Route path="/dashboard/setting" component={AllSettingSideBar}>
						<IndexRedirect to="/dashboard/setting/shops" />
						{/* main */}
						<Route path="/dashboard/setting/shops" component={ShopBox} />
						{/* seat */}
						<Route
							path="/dashboard/setting/seats"
							component={BookingSettingSeats}
						/>
						<Route
							path="/dashboard/setting/seats_order"
							component={BookingSeatsOrder}
						/>
						<Route
							path="/dashboard/setting/area"
							component={BookingSettingArea}
						/>
						<Route
							path="/dashboard/setting/tablemap_setting"
							component={BookingSettingTableMap}
						/>
						<Route
							path="/dashboard/setting/tablemap_setting/tablemap"
							component={TableMap}
							hideSideBar={true}
						/>
						{/* Booking */}
						<Route
							path="/dashboard/setting/enable"
							component={BookingSettingEnable}
						/>
						<Route
							path="/dashboard/setting/available_time"
							component={BsTest}
						/>
						<Route
							path="/dashboard/setting/date_config2"
							component={BookingSettingDateConfig2}
						/>
						<Route
							path="/dashboard/setting/date_config"
							component={BookingSettingDateConfig}
						/>
						<Route
							path="/dashboard/setting/custom_question"
							component={CustomQuestion}
						/>
						<Route
							path="/dashboard/setting/sms"
							component={BookingSettingMsg}
						/>
						<Route
							path="/dashboard/setting/payment"
							component={BookingSettingPayment}
						/>
						<Route
							path="/dashboard/setting/calendar"
							component={BookingSettingCalendar}
						/>
						<Route
							path="/dashboard/setting/google-reservation"
							component={BookingSettingGoogleReservation}
						/>
						<Route
							path="/dashboard/setting/advanced"
							component={BookingSettingAdvanced}
						/>
						{/* Queue */}
						<Route
							path="/dashboard/setting/display"
							component={QueueSettingDisplay}
						/>
						<Route
							path="/dashboard/setting/group"
							component={QueueSettingGroupType}
						/>
						<Route
							path="/dashboard/setting/time"
							component={QueueSettingTime}
						/>
						<Route
							path="/dashboard/setting/notification"
							component={QueueSettingNotification}
						/>
						{/* shop */}
						<Route path="/dashboard/setting/users" component={UserPanel} />
						{/* report */}
						<Route path="/dashboard/setting/report" component={ReportPanel} />
					</Route>
					<Route path="/dashboard/shops" component={ShopBox} />
					<Route path="/dashboard/users" component={UserPanel} />
					<Route path="/dashboard/users/profile" component={UserProfileBox} />
					<Route path="/dashboard/users/password" component={UserChangePassword} />
					{/* CRM */}
					<Route path="/dashboard/customers" component={Crm}>
						<IndexRoute component={CrmCustomerBox} />
						{/* <Route
							path="/dashboard/customers/:id"
							component={CustomerRecordBox}
						/> */}
						<Route
							path="/dashboard/customers/:id"
							component={CrmCustomerRecordBox}
						/>
						<Route
							path="/dashboard/crm/blocked"
							component={CrmBlockedList}
						/>
						<Route
							path="/dashboard/crm/tags"
							component={CrmSettingsTags}
						/>
						<Route
							path="/dashboard/messages"
							component={CrmMessageBox}
						/>
					</Route>
					<Route path="/dashboard/calls" component={CallBox} />
					<Route path="/dashboard/announcement" component={AnnouncementPanel} />
					<Route path="/dashboard/bookings" component={BookingPanel} />
					<Route
						path="/dashboard/bookings/booking_table_map"
						component={BookingTableMap}
					/>
					<Route path="/dashboard/queue_system" component={QueuePanel} />
					<Route path="/dashboard/queue_system/chart" component={QueueChart} />
					<Route path="/dashboard/new_module" component={NewModuleEntry}>
						<IndexRoute component={NewModule} />
					</Route>
					<Route path="/dashboard/menu_coupon" component={MenuCouponEntry}>
						<IndexRoute component={MenuCouponPanel} />
					</Route>
					<Route path="/dashboard/menu_user" component={MenuUserBox} />
				</Route>
			</Route>

			<Route path="*" component={NotFound} />
		</Router>
	</Provider>,
	document.getElementById("app")
);
