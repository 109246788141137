import React from 'react'
import $ from 'jquery'
var createReactClass = require('create-react-class');
export default createReactClass({
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  },
  render() {
    return (
      <div className="info-block">
        <label>{'建立者：' + this.props.data.user_create}</label><br />
        <label>{'建立日期：' + this.props.data.user_created_at}</label><br />
        <label>{'最近修改者：' + this.props.data.user_update}</label><br />
        <label>{'修改日期：' + this.props.data.user_updated_at}</label>
        <div className="title">個人資料</div>
        <div className="content">
          <div className="profile">
            <span className="name">{this.props.data.full_name}</span>
            <span className="gender">{this.props.data.gender_display}</span>
            <span className="phone">{this.props.data.tele_number}</span>
            <span className="phone">{this.props.data.phone_number}</span>
            <span className="phone">{this.props.data.email}</span>
            <span>
              { this.props.data.customer_tags.map(function(tag) {
                return (
                  <div className="menu-tag"
                        key={tag.id}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={tag.description}>
                    <img src={tag.image} alt="" />
                  </div>
                );
              })}
            </span>
          </div>
          <h5>備註：</h5>
          <div className="remark">
            {this.props.data.note}
          </div>
        </div>
      </div>
    );
  }
});
