import React, { useState, useEffect, useCallback, useContext } from 'react';
import $ from 'jquery';
import { Modal } from 'antd';

import { config } from '../../../../../utils/config';
import { handleError } from '../../../../../libs/handler';

import { MessageAddStateContext } from '../../../crmContext/MessageAddContext';

import { sendTestingSms } from '../../../api/CrmMessageSmsApi';

const SendTestMessageModal = () => {
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { points, messageSetting } = MessageAddState;
  const { content } = messageSetting;
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ disabled, setDisabled ] = useState(false);
  const [ buyPoints, setBuyPoints ] = useState('');
  const [ error, setError ] = useState('');

  useEffect(() => {
    if(points === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [ points ]);

  useEffect(() => {
    const phoneInput = document.getElementsByClassName('phoneInput')[0];

    if(phoneInput) {
      if(error) {
        phoneInput.style.border = '1px solid #EF6868';
      } else {
        phoneInput.style.border = '1px solid #C8C8C8';
      }
    }
  }, [ error ]);

  const handleSelectPoints = (e) => {
    const value = e.target.value;

    $('#apForm').html('');

    if(value < 100 || isNaN(value)){ 
      return setBuyPoints(undefined);
    };

    $.ajax({url: window.domain + '/dashboard/booking_settings/buy_msg_point', 
          method: 'POST',
          data: {amount: value},
          xhrFields: { withCredentials: true }})
      .done(function(data){        
        $('#apForm').html('');
        //debugger
        for(let k in data){ 
          if(data.hasOwnProperty(k))
            $('#apForm').append('<input name="' + k + '" type="hidden" value="' + data[k] + '">')
        }
        setBuyPoints(data);
      })
      .fail(function(xhr){
        handleError(xhr);
      });
  };

  const submitBuyMsgPoints = () => {
    if(buyPoints !== undefined && buyPoints !== "") {
      document.getElementById('apForm').submit();
      setBuyPoints('');
    } else {
      return window.app.alert.setMessage("請先選擇欲購買項目","tip");
    }
  };

  const toBuyMsgPoints = () => {
    MessageAddDispatch({ type: 'cancelPopup' });
    MessageAddDispatch({ type: 'showBuyMsgPointsModal', buyMsgPointsType: 'buyPoints' });
  };

  const handlePhoneNumber = (e) => {
    const value = e.target.value;
    const reg = /^\d+$/;
		const regTest = reg.test(value);
		if (value !== '' && !regTest) {
			return window.app.alert.setMessage('請輸入數字', 'error');
		}

    setPhoneNumber(value);
  }

  const send = async () => {
    if(phoneNumber.length > 0 && phoneNumber.length < 10) {
      return setError('valid');
    } else if(phoneNumber.length === 0){
      return setError('noContent');
    };

    const testingContent = {
      phone_number: phoneNumber,
      content
    };

    try {
      await sendTestingSms(testingContent);
      cancelPopup();
    } catch(err) {
      console.log('send testing sms error ----------', err);
    }
  };

  const cancelPopup = useCallback(() => {
    MessageAddDispatch({ type: 'cancelPopup' });
	}, [ MessageAddDispatch ]);

  const renderPhoneInput = () => {
    if(points !== 0) {
      return (
        <div className="sendTestMessageModal_input">
          <h5>輸入電話號碼</h5>
          <input
            className="phoneInput"
            type="text"
            value={phoneNumber}
            onChange={(e) => handlePhoneNumber(e)}
            placeholder="請輸入電話號碼"
            maxLength="10"
            disabled={disabled}
          />
        </div>
      )
    } else {
      return <div className="notice">提醒：您目前的點數不足，需先購買點數才能傳送測試訊息。</div>
    }
  };

  const renderBuyMsgPoints = () => {
    if(points !== 0) {
      return (
        <button onClick={() => toBuyMsgPoints()}>購買簡訊點數</button>
      )
    } else {
      return (
        <div className="sendTestMessageModal_buy">
          <h5>選擇購買簡訊點數</h5>
          <div className="selectBox">
            <select
              className="form-control input-lg msgInput"
              onChange={(e) => {handleSelectPoints(e)}}
            >
              <option value="" disabled selected>請選擇欲購買項目</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={500}>500</option>
            </select>
          </div>
        </div>
      )
    }
  };

  const renderFooterBtn = () => {
    if(points !== 0) {
      return <button onClick={() => send()}>傳送</button>
    } else {
      return <button onClick={() => submitBuyMsgPoints()}>購買點數</button>
    }
  };

  return (
    <Modal
      title="傳送測試訊息"
      visible
      centered
      className="sendTestMessageModal"
      onCancel={cancelPopup}
      footer={renderFooterBtn()}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      { renderPhoneInput() }

      { error === 'noContent' ? <div className="error_text">此欄位不可為空白。</div> : null }
      { error === 'valid' ? <div className="error_text">請輸入有效電話號碼。</div> : null }
      
      { points !== 0 ? <p>提醒：傳送一封測試訊息將扣1點。</p> : null}

      <div className="sendTestMessageModal_send">
        <div className="msg_point">
          <div className="text">您目前的點數</div>
          <div className="point">{points}點</div>
        </div>

        { renderBuyMsgPoints() }
      </div>

      <form
        action={config.allpay_checkout}
        id="apForm"
        method="post"
        target="_blank"
      ></form>
    </Modal>
  );
};

export default SendTestMessageModal;