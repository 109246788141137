import React, { useCallback, useContext } from 'react';
import { browserHistory } from 'react-router';
import { Modal } from 'antd';

import { MessageContext } from '../crmContext/MessageContext';
import { MessageAddStateContext } from '../crmContext/MessageAddContext';

const SelectMessagePathPopup = () => {
	const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const {
    menuUserBindStatus,
    showSelectMessagePathPopup
  } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { allCustomers, allFollowers } = MessageAddState;

  const addMessage = useCallback((editType) => {
    MessageAddDispatch({ type: 'cleanMessageSetting' });
    MessageStateDispatch({ type: 'switchMode', mode: 'addMessage', editType });
    MessageStateDispatch({ type: 'cancelPopup' });
	}, [ MessageStateDispatch, MessageAddDispatch ]);

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: 'cancelPopup' });
	}, [ MessageStateDispatch ]);

  const showMenuUserInfo = () => {
    MessageStateDispatch({ type: 'showMenuAppIntroModal' });
    cancelPopup();
  };

  const goToBindMenuUser = () => {
    browserHistory.push({
      pathname: '/dashboard/menu_user',
      state: {
        source: 'crm_msg_menuTalk'
      }
    });
  };

  const renderMessagePath = () => {
    if(menuUserBindStatus) {
      return (
        <div className="selectMessagePath_content">
          <h3>選擇傳送途徑</h3>
          <div className="selectMessagePath_block">
            <div
              className="selectMessagePath_box"
              style={{ marginRight: '24px', backgroundColor: '#f5f5f5', cursor: 'not-allowed' }}
              // onClick={() => addMessage('sms')}
            >
              <div>
                <img src={require('../../../images/crm/path_message_icon.svg')} alt='' />
                傳送簡訊
                <div>（目前無法使用）</div>
              </div>
              總顧客數{allCustomers}名
            </div>
            <div
              className="selectMessagePath_box"
              onClick={() => addMessage('menuTalk')}
            >
              <div>
                <img src={require('../../../images/crm/path_menuTalk_icon.svg')} alt='' />
                傳送MENU私訊
              </div>
              粉絲數{allFollowers}名
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="selectMessagePath_content_unbind">
          <h3>選擇傳送途徑</h3>
          <div className="selectMessagePath_block">
            <div
              className="selectMessagePath_box"
              style={{ marginRight: '24px', backgroundColor: '#f5f5f5', cursor: 'not-allowed' }}
              // onClick={() => addMessage('sms')}
            >
              <div style={{display: 'flex'}}>
                <img src={require('../../../images/crm/path_message_icon.svg')} alt='' />
                傳送簡訊
                <div>（目前無法使用）</div>
              </div>
              總顧客數{allCustomers}名
            </div>
            <div className="selectMessagePath_box_unbind">
              <div>
                <img src={require('../../../images/crm/path_menuTalk_icon.svg')} alt='' />
                傳送MENU私訊
              </div>
              <button onClick={() => goToBindMenuUser()}>綁定 MENU 帳號</button>
            </div>
          </div>
          <div className="selectMessagePath_text">
            MENU美食誌 全台最熱門的美食社群 app <br />
            綁定MENU帳號，傳送訊息給你的粉絲
          </div>
          <div
            className="selectMessagePath_menuIntro"
            onClick={() => showMenuUserInfo()}
          >
            綁定MENU帳號可以做什麼？
          </div>
        </div>
      )
    }
  }
  return (
    <Modal
      title="傳送訊息"
      visible={showSelectMessagePathPopup}
      centered
      className="selectMessagePathPopup"
      onCancel={cancelPopup}
      footer={null}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      { renderMessagePath() }
    </Modal>
  );
};

export default SelectMessagePathPopup;