import { useEffect, useState } from "react";
import { getCrmBlockedList } from '../api/CrmSettingsApi';

const useBlockedList = () => {
  const [ response, setResponse ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  const getBlockedList = async () => {
    try {
      setLoading(true);
      
      const { data } = await getCrmBlockedList();
      setResponse(data.blacklists);
      setLoading(false);
    } catch (error) {
      setError("Error getting the data");
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlockedList();
  }, []);

  return { response, loading, error, getBlockedList };
};


export default useBlockedList;