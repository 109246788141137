import React, { useState, useEffect, useContext } from 'react';

import { CustomerContext } from '../crmContext/CustomerContext';

import Customer from './CrmCustomer';

const CrmCustomerList = () => {
	const { CustomerState } = useContext(CustomerContext);
  const {
    searchCustomerMode,
    pageCustomers,
    searchCustomers,
    customerFilterNum,
    filterCustomerMode,
    filterCustomers
  } = CustomerState;

  const [ showCustomers, setShowCustomers ] = useState([]);

  useEffect(() => {
    if(searchCustomerMode) {
      setShowCustomers(searchCustomers);
    } else if(filterCustomerMode) {
      setShowCustomers(filterCustomers);
    } else {
      setShowCustomers(pageCustomers);
    }
  }, [ searchCustomerMode, filterCustomerMode, pageCustomers, searchCustomers ]);

  const renderList = () => {
    if(showCustomers.length > 0) {
      return (
        showCustomers.map((customer) => {
          return <Customer key={customer.id} data={customer} />
        })
      )
    } else {
      if(filterCustomerMode) return <div className='customer_empty'>沒有相關的顧客資料，請重新選擇篩選條件。</div>
      if(searchCustomerMode) return <div className='customer_empty'>沒有符合搜尋條件的顧客。</div>
      
      return <div className='customer_empty'>您目前沒有顧客資料。</div>
    }
  }

  return (
    <div className='crmCustomerList'>
      { renderList() }
    </div>
  )
}

export default CrmCustomerList;