export const messageStatusTab = {
	sent: '寄件備份',
	draft: '草稿',
	scheduling: '排程訊息'
};

export const messageSortOption = {
	latest: '從新到舊',
	oldest: '從舊到新'
};

export const messageFilterOption = {
	all: '全部',
	sms: '簡訊',
	menuTalk: 'MENU 私訊'
};