import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Modal } from 'antd';
import Datetime from 'react-datetime';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

import { MessageContext } from '../../crmContext/MessageContext';
import { MessageAddStateContext } from '../../crmContext/MessageAddContext';

import { createCrmMenuTalk, updateCrmMenuTalk } from '../../api/CrmMessageMenuTalkApi';

const ExceedTimesModal = () => {
  const { MessageStateDispatch } = useContext(MessageContext);
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { messageSetting } = MessageAddState;
  const {
    status,
    id,
    title,
    filterTag,
    scheduleTime,
    followers,
    filterConditions,
    menuTalkContent,
    uploadImg
  } = messageSetting;
  const {
    gender,
    exchangeMin,
    exchangeMax,
    fetchMin,
    fetchMax
  } = filterConditions;

  const [ newScheduleTime, setNewScheduleTime ] = useState(null);
  const [ action, setAction ] = useState('');
  const [ errorType, setErrorType ] = useState('');

  const dateText = status === 'real_time' ? '今日' : '此日';

  useEffect(() => {
    const dataTime = document.getElementById('newScheduleTime').getElementsByClassName('form-control')[0];
    const errorScheduled = document.getElementsByClassName('error_scheduled')[0];
    const errorExceed = document.getElementsByClassName('error_exceed')[0];
    const errorChoose = document.getElementsByClassName('error_choose')[0];

    if(errorType !== '') {
      if(errorType === 'scheduleTime') {
        errorScheduled.style.display = 'block';
        errorChoose.style.display = 'none';
        dataTime.style.border = '1.5px solid #EF6868';
      } else if(errorType === 'exceedTimes') {
        errorExceed.style.display = 'block';
        errorChoose.style.display = 'none';
        dataTime.style.border = '1.5px solid #EF6868';
      } else {
        errorChoose.style.display = 'block';
      }
    } else {
      dataTime.style.border = '1px solid #C8C8C8';
      errorScheduled.style.display = 'none';
      errorExceed.style.display = 'none';
      errorChoose.style.display = 'none';
    }
  }, [ errorType ])

  const cancelPopup = () => {
    MessageAddDispatch({ type: 'cancelPopup' });
  };

  const saveDraft = async () => {
    const newMenuTalkContent = _.cloneDeep(menuTalkContent);

    for(let i = 0; i < newMenuTalkContent.length; i++) {
      if(newMenuTalkContent[i].type === 'new_image') {
        delete newMenuTalkContent[i].url;
      }
    };

    const fd = new FormData();
    const crmMenuTalkContent = {
      title: title.trim() !== '' ? title.trim() : '未命名的私訊',
      contents: newMenuTalkContent,
      filter_tag: filterTag,
      status: 'draft',
      schedule_time: scheduleTime,
      followers: followers,
      filter_conditions: {
        exchange_min: exchangeMin ? exchangeMin : null,
        exchange_max: exchangeMax ? exchangeMax : null,
        fetch_min: fetchMin ? fetchMin : null,
        fetch_max: fetchMax ? fetchMax : null,
        gender: gender ? gender : null
      }
    };

    if(uploadImg.length !== 0) {
      uploadImg.forEach(ele => {
        fd.append('upload_imgs[]', ele)
      })
    };

    fd.append('crm_menu_body', JSON.stringify(crmMenuTalkContent));

    if(!id) {
      try {
        await createCrmMenuTalk(fd);

        // 儲存進入草稿
        MessageAddDispatch({ type: 'cleanMessageSetting' });
        MessageStateDispatch({ type: 'switchMode', mode: 'messageList' });
        MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: 'sent' });
        MessageStateDispatch({ type: 'setMessageNotification', notificationType: 'draft', msgType: 'menuTalk' });
      } catch(err) {
        console.log('save crm menuTalk message to draft err', err);
      }
    } else {
      try {
        await updateCrmMenuTalk(id, fd);

        // 儲存進入草稿
        MessageAddDispatch({ type: 'cleanMessageSetting' });
        MessageStateDispatch({ type: 'switchMode', mode: 'messageList' });
        MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: 'sent' });
        MessageStateDispatch({ type: 'setMessageNotification', notificationType: 'draft', msgType: 'menuTalk' });
      } catch(err) {
        console.log('update crm menuTalk message to draft err', err);
      }
    }
  };

  const submit = () => {
    if(action === '') {
      setErrorType('choose');
    } else if(action === 'scheduled') {
      if(newScheduleTime) {
        MessageAddDispatch({ type: 'setMessageStatus', status: action });
        MessageAddDispatch({ type: 'setScheduleTime', scheduleTime: newScheduleTime });
        cancelPopup();

        // setErrorType('exceedTimes');
      } else {
        setErrorType('scheduleTime');
      }
    } else {
      saveDraft();
    }
  };

  const renderDateTime = () => {
    let inputProps = {
      placeholder: '排程日期時間'
    };

    return (
      <div id='newScheduleTime' className="datetime">
        <Datetime
          inputProps={inputProps}
          value={newScheduleTime}
          onChange={(datetime) => {
            setNewScheduleTime(datetime);
          }}
          dateFormat="YYYY-MM-DD"
          timeFormat="HH:mm"
          isValidDate={(currentDate) => {
            return currentDate.isAfter(moment().subtract(1, 'day'));
          }}
        />
        <CalendarOutlined />
      </div>
    )
  }

  return (
    <Modal
      title={`已達${ dateText }傳送上限`}
      visible
      centered
      className="exceedLimitModal"
      onCancel={cancelPopup}
      footer={
        <button onClick={() => submit()}>確定</button>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className='text'>
        提醒：{ dateText }傳送MENU私訊已達三則上限，請更改排程時間，或將訊息儲存為草稿。
      </div>

      <div className='error_text error_choose'>請選擇下列方式。</div>

      <div className='radio'>
        <div className="crmMessageAdd_radio" style={{ marginTop: '32px' }}>
          <label>
            <input
              id='newDateTime'
              type="radio"
              name="newDateTime"
              onChange={() => setAction('scheduled')}
              checked={action === 'scheduled'}
            />
            <span>排程傳送</span>
          </label>
          <label
            className='newDateTime'
            htmlFor="newDateTime"
          >
            { renderDateTime() }
          </label>

          <div className='error_text error_scheduled'>請選擇傳送排程時間。</div>
          <div className='error_text error_exceed'>該日期已有三則排程訊息，請選擇其他日期。</div>
        </div>

        <div className="crmMessageAdd_radio" style={{ marginTop: '16px' }}>
          <label>
            <input
              type="radio"
              name="newDateTime"
              onChange={() => setAction('draft')}
              checked={action === 'draft'}
            />
            <span>儲存草稿</span>
          </label>
        </div>
      </div>
    </Modal>
  );
};

export default ExceedTimesModal;