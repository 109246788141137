import React, { useState, useCallback, useEffect, useMemo, useContext, Fragment } from 'react';
import moment from 'moment';
import BookingSystemApi from '../../booking_settings/BookingSystemApi';

import { WarningTemplate } from '../NewModuleComponent/NewModuleWarning';
import { customQAns } from '../NewModuleComponent/NewModuleTools';

import { seatAlertTitle, bookingStatus, useInterval } from '../utils/data';
import { Portal } from '../utils/Portal';

import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';
import { BaseSettingContext } from '../NewModuleReducer/BaseSettingReducer';

const BookingAPI = new BookingSystemApi();

const $tableStatusEmptyColor = 'rgb(255, 255, 255)';
const $status_confirmed = '#3fba88';
const $status_unconfirmed = '#bad959';
const $status_cancel = '#c8c8c8';
const $status_noShow = '#676767';
const $status_show = '#42a0a4';
const $status_seated = '#27496e';
const $status_check = '#664c77';

const BookingListCard = ({ booking, children }) => {
	return (
		<section id={booking.id} className="bookingList__card">
			{React.Children.map(children, (child) => child)}
		</section>
	);
};

const BookingListCardHeader = ({ children }) => {
	return <div className="bookingList__card__header">{React.Children.map(children, (child) => child)}</div>;
};

const BookingListCardHeaderTime = ({ booking }) => {
	const renderBookingSource = () => {
		if (booking.source === 'online') {
			if (booking.source_url_t === 'google') {
				return <span className="bookingSourceIcon bookingSourceIcon-google" />;
			} else if (booking.source_url_t === 'facebook') {
				return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
			} else if (booking.source_url_t === 'instagram') {
				return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
			} else if (booking.source_url_t === 'menuApp') {
				return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
			} else if (booking.source_url_t === 'other') {
				return <span className="bookingSourceIcon bookingSourceIcon-other" />;
			}
		}

		if(booking.source === 'google_booking' && booking.source_url_t === 'google_reservation') {
			return <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />;
		}
	};

	const renderBookingEndTime = () => {
		const endTime = moment(booking.booking_datetime).add(booking.original_dining_time, 'm').format('HH:mm');

		return <span className="bookingEndTime"> - {endTime}</span>;
	};
	return (
		<div className="bookingList__card__time">
			<span>{renderBookingSource()}</span>
			<span>
				{booking.time}
				{renderBookingEndTime()}
			</span>
		</div>
	);
};

const BookingListCardHeaderStatus = ({ booking }) => {
	const { CommomSettingState, setStatus } = useContext(CommomSettingContext);
	const { isToday, date } = CommomSettingState;

	const renderBookingStatus = () => {
		if (booking.status !== 'finish' && booking.status !== 'cancel' && booking.status !== 'no_show') {
			return <BookingStatus setStatus={setStatus} booking={booking} isToday={isToday} date={date} />;
		}
	};

	return <div className="bookingList__card__status">{renderBookingStatus()}</div>;
};

const BookingListCardBody = ({ booking, children }) => {
	const { BaseSettingState } = useContext(BaseSettingContext);
	const { customQ } = BaseSettingState;

	const customQAnsMemo = useMemo(
		() => {
			return customQAns(customQ, booking['customized_questions']);
		},
		[ customQ, booking ]
	);

	return (
		<div className="bookingList__card__body">
			{React.Children.map(children, (child) => child)}

			{booking.shop_memo && booking.shop_memo !== '' ? (
				<p className="bookingList__card__memo shopMemo">{booking.shop_memo}</p>
			) : null}

			{booking.memo !== null ? <p className="bookingList__card__memo">{booking.memo}</p> : null}

			<div className="bookingList__card__customeTag">
				<ul>
					{customQAnsMemo.serviceTagBlock.map((tag, index) => <li key={index}>{tag}</li>)}
					{customQAnsMemo.quantityBlock.map((ele, index) => (
						<li key={index}>
							{ele.name}x{ele.value}
						</li>
					))}
				</ul>
			</div>

			<div className="bookingList__card__noteWRap">
				<ul>{customQAnsMemo.questionsBlock.map((q, index) => <li key={index}>{q}</li>)}</ul>
			</div>

			<div className="bookingList__card__bookingCode">
				<p>
					{booking.source_t} | 建立: {moment(new Date(booking.created_at)).format('YYYY/MM/DD HH:mm')}
				</p>
				<p>
					最後更新: {moment(new Date(booking.updated_at)).format('YYYY/MM/DD HH:mm')} | 查詢代碼:{' '}
					{booking.active_code}{' '}
				</p>
			</div>
		</div>
	);
};

const BookingListCardBodyInfo = ({ booking, index, serviceTimeOptionEnabled }) => {
	const { editBooking } = useContext(CommomSettingContext);

	const renderSeatArea = () => {
		const { source, space_group_selection_t } = booking;
		if (source === 'online' && space_group_selection_t !== '') {
			return (
				<span className="seatArea diningTimeMarginBottom">
					<span>{space_group_selection_t}</span>
				</span>
			);
		}
	};

	const renderStandbyTag = () => {
		if(booking.status === 'show') {
			return <span className="standbyTag diningTimeMarginBottom">{ index+1 }</span>
		};
	};

	const renderOnSiteTag = () => {
		if(booking.on_site) {
			return <span className='onSiteTag diningTimeMarginBottom'>現場</span>
		};
	};
	
	const renderDiningTimeTag = () => {
		let diningTimeContent = booking.service_time_str;
		let diningTime = booking.dining_time;
		let diningTime_hour = Math.floor(parseInt(diningTime, 10) / 60);
		let diningTime_min = Math.floor(parseInt(diningTime, 10) % 60);

		if(serviceTimeOptionEnabled && booking.source !== 'offline') {
			return (
				<span className='diningTimeTag'>
					<i className="fa fa-clock-o diningTimeIcon"></i>
					<span className="diningTimeContent">{diningTimeContent}</span>&nbsp;
					<span>({diningTime_hour}小時{diningTime_min}分鐘)</span>
				</span>
			)
		} else {
			return (
				<span className='diningTimeTag'>
					<i className="fa fa-clock-o diningTimeIcon"></i>
					<span>({diningTime_hour}小時{diningTime_min}分鐘)</span>
				</span>
			)
		};
	};

	return (
		<Fragment>
			{renderStandbyTag()}
			{renderSeatArea()}
			{renderOnSiteTag()}
			<div>
			{renderDiningTimeTag()}
			</div>
			<div className="bookingList__card__info">
				<div className="bookingList__card__name">
					<CustomerTag booking={booking} />
					<span className="name">
						{booking.last_name}
					</span>
					<span className="gender">{booking.gender_desc}</span>
					<span className="bookingList__card__edit" onClick={() => editBooking(booking)} />
					<p className="bookingList__card__tel">{booking.phone_number}</p>
				</div>
				<div className="bookingList__card__attendance">
					<span>{booking.attendance}</span>位
				</div>
			</div>
		</Fragment>
	);
};

const BookingListCardBodySeats = ({ booking }) => {
	const { BaseSettingState } = useContext(BaseSettingContext);
	const { showCanvas } = BaseSettingState;

	const { CommomSettingState, CommomSettingDispatch } = useContext(CommomSettingContext);
	const { mode, tablemapTableGroup } = CommomSettingState;

	const bookingSeat = () => {
		if (mode !== 'tablemap') return;
		const newSeat = JSON.parse(booking.seating_json);
		CommomSettingDispatch({ type: 'setBookingCardSeats', seats: newSeat });
	};

	const renderGroupSeat = () => {
		let seatGroup = {},
			notHaveSeatGroup = [];

		JSON.parse(booking.seating_json).forEach((seat) => {
			let emptyGroupSeat = true;
			Object.keys(tablemapTableGroup).forEach((group) => {
				const findSeatIndx = tablemapTableGroup[group].indexOf(seat.table_number);
				if (findSeatIndx !== -1) {
					emptyGroupSeat = false;
					if (!seatGroup.hasOwnProperty(group)) {
						seatGroup[group] = [];
						seatGroup[group].push(seat.table_number);
					} else {
						seatGroup[group].push(seat.table_number);
					}
				}
			});
			if (emptyGroupSeat) notHaveSeatGroup.push(seat.table_number);
		});

		return (
			<React.Fragment>
				<div className="bookingList__card__seats">
					{Object.keys(seatGroup).map((group, index) => {
						return (
							<div key={index}>
								<span>{group}</span>
								{seatGroup[group].map((seat, seatIndex) => (
									<span key={seatIndex} onClick={() => bookingSeat()}>
										{seat}
									</span>
								))}
							</div>
						);
					})}

					<div className="bookingList__card__seats bookingList__card__normalSeats">
						{notHaveSeatGroup.map((seat, index) => <span key={index}>{seat}</span>)}
					</div>
				</div>
			</React.Fragment>
		);
	};

	const renderSeat = () => {
		return (
			<div className="bookingList__card__seats bookingList__card__normalSeats">
				{JSON.parse(booking.seating_json).map((seat, index) => <span key={index}>{seat.table_number}</span>)}
			</div>
		);
	};

	const renderDeposit = () => {
		const { deposit_required } = booking;
		if (!deposit_required) return;

		const { last_pay_info, deposit_paid, deposit, deposit_due_date, order_no } = booking;

		if (deposit_paid && last_pay_info) {
			let paymentInfo = null;
			const parseLastPayInfo = JSON.parse(last_pay_info);
			const payInfo = parseLastPayInfo.result.data;

			if (payInfo.PaymentType === 'CREDIT') {
				paymentInfo = (
					<Fragment>
						<p>
							付款狀態：<span style={{ color: '#3AA177' }}>已付款</span> ({payInfo.PayTime})
						</p>
						<p>金額：{deposit}</p>
						<p>付款方式：信用卡</p>
						<p>
							卡號：{payInfo.Card6No.slice(0, 4)} - {payInfo.Card6No.slice(4)}** - **** - {payInfo.Card4No}
						</p>
						<p>智付通訂單編號：{order_no}</p>
					</Fragment>
				);
			} else {
				paymentInfo = (
					<Fragment>
						<p>
							付款狀態：<span style={{ color: '#3AA177' }}>已付款</span> ({payInfo.PayTime})
						</p>
						<p>金額：{deposit}</p>
						<p>付款方式：超商</p>
						<p>條碼：{payInfo.CodeNo}</p>
						<p>智付通訂單編號：{order_no}</p>
					</Fragment>
				);
			}

			return (
				<div className="bookingList__card__payment">
					<button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						${booking.deposit}
					</button>

					<div className="dropdown-menu dropdown-menu-right" aria-labelledby="status">
						{paymentInfo}
					</div>
				</div>
			);
		}

		if (!deposit_paid) {
			return (
				<div className="bookingList__card__payment unpayment">
					<button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						${deposit}
					</button>
					<div className="dropdown-menu dropdown-menu-right" aria-labelledby="status">
						<p>付款狀態：未付款</p>
						<p>金額：{deposit}</p>
						<p>付款期限：{ deposit_due_date ? moment(deposit_due_date).format('YYYY/MM/DD HH:mm:ss') : '' }</p>
					</div>
				</div>
			);
		}
	};

	return (
		<div className="bookingList__card__row">
			{showCanvas ? renderGroupSeat() : renderSeat()}
			{renderDeposit()}
		</div>
	);
};

const BookingListCardFooter = ({ booking }) => {
	const { CommomSettingState, setStatus } = useContext(CommomSettingContext);
	const { isToday } = CommomSettingState;

	const renderStatusBtn = () => {
		if (isToday) {
			return <ShortCutStatusBtn booking={booking} setStatus={setStatus} />;
		} else if (!isToday && (booking.status === 'cancel' || booking.status === 'finish')) {
			//非當日：取消預約和完成消費才需要出現
			return <ShortCutStatusBtn booking={booking} setStatus={setStatus} />;
		}
	};
	return <Fragment>{renderStatusBtn()}</Fragment>;
};

const BookingStatus = ({ setStatus, booking, isToday, date }) => {
	const [ currentTime, setCurrentTime ] = useState(moment());
	const today = moment().format('YYYY/MM/DD');
	const pre = moment(new Date(booking.booking_datetime)).add(-60, 'm').format('YYYY/MM/DD HH:mm');
	const tomorrow = moment(new Date(today)).add(1, 'd').format('YYYY/MM/DD HH:mm');
	const [ delay, setDelay ] = useState(isToday ? 1000 : null);
	const [ bookingStatusBtn, setBookingStatusBtn ] = useState(3); //1: 前一天 2:當天時間>預約60分鐘 3:當天時間<=預約60分鐘
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);

	const findStatus = useCallback(
		() => {
			if (currentTime.isAfter(new Date(date), 'date')) {
				//過去: 前一天
				//待確認 確認預約 取消預約 完成預約
				//不顯示 finish show
				setBookingStatusBtn(1);
			} else if (currentTime.isBefore(new Date(pre))) {
				//當天時間>預約60分鐘
				//待確認 確認預約 已到店 取消預約
				setBookingStatusBtn(2);
			} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
				//當天時間<=預約60分鐘
				//待確認 確認預約 已到店 取消預約 未到店 已入座 完成消費
				setBookingStatusBtn(3);
				setDelay(null);
			}
		},
		[ currentTime, date, pre, tomorrow ]
	);

	useEffect(
		() => {
			if (isToday) {
				setDelay(1000);
			} else {
				setDelay(null);
			}
			findStatus();
		},
		[ isToday, findStatus ]
	);

	useInterval(() => {
		setCurrentTime(moment());
	}, delay);

	const seatedWarning = useCallback(
		() => {
			BookingAPI.checkSeated(booking.id).then((data) => {
				if (data.overlay_table.length !== 0) {
					setShowWarning(true);
					setWarningTales(data.overlay_table);
				} else {
					setStatus(booking, 'seated');
				}
			});
		},
		[ booking, setStatus ]
	);

	const renderStatus = () => {
		return (
			<div className="dropdown-menu fix-dropdown-menu" aria-labelledby="status">
				{bookingStatus.map((t) => {
					if (bookingStatusBtn === 1) {
						if (t.status === 'show' || t.status === 'no_show' || t.status === 'seated') {
							return null;
						}
					} else if (bookingStatusBtn === 2) {
						if (t.status === 'no_show' || t.status === 'seated' || t.status === 'finish') {
							return null;
						}
					}

					if (t.status === 'seated') {
						return (
							<li key={t.status}>
								<span
									style={{ backgroundColor: tableStatus(t.status).tableStatusColor }}
									onClick={() => seatedWarning()}
								>
									{t.status_t}
								</span>
							</li>
						);
					} else {
						return (
							<li key={t.status}>
								<span
									style={{ backgroundColor: tableStatus(t.status).tableStatusColor }}
									onClick={() => setStatus(booking, t.status)}
								>
									{t.status_t}
								</span>
							</li>
						);
					}
				})}
			</div>
		);
	};

	const cancelWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
	};

	const confirmWarning = () => {
		setStatus(booking, 'seated');
		setShowWarning(false);
		setWarningTales([]);
	};

	return (
		<div style={{ textAlign: 'right' }}>
			<div className="bookingStatus">
				<button
					style={{ color: tableStatus(booking.status).tableStatusColor }}
					className="bookingStatus-dropdown"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<i className="fa fa-circle" aria-hidden="true" />
					<span style={{ color: '#676767' }}>{booking['status_t']}</span>
				</button>
				{renderStatus()}
			</div>

			{showWarning && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title={seatAlertTitle}
					>
						<p>
							桌次
							{warningTales.map((table) => {
								return (
									<span key={table} className="tableName">
										{table}
									</span>
								);
							})}
							有尚未結束的預約
						</p>
						<p>是否確定入座？</p>
					</WarningTemplate>
				</Portal>
			)}
		</div>
	);
};

const tableStatus = (status) => {
	let tableStatusColor = $tableStatusEmptyColor;
	switch (status) {
		case 'unconfirmed':
			tableStatusColor = $status_unconfirmed;
			break;
		case 'confirmed':
			tableStatusColor = $status_confirmed;
			break;
		case 'show':
			tableStatusColor = $status_show;
			break;
		case 'seated':
			tableStatusColor = $status_seated;
			break;
		case 'finish':
			tableStatusColor = $status_check;
			break;
		case 'no_show':
			tableStatusColor = $status_noShow;
			break;
		case 'cancel':
			tableStatusColor = $status_cancel;
			break;
		default:
			tableStatusColor = $tableStatusEmptyColor;
			break;
	}

	return {
		tableStatusColor
	};
};

const CustomerTag = ({ booking }) => {
	const note = booking.customer_info.note,
		tags = booking.customer_info.tags;

	if (note.length !== 0 || tags.length !== 0) {
		return (
			<div className="customerTag">
				<span className="customerTagIcon" />
				<div className="dropdown-menu">
					<div>
						{tags.length !== 0 &&
							tags.map((tag, index) => {
								return <span key={index} className={`c-tag ${tag}`} />;
							})}
					</div>
					<div>{note.length !== 0 && <p>{note}</p>}</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const ShortCutStatusBtn = ({ booking, setStatus }) => {
	const [ currentTime, setCurrentTime ] = useState(moment());
	const [ delay, setDelay ] = useState(1000);
	const today = moment().format('YYYY/MM/DD');
	const pre = moment(new Date(booking.booking_datetime)).add(-60, 'm').format('YYYY/MM/DD HH:mm');
	const tomorrow = moment(new Date(today)).add(1, 'd').format('YYYY/MM/DD HH:mm');
	const [ hideStatusBtn, setHideStatusBtn ] = useState(true);

	useEffect(
		() => {
			if (currentTime.isBefore(new Date(pre))) {
				//當天時間>預約60分鐘
				// console.log('----預約>60分鐘----');
				setHideStatusBtn(true);
			} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
				//當天時間<=預約60分鐘
				// console.log('----預約前60分鐘----');
				setHideStatusBtn(false);
				setDelay(null);
			}
		},
		[ currentTime, pre, tomorrow ]
	);

	useInterval(() => {
		setCurrentTime(moment());
	}, delay);

	const rednerBtn = useCallback(
		() => {
			if (!hideStatusBtn) {
				return (
					<div className="bookingList__card__footer">
						<StatusBtn booking={booking} setStatus={setStatus} />
					</div>
				);
			} else {
				return null;
			}
		},
		[ hideStatusBtn, setStatus, booking ]
	);

	return rednerBtn();
};

const StatusBtn = ({ booking, setStatus }) => {
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);
	const statusTime = JSON.parse(booking.status_time);

	const seatedWarning = useCallback(
		() => {
			BookingAPI.checkSeated(booking.id).then((data) => {
				if (data.overlay_table.length !== 0) {
					setShowWarning(true);
					setWarningTales(data.overlay_table);
				} else {
					setStatus(booking, 'seated');
				}
			});
		},
		[ booking, setStatus ]
	);

	const cancelWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
	};

	const confirmWarning = () => {
		setStatus(booking, 'seated');
		setShowWarning(false);
		setWarningTales([]);
	};

	const renderBtn = () => {
		switch (booking.status) {
			case 'confirmed':
				return (
					<React.Fragment>
						<button
							className="newModule__statusBtn newModule__statusBtn-show"
							onClick={() => setStatus(booking, 'show')}
						>
							到店
						</button>
						<button
							className="newModule__statusBtn newModule__statusBtn-seated"
							onClick={() => seatedWarning()}
						>
							入座
						</button>
					</React.Fragment>
				);
			case 'show':
				return (
					<button
						className="newModule__statusBtn newModule__statusBtn-seated"
						onClick={() => seatedWarning()}
					>
						入座
						<span className="stautsBtnTime">(報到時間: {statusTime['show']})</span>
					</button>
				);
			case 'seated':
				return (
					<button
						className="newModule__statusBtn newModule__statusBtn-finish"
						onClick={() => setStatus(booking, 'finish')}
					>
						結帳
						<span className="stautsBtnTime">(入座時間: {statusTime['seated']})</span>
					</button>
				);
			case 'finish':
				return (
					<span className="newModule__statusBtn newModule__statusBtn-finishTxt">
						{statusTime['seated']}入座 - {statusTime['finish']}完成消費
					</span>
				);
			case 'no_show':
				return (
					<span className="newModule__statusBtn newModule__statusBtn-noShowTxt">
						{statusTime['no_show']}未到店
					</span>
				);
			case 'cancel':
				return (
					<span className="newModule__statusBtn newModule__statusBtn-cancelTxt">
						{statusTime['cancel']}取消預約
					</span>
				);
			default:
				return (
					<React.Fragment>
						<button
							className="newModule__statusBtn newModule__statusBtn-cancel"
							onClick={() => setStatus(booking, 'cancel')}
						>
							取消預約
						</button>
						<button
							className="newModule__statusBtn newModule__statusBtn-confirmed"
							onClick={() => setStatus(booking, 'confirmed')}
						>
							確認預約
						</button>
					</React.Fragment>
				);
		}
	};

	return (
		<React.Fragment>
			{renderBtn()}
			{showWarning && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title={seatAlertTitle}
					>
						<p>
							桌次
							{warningTales.map((table) => {
								return (
									<span key={table} className="tableName">
										{table}
									</span>
								);
							})}
							有尚未結束的預約
						</p>
						<p>是否確定入座？</p>
					</WarningTemplate>
				</Portal>
			)}
		</React.Fragment>
	);
};

BookingListCard.Header = BookingListCardHeader;
BookingListCard.Header.Time = BookingListCardHeaderTime;
BookingListCard.Header.Status = BookingListCardHeaderStatus;

BookingListCard.BookingListCardBody = BookingListCardBody;
BookingListCard.BookingListCardBody.Info = BookingListCardBodyInfo;
BookingListCard.BookingListCardBody.Seats = BookingListCardBodySeats;

BookingListCard.BookingListCardFooter = BookingListCardFooter;

export default BookingListCard;
