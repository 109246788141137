import { createContext } from 'react';
import _ from 'lodash';

// mode: customerList, messageList, addMessage 新增/編輯訊息, confirmMessage
// editType: sms, menuTalk
// messageFilter: all, sms, menuTalk 
// messageSort: latest 從新到舊, oldest 從舊到新
// messageStatus(tab): sent 寄件備份, draft 草稿, scheduling 排程訊息
// notificationType(通知提醒類別): sent 立即傳送, draft 草稿, scheduling 排程訊息
// deleteType(刪除類別): delete 刪除, cancel 取消排程
// statusMessages(各狀態訊息筆數): [real_time & finished, draft, scheduled]

export const messageState = {
	loading: true,
	mode: 'customerList',
	editType: '',
  messages: [], // msg lists
  originMessages: [],
	searchMessages: [],
	searchMode: false,
	statusMessages: [0, 0, 0],
  messageFilter: 'all',
  messageSort: 'latest',
  messageStatus: 'sent',
	notificationType: '',
	msgType: '',
	showNotification: false,
	showSelectMessagePathPopup: false,
	showMenuAppIntroModal: false,
	showEditDateTimeModal: false,
	showDeletePopup: false,
	deleteType: 'delete',
	messageId: '',
	messageType: '',
	previewInfo: {},
	searchInputText: ''
};

export const MessageContext = createContext(messageState);

export function MessageReducer(state, action) {
  const newState = _.cloneDeep(state);

  switch (action.type) {
		case 'setLoading':
			return Object.assign({}, newState, {
				loading: action.loading,
			});
		case 'switchMode':
			return Object.assign({}, newState, {
				mode: action.mode,
				editType: action.editType
			});
    case 'setInitMessages':
      return setInitMessages(newState, action);
		case 'setStatusMessagesNum':
      return setStatusMessagesNum(newState, action);
    case 'setMessageFilter':
			return setMessageFilter(newState, action);
    case 'setMessageSort':
			return setMessageSort(newState, action);
    case 'switchMessageStatus':
      return changeActiveMessages(newState, action);
		case 'showMenuAppIntroModal':
			return Object.assign({}, newState, {
				showMenuAppIntroModal: true
			});
		case 'setSelectMessagePath':
			return Object.assign({}, newState, {
				showSelectMessagePathPopup: true
			});
		case 'setPreviewMessage':
			return Object.assign({}, newState, {
				previewInfo: action.previewInfo
			});
		case 'setDeleteMessage':
			return Object.assign({}, newState, {
				showDeletePopup: true,
				deleteType: action.deleteType,
				messageId: action.id,
				messageType: action.messageType
			});
		case 'setEditDateTime':
			return Object.assign({}, newState, {
				showEditDateTimeModal: true,
				messageId: action.id,
				messageType: action.messageType
			});
		case 'setMessageNotification':
			return Object.assign({}, newState, {
				notificationType: action.notificationType,
				msgType: action.msgType,
				showNotification: true
			});
		case 'closeMessageNotification':
			return Object.assign({}, newState, {
				notificationType: '',
				msgType: '',
				showNotification: false
			});
		case 'closeMenuAppIntroModal':
			return Object.assign({}, newState, {
				showMenuAppIntroModal: false
			});
		case 'cancelPopup': // 共用關閉 popup & 可清空資料
			return Object.assign({}, newState, {
				showSelectMessagePathPopup: false,
				showEditDateTimeModal: false,
				showDeletePopup: false,
				previewInfo: {},
				searchMode: false,
				searchInputText: ''
			});
		case 'setSearchInputText':
			return Object.assign({}, newState, {
				searchInputText: action.searchInputText
			});
		case 'setSearchRes':
			return setSearchRes(newState, action);
		case 'setSearchResSort':
			return setSearchResSort(newState, action);
    default:
      return state;
  }
}

function setInitMessages(state, action) {
	const newState = _.cloneDeep(state);
	const { messageStatus, messageSort } = newState;
	const { originMessages } = action;

	let displayMessages = [];

	if(messageSort === 'latest') {
		originMessages.sort((a, b) => {
			return new Date(b.schedule_time) - new Date(a.schedule_time);
		})
	} else if(messageSort === 'oldest') {
		originMessages.sort((a, b) => {
			return new Date(a.schedule_time) - new Date(b.schedule_time);
		})
	}

  // sent 寄件備份
	if (messageStatus === 'sent') {
		displayMessages = originMessages.filter((message) => {
			return message.status === 'finished' || message.status === 'real_time';
		});
	}

  // draft 草稿
	if (messageStatus === 'draft') {
		displayMessages = originMessages.filter((message) => {
			return message.status === 'draft';
		});

		if(messageSort === 'latest') {
			displayMessages.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			})
		} else if(messageSort === 'oldest') {
			displayMessages.sort((a, b) => {
				return new Date(a.created_at) - new Date(b.created_at);
			})
		}
	}
	
  // scheduling 排程訊息
  if (messageStatus === 'scheduling') {
		displayMessages = originMessages.filter((message) => {
			return message.status === 'scheduled';
		});
	}

	newState['loading'] = false;
	newState['originMessages'] = originMessages;
	newState['messages'] = displayMessages;

	return newState;
}

function setStatusMessagesNum(state, action) {
	const newState = _.cloneDeep(state);
	const {
		originMessages,
		searchMessages,
		messageFilter,
		searchMode
	} = newState;

	let status_sent = 0;
	let status_draft = 0;
	let status_scheduling = 0;

	if(!searchMode) {
		if(messageFilter === 'all') {
			status_sent = originMessages.filter((message) => message.status === 'real_time' || message.status === 'finished');
			status_draft = originMessages.filter((message) => message.status === 'draft');
			status_scheduling = originMessages.filter((message) => message.status === 'scheduled');
		} else {
			status_sent = originMessages.filter((message) => (message.status === 'real_time' || message.status === 'finished') && message.type === messageFilter);
			status_draft = originMessages.filter((message) => message.status === 'draft' && message.type === messageFilter);
			status_scheduling = originMessages.filter((message) => message.status === 'scheduled' && message.type === messageFilter);
		}
	} else {
		if(messageFilter === 'all') {
			status_sent = searchMessages.filter((message) => message.status === 'real_time' || message.status === 'finished');
			status_draft = searchMessages.filter((message) => message.status === 'draft');
			status_scheduling = searchMessages.filter((message) => message.status === 'scheduled');
		} else {
			status_sent = searchMessages.filter((message) => (message.status === 'real_time' || message.status === 'finished') && message.type === messageFilter);
			status_draft = searchMessages.filter((message) => message.status === 'draft' && message.type === messageFilter);
			status_scheduling = searchMessages.filter((message) => message.status === 'scheduled' && message.type === messageFilter);
		}
	}

	let statusMessages = [status_sent.length, status_draft.length, status_scheduling.length];

	newState['statusMessages'] = statusMessages;

	return newState;
}

function changeActiveMessages(state, action) {
	const newState = _.cloneDeep(state);
	const { originMessages, searchMessages, messageSort } = newState;
  const { messageStatus, searchMode } = action;

	let messages = [];

	if(!searchMode) {
		if (messageStatus === 'sent') {
			messages = originMessages.filter((message) => 
				message.status === 'finished' || message.status === 'real_time'
			);
		} else if (messageStatus === 'draft') {
			messages = originMessages.filter((message) => message.status === 'draft');

			if(messageSort === 'latest') {
				messages.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				})
			} else if(messageSort === 'oldest') {
				messages.sort((a, b) => {
					return new Date(a.created_at) - new Date(b.created_at);
				})
			}
		} else if (messageStatus === 'scheduling') {
			messages = originMessages.filter((message) => message.status === 'scheduled');
		}
	} else {
		if (messageStatus === 'sent') {
			messages = searchMessages.filter((message) => 
				message.status === 'finished' || message.status === 'real_time'
			);
		} else if (messageStatus === 'draft') {
			messages = searchMessages.filter((message) => message.status === 'draft');

			if(messageSort === 'latest') {
				messages.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				})
			} else if(messageSort === 'oldest') {
				messages.sort((a, b) => {
					return new Date(a.created_at) - new Date(b.created_at);
				})
			}
		} else if (messageStatus === 'scheduling') {
			messages = searchMessages.filter((message) => message.status === 'scheduled');
		}
	}

	return Object.assign({}, state, {
		messages,
    messageStatus,
		searchMode
  });
}

function setMessageSort(state, action) {
	const newState = _.cloneDeep(state);
	const { originMessages } = newState;
  const { messageSort } = action;

	if(messageSort === 'latest') {
		originMessages.sort((a, b) => {
			return new Date(b.schedule_time) - new Date(a.schedule_time);
		})
	} else if(messageSort === 'oldest') {
		originMessages.sort((a, b) => {
			return new Date(a.schedule_time) - new Date(b.schedule_time);
		})
	}

	newState['messageSort'] = messageSort;
	newState['originMessages'] = originMessages;

	return newState;
}

function setMessageFilter(state, action) {
	const newState = _.cloneDeep(state);
	const { messages, searchMessages } = newState;
  const { messageFilter, searchMode } = action;

	let filterMessages = [];

	if(!searchMode) {
		if(messageFilter === 'sms') {
			filterMessages = messages.filter((message) => message.type === 'sms');
		} else if(messageFilter === 'menuTalk') {
			filterMessages = messages.filter((message) => message.type === 'menuTalk');
		} else {
			// all
			filterMessages = messages;
		}
	} else {
		if(messageFilter === 'sms') {
			filterMessages = searchMessages.filter((message) => message.type === 'sms');
		} else if(messageFilter === 'menuTalk') {
			filterMessages = searchMessages.filter((message) => message.type === 'menuTalk');
		} else {
			// all
			filterMessages = searchMessages;
		}
	}

	newState['messageFilter'] = messageFilter;
	newState['messages'] = filterMessages;

	return newState;
}

function setSearchRes(state, action) {
	const newState = _.cloneDeep(state);
	const { originMessages, searchInputText } = newState;

	let newSearchMessages = [];

	if(searchInputText.length > 0) {
		for(let i = 0; i < originMessages.length; i++) {
			if(originMessages[i].type === 'sms') {
				if(
					originMessages[i].title.includes(searchInputText) || 
					originMessages[i].content.includes(searchInputText)
				) {
					newSearchMessages.push(originMessages[i])
				}
			}
			
			if(originMessages[i].type === 'menuTalk') {
				let menuContents = JSON.parse(originMessages[i].contents);
				let isIncludes = false;

				for(let j = 0; j < menuContents.length; j++) {
					if(
						menuContents[j].type === 'text' &&
						menuContents[j].text.includes(searchInputText)
					) {
						isIncludes = true;
					}
				}
				
				if(
					originMessages[i].title.includes(searchInputText) ||
					isIncludes
				) {
					newSearchMessages.push(originMessages[i])
				}
			}
		}
	}

	newState['searchMessages'] = newSearchMessages;

	return newState;
}

function setSearchResSort(state, action) {
	const newState = _.cloneDeep(state);
	const { searchMessages } = newState;
  const { messageSort } = action;

	if(messageSort === 'latest') {
		searchMessages.sort((a, b) => {
			return new Date(b.schedule_time) - new Date(a.schedule_time);
		})
	} else if(messageSort === 'oldest') {
		searchMessages.sort((a, b) => {
			return new Date(a.schedule_time) - new Date(b.schedule_time);
		})
	}

	newState['messageSort'] = messageSort;
	newState['searchMessages'] = searchMessages;

	return newState;
}