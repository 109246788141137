import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Switch, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

import BookingSystemApi from '../booking_settings/BookingSystemApi';

import TablePicker from './items/TablePicker';
import Datepicker from './items/Datepicker';
import AttendanceNumPad from './items/AttendanceNumPad';
import NumPad from './items/Numpad';
import BlacklistWarning from './items/BlacklistWarning';

import {
	GenderOption,
	ServiceTag,
	Questionnaire,
	Quantity,
	DepositSetting,
	DepositStatus
} from './items/addBookingComponent';

import {
	fetchSetting,
	addBooking,
	editBooking,
	handleTagChange,
	handleQuestionChange,
	handleQuantityChange,
	handleInfoChange,
	phoneTypeahead,
	setTypehead,
	phoneNumpadVisible,
	toggleOnSite,
	onSiteNsetTime,
	attendanceNumpadVisible,
	handleAttendanceNumpadVisible,
	bookingAttendance,
	handleBookingAttendChange,
	sendTableTimeOpenDatepicker,
	handleTimeClick,
	handleChooseDate,
	toggleDatepicker,
	datepickerSubmit,
	toggleTablepicker,
	resetBookingTime,
	tablepickerSubmit,
	resetTablepicker,
	serviceTimeChange,
	getCQ,
	resetTableCondtion,
	NewAddBooking_ResetDefault,
	handleDepositDetailChange,
	handleDepositNotificationError,
	handleDepositNotificationResend
} from '../../actions/addBookingAction';

import { updateDayAnnouncements } from '../../actions/announcementAction';

const genderJson = [ { genderTxt: '男', value: 'male' }, { genderTxt: '女', value: 'female' } ];

const customerTagsMap = {
	vip: '貴賓',
	egg_vegan: '蛋奶素',
	vegan: '素食',
	not_friendly: '奧客'
};

const severTimeHour = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23 ],
	severTimeMin = [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 ];

const QUANTITY = 'quantity',
	TAG = 'tag',
	QUESTION = 'question';

const OUTCOMETYPE_3 = '該時段找不到可安排的座位';

const bookingTimeTxt_1 = '請先選擇預約人數',
	bookingTimeTxt_2 = '請選擇預約時間';

const SEARCHINGTXT = '搜尋中';

const attendanceNumpadRef = React.createRef();
const phoneNumPadRef = React.createRef();
const depositRef = React.createRef();

class NewAddBookingView extends Component {
	constructor(props) {
		super(props);

		this.renderGenderOption = this.renderGenderOption.bind(this);
		this.renderCustomerTag = this.renderCustomerTag.bind(this);
		this.renderCustomerMemo = this.renderCustomerMemo.bind(this);
		this.renderBookingDate = this.renderBookingDate.bind(this);
		this.renderBookingServerTime = this.renderBookingServerTime.bind(this);
		this.renderCustomeQ = this.renderCustomeQ.bind(this);
		this.renderCloseCustomQ = this.renderCloseCustomQ.bind(this);
		this.renderDepositBlock = this.renderDepositBlock.bind(this);

		this.openDatepicker = this.openDatepicker.bind(this);
		this.closeDatepicker = this.closeDatepicker.bind(this);
		this.checkDateTime = this.checkDateTime.bind(this);

		this.handleOnSite = this.handleOnSite.bind(this);
		this.handleAttendChange = this.handleAttendChange.bind(this);
		this.handleKeyin = this.handleKeyin.bind(this);
		this.handleCustomerInfoChange = this.handleCustomerInfoChange.bind(this);
		this.handleInputFocus = this.handleInputFocus.bind(this);
		this.handleTablePicker = this.handleTablePicker.bind(this);
		this.handleSeverTimeChange = this.handleSeverTimeChange.bind(this);
		this.handleDepositDetail = this.handleDepositDetail.bind(this);
		this.handleDepositNotification = this.handleDepositNotification.bind(this);

		this.addBookingSubmit = this.addBookingSubmit.bind(this);
		this.datepickerSubmit = this.datepickerSubmit.bind(this);
		this.tablePickerSubmit = this.tablePickerSubmit.bind(this);
		this.doubleCheckSubmit = this.doubleCheckSubmit.bind(this);

		this.options = {}; //for quantity options

		this.state = {
			emailIsSend: false,
			smsIsSend: false,
			blacklists: [],
			isBlacklist: false,
			showBlacklistWarning: false,
			isLoading: false
		}
	}

	debounce = () => {
		let timer;
		return (e) => {
			if (timer) clearTimeout(timer);
			timer = setTimeout(this.setReadOnly, 200, e);
		};
	};

	componentDidMount() {
		const { customQ, customQAns, date, diningTime, autoTable, modalType, newSelected, smsNotificationSettings, emailNotificationSettings } = this.props;

		this.API = new BookingSystemApi();
		this.w_w = window.innerWidth;
		this.device_w = window.innerWidth;
		this.isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
		this.newModal = document.getElementsByClassName('newModal')[0];

		window.addEventListener('resize', this.debounce());
		this.newModal.addEventListener('click', this.phoneNumpadClick);

		if (modalType === 'editor') {
			this.props.editBooking(newSelected).then(() => {
				let customized_questions = {};
				try {
					customized_questions = JSON.parse(newSelected.customized_questions);
				} catch (e) {
					customized_questions = {};
				}

				this.props.getCQ(customQ, customized_questions);
				this.phone();
				this.setReadOnly();
			});
		} else {
			this.props.addBooking(date, diningTime, autoTable, smsNotificationSettings, emailNotificationSettings ).then(() => {
				this.props.getCQ(customQ, customQAns);
				this.phone();
				this.setReadOnly();
			});
		}

		// 從 customer record 新增預約
		if(this.props.customerInfo) {
			if(this.props.customerInfo.phone) this.props.handleInfoChange(this.props.customerInfo.phone, 'phone', 'customer');
			if(this.props.customerInfo.name) this.props.handleInfoChange(this.props.customerInfo.name, 'name', 'customer');
			if(this.props.customerInfo.email) this.props.handleInfoChange(this.props.customerInfo.email, 'email', 'customer');
			if(this.props.customerInfo.gender) this.props.handleInfoChange(this.props.customerInfo.gender, 'gender', 'customer');
		}

		this.API.getBookingSetting()
			.then((data) => {
				const { blacklists } = data;
				this.setState({ blacklists });

				if(
					modalType === 'editor' &&
					newSelected.id !== null &&
					newSelected.phone_number.length === 10
				) {
					let idx = blacklists.indexOf(newSelected.phone_number);
		
					if(idx !== -1) {
						this.setState({ isBlacklist: true });
						this.refs.phoneRef.classList.add('empty');
					} else {
						this.setState({ isBlacklist: false });
						this.refs.phoneRef.classList.remove('empty');
					}
				}
			}).catch((error) => {
				console.log('add booking get blacklists error', error);
			});
	}

	setReadOnly = () => {
		if (!ReactDOM.findDOMNode(this.refs.attendRef)) return;
		const readOnly = ReactDOM.findDOMNode(this.refs.attendRef).hasAttribute('readonly');

		this.w_w = window.innerWidth;

		if (this.device_w !== this.w_w) {
			if (this.refs.attendRef) {
				this.refs.attendRef.blur();
			}

			if (this.refs.phoneRef) {
				this.refs.phoneRef.blur();
			}

			this.device_w = this.w_w;
		}

		if (this.w_w <= 768 && this.refs.phoneRef) {
			this.refs.phoneRef.removeAttribute('readonly');
		} else if (this.w_w > 768 && this.isMobile && this.refs.phoneRef) {
			//>768 mobile device不要原生鍵盤
			this.refs.phoneRef.setAttribute('readonly', true);
		} else if (this.w_w > 768 && !this.isMobile && this.refs.phoneRef) {
			//>768 pc device要電腦鍵盤key in && 數字鍵盤
			this.refs.phoneRef.removeAttribute('readonly');
		}

		if (this.w_w < 600 && readOnly && this.refs.attendRef) {
			this.refs.attendRef.removeAttribute('readonly');
		} else if (this.w_w >= 600 && !readOnly && this.refs.attendRef) {
			this.refs.attendRef.setAttribute('readonly', true);
		}

		this.props.phoneNumpadVisible(true);
		this.props.attendanceNumpadVisible(true);
	};

	phoneNumpadClick = (e) => {
		const target = e.target.classList;

		if (phoneNumPadRef.current) {
			if (
				!target.contains('phoneNumber') &&
				!target.contains('phoneNumpad') &&
				!target.contains('numPadCell') &&
				!target.contains('numPad_del') &&
				!target.contains('numPad_clear')
			) {
				this.props.phoneNumpadVisible(true);
			}
		}
	};

	componentWillUnmount() {
		const body = document.getElementsByTagName('body')[0];
		const depositInput = document.getElementById('ga-deposit');

		body.classList.remove('modal-open');

		window.removeEventListener('resize', this.debounce());
		window.removeEventListener('click', this.phoneNumpadClick);

		ReactDOM.findDOMNode(this.refs.dateRef).removeAttribute('id');
		ReactDOM.findDOMNode(this.refs.phoneRef).removeAttribute('id');
		ReactDOM.findDOMNode(this.refs.nameRef).removeAttribute('id');
		ReactDOM.findDOMNode(this.refs.bookingResRef).removeAttribute('id');
		ReactDOM.findDOMNode(this.refs.attendRef).removeAttribute('id');

		if(depositInput) ReactDOM.findDOMNode(depositRef.current).removeAttribute('id');

		this.props.NewAddBooking_ResetDefault();
	}

	phone = () => {
		$('.phoneNumber').typeahead(
			{
				hint: false,
				highlight: true,
				minLength: 1
			},
			{
				name: 'brands',
				display: 'phone_number',
				source: function(query, syncResults, asyncResults) {
					if (query.length < 8) return;

					$.ajax({
						url: window.domain + '/dashboard/customers/query',
						dataType: 'json',
						data: { query: query },
						xhrFields: { withCredentials: true },
						success: function(data) {
							asyncResults(data.customers);
						}
					});
				},
				templates: {
					empty: [ '<div class="empty-message">', '查無資料', '</div>' ].join('\n'),
					suggestion: function(context) {
						return '<div><strong>' + context.phone_number + '</strong> – ' + context.full_name + '</div>';
					}
				}
			}
		);

		$('.phoneNumber').bind(
			'typeahead:selected',
			function(_, data) {
				if (data.email === null) {
					data.email = '';
				}

				// 手機號碼是否在後台封鎖名單
				if (data.phone_number.length !== 10) {
					this.setState({ isBlacklist: false });
					this.refs.phoneRef.classList.remove('empty');
				} else {
					let idx = this.state.blacklists.indexOf(data.phone_number);
		
					if(idx !== -1) {
						this.setState({ isBlacklist: true });
						this.refs.phoneRef.classList.add('empty');
					} else {
						this.setState({ isBlacklist: false });
						this.refs.phoneRef.classList.remove('empty');
					}
				}

				this.props.setTypehead(data).then(() => {
					this.refs.nameRef.classList.remove('empty');
				});
			}.bind(this)
		);
	};

	openDatepicker() {
		const { bookingAttend, bookingTimeRes } = this.props.NewAddBooking_SeatCondition;

		if (bookingTimeRes === bookingTimeTxt_1 || bookingAttend === '') {
			window.app.alert.setMessage('請先選擇預約人數', 'error');
		} else if (parseInt(bookingAttend, 10) <= 0) {
			window.app.alert.setMessage('人數請大於0', 'error');
		} else if (bookingTimeRes !== SEARCHINGTXT && bookingTimeRes !== bookingTimeTxt_1) {
			this.newModal.classList.add('newModal-open');
			this.props.toggleDatepicker(false);
		}

		// setTimeout(() => {
		// 	ReactDOM.findDOMNode(this.refs.dateRef).removeAttribute('id');
		// }, 500);
	}

	checkDateTime() {
		const { bookingTime, newSelected } = this.props.NewAddBooking_SeatCondition;
		const today = new Date();
		let bookingDate = ''
			bookingDate = new Date(bookingTime.date);
			bookingDate.setHours(parseInt(bookingTime.hour, 10));
			bookingDate.setMinutes(parseInt(bookingTime.min, 10));

		if(newSelected.id === null && new Date(bookingDate) < today) {
			this.props.handleDepositDetailChange('', 'disabled');
		}
	}

	closeDatepicker() {
		this.newModal.classList.remove('newModal-open');

		// 取消設回原本時間(bookingTime在確認時才會改變)
		this.props.toggleDatepicker(true);
		this.props.resetBookingTime();
		this.checkDateTime(); // 小於當前時間不可使用訂金功能
	}

	datepickerSubmit() {
		const { tempBookingTime, autoTable, newSelected } = this.props.NewAddBooking_SeatCondition;

		ReactDOM.findDOMNode(this.refs.dateRef).removeAttribute('id');

		if (tempBookingTime.hour === '' || tempBookingTime.min === '') {
			window.app.alert.setMessage('請選擇時間', 'tip');
		} else {
			this.props.toggleDatepicker(true);
			this.props.datepickerSubmit().then((seats) => {
				if ((!autoTable && newSelected.id === null) || seats.length === 0) {
					// 新增沒有自動配位 or 沒有選位子：打開座位表
					this.props.toggleTablepicker(false);

					if (autoTable && newSelected.id === null) {
						//沒有選位子: 新增時有開啟自動配位但無位子可分配
						window.app.alert.setMessage('店內沒有可自動分配的座位，請手動選擇顧客座位', 'tip');
					}
				}
			});
			this.newModal.classList.remove('newModal-open');
			this.checkDateTime(); // 小於當前時間不可使用訂金功能
		}
	}

	doubleCheckSubmit() {
		const {
			newSelected,
			bookingAttend,
			bookingTime,
			seating,
			bookingTimeRes
		} = this.props.NewAddBooking_SeatCondition;
		const { customerInfo, onSite, depositDetail } = this.props.NewAddBooking_CustomerInfo;
		const { name, phone, email } = customerInfo;
		const { depositMode, deposit, notification } = depositDetail;
		const reg = /^\d+$/;
		const regEmail = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		const checkInfo = []; // 檢查資料是否有缺漏

		const today = new Date();
		const todayTime = today.getTime();
		today.setTime(todayTime + 30 * 1000 * 60) // 自建訂金預約至少要在當下時間的 30 分鐘後才可建立

		if (bookingAttend === '') {
			this.refs.attendRef.setAttribute('placeholder', '請填寫');
			this.refs.attendRef.classList.add('empty');
			window.app.alert.setMessage('請填寫預約人數', 'error');
			ReactDOM.findDOMNode(this.refs.attendRef).setAttribute('id', 'ga-attendance');
			return;
		}

		if (bookingTime.min === '' || bookingTime.hour === '') {
			window.app.alert.setMessage('請選擇預約時間', 'error');
			ReactDOM.findDOMNode(this.refs.dateRef).setAttribute('id', 'ga-bookingDate');
			return;
		}

		if (seating.length === 0) {
			window.app.alert.setMessage('請選擇座位', 'error');
			ReactDOM.findDOMNode(this.refs.bookingResRef).setAttribute('id', 'ga-bookingRes');
			return;
		}

		if (!onSite) {
			if (phone.trim() === '' || !reg.test(phone.trim())) {
				this.refs.phoneRef.setAttribute('placeholder', '請填寫');
				this.refs.phoneRef.classList.add('empty');
				window.app.alert.setMessage('請填寫完整資訊', 'error');
				ReactDOM.findDOMNode(this.refs.phoneRef).setAttribute('id', 'ga-phone');

				checkInfo.push('phone');
			} else {
				const idx = checkInfo.indexOf('phone');
				if(idx !== -1) {
					checkInfo.splice(idx, 1);
				}
			}
		};

		if (name === '') {
			this.refs.nameRef.setAttribute('placeholder', '請填寫');
			this.refs.nameRef.classList.add('empty');
			window.app.alert.setMessage('請填寫完整資訊', 'error');
			ReactDOM.findDOMNode(this.refs.nameRef).setAttribute('id', 'ga-name');

			checkInfo.push('name');
		} else {
      const idx = checkInfo.indexOf('name');
      if(idx !== -1) {
        checkInfo.splice(idx, 1);
      }
    };

		// deposit
		if(depositMode) {
			if(
				deposit === ''||
				Number(deposit) === 0 ||
				!reg.test(deposit)
			) {
				depositRef.current.setAttribute('placeholder', '請填寫');
				depositRef.current.classList.add('empty');
				window.app.alert.setMessage('請填寫完整資訊', 'error');
				ReactDOM.findDOMNode(depositRef.current).setAttribute('id', 'ga-deposit');

				checkInfo.push('deposit');
			} else {
				const idx = checkInfo.indexOf('deposit');
				if(idx !== -1) {
					checkInfo.splice(idx, 1);
				}
			}

			if(
				notification.email && email === '' ||
				notification.email && !regEmail.test(email)
			) {
				this.refs.emailRef.setAttribute('placeholder', '請填寫');
				this.refs.emailRef.classList.add('empty');
				window.app.alert.setMessage('請填寫完整資訊', 'error');
				ReactDOM.findDOMNode(this.refs.emailRef).setAttribute('id', 'ga-email');

				checkInfo.push('email');
			} else {
				const idx = checkInfo.indexOf('email');
				if(idx !== -1) {
					checkInfo.splice(idx, 1);
				}
			}

			// 1. 點數大於 0, sms 和 email 都未選 2. 點數小於等於 0 && 未選 email 通知
			if(
				!notification.sms && !notification.email ||
				this.props.msgPoint <= 0 && !notification.email
				) {
				this.props.handleDepositNotificationError('required');
				checkInfo.push('notification');
			} else {
				const idx = checkInfo.indexOf('notification');

				if(idx !== -1) {
					checkInfo.splice(idx, 1);
				}
				this.props.handleDepositNotificationError('');
			}

			if(newSelected.id === null && (new Date(bookingTimeRes) < today)) {
				return window.app.alert.setMessage('預約時間小於付款最小期限30分鐘', 'error');
			}
		}

		if(!onSite) {
			const phone_idx = checkInfo.indexOf('phone');
			const name_idx = checkInfo.indexOf('name');
			const deposit_idx = checkInfo.indexOf('deposit');
			const email_idx = checkInfo.indexOf('email');
			const notification_idx = checkInfo.indexOf('notification');

			// 開啟訂金
			if(depositMode) {
				if(
					phone_idx !== -1 ||
					name_idx !== -1 ||
					deposit_idx !== -1 ||
					notification_idx !== -1
				) {
					return;
				}
				if(notification.email && email_idx !== -1) return;
			} else {
				// 一般自建
				if(phone_idx !== -1 || name_idx !== -1) return;
			}
		}

		if(this.state.isBlacklist) {
			this.setState({ showBlacklistWarning: true });
		} else {
			this.addBookingSubmit();
		}
	}

	async addBookingSubmit() {
		const {
			newSelected,
			bookingAttend,
			bookingTime,
			severTime_hour,
			severTime_min,
			seating
		} = this.props.NewAddBooking_SeatCondition;
		const { customerInfo, shopNote, onSite, depositDetail } = this.props.NewAddBooking_CustomerInfo;
		const { name, phone, gender, email } = customerInfo;
		const { depositMode, deposit, notification } = depositDetail;
		const { customQAns } = this.props.NewAddBooking_Question;
		let dataSubmit = {};
		const serviceTime = parseInt(severTime_hour, 10) * 60 + parseInt(severTime_min, 10);
		const btn = document.getElementsByClassName('bookingSaveBtn')[0];

		const today = new Date();
		const todayTime = today.getTime();
		today.setTime(todayTime + 30 * 1000 * 60) // 自建訂金預約至少要在當下時間的 30 分鐘後才可建立

		//bookingAttend 人數
		//customerInfo: name phone email gender 顧客資訊
		//bookingTime: date hour min 預約時間
		//serviceTime 分
		//shopNote 店家備註
		//serviceTagAns 服務標記
		//questionnairesTagAns 問題
		// depositDetail 訂金細節

		seating.map((seat) => {
			delete seat['combinable'];
			delete seat['name'];
			delete seat['timeline'];
			return seating;
		});

		btn.classList.add('disableBtn');

		const customerInfoData = {
			customer: {
				last_name: name,
				email: email,
				phone_number: phone.trim(),
				tele_number: '',
				gender: gender === '' ? 'other' : gender,
				note: ''
			}
		};

		const depositModeSettings = {
			notification : {
				email: notification.email,
				sms: notification.sms
			}
		}

		dataSubmit['last_name'] = name;
		dataSubmit['email'] = email;
		dataSubmit['phone_number'] = phone;
		dataSubmit['gender'] = gender === '' ? 'other' : gender;
		dataSubmit['booking_datetime'] = bookingTime.date + ' ' + bookingTime.hour + ':' + bookingTime.min;
		dataSubmit['attendance'] = bookingAttend;
		dataSubmit['dining_time'] = serviceTime;
		dataSubmit['shop_memo'] = shopNote;
		dataSubmit['seating_json'] = JSON.stringify(seating);
		dataSubmit['customized_questions'] = JSON.stringify(customQAns);
		dataSubmit['on_site'] = onSite;
		// deposit
		dataSubmit['deposit_mode'] = depositMode;
		dataSubmit['deposit_mode_settings'] = JSON.stringify(depositModeSettings);
		dataSubmit['deposit'] = Number(deposit);


		//uuid
		const eventId = uuidv4();
		dataSubmit['event_id'] = eventId;

		if (this.props.setUuid) this.props.setUuid(eventId);

		await this.API.createCustomer(customerInfoData).catch((err) => console.log('createCustomer---', err));

		this.setState({ isLoading: true });

		if (newSelected.id !== null) {
			dataSubmit['date'] = bookingTime.date;

			// if(this.props.setUuid) this.props.setUuid(eventId)

			await this.API
				.updateBooking(newSelected.id, dataSubmit)
				.then((data) => {
					const booking = data.find((booking) => booking.id === newSelected.id);

					window.app.alert.setMessage('修改預約成功', 'done');
					this.setState({ isLoading: false });

					// props.setOverlayTables 在新模組裡
					if (booking && this.props.setOverlayTables) this.props.setOverlayTables(booking.overlay_tables);
					this.props.updateData();
					this.props.hideNewAddBookingModal();
				})
				.catch((error) => {
					try {
						this.setState({ isLoading: false });
						let erroMsg = error.responseJSON.errors;
						btn.classList.remove('disableBtn');
						window.app.alert.setMessage(erroMsg, 'error');
					} catch (e) {
						this.setState({ isLoading: false });
						btn.classList.remove('disableBtn');
						window.app.alert.setMessage('新增預約失敗請重試', 'error');
					}
				});
		} else {

			await this.API
				.createBooking(dataSubmit)
				.then(({ booking: { overlay_tables } }) => {
					this.setState({ isLoading: false });

					// props.setOverlayTables 在新模組裡
					if (this.props.setOverlayTables) this.props.setOverlayTables(overlay_tables);
					this.props.hideNewAddBookingModal();
					this.props.updateData();
					window.app.alert.setMessage('新增預約成功', 'done');
				})
				.catch((error) => {
					try {
						this.setState({ isLoading: false });
						let erroMsg = error.responseJSON.errors;
						btn.classList.remove('disableBtn');
						window.app.alert.setMessage(erroMsg, 'error');
					} catch (e) {
						this.setState({ isLoading: false });
						btn.classList.remove('disableBtn');
						window.app.alert.setMessage('新增預約失敗請重試', 'error');
					}
				});
		}
	}

	tablePickerSubmit(tempSeating, chosenGroup) {
		const { outcomeType } = this.props.NewAddBooking_SeatCondition;

		if (tempSeating.length !== 0) {
			this.props.tablepickerSubmit(tempSeating, chosenGroup);

			this.props.toggleTablepicker(true);
			this.newModal.classList.remove('newModal-open');
		} else if (tempSeating.length === 0 && outcomeType !== OUTCOMETYPE_3) {
			window.app.alert.setMessage('請選擇座位', 'tip');
		} else if (tempSeating.length === 0 && outcomeType === OUTCOMETYPE_3) {
			//找不到座位的時候直接關閉modal
			this.props.toggleTablepicker(true);
		}

		ReactDOM.findDOMNode(this.refs.bookingResRef).removeAttribute('id');
	}

	handleOnSite = () => {
		this.props.onSiteNsetTime();
		this.props.handleDepositDetailChange('', 'disabled');
	};

	handleCustomerInfoChange(e, type) {
		let typeRef = type + 'Ref';

		if(type !== 'gender' && type !== 'shopNote') {
			this.refs[typeRef].classList.remove('empty');
		}

		this.props.handleInfoChange(e, type);

		// 手機號碼是否在後台封鎖名單
		if(type === 'phone' && this.state.blacklists.length !== 0) {
			if (e.target.value.length !== 10) {
				this.setState({ isBlacklist: false });
				this.refs.phoneRef.classList.remove('empty');
			} else {
				let phoneNumber = e.target.value.toString();
				let idx = this.state.blacklists.indexOf(phoneNumber);
	
				if(idx !== -1) {
					this.setState({ isBlacklist: true });
					this.refs.phoneRef.classList.add('empty');
				} else {
					this.setState({ isBlacklist: false });
					this.refs.phoneRef.classList.remove('empty');
				}
			}
		}

		ReactDOM.findDOMNode(this.refs.phoneRef).removeAttribute('id');
		ReactDOM.findDOMNode(this.refs.nameRef).removeAttribute('id');
	}

	handleAttendChange(e) {
		const reg = /^\d+$/;
		const attend = e.target.value;

		if (reg.test(attend) || attend === '') {
			this.props.handleBookingAttendChange(true);
			this.props.bookingAttendance(attend);
			this.props.resetTableCondtion();
		} else {
			window.app.alert.setMessage('請輸入數字', 'error');
		}

		ReactDOM.findDOMNode(this.refs.attendRef).removeAttribute('id');
	}

	handleKeyin(e) {
		const { customerInfo } = this.props.NewAddBooking_CustomerInfo;

		this.props.phoneTypeahead(e).then(() => {
			$('.phoneNumber').typeahead('val', customerInfo['phone']);
			$('.phoneNumber').typeahead('open');
		});
	}

	checkAttendance = (attendance) => {
		const { bookingAttend, newSelected, seating } = this.props.NewAddBooking_SeatCondition;
		// const { onSite } = this.props.NewAddBooking_CustomerInfo;

		if (parseInt(attendance, 10) === 0 || attendance === '') {
			window.app.alert.setMessage('請輸入正確人數', 'error');
			return;
		}

		if (attendance !== bookingAttend) {
			this.props.bookingAttendance(attendance);
			this.props.attendanceNumpadVisible(true, true).then(() => {
				const seatMaxAttendace = seating.reduce((prev, cur) => prev + cur.max_seat, 0);

				if (newSelected.id !== null && parseInt(attendance, 10) > seatMaxAttendace) {
					//修改預約：修改人數大於原位子可容納人數後打開座位表
					this.props.toggleTablepicker(false);
				} else if (newSelected.id === null) {
					//新增預約：修改人數後打開時間表
					this.props.sendTableTimeOpenDatepicker();
				}
				// .then(load => {
				// 	if (load) {
				// 		this.props.fetchSetting();
				// 	}
				// });
			});
		} else {
			this.props.attendanceNumpadVisible(true, false);
		}
	};

	handleAttendSend = () => {
		const readOnly = this.refs.attendRef.hasAttribute('readonly');

		if (readOnly) return;

		this.props.handleBookingAttendChange(true);
		this.props.sendTableTimeOpenDatepicker().then((load) => {
			if (load) {
				this.props.fetchSetting();
			}
		});
	};

	renderBookingDate() {
		const { bookingTimeRes } = this.props.NewAddBooking_SeatCondition;

		return (
			<div className="resultDiv position-relative pointer" onClick={() => this.openDatepicker()}>
				<span ref="dateRef" style={{ display: 'inline-block', width: '100%' }}>
					{bookingTimeRes}
				</span>
				<i className="fa fa-caret-down" aria-hidden="true" />
			</div>
		);
	}

	handleInputFocus(type) {
		if (type === 'phoneRef') {
			this.refs.phoneRef.setAttribute('placeholder', '請輸入手機號碼');
			if (this.w_w > 768 && this.isMobile) {
				this.props.phoneNumpadVisible(false).then(() => {
					// this.refs.PhoneNumPad.refs.NumPadComponent.focus();
					phoneNumPadRef.current.focus();
				});
			} else if (this.w_w > 768 && !this.isMobile) {
				this.props.phoneNumpadVisible(false).then(() => {
					this.refs.phoneRef.focus();
				});
			} else {
				this.props.phoneNumpadVisible(true);
			}
		} else if (type === 'nameRef') {
			this.refs.nameRef.setAttribute('placeholder', '請輸入姓名');
		} else if (type === 'attendRef') {
			this.refs.attendRef.setAttribute('placeholder', '');
			if (this.w_w >= 600) {
				this.props.attendanceNumpadVisible(false, false).then(() => {
					attendanceNumpadRef.current.focus();
				});
			} else {
				this.props.attendanceNumpadVisible(true, false);
			}
		} else if(type === 'emailRef') {
			this.refs.emailRef.setAttribute('placeholder', '請輸入 E-mail');
		}
	}

	handleTablePicker(type) {
		const { bookingAttend, bookingResult, outcomeType, bookingTimeRes } = this.props.NewAddBooking_SeatCondition;

		if (type === 'close') {
			this.newModal.classList.remove('newModal-open');
			this.props.toggleTablepicker(true);
			this.props.resetTablepicker();
		} else {
			if (bookingAttend === '') {
				window.app.alert.setMessage('請先選擇預約人數', 'error');
			} else if (bookingTimeRes === bookingTimeTxt_1 || bookingTimeRes === bookingTimeTxt_2) {
				window.app.alert.setMessage(bookingTimeRes, 'error');
			} else if (
				bookingResult === SEARCHINGTXT ||
				bookingTimeRes === SEARCHINGTXT ||
				outcomeType === OUTCOMETYPE_3
			) {
				this.newModal.classList.remove('newModal-open');
				this.props.toggleTablepicker(true);
			} else {
				this.newModal.classList.add('newModal-open');
				this.props.toggleTablepicker(false);
			}
		}

		// setTimeout(() => {
		// 	ReactDOM.findDOMNode(this.refs.bookingResRef).removeAttribute('id');
		// }, 500);
	}

	handleSeverTimeChange(type, t) {
		const { severTime_hour, severTime_min } = this.props.NewAddBooking_SeatCondition;
		const time = parseInt(t.target.value, 10);

		if (type === 'hour') {
			if (parseInt(time, 10) === 0 && parseInt(severTime_min, 10) === 0) {
				window.app.alert.setMessage('服務時間最少為5分鐘', 'tip');
				return;
			}
		} else {
			if (parseInt(time, 10) === 0 && parseInt(severTime_hour, 10) === 0) {
				window.app.alert.setMessage('服務時間最少為5分鐘', 'tip');
				return;
			}
		}

		this.props.serviceTimeChange(type, t);
	}

	handleDepositDetail(e, type) {
		const depositInput = document.getElementById('ga-deposit');

		this.props.handleDepositDetailChange(e, type);

		if(type === 'email_notification') {
			let value = e.target.value;
			if(value) {
				this.refs.emailRef.classList.remove('empty');
				this.refs.emailRef.setAttribute('placeholder', '');
			}
		} else if(type === 'deposit') {
			depositRef.current.classList.remove('empty');
		}

		if(depositInput) {
			ReactDOM.findDOMNode(depositRef.current).removeAttribute('id');
		}
	}

	async handleDepositNotification(type) {
		const { customerInfo } = this.props.NewAddBooking_CustomerInfo;
		const { email, phone } = customerInfo;
		const reg = /^\d+$/;

		const emailData = {
			id: this.props.newSelected.id,
			email: email
		}

		const smsData = {
			id: this.props.newSelected.id,
			phone_number: phone
		}

		if(type === 'email') {
			if(email.trim() === '') {
				this.refs.emailRef.setAttribute('placeholder', '請填寫');
				this.refs.emailRef.classList.add('empty');
				window.app.alert.setMessage('請填寫完整資訊', 'error');
				ReactDOM.findDOMNode(this.refs.emailRef).setAttribute('id', 'ga-email');
				
				this.props.handleDepositNotificationError('email');
	
				return;
			} else {
				this.setState({ emailIsSend: true });

				await this.API.sendEmailNotification(emailData)
				.then(() => {
					this.props.handleDepositNotificationError('');
					// 已發送
					this.props.handleDepositNotificationResend(type);
					this.setState({ emailIsSend: false });
				})
				.catch((err) => {
					console.log('send email notification err ----------', err)
				})
			}
		} else {
			if (phone.trim() === '' || !reg.test(phone.trim())) {
				this.refs.phoneRef.setAttribute('placeholder', '請填寫');
				this.refs.phoneRef.classList.add('empty');
				window.app.alert.setMessage('請填寫完整資訊', 'error');
				ReactDOM.findDOMNode(this.refs.phoneRef).setAttribute('id', 'ga-phone');
			}	else {
				this.setState({ smsIsSend: true });

				await this.API.sendSmsNotification(smsData)
				.then(() => {
					this.refs.phoneRef.classList.remove('empty');
					// 已發送
					this.props.handleDepositNotificationResend(type);
					this.setState({ smsIsSend: false });
				})
				.catch((err) => {
					console.log('send sms notification err ----------', err)
				})
			}
		}
	}

	renderGenderOption() {
		const { customerInfo } = this.props.NewAddBooking_CustomerInfo;
		return genderJson.map((g) => {
			return (
				<GenderOption
					key={g.value}
					customerInfo={customerInfo}
					gender={g.value}
					genderTxt={g.genderTxt}
					handleChange={this.handleCustomerInfoChange}
				/>
			);
		});
	}

	renderCustomerTag() {
		const { customerTag, customerNote } = this.props.NewAddBooking_CustomerInfo;
		let tags = [];

		customerTag.map((ele) => {
			if (ele.description) {
				return tags.push(ele.description);
			} else {
				return tags.push(customerTagsMap[ele]);
			}
		});

		return (
			<div className="bookingContentRow">
				<div className="bookingContentRow-value">
					{tags.length !== 0 && <p className="c-tagsWrapper">{tags.join('、')}</p>}
					{
						customerNote !== null && customerNote !== undefined && customerNote.trim() !== '' && 
						<p className="c-tagsWrapper">{customerNote}</p>
					}
				</div>
			</div>
		);
	}

	renderCustomerMemo() {
		const { customerMemo } = this.props.NewAddBooking_CustomerInfo;

		return (
			<div className="bookingContentRow">
				<div className="bookingContentRow-title">
					<p>客戶備註</p>
				</div>
				<div className="bookingContentRow-value">
					<textarea value={customerMemo || ''} disabled />
				</div>
			</div>
		);
	}

	renderBookingServerTime() {
		const { severTime_hour, severTime_min } = this.props.NewAddBooking_SeatCondition;
		const diningHour = severTimeHour;

		return (
			<div className="bookingContentRow-value">
				<select
					className="serverTimeSelect"
					value={severTime_hour}
					onChange={(e) => this.handleSeverTimeChange('hour', e)}
				>
					{diningHour.map((h) => {
						return (
							<option key={h} value={h}>
								{h}
							</option>
						);
					})}
				</select>
				<span>小時</span>
				<select
					className="serverTimeSelect"
					value={severTime_min}
					onChange={(e) => this.handleSeverTimeChange('min', e)}
				>
					{severTimeMin.map((m) => {
						return (
							<option key={m} value={m}>
								{m}
							</option>
						);
					})}
				</select>
				<span>分鐘</span>
			</div>
		);
	}

	renderCustomeQ() {
		const { customQ, customQAns } = this.props.NewAddBooking_Question;

		return customQ.map((q) => {
			let ans = {};
			if (q.is_available && q.question_type === QUANTITY) {
				if (!this.options[q.id]) {
					let numberOption = JSON.parse(q.content);
					this.options[q.id] = {};
					Object.keys(numberOption).map((o) => {
						let range = [];
						range[0] = 0;
						for (let i = numberOption[o].min; i <= numberOption[o].max; i++) {
							range.push(i);
						}
						this.options[q.id][o] = range;
						return true;
					});
				}

				if (this.options[q.id] && Object.keys(customQAns).length !== 0) {
					if (customQAns[q.id]) {
						ans = customQAns[q.id];

						Object.keys(customQAns[q.id]).map((a) => {
							if (!this.options[q.id][a]) {
								delete ans[a];
							} else {
								let index = this.options[q.id][a].indexOf(parseInt(ans[a], 10));

								if (index === -1) {
									this.options[q.id][a].splice(1, 0, parseInt(ans[a], 10));
								}
							}
							return true;
						});
					} else {
						ans = {};
					}
				}

				return (
					<Quantity
						key={q.id}
						ele={q}
						options={this.options[q.id]}
						customQAns={ans}
						handleQuantityChange={this.props.handleQuantityChange}
					/>
				);
			} else if (q.is_available && q.question_type === TAG) {
				if (customQAns[q.id]) {
					ans = customQAns[q.id];
				} else {
					ans = {};
				}
				return <ServiceTag key={q.id} ele={q} customQAns={ans} handleTagChange={this.props.handleTagChange} />;
			} else if (q.is_available && q.question_type === QUESTION) {
				if (customQAns[q.id]) {
					ans = customQAns[q.id];
				} else {
					ans = '';
				}
				return (
					<Questionnaire
						key={q.id}
						ele={q}
						customQAns={ans}
						handleQuestionChange={this.props.handleQuestionChange}
					/>
				);
			}
			return true;
		});
	}

	renderCloseCustomQ() {
		const { customQ, customQAns } = this.props.NewAddBooking_Question;
		const index = customQ.map((q) => q.is_available).indexOf(false);

		if (index !== -1) {
			return (
				<div className="closeQArea">
					<p className="closeQArea__closeQTitle">
						<span>已關閉的題目</span>
					</p>
					{customQ.map((q) => {
						let ans = {};
						if (!q.is_available && q.question_type === QUANTITY) {
							if (!this.options[q.id]) {
								let numberOption = JSON.parse(q.content);
								this.options[q.id] = {};

								Object.keys(numberOption).map((o) => {
									let range = [];
									range[0] = 0;
									for (let i = numberOption[o].min; i <= numberOption[o].max; i++) {
										range.push(i);
									}
									this.options[q.id][o] = range;
									return true;
								});
							}

							if (this.options[q.id] && Object.keys(customQAns).length !== 0) {
								if (customQAns[q.id]) {
									ans = customQAns[q.id];

									Object.keys(customQAns[q.id]).map((a) => {
										if (!this.options[q.id][a]) {
											delete ans[a];
										} else {
											let index = this.options[q.id][a].indexOf(parseInt(ans[a], 10));

											if (index === -1) {
												this.options[q.id][a].splice(1, 0, parseInt(ans[a], 10));
											}
										}
										return true;
									});
								} else {
									ans = {};
								}
							}

							return (
								<Quantity
									key={q.id}
									ele={q}
									options={this.options[q.id]}
									customQAns={ans}
									handleQuantityChange={this.props.handleQuantityChange}
								/>
							);
						} else if (!q.is_available && q.question_type === TAG) {
							if (customQAns[q.id]) {
								ans = customQAns[q.id];
							} else {
								ans = {};
							}
							return (
								<ServiceTag
									key={q.id}
									ele={q}
									customQAns={ans}
									handleTagChange={this.props.handleTagChange}
								/>
							);
						} else if (!q.is_available && q.question_type === QUESTION) {
							if (customQAns[q.id]) {
								ans = customQAns[q.id];
							} else {
								ans = '';
							}
							return (
								<Questionnaire
									key={q.id}
									ele={q}
									customQAns={ans}
									handleQuestionChange={this.props.handleQuestionChange}
								/>
							);
						}
						return true;
					})}
				</div>
			);
		}
	}

	renderOnSpotCustomer = () => {
		const { isHiddenOnSpotCustomer } = this.props.NewAddBooking_UI;
		const { onSite } = this.props.NewAddBooking_CustomerInfo;

		if (!isHiddenOnSpotCustomer) {
			return (
				<div className="bookingContentRow">
					<label className="customerCheckBox">
						<input type="checkbox" checked={onSite} onChange={() => this.handleOnSite()} />
						<span className="c-checkbox" />
						現場顧客
					</label>
					<p className="text-note">若勾選，姓名欄位將自動帶入且無須填寫手機號碼</p>
				</div>
			);
		}
	};

	renderBookingResult = () => {
		const { bookingResult } = this.props.NewAddBooking_SeatCondition;

		if (typeof bookingResult === 'string') {
			return <p>{bookingResult}</p>;
		} else {
			return (
				<React.Fragment>
					{Object.keys(bookingResult).map((group, index) => {
						let tableRes = '';

						bookingResult[group].map((seat) => {
							return (tableRes += ', ' + seat);
						});

						return (
							<p key={index}>
								【{group}】: {tableRes.substr(1)}
							</p>
						);
					})}
				</React.Fragment>
			);
		}
	};

	renderDepositBlock = () => {
		const { modalType } = this.props;
		const { onSite, depositDetail } = this.props.NewAddBooking_CustomerInfo;
		const { bookingTime } = this.props.NewAddBooking_SeatCondition;
		const today = new Date();
		let bookingDateTime = '';
			bookingDateTime = new Date(bookingTime.date);
			bookingDateTime.setHours(parseInt(bookingTime.hour, 10));
			bookingDateTime.setMinutes(parseInt(bookingTime.min, 10));
		let disabled = false;

		// disabled: 1. 現場顧客 2. 預約時間小於當前 3. 後台訂金設定未啟用
		if(
			onSite || new Date(bookingDateTime) < today ||
			this.props.spg_merchant_id === '' || this.props.spg_merchant_id === null ||
			this.props.spg_hash_key === '' || this.props.spg_hash_key === null ||
			this.props.spg_hash_iv === '' || this.props.spg_hash_iv === null ||
			!this.props.credit_enabled
		) {
			disabled = true;
		}

		// 新建
		if(modalType === 'addNew' || modalType === 'customer') {
			return (
				<div className='bookingContentRow'>
					<div className='bookingDeposit'>
						<div className='bookingDeposit_switch'>
							<div className='bookingDeposit_title'>要求訂金</div>
							<Switch
								checked={depositDetail.depositMode}
								onChange={(e) => { this.handleDepositDetail(e, 'depositMode') }}
								disabled={disabled}
							/>
						</div>
						<div className='bookingDeposit_text'>開啟將發送繳納預約訂金連結給顧客。</div>
						<DepositSetting
							depositDetail={depositDetail}
							handleDepositDetail={this.handleDepositDetail}
							depositRef={depositRef}
							msgPoint={this.props.msgPoint}
						/>
					</div>
				</div>
			)
		} else {
			// 編輯
			// 非線上 且 建立時有開啟訂金功能
			if(!onSite && depositDetail.depositMode) {
				return (
					<div className='bookingContentRow'>
						<div className='bookingDeposit'>
							<div className='bookingDeposit_title'>要求訂金</div>
							<div className='bookingDeposit_paid'>{depositDetail.deposit}<span>元</span></div>
							<DepositStatus
								msgPoint={this.props.msgPoint}
								emailIsSend={this.state.emailIsSend}
								smsIsSend={this.state.smsIsSend}
								newSelected={this.props.newSelected}
								depositDetail={depositDetail}
								handleDepositNotification={this.handleDepositNotification}
							/>
						</div>
					</div>
				)
			}
		}
	};

	render() {
		const { modalType } = this.props;
		const { modalTitle } = this.props.NewAddBooking_ModalSetting;
		const {
			isHiddenTablePicker,
			isHiddenCustomerMemo,
			isHiddenAttendanceNumpad,
			isHiddenDatepicker
		} = this.props.NewAddBooking_UI;
		const {
			bookingAttend,
			tempBookingTime,
			loadingTime,
			availableTime,
			tablePickerInit,
			defaultTableGroupTxt,
			newSelected,
			seating,
			chosenTableGroup,
			availableTables,
			tables,
			filterCombinationTables
		} = this.props.NewAddBooking_SeatCondition;
		const { customerInfo, shopNote, isHiddenPhoneNumpad } = this.props.NewAddBooking_CustomerInfo;

		return (
      <Modal
        title=""
        visible
        centered
        footer={null}
        className='newAddBookingModal'
        transitionName="none"
        maskTransitionName="none"
        maskClosable={false}
      >
        <div className="bookingModal">
          <div className="newModal">
            <div className="newModal-body">
							{ this.state.isLoading && <div className='modal_loading'><LoadingOutlined /></div> }
              <div className="bookingContent">
                <div className="bookingContent-left" ref="bookingContentLeft">
                  <h2>{modalTitle}預約</h2>
                  {this.renderOnSpotCustomer()}
                  <div className="bookingContentRow">
                    <div className="bookingContentRow-title">
                      <p>*人數</p>
                    </div>
                    <div className="bookingContentRow-value">
                      <div className="keyboardWrap">
                        <input
                          ref="attendRef"
                          className="rowValutWidth"
                          type="tel"
                          value={bookingAttend}
                          onFocus={() => this.handleInputFocus('attendRef')}
                          onChange={(e) => this.handleAttendChange(e)}
                          onBlur={(e) => this.handleAttendSend(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bookingContentRow">
                    <div className="bookingContentRow-title">
                      <p>服務時間</p>
                    </div>
                    {this.renderBookingServerTime()}
                  </div>
                  <div className="bookingContentRow">
                    <div className="bookingContentRow-title">
                      <p>*預約時間</p>
                    </div>
                    <div className="bookingContentRow-value">{this.renderBookingDate()}</div>
                  </div>
                  <div className="bookingContentRow">
                    <div className="bookingContentRow-title">
                      <p>*安排結果</p>
                    </div>
                    <div className="bookingContentRow-value">
                      <div
                        className="resultDiv bookingResult"
                        onClick={() => this.handleTablePicker('open')}
                      >
                        <div
                          ref="bookingResRef"
                          style={{
                            display: 'inline-block',
                            width: '100%'
                          }}
                        >
                          {this.renderBookingResult()}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  {this.renderDepositBlock()}

                </div>
                <div className="bookingContent-right" ref="bookingContentRight">
                  <div className="bookingContentRow">
                    <div className="bookingContentRow-title">
                      <p>*手機號碼</p>
                    </div>
                    <div className="bookingContentRow-value">
                      <div className="keyboardWrap">
                        <input
                          ref="phoneRef"
                          className="phoneNumber"
                          type="tel"
                          placeholder="請輸入手機號碼"
                          value={customerInfo.phone}
                          onFocus={() => this.handleInputFocus('phoneRef')}
                          onChange={(e) => this.handleCustomerInfoChange(e, 'phone')}
                        />
                        { this.state.isBlacklist ? <div className='text_error'>此為封鎖名單號碼</div> : null }

                        {!isHiddenPhoneNumpad && (
                          <NumPad
                            ref={phoneNumPadRef}
                            phoneStyle=" phoneNumpad"
                            handleKeyin={this.handleKeyin}
                            numpadBlur={() =>
                              this.props.phoneNumpadVisible(true).then(() => {
                                $('.phoneNumber').typeahead('close');
                              })}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bookingContentRow">
                    <div className="bookingContentRow-value">
                      <div className="valueRow">
                        <div className="valueRow-left">
                          <p>*姓名</p>
                          <input
                            ref="nameRef"
                            type="text"
                            placeholder="請輸入姓名"
                            value={customerInfo.name}
                            onFocus={() => this.handleInputFocus('nameRef')}
                            onChange={(e) => this.handleCustomerInfoChange(e, 'name')}
                          />
                        </div>
                        <div className="valueRow-right">
                          <p>性別</p>
                          {this.renderGenderOption()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bookingContentRow">
                    <div className="bookingContentRow-value">
                      <div className="valueRow">
                        <div className="valueRow-left">
                          <p>email</p>
                          <input
                            ref="emailRef"
                            type="email"
                            value={customerInfo.email}
                            onFocus={() => this.handleInputFocus('emailRef')}
                            onChange={(e) => this.handleCustomerInfoChange(e, 'email')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.renderCustomerTag()}
                  {/* <div className='bookingContentRow'>
                    <div className='bookingContentRow-value'>
                      {this.renderCustomerTag()}
                    </div>
                  </div> */}
                  <div className="bookingContentRow">
                    <div className="bookingContentRow-title">
                      <p>備註</p>
                    </div>
                    <div className="bookingContentRow-value">
                      <textarea
                        value={shopNote || ''}
                        onChange={(e) => this.handleCustomerInfoChange(e, 'shopNote')}
                      />
                    </div>
                  </div>
                  {this.renderCustomeQ()}
                  {!isHiddenCustomerMemo && this.renderCustomerMemo()}
                  {this.renderCloseCustomQ()}
                </div>
              </div>
            </div>
            <div className="newModal-footer">
              <div className="btn cancelBtn" onClick={() => this.props.hideNewAddBookingModal()}>
                取消
              </div>
              <div className="btn saveBtn bookingSaveBtn" onClick={() => this.doubleCheckSubmit()}>
                儲存
              </div>
            </div>
          </div>
          {!isHiddenDatepicker && (
            <Datepicker
              loadingTime={loadingTime}
              availableTime={availableTime}
              tempBookingDate={tempBookingTime.date}
              tempBookingTimeHour={tempBookingTime.hour}
              tempBookingTimeMin={tempBookingTime.min}
              openDatepicker={this.openDatepicker}
              closeDatepicker={this.closeDatepicker}
              chooseDate={this.props.handleChooseDate}
              handleTimeClick={this.props.handleTimeClick}
              datepickerSubmit={this.datepickerSubmit}
              filterCombinationTables={filterCombinationTables}
              dayAnnouncements={this.props.announcementListReducer.dayAnnouncements}
              updateDayAnnouncements={this.props.updateDayAnnouncements}
              date={this.props.date}
            />
          )}

          {!isHiddenTablePicker && (
            <TablePicker
              tablePickerInit={tablePickerInit}
              defaultTableGroupTxt={defaultTableGroupTxt}
              newSelectedId={newSelected.id}
              bookingAttend={bookingAttend}
              seating={seating}
              chosenTableGroup={chosenTableGroup}
              availableTables={availableTables}
              tables={tables}
              handleTablePicker={this.handleTablePicker}
              tablePickerSubmit={this.tablePickerSubmit}
            />
          )}
          {!isHiddenAttendanceNumpad && (
            <AttendanceNumPad
              ref={attendanceNumpadRef}
              bookingAttend={bookingAttend}
              bookingAttendFirstClick={this.props.NewAddBooking_SeatCondition.bookingAttendFirstClick}
              checkAttendance={this.checkAttendance}
              closeAttendanceNumpad={() => this.props.handleAttendanceNumpadVisible(true)}
            />
          )}

          { this.state.showBlacklistWarning && (
            <BlacklistWarning
              modalType={modalType}
              addBookingSubmit={this.addBookingSubmit}
              cancelBlacklistWarning={() => this.setState({ showBlacklistWarning: false })}
            />
          )}
        </div>
      </Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	NewAddBooking_ModalSetting: state.NewAddBooking_ModalSetting,
	NewAddBooking_Question: state.NewAddBooking_Question,
	NewAddBooking_CustomerInfo: state.NewAddBooking_CustomerInfo,
	NewAddBooking_UI: state.NewAddBooking_UI,
	NewAddBooking_SeatCondition: state.NewAddBooking_SeatCondition,
	auth: state.auth,
	announcementListReducer: state.announcementListReducer,
  addAnnouncementReducer: state.addAnnouncementReducer
});

const mapDispatchToProps = (dispatch) => ({
	addBooking: bindActionCreators(addBooking, dispatch),
	editBooking: bindActionCreators(editBooking, dispatch),
	handleTagChange: bindActionCreators(handleTagChange, dispatch),
	handleQuestionChange: bindActionCreators(handleQuestionChange, dispatch),
	handleQuantityChange: bindActionCreators(handleQuantityChange, dispatch),
	handleInfoChange: bindActionCreators(handleInfoChange, dispatch),
	phoneTypeahead: bindActionCreators(phoneTypeahead, dispatch),
	setTypehead: bindActionCreators(setTypehead, dispatch),
	phoneNumpadVisible: bindActionCreators(phoneNumpadVisible, dispatch),
	toggleOnSite: bindActionCreators(toggleOnSite, dispatch),
	onSiteNsetTime: bindActionCreators(onSiteNsetTime, dispatch),
	attendanceNumpadVisible: bindActionCreators(attendanceNumpadVisible, dispatch),
	handleAttendanceNumpadVisible: bindActionCreators(handleAttendanceNumpadVisible, dispatch),
	bookingAttendance: bindActionCreators(bookingAttendance, dispatch),
	handleBookingAttendChange: bindActionCreators(handleBookingAttendChange, dispatch),
	sendTableTimeOpenDatepicker: bindActionCreators(sendTableTimeOpenDatepicker, dispatch),
	fetchSetting: bindActionCreators(fetchSetting, dispatch),
	handleTimeClick: bindActionCreators(handleTimeClick, dispatch),
	handleChooseDate: bindActionCreators(handleChooseDate, dispatch),
	toggleDatepicker: bindActionCreators(toggleDatepicker, dispatch),
	datepickerSubmit: bindActionCreators(datepickerSubmit, dispatch),
	toggleTablepicker: bindActionCreators(toggleTablepicker, dispatch),
	resetBookingTime: bindActionCreators(resetBookingTime, dispatch),
	tablepickerSubmit: bindActionCreators(tablepickerSubmit, dispatch),
	resetTablepicker: bindActionCreators(resetTablepicker, dispatch),
	serviceTimeChange: bindActionCreators(serviceTimeChange, dispatch),
	handleDepositDetailChange: bindActionCreators(handleDepositDetailChange, dispatch),
	handleDepositNotificationError: bindActionCreators(handleDepositNotificationError, dispatch),
	handleDepositNotificationResend: bindActionCreators(handleDepositNotificationResend, dispatch),
	getCQ: bindActionCreators(getCQ, dispatch),
	resetTableCondtion: bindActionCreators(resetTableCondtion, dispatch),
	NewAddBooking_ResetDefault: bindActionCreators(NewAddBooking_ResetDefault, dispatch),
	updateDayAnnouncements: bindActionCreators(updateDayAnnouncements, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAddBookingView);
