import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Modal, DatePicker } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import { CustomerContext } from '../../crmContext/CustomerContext';

import {
  createCrmCustomer,
  updateCrmCustomer,
  updateCrmCustomerTags,
  getCrmCustomerTags
} from '../../api/CrmCustomerApi';
import useBlockedList from '../../utils/useBlockedList';
import ConfirmBlockedCustomerModal from './ConfirmBlockedCustomerModal';

import '../crmCustomerRecord/crmCustomerRecordModal.scss';

const defaultPhonePlaceholder = "0912345678";
const errorPhonePlaceholder = "請輸入號碼";

const CrmCustomerEditorModal = ({ load, setShowCustomerDeleteModal }) => {
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
    customerInfo,
    tags,
    isSaved,
    page,
    per,
    showCancelEditModal
  } = CustomerState;
  const {
    id,
    last_name,
    gender,
    birthday,
    phone_number,
    company,
    company_id,
    company_tele,
    email,
    note,
    tags_checked,
  } = customerInfo;

  const [ data, setData] = useState({
    last_name,
    gender,
    birthday,
    phone_number,
    email,
    note,
    company,
    company_id,
    company_tele
  })
  const [ displayChecked, setDisplayChecked ] = useState(tags_checked);
  const [ displayTags, setDisplayTags ] = useState([]);

  const [ searchTag, setSearchTag ] = useState(''); // 搜尋標記

  const [ isBlocked, setIsBlocked ] = useState(false);
  const [ blockedList, setBlockedList ] = useState([]);
  const [ blockedNumberError, setBlockedNumberError ] = useState(false);
  const [ emailFormatError, setEmailFormatError ] = useState(false);
  const [ showConfirmBlockedListModal, setShowConfirmBlockedListModal ] = useState(false);

  const customerNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const emailRef = useRef(null);

  const { response } = useBlockedList();

  useEffect(() => {
    getTags();
  },[]);

  useEffect(() => {
    if(response !== null) {
      setBlockedList(response);

      if(id && response.includes(phone_number)) {
        setIsBlocked(true);
      }
    }
  }, [ response ]);

  // create tag style
  // useEffect(() => {
  //   const dropdownOption = document.getElementsByClassName('ant-select-item-option-content')[0];
  //   let allTags = displayChecked.concat(displayTags.map((tag) => tag.label));
  //   let tagIdx = allTags.indexOf(searchTag);

  //   if(dropdownOption) dropdownOption.classList.remove('create_tag');

  //   if(searchTag.length !== 0 && tagIdx === -1) {
  //     dropdownOption.classList.add('create_tag');
  //   } 
  // }, [ displayChecked, displayChecked, searchTag ]);

  const cancelPopup = () => {
    if(isSaved) {
      CustomerStateDispatch({ type: 'cancelPopup' });

      if(!id) CustomerStateDispatch({ type: 'cleanCustomerInfo' });
    } else {
      CustomerStateDispatch({ type: 'setCancelEditModal', show: true });
    }
  };

  const validateData = () => {
    const regEmail = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    const setError = (field, ref, newPlaceholder, message) => {
      error.push(field);
      ref.current.classList.add('input_error_empty');
      if (newPlaceholder) ref.current.placeholder = newPlaceholder;
      if (message) window.app.alert.setMessage(message, 'error');
    };
  
    const clearError = (field, ref, placeholder) => {
      const index = error.indexOf(field);
      if (index !== -1) error.splice(index, 1);
      ref.current.classList.remove('input_error_empty');
      if (placeholder) ref.current.placeholder = placeholder;
    };

    let error = [];

    if (data.last_name.trim() === '') {
      setError('last_name', customerNameRef, '', '姓名與手機號碼不得為空白');
    } else {
      clearError('last_name', customerNameRef, '');
    }
  
    if (data.phone_number.trim() === '') {
      setError('phone_number', phoneNumberRef, errorPhonePlaceholder, '姓名與手機號碼不得為空白');
    } else {
      clearError('phone_number', phoneNumberRef, defaultPhonePlaceholder);
    }

    if(data.email.trim() !== '' && !regEmail.test(data.email)) {
      setError('email', emailRef, '', '');
      setEmailFormatError(true);
    } else {
      clearError('email', emailRef, '');
      setEmailFormatError(false);
    }
  
    if(error.length !== 0) return;
    if(
      blockedNumberError ||
      (isBlocked && phone_number !== data.phone_number)
    ) {
      return setShowConfirmBlockedListModal(true);
    }

    submit();
  };

  const submit = async () => {
    if(id) {
      try {
        await updateCrmCustomer(id, data);
        await tagsSubmit(id, 'edit');
        CustomerStateDispatch({ type: 'cancelPopup' });
        CustomerStateDispatch({ type: 'cleanCustomerInfo' });
        CustomerStateDispatch({ type: 'setIsSaved', isSaved: true });

        load(false);
      } catch(error) {
        window.app.alert.setMessage(error.response.data.message, 'error');
      }
    } else {
      try {
        const result = await createCrmCustomer(data);
        await tagsSubmit(result.data.meta, 'create');
        CustomerStateDispatch({ type: 'cancelPopup' });
        CustomerStateDispatch({ type: 'cleanCustomerInfo' });
        CustomerStateDispatch({ type: 'setIsSaved', isSaved: true });

        load(false);
      } catch(error) {
        if(error.response.status === 422) {
          window.app.alert.setMessage('此顧客已存在於顧客列表中', 'error');
        } else {
          window.app.alert.setMessage(error.response.data.message, 'error');
        }
      }
    }
  };

  const tagsSubmit = async (id, type) => {
    let msg = type === 'create' ? '已新增顧客' : '已儲存變更';
    const checkedTags = {
      checked: displayChecked,
      id: id
    }

    try {
      await updateCrmCustomerTags(checkedTags)
        .then(res => 
          window.app.alert.setMessage(msg, 'done')
        );

      if (typeof(load) !== 'undefined') {
        load(false);
      }

      CustomerStateDispatch({ type: 'cancelPopup' });
      CustomerStateDispatch({ type: 'cleanCustomerInfo' });
      CustomerStateDispatch({ type: 'setIsSaved', isSaved: true });
    } catch(err) {
      window.app.alert.setMessage(err.response.data.message, 'error');
    }
  };

  const getTags = async () => {
    try {
      const { data } = await getCrmCustomerTags();
      let tagsOptions = [];

      data.customer_tags.map((tag) => {
        tagsOptions.push({
          label: tag.description,
          value: `${tag.id}`
        })
      })

      setDisplayTags(tagsOptions);
      CustomerStateDispatch({ type: 'getTags', tags: data.customer_tags });
    } catch(err) {
      console.log('get tags err ----------', err);
    }
  };

  const handleTagChange = useCallback((id) => {
    setDisplayChecked(prevChecked => {
      if (prevChecked.includes(id)) {
        return prevChecked.filter(tagId => tagId !== id);
      } else {
        return [...prevChecked, id];
      }
    });

    CustomerStateDispatch({ type: 'setIsSaved', isSaved: false });
  }, []);

  const renderTags = () => {
    return tags.map((tag) => {
      return (
        <label key={tag.id}>
          <input
            type="checkbox"
            value={tag.id}
            checked={displayChecked.includes(tag.id)}
            onChange={() => handleTagChange(tag.id)}
          />
          <span>{tag.description}</span>
        </label>
      )
    })
  };

  const handleInputChange = (e) => {
    const reg = /^\d+$/;
    const name = e.target.name;
    const value = e.target.value;
    const isNumericInput = value.trim().length > 0 ? reg.test(value.trim()) : true;

    let newData = _.cloneDeep(data);

    if(name === 'last_name') customerNameRef.current.classList.remove('input_error_empty');
    if(name === 'email') emailRef.current.classList.remove('input_error_empty');

    if((name === 'phone_number' || name === 'company_tele') && !isNumericInput) {
      return;
    };

    if(name === 'phone_number') {
      const isBlockedNumber = blockedList.includes(value);

      if(isBlockedNumber) {
        setBlockedNumberError(true);
        phoneNumberRef.current.classList.add('input_error_empty');
      } else {
        setBlockedNumberError(false);
        phoneNumberRef.current.classList.remove('input_error_empty');
        phoneNumberRef.current.placeholder = defaultPhonePlaceholder;
      }
    }

    newData[name] = value;
    setData(newData);
    CustomerStateDispatch({ type: 'setIsSaved', isSaved: false });
  };

  const renderDatePicker = () => {
    const disabledDate = (current) => {
      // Can not select days after today
      const time = moment().endOf('day').subtract(1, 'days');
      return current && current > time;
    };

    const handleDateChange = (date, dateString) => {
      let newData = _.cloneDeep(data);
      newData['birthday'] = dateString;
      setData(newData);
      CustomerStateDispatch({ type: 'setIsSaved', isSaved: false });
    };

    return (
      <div className="customer_datepicker">
        <DatePicker
          showToday={false}
					defaultValue={data.birthday ? moment(data.birthday) : null}
          dateFormat="YYYY-MM-DD"
          disabledDate={disabledDate}
					onChange={handleDateChange}
					placeholder="YYYY-MM-DD"
          inputReadOnly
				/>
      </div>
    )
  };

  return (
    <Modal
      title={id ? '編輯顧客資訊' : '新增顧客'}
      visible
      centered
      closable={false}
      className="crmCustomerEditorModal"
      footer={
        <div className='btn_action'>
          {
            id &&
            <button
              type="button"
              className='btn_delete'
              onClick={() => setShowCustomerDeleteModal(true)}
            >
              刪除顧客
            </button>
          }
          <div>
            <button
              type="button"
              className='btn_cancel'
              onClick={cancelPopup}
            >
              取消
            </button>
            <button
              type="button"
              className='btn_confirm'
              onClick={validateData}
            >
              儲存
            </button>
          </div>
        </div>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className='wrap'>
        <div className='customer_basic'>
          <h4>{id ? '編輯顧客資訊' : '新增顧客'}</h4>
          <div className='customer_row'>
            <div className='title'>姓名*</div>
            <input
              type="text"
              ref={customerNameRef}
              name='last_name'
              placeholder='請輸入姓名'
              value={data.last_name}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className='customer_row'>
            <div className='title'>性別</div>
            <select
              name='gender'
              value={data.gender}
              onChange={(e) => handleInputChange(e)}
              placeholder='選擇性別'
            >
              <option value="other">其他</option>
              <option value="male">男性</option>
              <option value="female">女性</option>
            </select>
          </div>
          <div className='customer_row'>
            <div className='title'>手機號碼*</div>
            <input
              type="text"
              ref={phoneNumberRef}
              name='phone_number'
              placeholder='0912345678'
              value={data.phone_number}
              onChange={(e) => handleInputChange(e)}
            />
            {blockedNumberError && <div className='text text_error'>此組號碼已被加入封鎖名單</div>}
            {isBlocked && <div className='text text_notice'>顧客號碼已被加入封鎖名單，如需解除封鎖，請至「封鎖名單」。</div>}
          </div>
          <div className='customer_row'>
            <div className='title'>E-mail</div>
            <input
              type="text"
              ref={emailRef}
              name='email'
              placeholder='abc@example.com'
              value={data.email}
              onChange={(e) => handleInputChange(e)}
            />
            {emailFormatError && <div className='text text_error'>信箱格式錯誤</div>}
          </div>
          <div className='customer_row customer_row_checkbox'>
            <div className='title'>顧客標記</div>
            {renderTags()}
          </div>
          {/* <div className='customer_row'>
            <div className='title'>快速標記</div>
            <Space
              style={{
                width: '100%',
              }}
              direction="vertical"
            >
              <Select
                mode="tags"
                optionFilterProp="label"
                className='customer_select_tags'
                style={{
                  width: '100%',
                }}
                placeholder="可自訂標記將顧客快速分類"
                notFoundContent='目前尚無任何標記'
                value={displayChecked}
                onSearch={(e) => setSearchTag(e)}
                options={displayTags}
                onChange={handleTagChange}
                dropdownClassName='customer_select_tags_dropdown'
              />
            </Space>
            <div className='text'>可選擇既有標記或快速建立標記；如需修改或刪除標記，請至「設定」。</div>
          </div> */}
          <hr />
        </div>
        <div className='customer_advanced'>
          <h5>進階選填</h5>
          <div className='customer_row'>
            <div className='title'>生日</div>
            {renderDatePicker()}
          </div>
          <div className='customer_row'>
            <div className='title'>公司名稱</div>
            <input
              type="text"
              name='company'
              placeholder='MENU店+'
              onChange={(e) => handleInputChange(e)}
              value={data.company || ''}
            />
          </div>
          <div className='customer_row'>
            <div className='title'>公司電話</div>
            <input
              type="text"
              name='company_tele'
              placeholder='0212345678'
              onChange={(e) => handleInputChange(e)}
              value={data.company_tele || ''}
            />
          </div>
          <div className='customer_row'>
            <div className='title'>統編</div>
            <input
              type="text"
              name='company_id'
              placeholder='66668888'
              onChange={(e) => handleInputChange(e)}
              value={data.company_id || ''}
            />
          </div>
          <div className='customer_row'>
            <div className='title'>顧客筆記</div>
            <textarea
              name='note'
              placeholder='您可以輸入顧客的用餐喜好或是個人特徵，例如：海鮮過敏/需兒童椅'
              onChange={(e) => handleInputChange(e)}
              value={data.note}
            />
          </div>
        </div>
      </div>

      { showCancelEditModal && <CustomerEditCancelPopup /> }
      { showConfirmBlockedListModal &&
        <ConfirmBlockedCustomerModal
          type={id ? 'edit' : 'create'}
          isBlocked={isBlocked}
          setShowConfirmBlockedListModal={setShowConfirmBlockedListModal}
          submit={submit}
        />
      }
    </Modal>
  );
};

const CustomerEditCancelPopup = () => {
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const { customerInfo } = CustomerState;
  const { id } = customerInfo;

  const continueEdit = () => {
    CustomerStateDispatch({ type: 'setCancelEditModal', show: false });
  };

  const handleSubmit = () => {
    CustomerStateDispatch({ type: 'setIsSaved', isSaved: true });
    CustomerStateDispatch({ type: 'cancelPopup' });
  };

  return (
    <Modal
      title={`放棄${id ? '編輯' : '新增'}嗎？`}
      visible
      centered
      closable={false}
      className="customerEditCancelPopup"
      footer={
        <React.Fragment>
          <button
            className='btn_cancel'
            onClick={continueEdit}
          >
            取消
          </button>
          <button
            className='btn_confirm'
            onClick={handleSubmit}
          >
            確認
          </button>
        </React.Fragment>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      {
        id 
        ? '退出將不會保留已變更的資料。確定退出嗎？' 
        : '退出將不會保留顧客資料。確定退出嗎？'
      }
      
    </Modal>
  );
};

export default CrmCustomerEditorModal;