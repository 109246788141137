import React from "react";
import _ from 'lodash';
import $ from "jquery";
import { Link } from "react-router";
import moment from "moment";

import RecordModalCreate from "./RecordModalCreate";
import RecordModalDelete from "./RecordModalDelete";
import RecordModalEdit from "./RecordModalEdit";
import RecordModalEditInfo from "./RecordModalEditInfo_new";

import RecordInfo from "./RecordInfo";
import RecordList from "./RecordList";
import CallList from "./CallList";
import BookingList from "./BookingList";

import NewAddBookingView from "../AddNewBooking/NewAddBooking";
import BookingSystemApi from "../booking_settings/BookingSystemApi";

// import { handleError } from "../../libs/handler";
// var createReactClass = require('create-react-class');
const QUESTION = "question";

export default class CustomerRecordBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isHiddenNewBookingAddView: true,
			data: {
				records: [],
				calls: [],
				bookings: [],
				customer_tags: []
			},
			displayData: {
				records: [],
				calls: [],
				bookings: [],
				customer_tags: []
			},
			edit: {},
			date: new moment().format("YYYY-MM-DD"),
			modalType: "customer",
			newSelected:{},
			customerInfo:{},
			diningTime: 120,
			customQ: [],
			customQAns: {},
			tempCustomQAns: {},
			msgPoint: 0,
			smsNotificationSettings: {},
			emailNotificationSettings: {},
			spg_hash_iv: null,
			spg_hash_key: null,
			spg_merchant_id: null,
			credit_enabled: '',
			checked: []
		};

		this.load = this.load.bind(this);
	}
	componentDidMount() {
		this.API = new BookingSystemApi();

		this.load();
		this.getCustomQuestion();
		this.getBookingSetting();
		this.getCreditEnabled();

		window.app.booking = this.refs.booking;
	}
	getParentPath = () => {
		return window.location.pathname
			.split("/")
			.slice(0, -1)
			.join("/");
	};
	load() {
		let { newSelected, customerInfo, checked } = this.state;
		this.API.crm_getCustomerData(window.location.pathname)
			.then(data => {
				newSelected["id"] = data.customer.id;
				newSelected["bookingDate"] = new moment().format("YYYY-MM-DD");
				newSelected["time"] = "";
				newSelected["dining_time"] = 120;

				customerInfo["phone"] = data.customer.phone_number;
				customerInfo["name"] = data.customer.full_name;
				customerInfo["gender"] = data.customer.gender;
				customerInfo["email"] = data.customer.email;

				let newChecked = data.customer.customer_tags.map(function(tag) {
					return tag.id;
				});

				this.setState({ data: data.customer, displayData: data.customer, newSelected, customerInfo, checked: newChecked });
			})
			.catch(error => {
				console.log("error----", error);
			});
	}

	getCustomQuestion = () => {
		let { customQAns } = this.state;
		this.API.getQuestions().then(data => {
			data.map(q => {
				if (q.question_type === QUESTION) {
					customQAns[q.id] = "";
				} else {
					customQAns[q.id] = {};
				}
				return true;
			});
			this.setState({
				customQ: data,
				customQAns,
				tempCustomQAns: JSON.stringify(customQAns)
			});
		});
	};

	getBookingSetting = () => {
		try {
			this.API.getBookingSetting().then(data => {
				this.setState({
					msgPoint: data.shop.msg_point,
					smsNotificationSettings: data.sms_notification_settings,
					emailNotificationSettings: data.email_notification_settings,
					spg_hash_iv: data.spg_hash_iv,
					spg_hash_key: data.spg_hash_key,
					spg_merchant_id: data.spg_merchant_id
				});
			})
		} catch(err) {
			console.log('customer get booking setting err ----------', err);
		}
	};

	getCreditEnabled = () => {
		$.ajax({url: window.domain + '/dashboard/booking_settings/spg_data',
      method: 'GET',
      xhrFields: { withCredentials: true }})
    .done(function(data){
      this.setState({ credit_enabled: data.credit_enabled });
    }.bind(this))
    .fail(function(xhr){
      console.log('get credit enabled err ---------')
    });
	};

	handleSubmit = (data, type, url, callback) => {
		//debugger

		$.ajax({
			url: window.domain + url,
			dataType: "json",
			type: type,
			data: data,
			xhrFields: { withCredentials: true },
			success: function(d) {
				if (typeof callback === "function") {
					callback();
				}
				if (type !== "DELETE") {
					this.load();
					window.app.alert.setMessage(d.message, "done");
				} else {
					window.app.alert.setMessage(d.message, "tip");
				}
			}.bind(this),
			error: function(xhr) {
				console.log("xhr-----", xhr)
				window.app.alert.setMessage(xhr.responseJSON.message, "error");
			}
		});
	};

	handleInfoChange = e => {
		let newData = _.cloneDeep(this.state.data);
		newData[e.target.name] = e.target.value;
		this.setState({ data: newData });
	};
	// handleEditChange = e => {
	// 	var new_state = this.state.edit;
	// 	new_state[e.target.name] = e.target.value;
	// 	this.setState({ edit: new_state });
	// };

	cancelEdit = () => {
		let new_state = _.cloneDeep(this.state);
		let displayTags = new_state["displayData"]['customer_tags'];
		new_state["data"] = new_state["displayData"];
		new_state["checked"] = displayTags.map((tag) => { return tag.id });
		this.setState(new_state);
	};

	handleEditClick = record => {
		this.setState({ edit: record });
	};

	hideNewAddBookingModal = () => {
		let { tempCustomQAns } = this.state;
		let bodyEle = document.getElementsByTagName("body");
		bodyEle[0].classList.remove("modal-open");
		this.setState({
			isHiddenNewBookingAddView: true,
			customQAns: JSON.parse(tempCustomQAns)
		});
	};

	handleBookingClick = () => {
		let bodyEle = document.getElementsByTagName("body");
		bodyEle[0].classList.add("modal-open");

		this.setState({
			isHiddenNewBookingAddView: false,
		});
	};

	handleTagInputChange = (e) => {
		let checked = $.extend([], this.state.checked);
		let id = parseInt(e.target.value, 10);
		let index = checked.indexOf(id);
		index === -1 ? checked.push(id) : checked.splice(index, 1);
		this.setState({ checked: checked });
	};

	updateData=()=>{
		this.load()
	}

	render() {
		return (
			<div>
				<div className='container container-shadow'>
					<div className='func-title '>
						<Link to={this.getParentPath()}>
							<img
								src={require("../../images/component/f_crm.svg")}
								alt=''
							/>
						</Link>
						<h1>
							<Link to={this.getParentPath()}>顧客關係管理 </Link>
							<span className='breadcrumbs-slash'>/</span>
							{this.state.data.full_name}來店記錄
						</h1>
					</div>
					<div className='toolBar'>
						<ul>
							<li>
								<a
									className='btn btn-color-white btn-style3'
									data-toggle='modal'
									data-target='.newrecorditem'>
									{" "}
									新增紀錄
								</a>
							</li>
							<li>
								<a
									className='btn btn-color-white btn-style3'
									data-toggle='modal'
									data-target='.editmodal'
									>
									{" "}
									編輯顧客資訊
								</a>
							</li>
							<li>
								<a
									className='btn btn-color-white btn-style3'
									data-toggle='modal'
									data-target='.deleteitem'>
									{" "}
									刪除此客戶
								</a>
							</li>
							<li>
								<a
									className='btn btn-color-white btn-style3'
									onClick={this.handleBookingClick}>
									{" "}
									新增預約
								</a>
							</li>
						</ul>
					</div>
					<RecordInfo data={this.state.displayData} />
					<RecordList
						data={this.state.data.records}
						onEditClick={this.handleEditClick}
					/>
					<CallList data={this.state.data.calls} />
					<BookingList
						data={this.state.data.bookings}
						submitCallback={this.load}
					/>
				</div>
				<RecordModalCreate
					onRecordSubmit={this.handleSubmit}
					data={this.state.data}
					url={this.props.record_url}
				/>

				<RecordModalEditInfo
					onInfoSubmit={this.handleSubmit}
					onInfoChange={this.handleInfoChange}
					cancelEdit={this.cancelEdit}
					handleTagInputChange={this.handleTagInputChange}
					data={this.state.data}
					checked={this.state.checked}
					url={window.location.pathname}
				/>

				<RecordModalEdit
					onRecordSubmit={this.handleSubmit}
					data={this.state.edit}
					url={this.props.record_url}
				/>

				<RecordModalDelete
					onRecordSubmit={this.handleSubmit}
					data={this.state.data}
					url={window.location.url}
				/>

				{!this.state.isHiddenNewBookingAddView && (
					<NewAddBookingView
						hideNewAddBookingModal={this.hideNewAddBookingModal}
						updateData={this.updateData}
						modalType={this.state.modalType}
						date={this.state.date}
						diningTime={this.state.diningTime}
						newSelected={this.state.newSelected}
						customerInfo={this.state.customerInfo}
						customQAns={this.state.customQAns}
						customQ={this.state.customQ}
						msgPoint={this.state.msgPoint}
						smsNotificationSettings={this.state.smsNotificationSettings}
						emailNotificationSettings={this.state.emailNotificationSettings}
						spg_hash_iv={this.state.spg_hash_iv}
						spg_hash_key={this.state.spg_hash_key}
						spg_merchant_id={this.state.spg_merchant_id}
						credit_enabled={this.state.credit_enabled}
					/>
				)}

				{/* <NewAddBookingModal ref='booking' data={this.state.data} submitCallback={this.load} /> */}
			</div>
		);
	}
}

CustomerRecordBox.defaultProps = {
	record_url: "/dashboard/records.json",
	tag_url: "/dashboard/customer_tags.json"
};
