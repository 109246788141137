import axios from 'axios';
import { config } from '../../../utils/config';

const crmCustomerAxios = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

/* 顧客頁面資料 */
export const getCrmCustomerPage = (params) => crmCustomerAxios.get('/dashboard/customers', { params });

/* 顧客數 */
export const getCrmCustomerCount = () => crmCustomerAxios.get('/dashboard/customers/count.json');

/* 新增顧客 */
export const createCrmCustomer = (data) => crmCustomerAxios.post('/dashboard/customers', data);

/* 編輯顧客 */
export const updateCrmCustomer = (id, data) => crmCustomerAxios.put(`/dashboard/customers/${id}`, data);

/* 刪除顧客 */
export const deleteCrmCustomer = (id) => crmCustomerAxios.delete(`/dashboard/customers/${id}`);

/* update tags */
export const updateCrmCustomerTags = (data) => crmCustomerAxios.post('/dashboard/customer_tags.json', data);

/* get tags */
export const getCrmCustomerTags = (data) => crmCustomerAxios.get('/dashboard/customer_tags.json', data);

/* search customer */
export const searchCustomer = (params) => crmCustomerAxios.get('/dashboard/customers/query', { params });

/* get customer data */
export const getCustomerData = (id) => crmCustomerAxios.get(`/dashboard/customers/${id}`);

/* filter customers by bookings and queue records */
export const filterCustomers = (params) => crmCustomerAxios.post('/dashboard/customers/filter', params);

/* export customers csv */ 
export const getCustomersCSV = () => crmCustomerAxios.get('/dashboard/customers.csv');

/* export filter customers csv */ 
export const filterCustomersCSV = (params) => crmCustomerAxios.post('/dashboard/customers/filter.csv', params, {
	responseType: 'blob',
});