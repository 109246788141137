import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Modal } from 'antd';

const Table = ({ table, removeTable, editTable, groups, tables }) => {
	const [ tableName, setTableName ] = useState(table.table_number);
	const [ editTableName, setEditTableName ] = useState(table.table_number === '' ? true : false);
	const tableRef = useRef();

	useEffect(
		() => {
			if (editTableName && tableRef.current) tableRef.current.focus();
		},
		[ editTableName ]
	);

	const edit = () => {
		setEditTableName(true);
	};

	const done = () => {
		const flattenTableNumbers = _.flatten(
			groups.map(group => 
				group.table_objs?.filter(obj => obj.available === true).map(obj => obj.table_number) || []
			)
		);
		const tablesNumber = tables.filter((t) => t.available === true && t.idx !== table.idx).map((table) => table.table_number);
		const concatTableNumbers = _.concat(flattenTableNumbers, tablesNumber);
		const findIndex = concatTableNumbers.indexOf(tableName.trim());

		if (findIndex !== -1) {
			return window.app.alert.setMessage('桌子名稱重複', 'error');
		}

		if (tableName.trim() === '') {
			return window.app.alert.setMessage('桌子名稱不可空白', 'error');
		}

		setEditTableName(false);
		editTable(tableName.trim());
	};

	const removeConfrim = () => {
		Modal.confirm({
			className: 'bookingSettingConfirmModal',
			zIndex: 1035,
			title: '確定刪除？',
			content: '刪除後將會影響現有預約，請問是否確認刪除',
			okText: '確定',
			cancelText: '取消',
			onOk() {
				removeTable();
			}
		});
	};

	//編輯
	if (editTableName) {
		return (
			<div className="tableInput">
				<div>
					<input
						ref={tableRef}
						type="text"
						autoFocus={true}
						value={tableName}
						onChange={(e) => setTableName(e.target.value.trim())}
					/>
				</div>

				<button className="addBtn" onClick={() => done()}>
					<i className="fa fa-check" aria-hidden="true" />
				</button>
				<button
					className="cancelBtn"
					onClick={() => {
						setEditTableName(false);
						setTableName(table.table_number);
					}}
				>
					<i className="fa fa-times" aria-hidden="true" />
				</button>
			</div>
		);
	}

	return (
		<div className="tableInfo">
			<div onClick={edit}>{tableName}</div>
			<button onClick={() => removeConfrim()}>
				<i className="fa fa-trash-o" aria-hidden="true" />
			</button>
		</div>
	);
};

export default Table;
