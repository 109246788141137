import { createContext } from 'react';

import _ from 'lodash';
import moment from 'moment';

// allCustomers: 所有顧客數
// selectCustomer: 選擇顧客 (小於等於 allCustomers)
// status: 訊息狀態 (finished 完成發送, real_time 立即發送, scheduled 排程, draft 存成草稿)
// filterJson: 篩選條件 Json 格式 -> params
// customers: 所有欲發送顧客的 id
// menuTalkContent: includes text / img / coupon
// uploadImg: 所有 menuTalk 圖片檔案
// insertCoupons: 已嵌入的優惠券, 檢查不可重複嵌入
// buyMsgPointsType: 購買點數狀態 (notEnough 點數不足, buyPoints 儲值)

export const messageAddInitState = {
  isSaved: true,
  isLoading: false,
  points: 0,
  allCustomers: null,
  selectCustomer: 0,
  allFollowers: null,
  selectFollower: 0,
	messageSetting: {
    id: null,
    status: '',
    filterConditions: {
      dateAfter: null,
      dateBefore: null,
      bookingMin: '', // 有效預約筆數
      bookingMax: '',
      exchangeMin: '', // 優惠券核銷筆數
      exchangeMax: '',
      fetchMin: '', // 優惠券領取筆數
      fetchMax: '',
      gender: '',
      tags: []
    },
    filterJson: {},
		title: '',
		content: '',
    filterTag: '',
		scheduleTime: null,
    customers: [],
    followers: [],
    cursorPosition: 0,
    oneSmsPoint: 0,
    estimatedMsgPoints: 0,
    menuTalkContent: [],
    uploadImg: [],
    couponInfo: {}
	},
  originCouponLists: [],
  couponLists: [], // status: enable
  shopName: '',
  insertMenuCoupon: 0,
  insertCoupons: [],
  buyMsgPointsType: '',
  showSendObjectFilterModal: false,
  showInsertMenuCouponModal: false,
  showSendTestMessageModal: false,
  showBuyMsgPointsModal: false,
  showPreviewPopup: false,
  showReturnCheckModal: false,
  showExceedLimitModal: false,
  error: [],
  errorCoupon: []
};

export const MessageAddStateContext = createContext(messageAddInitState);

export function MessageAddReducer(state, action) {
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case 'getEnableCoupons':
			return getMenuCoupon(state, action);
    case 'getShopName':
      return Object.assign({}, newState, {
        shopName: action.shopName
      });
    case 'getPoints':
      return Object.assign({}, newState, {
        points: action.points
      });
    case 'getAllCustomersLength':
      return Object.assign({}, newState, {
        allCustomers: action.allCustomers
      });
    case 'getAllFollowersLength':
      return Object.assign({}, newState, {
        allFollowers: action.allFollowers
      });
    case 'setIsSaved':
      return Object.assign({}, newState, {
        isSaved: action.isSaved
      });
    case 'setIsLoading':
      return Object.assign({}, newState, {
        isLoading: action.isLoading
      });
    case 'setSelectCustomer':
      return Object.assign({}, newState, {
        isSaved: false,
        selectCustomer: action.selectCustomer,
        messageSetting: {
          ...newState.messageSetting,
          customers: action.customers
        }
      });
    case 'setInitFilterCustomer':
      return Object.assign({}, newState, {
        selectCustomer: action.selectCustomer,
        messageSetting: {
          ...newState.messageSetting,
          customers: action.customers
        }
      });
    case 'setSelectFollower':
      return Object.assign({}, newState, {
        isSaved: false,
        selectFollower: action.selectFollower,
        messageSetting: {
          ...newState.messageSetting,
          followers: action.followers
        }
      });
    case 'setInitFilterFollower':
      return Object.assign({}, newState, {
        selectFollower: action.selectFollower,
        messageSetting: {
          ...newState.messageSetting,
          followers: action.followers
        }
      });
    case 'setMessageTitle':
      return Object.assign({}, newState, {
        isSaved: false,
        messageSetting: {
          ...newState.messageSetting,
          title: action.title
        }
      });
    case 'setMessageContent':
      return Object.assign({}, newState, {
        isSaved: false,
        messageSetting: {
          ...newState.messageSetting,
          content: action.content
        }
      });
    case 'setSmsContentCursorPosition':
      return Object.assign({}, newState, {
        messageSetting: {
          ...newState.messageSetting,
          cursorPosition: action.cursorPosition
        }
      });
    case 'setMessageStatus':
      return Object.assign({}, newState, {
        isSaved: false,
        messageSetting: {
          ...newState.messageSetting,
          status: action.status
        }
      });
    case 'setScheduleTime':
      return Object.assign({}, newState, {
        isSaved: false,
        messageSetting: {
          ...newState.messageSetting,
          scheduleTime: action.scheduleTime
        }
      });
    case 'setEstimatedMsgPoints':
      return Object.assign({}, newState, {
        messageSetting: {
          ...newState.messageSetting,
          oneSmsPoint: action.oneSmsPoint,
          estimatedMsgPoints: action.estimatedMsgPoints
        }
      });
    case 'setFilterConditions':
      return setFilterConditions(state, action);
    case 'setFilterDateRanges':
      return setFilterDateRanges(state, action);
    case 'setFilterGender':
      return setFilterGender(state, action);
    case 'setFilterMenuOtherGender':
      return setFilterMenuOtherGender(state, action);
    case 'setFilterTag':
      return setFilterTag(state, action);
    case 'setObjectFilterTag':
      return Object.assign({}, newState, {
        messageSetting: {
          ...newState.messageSetting,
          filterTag: action.filterTag
        }
      });
    case 'cleanFilterConditions':
      return Object.assign({}, newState, {
        messageSetting: {
          ...newState.messageSetting,
          filterJson: {},
          filterTag: '',
          filterConditions: {
            dateAfter: null,
            dateBefore: null,
            bookingMin: '',
            bookingMax: '',
            exchangeMin: '',
            exchangeMax: '',
            fetchMin: '',
            fetchMax: '',
            gender: '',
            tags: []
          }
        }
      });
    case 'setInsertMenuCoupon':
      return Object.assign({}, newState, {
        insertMenuCoupon: action.insertMenuCoupon,
        messageSetting: {
          ...newState.messageSetting,
          couponInfo: action.couponInfo
        }
      });
    case 'setMenuTalkContent':
      return Object.assign({}, newState, {
        isSaved: false,
        messageSetting: {
          ...newState.messageSetting,
          menuTalkContent: action.menuTalkContent
        }
      });
    case 'setMenuTalkUploadImg':
      return Object.assign({}, newState, {
        messageSetting: {
          ...newState.messageSetting,
          uploadImg: action.uploadImg
        }
      });
    case 'setMenuTalkInsertCoupons':
      return Object.assign({}, newState, {
        insertCoupons: action.insertCoupons,
      });
    case 'showSendObjectFilterModal':
      return Object.assign({}, newState, {
        showSendObjectFilterModal: true
      });
    case 'showInsertMenuCouponModal':
      return Object.assign({}, newState, {
        showInsertMenuCouponModal: true
      });
    case 'showSendTestMessageModal':
      return Object.assign({}, newState, {
        showSendTestMessageModal: true
      });
    case 'showBuyMsgPointsModal':
      return Object.assign({}, newState, {
        showBuyMsgPointsModal: true,
        buyMsgPointsType: action.buyMsgPointsType
      });
    case 'showPreviewPopup':
      return Object.assign({}, newState, {
        showPreviewPopup: true
      });
    case 'showReturnCheckModal':
      return Object.assign({}, newState, {
        showReturnCheckModal: true
      });
    case 'showExceedLimitModal':
      return Object.assign({}, newState, {
        showExceedLimitModal: true
      });
    case 'cancelPopup': // 共用關閉 popup & 可清空資料
			return Object.assign({}, newState, {
				showSendObjectFilterModal: false,
        showInsertMenuCouponModal: false,
        showSendTestMessageModal: false,
        showBuyMsgPointsModal: false,
        showPreviewPopup: false,
        showReturnCheckModal: false,
        showExceedLimitModal: false,
        buyMsgPointsType: ''
      });
    case 'checkMessage':
      return Object.assign({}, newState, {
        error: action.error,
        errorCoupon: action.errorCoupon
      });
    case 'setEditorSetting':
      return setEditorSetting(state, action);
    case 'cleanMessageSetting':
      return Object.assign({}, newState, {
        isSaved: true,
        selectCustomer: 0,
        selectFollower: 0,
        error: [],
        errorCoupon: [],
        insertCoupons: [],
        messageSetting: {
          id: null,
          status: '',
          filterConditions: {
            dateAfter: null,
            dateBefore: null,
            bookingMin: '',
            bookingMax: '',
            exchangeMin: '',
            exchangeMax: '',
            fetchMin: '',
            fetchMax: '',
            gender: '',
            tags: []
          },
          filterJson: {},
          title: '',
          content: '',
          filterTag: '',
          scheduleTime: null,
          customers: [],
          followers: [],
          oneSmsPoint: 0,
          estimatedMsgPoints: 0,
          menuTalkContent: [],
          uploadImg: [],
          couponInfo: {}
        }
      });
    default:
      return state;
  }
}

function getMenuCoupon(state, action) {
	let newState = _.cloneDeep(state);
	const { list } = action;

  newState['originCouponLists'] = list;
	newState['couponLists'] = list.filter((list) => list.status === 'enable');

	return newState;
}

function setEditorSetting(state, action) {
	const newState = _.cloneDeep(state);
  const { messageSetting } = newState;
	const { msgSettingState } = action;
	const {
    type,
    id,
    status,
    title,
    content,
    contents,
    filter_tag,
    schedule_time,
    filter_conditions
  } = msgSettingState;
  const {
    date_after,
    date_before,
    booking_min,
    booking_max,
    exchange_max,
    exchange_min,
    fetch_max,
    fetch_min,
    gender,
    customer_tags
  } = filter_conditions;

  const initFilterJson = {};

  if(type === 'sms') {
    if(date_after !== null && date_after !== '') {
      initFilterJson.date_after = date_after;
    };
  
    if(date_before !== null && date_before !== '') {
      initFilterJson.date_before = date_before;
    };
  
    if(booking_min !== null && booking_min !== '') {
      initFilterJson.booking_min = booking_min;
    };
  
    if(booking_max !== null && booking_max !== '') {
      initFilterJson.booking_max = booking_max;
    };
  
    if(customer_tags !== null) {
      initFilterJson.customer_tags =  customer_tags.toString();
    };

    if(gender !== null && gender !== '') {
      initFilterJson.gender = gender;
    };
  } else {
    if(exchange_min !== null && exchange_min !== '') {
      initFilterJson.minExchangeCount = exchange_min;
    };
  
    if(exchange_max !== null && exchange_max !== '') {
      initFilterJson.maxExchangeCount = exchange_max;
    };
  
    if(fetch_min !== null && fetch_min !== '') {
      initFilterJson.minFetchCount = fetch_min;
    };
  
    if(fetch_max !== null && fetch_max !== '') {
      initFilterJson.maxFetchCount = fetch_max;
    };

    if(gender !== null && gender !== '') {
      if(gender === 'male') {
        initFilterJson.isMale = true;
      } else if(gender === 'female') {
        initFilterJson.isMale = false;
      } else {
        initFilterJson.isMale = null;
      }
    };
  }

	messageSetting['id'] = id;
	messageSetting['title'] = title;
	messageSetting['content'] = content ? content : '';
	messageSetting['menuTalkContent'] = contents ? JSON.parse(contents) : [];
	messageSetting['filterTag'] = filter_tag;
	messageSetting['status'] = status;
	messageSetting['scheduleTime'] = (Boolean(schedule_time) && moment(schedule_time)) || '';
  messageSetting['filterConditions']['dateAfter'] = date_after ? date_after : null;
  messageSetting['filterConditions']['dateBefore'] = date_before ? date_before : null;
  messageSetting['filterConditions']['bookingMin'] = booking_min ? booking_min : '';
  messageSetting['filterConditions']['bookingMax'] = booking_max ? booking_max : '';
  messageSetting['filterConditions']['exchangeMin'] = exchange_min ? exchange_min : '';
  messageSetting['filterConditions']['exchangeMax'] = exchange_max ? exchange_max : '';
  messageSetting['filterConditions']['fetchMin'] = fetch_min ? fetch_min : '';
  messageSetting['filterConditions']['fetchMax'] = fetch_max ? fetch_max : '';
  messageSetting['filterConditions']['gender'] = gender ? gender : '';
  messageSetting['filterConditions']['tags'] = customer_tags ? customer_tags : [];
  messageSetting['filterJson'] = initFilterJson;

  newState['isSaved'] = true;
	newState['messageSetting'] = messageSetting;

	return newState;
}

function setFilterConditions(state, action) {
	const newState = _.cloneDeep(state);
  const { messageSetting } = newState;
  const { filterConditions, filterJson } = messageSetting;
	const { filterType, value } = action;

  const newFilterConditions = filterConditions;
  const newFilterJson = filterJson;

  if(filterType === 'bookingMin') {
    newFilterConditions['bookingMin'] = value;

    if(value !== '0' && value !== '') {
      newFilterJson.booking_min = value;
    } else {
      delete newFilterJson.booking_min;
    }
  } else if(filterType === 'bookingMax') {
    newFilterConditions['bookingMax'] = value;

    if(value !== '0' && value !== '') {
      newFilterJson.booking_max = value;
    } else {
      delete newFilterJson.booking_max;
    }
  } else if(filterType === 'exchangeMin') {
    newFilterConditions['exchangeMin'] = value;

    if(value !== '0' && value !== '') {
      newFilterJson.minExchangeCount = value;
    } else {
      delete newFilterJson.minExchangeCount;
    }
  } else if(filterType === 'exchangeMax') {
    newFilterConditions['exchangeMax'] = value;

    if(value !== '0' && value !== '') {
      newFilterJson.maxExchangeCount = value;
    } else {
      delete newFilterJson.maxExchangeCount;
    }
  } else if(filterType === 'fetchMin') {
    newFilterConditions['fetchMin'] = value;

    if(value !== '0' && value !== '') {
      newFilterJson.minFetchCount = value;
    } else {
      delete newFilterJson.minFetchCount;
    }
  } else if(filterType === 'fetchMax') {
    newFilterConditions['fetchMax'] = value;

    if(value !== '0' && value !== '') {
      newFilterJson.maxFetchCount = value;
    } else {
      delete newFilterJson.maxFetchCount;
    }
  }

  newState['isSaved'] = false;
	newState['messageSetting']['filterConditions'] = newFilterConditions;
  newState['messageSetting']['filterJson'] = newFilterJson;

	return newState;
}

function setFilterDateRanges(state, action) {
	const newState = _.cloneDeep(state);
  const { messageSetting } = newState;
  const { filterConditions, filterJson } = messageSetting;
	const { dateAfter, dateBefore } = action;

  const newFilterConditions = filterConditions;
  const newFilterJson = filterJson;

  if(dateAfter === '19700101' || dateAfter === null) {
    newFilterConditions['dateAfter'] = null;
    delete newFilterJson.date_after;
  } else if(dateAfter !== null) {
    newFilterConditions['dateAfter'] = dateAfter;
    newFilterJson.date_after = dateAfter;
  }

  if(dateBefore === '19700101' || dateBefore === null) {
    newFilterConditions['dateBefore'] = null;
    delete newFilterJson.date_before;
  } else if(dateBefore !== null) {
    newFilterConditions['dateBefore'] = dateBefore;
    newFilterJson.date_before = dateBefore;
  }

  newState['isSaved'] = false;
	newState['messageSetting']['filterConditions'] = newFilterConditions;
  newState['messageSetting']['filterJson'] = newFilterJson;

	return newState;
}

function setFilterGender(state, action) {
	const newState = _.cloneDeep(state);
  const { messageSetting } = newState;
  const { filterConditions, filterJson } = messageSetting;
	const { gender, editType } = action;

  const newFilterConditions = filterConditions;
  const newFilterJson = filterJson;

  if(editType === 'sms') {
    if(gender === '') {
      newFilterConditions['gender'] = '';
      delete newFilterJson.gender;
    } else {
      newFilterConditions['gender'] = gender;
      newFilterJson.gender = gender;
    }
  } else {
    if(gender === '') {
      newFilterConditions['gender'] = '';
      delete newFilterJson.isMale;
    } else {
      if(gender === 'female') {
        newFilterConditions['gender'] = gender;
        newFilterJson.isMale = false;
      } else if(gender === 'male') {
        newFilterConditions['gender'] = gender;
        newFilterJson.isMale = true;
      } else {
        newFilterConditions['gender'] = gender;
        newFilterJson.isMale = null;
      }
    }
  }

  newState['isSaved'] = false;
	newState['messageSetting']['filterConditions'] = newFilterConditions;
  newState['messageSetting']['filterJson'] = newFilterJson;

	return newState;
}

function setFilterMenuOtherGender(state, action) {
  const newState = _.cloneDeep(state);
	const { followers } = action;

  let newFollowers = [];

  for(let i = 0; i< followers.length; i++) {
    if(!followers[i].hasOwnProperty('isMale')) {
      newFollowers.push(followers[i].followerId)
    }
  }

	newState['messageSetting']['followers'] = newFollowers;
	newState['selectFollower'] = newFollowers.length;
  return newState;
}

function setFilterTag(state, action) {
	const newState = _.cloneDeep(state);
  const { messageSetting } = newState;
  const { filterJson } = messageSetting;
	const { tags } = action;

  const newFilterJson = filterJson;
  
  if(tags.length !== 0) {
    newFilterJson.customer_tags = tags.toString();
  } else {
    delete newFilterJson.customer_tags;
  }

  newState['isSaved'] = false;
  newState['messageSetting']['filterConditions']['tags'] = tags;
  newState['messageSetting']['filterJson'] = newFilterJson;

	return newState;
}