import React, { useCallback, useEffect, useContext }  from 'react';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import { getMenuUserInfo } from '../shops/menu_user/MenuUserApi';

import Toolbar from './crmMessage/CrmMessageToolbar';
import Tab from './crmMessage/CrmMessageTab';
import MessageEmpty from './crmMessage/CrmMessageEmpty';
import Message from './crmMessage/Message';

import SelectMessagePath from './crmMessagePopup/SelectMessagePathPopup';
import MenuAppIntroModal from '../shops/menu_user/MenuAppIntro';
import MessageNotification from './crmMessage/CrmMessageNotification';
import EditDateTimeModal from './crmMessagePopup/EditDateTimeModal';
import DeletePopup from './crmMessagePopup/DeleteMessagePopup';
import PreviewSmsPopup from './crmMessagePopup/PreviewMessagePopup';
import PreviewMenuTalkPopup from './crmMessageAdd/crmMessageAddPopup/PreviewMenuTalkPopup';

import { MessageContext } from './crmContext/MessageContext';
import { MessageAddStateContext } from './crmContext/MessageAddContext';

import { getCrmMessagesSms, filterCustomers } from './api/CrmMessageSmsApi';
import { getCrmMessagesMenuTalk, filterFollowers } from './api/CrmMessageMenuTalkApi';

import MenuCouponApi from '../menu_coupon/MenuCouponApi';
const menuCouponAPI = new MenuCouponApi();

const CrmMessageBox = () => {
  const auth = useSelector((state) => state.auth);
	const { name } = auth.shop;
  const { MessageAddDispatch } = useContext(MessageAddStateContext);
	const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const {
    loading,
    messageFilter,
    messageSort,
    messageStatus,
    messages,
    previewInfo,
    showNotification,
    showMenuAppIntroModal,
    showEditDateTimeModal,
    showDeletePopup
  } = MessageState;


  useEffect(() => {
    getCrmMessageList();
    getBindStatus();
    getShop();
    getAllCustomersLength();
    getAllFollowersLength();
    getCouponList();
  }, []);

  useEffect(() => {
		const msgContainer = document.getElementsByClassName('crmMessageBox_container')[0];

		if(messages.length === 0) {
      msgContainer.classList.add('crmMessageBox_full');
    } else {
      msgContainer.classList.remove('crmMessageBox_full');
    }

	}, [ messages ]);

  const getCrmMessageList = async () => {
    try {
      MessageStateDispatch({ type: 'setLoading', loading: true });

      const smsMessagesResult = await getCrmMessagesSms(),
        smsMessages = smsMessagesResult.data.crm_sms_body;
      const menuTalkMessagesResult = await getCrmMessagesMenuTalk(),
        menuTalkMessages = menuTalkMessagesResult.data.crm_menu_body;

      for(let i = 0; i < smsMessages.length; i++) {
        smsMessages[i].type = 'sms';
      };

      for(let j = 0; j < menuTalkMessages.length; j++) {
        menuTalkMessages[j].type = 'menuTalk';
      };

      const allMessages = smsMessages.concat(menuTalkMessages);
      MessageStateDispatch({ type: 'setInitMessages', originMessages: allMessages });
      MessageStateDispatch({ type: 'setStatusMessagesNum' });
      MessageStateDispatch({ type: 'setMessageSort', messageSort });
      MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
    } catch (err) {
      console.log(err)
    }
  };

  const getBindStatus = async () => {
		try {
			const { data } = await getMenuUserInfo();

			if(data.menu_user.bind_menu) {
        MessageStateDispatch({ type: 'getMenuUserBindStatus', menuUserBindStatus: true, menuUserAvatar: data.menu_user.thumbnailURL });
			} else {
        MessageStateDispatch({ type: 'getMenuUserBindStatus', menuUserBindStatus: false, menuUserAvatar: '' });
			}
		} catch(err) {
			console.log(err);
      MessageStateDispatch({ type: 'getMenuUserBindStatus', menuUserBindStatus: false, menuUserAvatar: '' });
		}
	};

  const getShop = async () => {
    try {
      const data = await menuCouponAPI.getShops();
      MessageAddDispatch({ type: 'getShopName', shopName: data.shop.display_name });
    } catch (err) {
      console.log('get shop name err-----', err);
    }
  };

  const getAllCustomersLength = async () => {
    try {
      const { data } = await filterCustomers();
      MessageAddDispatch({ type: 'getAllCustomersLength', allCustomers: data.customers.length })
    } catch(err) {
      console.log('get all customers length err', err);
    }
  };

  const getAllFollowersLength = async () => {
    try {
      const { data } = await filterFollowers();
      MessageAddDispatch({ type: 'getAllFollowersLength', allFollowers: data.followers.length })
    } catch(err) {
      console.log('get all followers length err', err);
    }
  }

  // get enable coupons
	const getCouponList = useCallback(
		async () => {
			try {
				const list = await menuCouponAPI.getCoupon(name);
				MessageAddDispatch({ type: 'getEnableCoupons', list });
			} catch (err) {
				console.log('get coupon list err-----', err);
			}
		},
		[ name, MessageAddDispatch ]
	);

  const cancelModal = useCallback(() => {
    MessageStateDispatch({ type: 'closeMenuAppIntroModal' });
	}, [ MessageStateDispatch ]);

  const renderMessage = useCallback(() => {
    if(loading) {
      return (
        <div className="crmMessageLoading">
          <LoadingOutlined style={{ fontSize: 30 }} />
          <p>Loading ...</p>
        </div>
      );
    };

    if(messages.length === 0) {
      return <MessageEmpty messageStatus={messageStatus} />
    } else {
      return (
        <div className="crmMessageList">
          { messages.map((message, index) => {
            return (
              <Message
                key={index}
                message={message}
              />
            )
          })}
        </div>
      )
    }},
    [
      messageFilter,
      messageSort,
      messageStatus,
      messages,
      loading
    ]
	);

  return (
    <div className="crmMessageBox_container">
      <Toolbar />
      <Tab />
      { renderMessage() }

      <SelectMessagePath />
      <MenuAppIntroModal
        visible={showMenuAppIntroModal}
        handleMenuAppIntroModal={cancelModal}
        source='crm_msg_menuTalk'
      />
      { showNotification && <MessageNotification /> }
      { showEditDateTimeModal && <EditDateTimeModal /> }
      { showDeletePopup && <DeletePopup /> }
      { previewInfo.type === 'sms' && <PreviewSmsPopup /> }
      { previewInfo.type === 'menuTalk' && <PreviewMenuTalkPopup /> }
    </div>
  )
}

export default CrmMessageBox;
