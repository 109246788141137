import axios from 'axios';
import { config } from '../../../utils/config';

const crmSettingsAxios = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

/* 取得封鎖名單 */
export const getCrmBlockedList = () => crmSettingsAxios.get('/dashboard/booking_settings/blacklists');

/* 編輯封鎖名單 */
export const updateCrmBlockedList = (data) => crmSettingsAxios.post('/dashboard/booking_settings/blacklists', data);