import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import { Modal } from 'antd';

import { MessageContext } from '../crmContext/MessageContext';

const PreviewMessagePopup = () => {
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { previewInfo } = MessageState;

  const renderSentDateTime = () => {
    const dateTime = moment(new Date(previewInfo.schedule_time)).format('YYYY年MM月DD日 HH:mm:ss');
    return dateTime;
  };

  const renderCounts = () => {
    if(
      previewInfo.status === 'finished') {
      return previewInfo.sending_counts;
    } else if(
      previewInfo.status === 'real_time' ||
      previewInfo.status === 'scheduled'
    ) {
      return previewInfo.estimated_sending_counts;
    }
  };

  const renderPoints = () => {
    if( previewInfo.status === 'finished') {
      return previewInfo.consumed_msg_points;
    } else if(
      previewInfo.status === 'real_time' ||
      previewInfo.status === 'scheduled'
    ) {
      return previewInfo.estimated_msg_points;
    }
  };

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: 'cancelPopup' });
	}, [ MessageStateDispatch ]);

  return (
    <Modal
      title={previewInfo.title}
      visible
      centered
      className="previewMessagePopup"
      onCancel={cancelPopup}
      footer={null}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className="content">{ previewInfo.content }</div>
      <hr />
      <div className="block">
        {
          previewInfo.schedule_time &&
          <div className="text">傳送日期：{ renderSentDateTime() }</div>
        }
        { previewInfo.filter_tag !== '' && 
          <div className="text">傳送對象：{ previewInfo.filter_tag }</div>
        }
        <div className="tags">
          <div className="tag type">簡訊</div>
          <div className="tag">{ renderCounts() }則</div>
          <div className="tag">{ renderPoints() }點</div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewMessagePopup;