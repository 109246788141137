import React from 'react'
import $ from 'jquery'
import { browserHistory } from 'react-router'
import { config } from '../../utils/config'
var createReactClass = require('create-react-class');
export default createReactClass({
  load() {
    $.ajax({
      url: config.domain + '/dashboard/customers/query',
      dataType: 'json',
      data: { query: this.state.query },
      xhrFields: { withCredentials: true },
      success: function(data) {
        this.setState({results: data.customers}, function() {
          if (this.state.query === '') {
            this.setState({results: []});
          }
        })
      }.bind(this)
    });
  },
  handleInputChange(e) {
    this.setState({query: e.target.value}, this.load)
  },
  handleClick(id) {
    browserHistory.push(window.location.pathname + '/' + id);
  },
  getInitialState() {
    return {
      query: '',
      results: []
    }
  },
  cleanResults() {
    setTimeout(function() {
      this.setState({results: []});
    }.bind(this), 10);
  },
  renderResults() {
    if (this.state.results.length > 0) {
      var results = this.state.results.map(function(result) {
        return (
          <div className="suggestion tt-suggestion tt-selectable" 
               key={result.id}
               onClick={this.handleClick.bind(this, result.id)}>
            <strong>{result.full_name}</strong> - {result.phone_number}
          </div>
        );
      }.bind(this));
      return (
        <div className="tt-menu tt-open" style={{position: 'absolute', top: '100%', left: 0, zIndex: 100, display: 'block'}}>
          <div className="tt-dataset tt-dataset-best-pictures">
            { results }
          </div>
        </div>
      )
    }
  },
  render() {
    return (
      <div className="toolBar">
        <button type="button" className="btn-style3 btn-color-white" id="addMember">
          <span className="glyphicon glyphicon-plus" /> 新增顧客
        </button>
        <a href={window.domain + "/dashboard/customers.csv"} download>
          <button type="button" className="btn-style3 btn-color-white">
            <span className="glyphicon glyphicon-export" /> 輸出資料
          </button>
        </a>
        <div id="typeahead">
          <span className="twitter-typeahead" 
                style={{position: 'relative', 
                display: 'inline-block'}}>
            <input className="form-control tt-hint" 
                   type="text" 
                   readOnly 
                   autoComplete="off" 
                   spellCheck="false" 
                   tabIndex={-1} 
                   dir="ltr" 
                   style={{position: 'absolute', 
                           top: 0, 
                           left: 0, 
                           borderColor: 'transparent', 
                           boxShadow: 'none', 
                           opacity: 1, 
                           background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)'}} />
            <input id="searchMember" 
                   className="form-control tt-input" 
                   type="text" 
                   name='query'
                   value={this.state.query}
                   placeholder="搜尋顧客姓名或電話" 
                   autoComplete="off" 
                   dir="auto" 
                   style={{position: 'relative', 
                           verticalAlign: 'top', 
                           backgroundColor: 'transparent'}}
                   onChange={this.handleInputChange}
                   onFocus={this.load} />
            {this.renderResults()}
          </span>
        </div>
      </div>
    );
  }
});
