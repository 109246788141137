import React from 'react'
import moment from "moment";
import { browserHistory } from "react-router";


// import EditBookingModal from '../bookings/EditBookingModal'

export default class BookingList extends React.Component{
  toList=(date, activeCode, status)=>{
    browserHistory.push({
        pathname: "/dashboard/bookings",
        query: {
        date: date,
        mode: "list"
      },
      state:{
        activeCode: activeCode
      }
    })
    
    setTimeout(() => {
      document.getElementById(status).click();
    }, 300);
  }

  render(){
    let component = this;
    var bookings = this.props.data.map(function(booking) {
      let date = moment(new Date(booking.date)).format("YYYY-MM-DD");
      let activeColde = booking.active_code;
      let status = booking.status

      return (
        <tr key={booking.id}>
          <td style={{width: '200px'}}>{booking.booked_at}</td>
          <td>
            <div>
              <span> {'訂 ' + booking.date + ' ' + booking.time + ' ' + booking.attendance + '位'}</span>
              <span style={checkStyle} onClick={()=>component.toList(date, activeColde, status)}>查看</span>
            </div>
          </td>
          <td>{booking.source_t}</td>
          <td>{booking.memo}</td>
          <td>
            {/* <button className="btn btn-color-white btn-style3"
                    role="presentation"
                    onClick={()=>this.handleClick(booking)} >
              <i className="fa fa-pencil" /> 編輯
            </button> */}
          </td>
        </tr>
      )
    });
    return(
      <div className="menu-section">
        <div className="menu-panel">
          <table className="table table-hover table-style">
            <thead>
              <tr>
                <th colSpan={5}>預約</th>
              </tr>
            </thead>
            <tbody>
              {bookings}
            </tbody>
          </table>
        </div>
        {/* <EditBookingModal ref='booking' submitCallback={this.props.submitCallback} /> */}
      </div>
    )
  }
}

const checkStyle={
  backgroundColor: '#eeeeee',
  fontSize: 11,
  padding: '3px 10px',
  display: 'inline-block',
  borderRadius: 3,
  marginLeft: 10,
  color: '#333',
  cursor: 'pointer'
}


