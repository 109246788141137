import React, { useEffect, useContext, useCallback }  from 'react';

import { MessageContext } from '../crmContext/MessageContext';
import { MessageAddStateContext } from '../crmContext/MessageAddContext';

import MessageAddHeader from './CrmMessageHeader';
import MessageSendObjectSection from './MessageSendObjectSection';
import MessageInfoSection from './MessageInfoSection';
import MessagePublishSection from './MessagePublishSection';
import MessageNextStep from './MessageNextStep';

import SendObjectFilterModal from './crmMessageAddPopup/SendObjectFilterModal';
import InsertMenuCouponModal from './crmMessageAddPopup/InsertMenuCouponModal';
import SendTestMessageModal from './crmMessageAddPopup/SendTestMessageModal';
import BuyMsgPointsModal from './crmMessageAddPopup/BuyMsgPointsModal';
import PreviewMenuTalkPopup from './crmMessageAddPopup/PreviewMenuTalkPopup';
import ReturnCheckModal from './crmMessageAddPopup/ReturnCheckModal';
import ExceedTimesModal from './crmMessageAddPopup/ExceedTimesModal';

import Loading from './CrmSubmitLoading';

import './crmMessageAdd.scss';

import BookingSystemApi from '../../booking_settings/BookingSystemApi';
import { filterCustomers } from '../api/CrmMessageSmsApi';
import { filterFollowers } from '../api/CrmMessageMenuTalkApi';

const bookingSettingAPI = new BookingSystemApi();

const CrmMessageAdd = () => {
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const {
    isLoading,
    messageSetting,
    showSendObjectFilterModal,
    showInsertMenuCouponModal,
    showSendTestMessageModal,
    showBuyMsgPointsModal,
    showPreviewPopup,
    showReturnCheckModal,
    showExceedLimitModal
  } = MessageAddState;
  const { filterJson, filterConditions } = messageSetting;
  const { gender } = filterConditions;

  const getMsgPoint = useCallback(() => {
    const getBookingSetting = async () => {
      try {
        const data = await bookingSettingAPI.getBookingSetting();
        MessageAddDispatch({ type: 'getPoints', points: data.shop.msg_point });
      } catch (err) {
        console.log('get booking setting err-----', err);
      }
    };
  
    getBookingSetting();
  }, [ MessageAddDispatch ]);

  const initFilterConditions = async () => {
    if(editType === 'sms') {
      try {
        const customersId = [];
        const { data } = await filterCustomers(filterJson);
  
        data.customers.map((customer) => {
          return customersId.push(customer.id);
        })
  
        MessageAddDispatch({
          type: 'setInitFilterCustomer',
          selectCustomer: data.customers.length,
          customers: customersId
        })
      } catch(err) {
        console.log('filter customers err ----------', err);
      }
    } else if(editType === 'menuTalk') {
      try {
        const followersId = [];
        const { data } = await filterFollowers(filterJson);
  
        data.followers.map((follower) => {
          return followersId.push(follower.followerId);
        })

        MessageAddDispatch({
          type: 'setInitFilterFollower',
          selectFollower: data.followers.length,
          followers: followersId
        });

        if(gender === 'other') {
          MessageAddDispatch({
            type: 'setFilterMenuOtherGender',
            followers: data.followers,
          });
        }
      } catch(err) {
        console.log('filter followers err ----------', err);
      }
    }
  };

  useEffect(() => {
    const aside = document.getElementsByClassName('crm_aside')[0];
    const container = document.getElementsByClassName('crm_main')[0];

    aside.style.display = 'none';
    container.style.width = '100%';
    container.style.left = '0';
  }, []);

  useEffect(() => {
    getMsgPoint();
	},[ getMsgPoint ]);

  useEffect(() => {
    if(JSON.stringify(filterJson) !== "{}") {
      initFilterConditions();
    }
  }, []);

  return (
    <div className="crmMessageAdd">
      <MessageAddHeader />

      <div className="crmMessageAdd_main">
        <MessageSendObjectSection>
					<MessageSendObjectSection.Title />
					<MessageSendObjectSection.Progress />
					<MessageSendObjectSection.Filter />
				</MessageSendObjectSection>

        <MessageInfoSection>
					<MessageInfoSection.Title />
					<MessageInfoSection.MessageTitle />
					<MessageInfoSection.MessageContent />
				</MessageInfoSection>

        <MessagePublishSection>
					<MessagePublishSection.Title />
					<MessagePublishSection.Schedule />
				</MessagePublishSection>

        <MessageNextStep>
					<MessageNextStep.Button />
				</MessageNextStep>
      </div>

      { isLoading && <Loading /> }
      { showSendObjectFilterModal && <SendObjectFilterModal /> }
      { showInsertMenuCouponModal && <InsertMenuCouponModal /> }
      { showSendTestMessageModal && <SendTestMessageModal /> }
      { showBuyMsgPointsModal && <BuyMsgPointsModal /> }
      { showPreviewPopup && <PreviewMenuTalkPopup /> }
      { showReturnCheckModal && <ReturnCheckModal /> }
      { showExceedLimitModal && <ExceedTimesModal /> }
    </div>
  )
}

export default CrmMessageAdd;
