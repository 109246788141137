import React, { useContext, useState } from 'react';
import { browserHistory } from 'react-router';
import { Modal } from 'antd';
import ReactCodeInput from 'react-code-input'
import { UserBindContext } from '../MenuUserBindContext/MenuUser_bindContext';
import useInterval from '../../../bookings/utils/useInterval'

import { createMenuUser, resendVerificationCode, bindMenuUser } from '../MenuUserApi'

import '../menuUser.scss';

export default () => {
  const [ verificationCode, serVerificationCode ] = useState('');
  const [ resendCode, setResendCode ] = useState(true);
  const [ resendInterval, setResendInterval ] = useState(null);
  const [ verificationError, setVerificationError ] = useState(false);
  const [ verificationExpiredTime, setVerificationExpiredTime ] = useState(null);
  const [ redirectPage ] = useState(browserHistory.getCurrentLocation().state ? browserHistory.getCurrentLocation().state.source : '');

  const { UserBindState, UserBindStateDispatch } = useContext(UserBindContext);
  const { step, userInfo, sendVerificationCode } = UserBindState;

  const handleCancel = () => {
    UserBindStateDispatch({ type: 'closeUserVerification', step: 'search' });
    UserBindStateDispatch({ type: 'toggleUserVerificationCode', sendVerificationCode: false });
  };

  const handleSendVerificationCode = async () => {
    try {
      const { data } = await createMenuUser({"menu_user_id" : userInfo.menuUserId});

      setVerificationExpiredTime(data.menu_user.verification_expired_time)
      UserBindStateDispatch({ type: 'toggleUserVerificationCode', sendVerificationCode: true });
    } catch(err) {
      console.log(err)
    }
  };

  const handleVerificationCode = (e) => {
    const reg = /^\d+$/;
		const regTest = reg.test(e);

		if (e !== '' && !regTest) {
			return window.app.alert.setMessage('請輸入數字', 'error');
		} else {
      serVerificationCode(e);
    }
  };

  const submitBindUser = () => {
    let getTime = new Date();
    let expiredTime = new Date(verificationExpiredTime);

    if(getTime < expiredTime) {
      bindMenuUser({
        "menu_user_id": userInfo.menuUserId,
        "verification_code": verificationCode
      }).then(res => {
        setVerificationError(false);
        browserHistory.push({
          pathname: '/dashboard/setting/shops'
        });

        if (redirectPage === 'crm_msg_menuTalk') {
          browserHistory.push({
            pathname: '/dashboard/messages'
          });
        }
      }).catch(err => {
        setVerificationError(true);
      })
    } else {
      setVerificationError(true);
    }
  };

  useInterval(() => {
    let updateTime = resendInterval;
    if(resendInterval > 0) {
      updateTime --;
      setResendInterval(updateTime)
    } else if (resendInterval === 0) {
      setResendInterval(null);
    };
  }, 1000);
  
  const renderMenuAccount = () => {
    const resendIntervalMin = Math.floor(parseInt(resendInterval, 10) / 60);
    const resendIntervalSec = Math.floor(parseInt(resendInterval, 10) % 60);

    const resend = async () => {
      try {
        const { data } = await resendVerificationCode();
        const interval = 120; // 2 mins

        setVerificationExpiredTime(data.menu_user.verification_expired_time);
        setResendCode(false);
        setResendInterval(interval);
  
        setTimeout(() => {
          setResendCode(true)
        }, interval * 1000);
      } catch(err) {
        console.log('resendVerificationCode ------- error', err);
      }
    };

    const renderSecs = () => {
      if(resendIntervalSec < 10 || resendIntervalSec === 0) {
        return <span>{ '0'+ resendIntervalSec }</span>;
      } else if(!isNaN(resendIntervalSec)) {
        return <span>{ resendIntervalSec }</span>;
      }
    };

    const renderResendVerificationCode = () => {
      if (resendCode) {
        return (
          <div className="resend_text" onClick={resend}>重新傳送驗證碼</div>
        )
      } else {
        return (
          <div className="resend_text_disable">
            重新傳送驗證碼（{resendIntervalMin}:{renderSecs()})
          </div>
        )
      }
    };

    if(!sendVerificationCode) {
      return (
        <div className="checkAccountInfo">
          <div className="account_info">
            <div className="account_info_logo">
              {
                userInfo.thumbnailURL ?
                <img src={userInfo.thumbnailURL} alt="" /> : ''
              }
            </div>
            <div className="account_info_name">
              { userInfo.displayName }
            </div>
          </div>
          <div className="checkAccountInfo_text">是您的帳號嗎？</div>
          <div className="checkAccountInfo_intro">
            <div>
              若以上帳號為您本人持有，
              <br />
              我們將發送驗證碼至您的 <strong>MENU 美食誌App</strong>。
            </div>
            <div>
              <br />
              請先於行動裝置登入 <strong>MENU 美食誌App</strong>
              <br />
              以接收驗證碼。
            </div>
          </div>
          <div className="checkAccountInfo_btn">
            <button
              className="btn_send"
              onClick={handleSendVerificationCode}
            >
              是，發送驗證碼
            </button>
            <button
              className="btn_reject"
              onClick={handleCancel}
            >
              不是，重新輸入帳號
            </button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="verifyAccount">
          <div className="account_info">
            <div className="account_info_logo account_info_logo_s">
              { userInfo.thumbnailURL ?
                <img src={userInfo.thumbnailURL} alt="" /> : ''
              }
            </div>
            <div className="account_info_name account_info_name_s">
              { userInfo.displayName }
            </div>
          </div>
          <div className="verifyAccount_text">
            已發送驗證碼至您的 <strong>MENU 美食誌App</strong>。
            <br />
            請在 15 分鐘內輸入驗證碼完成用戶綁定。
          </div>

          <ReactCodeInput
            type='text'
            fields={4}
            onChange={(e) => handleVerificationCode(e)}
          />

          { verificationError && <div className="validationCode_error">驗證碼逾時或無效。</div> }

          <div className="verifyAccount_btn">
            <button 
              onClick={submitBindUser}
              style={verificationCode.length < 4 ? { backgroundColor: '#c8c8c8' } : { backgroundColor: '#3FBA88' }}
              disabled={verificationCode.length < 4}
            >
              綁定帳號
            </button>
            { renderResendVerificationCode() }
          </div>
        </div>
      )
    }
  };

  return (
		<Modal
			destroyOnClose
			centered
			visible={ step === 'userVerification' }
			wrapClassName="menuAccountValidationModal"
      title="綁定美食誌帳號"
      footer={null}
      onCancel={handleCancel}
		>
			<div className="menuAccountValidationModalBody">
        { renderMenuAccount() }
      </div>
		</Modal>
	);
};
