import { createContext } from 'react';
import _ from 'lodash';

// customerStatus(tab): customers 顧客, followers 粉絲
// customerSort: latest 從新到舊, oldest 從舊到新

export const customerState = {
	loading: true,
  isSaved: true,
  followers : null,
	searchCustomerMode: false,
  searchCustomerText: '',
  searchCustomers: [],
  pageCustomers: [],
  showCustomers: [],
  page: 1,
  per: 10,
  page_num: 0,
  count: 0,
  customerStatus: 'customers',
  customerSort: 'latest',
  customerInfo: {
    id: null,
    last_name: '',
    birthday: '',
    gender: 'other',
    phone_number: '',
    tele_number: '',
    company: '',
    company_id: null,
    company_tele: '',
    email: '',
    note: '',
    tags_checked: [],
    customer_tags_str: '',
    originalBookings: [],
    bookings: [],
    bookingStatus: 'all',
    bookingStatusNum: [ 0, 0, 0, 0, 0 ],
    user_create: '',
    user_created_at: null,
    user_update: '',
    user_updated_at: null
  },
  filterCustomerMode: false,
  filterCustomers: [],
  listPage: 1,
  listCount: null,
  customerFilter: {
    bookingRecord: {
      bookingDate: { // 紀錄時間
        fixed: '',
        startDate: '',
        endDate: ''
      },
      bookingCount: { // 預約次數
        min: '',
        max: '',
      },
      validCount: { // 有效預約次數
        min: '',
        max: '',
      },
      cancelCount: { // 取消預約次數
        min: '',
        max: '',
      },
      noShowCount: { // 未到店次數
        min: '',
        max: '',
      }
    },
    queueRecord: {
      queueDate: {
        fixed: '',
        startDate: '',
        endDate: ''
      },
      queueCount: { // 排隊次數
        min: '',
        max: '',
      },
      finishCount: { // 報到次數
        min: '',
        max: '',
      }
    },
    customerInfo: {
      gender: [], // 顧客性別: male, female, other
      birthday: { // 顧客生日
        year: '',
        month: '',
        date: ''
      },
      tags: [], // 顧客標記
      type: [], // 顧客身份: verified, blocked
    },
  },
  customerFilterNum: 0,
  filterCustomersParams: {},
  shopName: '',
  shopLogo: '',
  menuUserBindStatus: false,
	menuUserAvatar: '',
  tags: [],
  showAddCustomerModal: false,
  showCancelEditModal: false,
  showCustomerRecordModal: false,
  showDeleteCustomerModal: false,
  showCustomerFilterBar: false
};

export const CustomerContext = createContext(customerState);

export function CustomerReducer(state, action) {
  const newState = _.cloneDeep(state);

  switch (action.type) {
		case 'setLoading':
			return Object.assign({}, newState, {
				loading: action.loading
			});
    case 'setIsSaved':
			return Object.assign({}, newState, {
				isSaved: action.isSaved
			});
    case 'getMenuUserBindStatus':
			return Object.assign({}, newState, {
				menuUserBindStatus: action.menuUserBindStatus,
				menuUserAvatar: action.menuUserAvatar
			});
    case 'getShopName':
      return Object.assign({}, newState, {
        shopName: action.shopName,
        shopLogo: action.shopLogo
      });
    case 'getFollowersLength':
      return Object.assign({}, newState, {
        followers: action.followers
      });
    case 'setLoadCustomers':
			return setLoadCustomers(newState, action);
    case 'setListPage':
			return Object.assign({}, newState, {
				page: action.page
			});
    case 'setListPageSize':
			return Object.assign({}, newState, {
				per: action.per
			});
    case 'setCustomerCount':
			return Object.assign({}, newState, {
				count: action.count
			});
		case 'setCustomerStatus':
			return Object.assign({}, newState, {
				customerStatus: action.customerStatus
			});
		case 'setCustomerSort':
			return setCustomerSort(newState, action);
    case 'getTags':
      return Object.assign({}, newState, {
        tags: action.tags
      });
    case 'setCustomerInfo':
      return Object.assign({}, newState, {
        customerInfo: {
          ...newState.customerInfo,
          id: action.id,
          last_name: action.last_name,
          birthday: action.birthday,
          gender: action.gender,
          phone_number: action.phone_number,
          tele_number: action.tele_number,
          company: action.company,
          company_id: action.company_id,
          company_tele: action.company_tele,
          email: action.email,
          note: action.note,
          tags_checked: action.tags_checked
        }
      });
    case 'switchBookingStatus':
			return switchBookingStatus(newState, action);
    case 'setSearchCustomerText':
			return Object.assign({}, newState, {
				searchCustomerText: action.searchCustomerText
			});
    case 'setSearchCustomerResult':
			return Object.assign({}, newState, {
				searchCustomers: action.searchCustomers,
        searchCustomerMode: action.searchCustomerMode,
        listPage: action.listPage,
        listCount: action.listCount
			});
    case 'setFilterCustomerResult':
			return Object.assign({}, newState, {
				filterCustomers: action.filterCustomers,
        filterCustomerMode: action.filterCustomerMode,
        listPage: action.listPage,
        listCount: action.listCount
			});
    case 'setAddCustomerModal':
			return Object.assign({}, newState, {
				showAddCustomerModal: action.show
			});
    case 'setCancelEditModal':
			return Object.assign({}, newState, {
				showCancelEditModal: action.show
			});
    case 'setDeleteCustomerModal':
			return Object.assign({}, newState, {
				showDeleteCustomerModal: action.show
			});
    case 'setCustomerFilterBar':
      return Object.assign({}, newState, {
        showCustomerFilterBar: action.show
      })
    case 'setCustomerFilter':
      return handleCustomerFilter(newState, action);
    case 'resetCustomerFilter':
      return Object.assign({}, newState, {
        customerFilter: {
          bookingRecord: {
            bookingDate: {
              fixed: '',
              startDate: '',
              endDate: ''
            },
            bookingCount: {
              min: '',
              max: '',
            },
            validCount: {
              min: '',
              max: '',
            },
            cancelCount: {
              min: '',
              max: '',
            },
            noShowCount: {
              min: '',
              max: '',
            }
          },
          queueRecord: {
            queueDate: {
              fixed: '',
              startDate: '',
              endDate: ''
            },
            queueCount: {
              min: '',
              max: '',
            },
            finishCount: {
              min: '',
              max: '',
            }
          },
          customerInfo: {
            gender: [],
            birthday: {
              year: '',
              month: '',
              date: ''
            },
            tags: [],
            type: [],
          },
        },
        customerFilterNum: 0,
        filterCustomers: [],
        filterCustomerMode: false,
        filterCustomersParams: {},
        listPage: 1,
        listCount: null
      });
    case 'setCustomerFilterNum':
      return Object.assign({}, newState, {
        customerFilterNum: action.customerFilterNum
      })
    case 'setFilterCustomersParams':
      return Object.assign({}, newState, {
        filterCustomersParams: action.filterCustomersParams
      })
    case 'setPreviewCustomerId':
      return Object.assign({}, newState, {
        customerInfo: {
          ...newState.customerInfo,
          id: action.id
        },
        showCustomerRecordModal: action.show
      });
    case 'setPreviewCustomerRecord':
			return previewCustomerRecord(newState, action);
    case 'cleanCustomerInfo':
      return Object.assign({}, newState, {
        customerInfo: {
          id: null,
          last_name: '',
          gender: 'other',
          birthday: '',
          phone_number: '',
          tele_number: '',
          company: '',
          company_id: null,
          company_tele: '',
          email: '',
          note: '',
          tags_checked: [],
          customer_tags_str: '',
          originalBookings: [],
          bookings: [],
          bookingStatus: 'all',
          bookingStatusNum: [ 0, 0, 0, 0, 0 ],
          user_create: '',
          user_created_at: null,
          user_update: '',
          user_updated_at: null
        }
      });
    case 'setBookingsStatusNum':
			return setBookingsStatusNum(newState, action);
    case 'cancelPopup':
			return Object.assign({}, newState, {
				showAddCustomerModal: false,
        showCancelEditModal: false,
        showCustomerRecordModal: false,
        showDeleteCustomerModal: false
			});
    default:
      return state;
  }
}

function setLoadCustomers(state, action) {
	const newState = _.cloneDeep(state);
	const { pageCustomers, page_num } = action;

	newState['loading'] = false;
	newState['pageCustomers'] = pageCustomers;
	newState['page_num'] = page_num;

	return newState;
}

function setCustomerSort(state, action) {
	const newState = _.cloneDeep(state);
	const {
    pageCustomers,
    searchCustomerText,
    searchCustomerMode,
    searchCustomers
  } = newState;
  const { customerSort } = action;

  const isSearching = () => {
    return searchCustomerMode && searchCustomerText.length > 0;
  };

  let customerLists = isSearching() ? searchCustomers : pageCustomers;

	if(customerSort === 'latest') {
		customerLists.sort((a, b) => {
      // a.XXX_time & b.XXX_time 待更改
      // 依照建立時間 user_created_at
			return new Date(b.schedule_time) - new Date(a.schedule_time);
		})
	} else if(customerSort === 'oldest') {
		customerLists.sort((a, b) => {
			return new Date(a.schedule_time) - new Date(b.schedule_time);
		})
	}

	newState['customerSort'] = customerSort;
	isSearching() ? 
    newState['searchCustomers'] = customerLists : newState['pageCustomers'] = customerLists;

	return newState;
}

function previewCustomerRecord(state, action) {
	const newState = _.cloneDeep(state);
  const { customerInfo } = action;
  const {
    id,
    last_name,
    gender_display,
    phone_number,
    tele_number,
    email,
    note,
    customer_tags,
    bookings,
    user_create,
    user_created_at,
    user_update,
    user_updated_at
  } = customerInfo;

  let newGender = '';
  let newChecked = [];
  let newTagsStr = []

  if(gender_display === '女') {
    newGender = 'female';
  } else if(gender_display === '男') {
    newGender = 'male';
  } else {
    newGender = 'other';
  }

  for(let i = 0; i < customer_tags.length; i ++) {
    newChecked.push(customer_tags[i].id);
    newTagsStr.push(customer_tags[i].description);
  }

	newState['customerInfo']['id'] = id;
	newState['customerInfo']['last_name'] = last_name;
	newState['customerInfo']['gender'] = newGender;
	newState['customerInfo']['phone_number'] = phone_number;
	newState['customerInfo']['tele_number'] = tele_number;
	newState['customerInfo']['email'] = email;
	newState['customerInfo']['note'] = note;
	newState['customerInfo']['checked'] = newChecked;
  newState['customerInfo']['customer_tags_str']= newTagsStr;
	newState['customerInfo']['originalBookings'] = bookings;
	newState['customerInfo']['bookings'] = bookings;
	newState['customerInfo']['user_create'] = user_create;
	newState['customerInfo']['user_created_at'] = user_created_at;
	newState['customerInfo']['user_update'] = user_update;
	newState['customerInfo']['user_updated_at'] = user_updated_at;

	return newState;
}

function switchBookingStatus(state, action) {
	const newState = _.cloneDeep(state);
  const { customerInfo } = newState;
  const { originalBookings } = customerInfo;
  const { bookingStatus } = action;

  let newBookings = [];

  if(bookingStatus === 'all') {
    newBookings = originalBookings;
  } else if(bookingStatus === 'online') {
		newBookings = originalBookings.filter((booking) => {
			return booking.source === 'online';
		});
  } else if(bookingStatus === 'offline') {
		newBookings = originalBookings.filter((booking) => {
			return booking.source === 'offline';
		});
  } else if(bookingStatus === 'cancel') {
		newBookings = originalBookings.filter((booking) => {
			return booking.status === 'cancel';
		});
  } else if(bookingStatus === 'no_show') {
		newBookings = originalBookings.filter((booking) => {
			return booking.status === 'no_show';
		});
  }

	newState['customerInfo']['bookings'] = newBookings;
	newState['customerInfo']['bookingStatus'] = bookingStatus;

	return newState;
}

function setBookingsStatusNum(state, action) {
	const newState = _.cloneDeep(state);
  const { customerInfo } = newState;
  const { originalBookings } = customerInfo;

  let newBookingsStatusNum = [];

  let online_num = originalBookings.filter((booking) => {
    return booking.source === 'online';
  });

  let offline_num = originalBookings.filter((booking) => {
    return booking.source === 'offline';
  });

  let cancel_num = originalBookings.filter((booking) => {
    return booking.status === 'cancel';
  });

  let noShow_num = originalBookings.filter((booking) => {
    return booking.status === 'no_show';
  });

  newBookingsStatusNum = [
    originalBookings.length,
    online_num.length,
    offline_num.length,
    noShow_num.length,
    cancel_num.length
  ]

	newState['customerInfo']['bookingStatusNum'] = newBookingsStatusNum;

	return newState;
}

function handleCustomerFilter(state, action) {
	const newState = _.cloneDeep(state);
  const { name, value } = action;

  newState['customerFilter'][name] = value;

  return newState;
}