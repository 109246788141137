import React from 'react';

const num = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear', '0', 'delete' ];

const QueueAttendanceNumPad = React.forwardRef(({ handleKeyin, numpadBlur }, ref) => {
	return (
		<div ref={ref} tabIndex="0" onBlur={() => numpadBlur()} className="QueueNumpadWrap QueueAttendanceNumPad">
			<div className="QueueNumpad">
				{num.map((number) => {
					if (number === 'delete') {
						return (
							<div key={number} className="numpad-delete" onClick={() => handleKeyin(number)}>
								<img
									className="numPad_del"
									src={require('../../../images/delete_black_icon.svg')}
									alt="delete"
								/>
							</div>
						);
					} else if (number === 'clear') {
						return (
							<div key={number} className="numpad-clear" onClick={() => handleKeyin(number)}>
								X
							</div>
						);
					} else {
						return (
							<div key={number} onClick={() => handleKeyin(number)}>
								{number}
							</div>
						);
					}
				})}
			</div>
		</div>
	);
});

const QueuePhoneNumPad = React.forwardRef(({ handleKeyin, numpadBlur }, ref) => {
	return (
		<div ref={ref} tabIndex="0" onBlur={() => numpadBlur()} className="QueueNumpadWrap QueuePhoneNumPad">
			<div className="QueueNumpad">
				{num.map((number) => {
					if (number === 'delete') {
						return (
							<div key={number} className="numpad-delete" onClick={() => handleKeyin(number)}>
								<img
									className="numPad_del"
									src={require('../../../images/delete_black_icon.svg')}
									alt="delete"
								/>
							</div>
						);
					} else if (number === 'clear') {
						return (
							<div key={number} className="numpad-clear" onClick={() => handleKeyin(number)}>
								X
							</div>
						);
					} else {
						return (
							<div key={number} onClick={() => handleKeyin(number)}>
								{number}
							</div>
						);
					}
				})}
			</div>
		</div>
	);
});

const customQAns = (customQ, customizedQuestions) => {
	const existQuestion = customQ.map((q) => q.id.toString());
	const serviceMark = JSON.parse(customizedQuestions);
	const keys = Object.keys(serviceMark);

	let serviceTagBlock = [],
		quantityBlock = [],
		questionsBlock = [];

	customQ.forEach((q) => {
		keys.forEach((el) => {
			if (q['id'].toString() === el && typeof serviceMark[el] === 'object' && existQuestion.indexOf(el) !== -1) {
				let content = JSON.parse(q.content);
				let ansObj = serviceMark[el];

				Object.keys(ansObj).forEach((index) => {
					let optionAnsw = {};
					if (content[index] && ansObj[index] === -1) {
						serviceTagBlock.push(content[index].name);
					} else if (content[index] && ansObj[index] !== -1 && ansObj[index] !== 0) {
						optionAnsw['name'] = content[index].name;
						optionAnsw['value'] = ansObj[index];
						quantityBlock.push(optionAnsw);
					}
				});
			} else if (
				q['id'].toString() === el &&
				q['question_type'] === 'question' &&
				existQuestion.indexOf(el) !== -1 &&
				serviceMark[el] !== ''
			) {
				questionsBlock.push(serviceMark[q.id]);
			}
		});
	});

	return {
		serviceTagBlock,
		quantityBlock,
		questionsBlock
	};
};

const CustomerTag = ({ booking }) => {
	const isBlocked = booking.customer_info.blacklisted;
	const note = booking.customer_info.note,
		tags = booking.customer_info.tags;

	const customerTagsMap = {
		vip: 'VIP',
		egg_vegan: '蛋奶素',
		vegan: '素食',
		not_friendly: '奧客'
	};

	if (note.length !== 0 || tags.length !== 0 || isBlocked) {
		return (
			<div className="customerTag">
				<span className="customerTagIcon" />
				<div className="dropdown-menu">
					{
						isBlocked &&
						<span className='c-tag c-blocked'>封鎖</span>
					}
					{tags.length !== 0 &&
						tags.map((tag, index) => {
							return <span key={index} className={`c-tag ${tag}`}>{customerTagsMap[tag]}</span>
						})}
					{tags.length !== 0 && (note.length !== 0 || isBlocked) ? <div style={{ marginTop: '16px' }} /> : null}
					<div>{note.length !== 0 && <p>{note}</p>}</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};


const moveCanvasClass = () => {
	const $canvas = document.getElementsByTagName('canvas')[0];
	const $konvajsContent = document.getElementsByClassName('konvajs-content')[0];
	const $canvasBackgound = document.getElementsByClassName('canvasBackgound')[0];
	
	$canvasBackgound.appendChild($canvas);
	$konvajsContent.style.display = 'none';
};

const moveBackCanvasClass = () => {
	const $konvajsContent = document.getElementsByClassName('konvajs-content')[0];
	const $canvas = document.getElementsByTagName('canvas')[0];

	$konvajsContent.appendChild($canvas);
	$konvajsContent.style.display = 'block';
};


export { QueueAttendanceNumPad, QueuePhoneNumPad, customQAns, moveCanvasClass , moveBackCanvasClass, CustomerTag};
