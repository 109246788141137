import React, { Fragment } from 'react';
import { Modal } from 'antd';
import '../crmSettingsPopup/crmSettingPopup.scss';

const DeleteBlockedModal = ({
  type,
  submit,
  cancel,
  selectNumbers = 0,
  selectTag = {}
}) => {
  const renderContent = () => {
    if(type === 'blockedList') {
      return `已選取 ${selectNumbers.length} 組號碼，刪除後擁有此組號碼的顧客都將同時被解除封鎖。確定刪除嗎？`
    }

    if(type === 'settingTag') {
      return `刪除後有「${selectTag.name}」標記的顧客都將同時移除此標記註記。確定刪除嗎？`;
    }
  }
  return (
    <Modal
      title='確定刪除嗎？'
      visible
      centered
      className="deleteBlockedModal"
      footer={
        <Fragment>
          <button
            className='btn_cancel'
            onClick={cancel}
          >
            取消
          </button>
          <button
            className='btn_confirm'
            onClick={submit}
          >
            確定
          </button>
        </Fragment>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      {renderContent()}
    </Modal>
  );
};

export default DeleteBlockedModal;