import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames';
import QueueOptionSelect from './QueueOptionSelect';
import QueuePanelAPI from './api/QueuePanelAPI';
import BookingSystemApi from '../booking_settings/BookingSystemApi';

import { getEstimatedWaitingTime } from './QueuePanelHelper';

const Link = require('react-router').Link;

export default class QueueOptionArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOptionEditing: false,
			peopleCount: 0,
			name: '',
			phone: '',
			email: '',
			gender: 'other',
			groupStatus: {},
			createdResponse: {},
			currentState: props.currentState,
			addDisabled: false
		};
	}

	componentDidMount() {
		this.API = new QueuePanelAPI();
		this.BookingSystemAPI = new BookingSystemApi(); 
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentState !== this.props.currentState) {
			this.clearQueueRecordContent();
			this.setState({
				currentState: this.props.currentState
			});
		}

		if (prevProps.groupTypes !== this.props.groupTypes) {
			let groupStatus = {};
			this.props.groupTypes.forEach((group) => {
				groupStatus[group.id] = group.status;
			});
			this.setState({
				groupStatus: groupStatus
			});
		}
	}

	isNextButtonAvailable = () => {
		if (this.state.name !== '' && this.state.phone !== '') {
			return true;
		} else {
			return false;
		}
	};

	goToCustomQuestions = () => {
		this.props.updateOptionAreaState('customQuestion');
	};

	createQueueRecord = async () => {
		const regEmail = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if (isNaN(this.state.peopleCount)) {
			alert('人數請輸入阿拉伯數字');
			return;
		}

		if (this.state.peopleCount === 0) {
			alert('請輸入人數');
			return;
		}

		if (isNaN(this.state.phone)) {
			alert('電話請輸入阿拉伯數字');
			return;
		}

		if(this.state.email !=='' && !regEmail.test(this.state.email)) {
			alert('信箱格式錯誤');
			return
		}

		this.setState({ addDisabled: true });

		const customerInfoData = {
			customer: {
				last_name: this.state.name,
				email: this.state.email,
				phone_number: this.state.phone.trim(),
				tele_number: '',
				gender: this.state.gender,
				note: ''
			}
		};

		await this.BookingSystemAPI.createCustomer(customerInfoData).catch((err) => console.log('queue system createCustomer---', err));

		await this.API
			.createQueueRecord({
				queue_group_id: this.props.currentGroupType.id,
				name: this.state.name,
				phone: this.state.phone,
				gender: this.state.gender,
				people_count: this.state.peopleCount,
				email: this.state.email
			})
			.then((data) => {
				this.setState({
					createdResponse: data,
					addDisabled: false
				});
				this.props.updateOptionAreaState('addSuccess');
			})
			.catch(() => {
				this.props.updateOptionAreaState('addFailed');
			});
	};

	clearQueueRecordContent = () => {
		this.setState({
			peopleCount: 0,
			name: '',
			phone: '',
			email: ''
		});
	};

	updateSelectStatus = (id, status) => {
		let groupStatus = this.state.groupStatus;
		groupStatus[id] = status;
		this.setState({
			groupStatus: groupStatus
		});
	};

	toggleSwitch = (e) => {
		this.setState(
			(prevState) => ({
				isOptionEditing: !this.state.isOptionEditing
			}),
			() => {
				if (!this.state.isOptionEditing) {
					Object.keys(this.state.groupStatus).forEach((key) => {
						this.API.editQueueGroup(
							{
								id: key,
								status: this.state.groupStatus[key]
							},
							(data) => {
								this.props.refreshAction();
								this.props.startTimer();
							}
						);
					});
				} else {
					this.props.stopTimer();
				}
			}
		);
	};

	expandOptionArea = (e) => {
		e.stopPropagation();
		let arrow = e.target;
		let arrow_icon = e.target.childNodes[0];
		let option = arrow.parentNode.parentNode.nextSibling;
		let style = window.getComputedStyle(option);

		console.log('arrow_icon', arrow_icon);

		if (style.display === 'none') {
			arrow_icon.classList.remove('fa-angle-double-down');
			arrow_icon.classList.add('fa-angle-double-up');
			option.style.display = 'block';
		} else if (style.display === 'block') {
			option.style.display = 'none';
			arrow_icon.classList.remove('fa-angle-double-up');
			arrow_icon.classList.add('fa-angle-double-down');
		}
	};

	render() {
		let queueOptionArea = [];
		let customQuestion = [];
		this.props.customQuestions.forEach((question) => {
			switch (question.question_type) {
				case 'tag':
					let tagContents = [];
					let tagObject = JSON.parse(question.content);
					Object.keys(tagObject).forEach((key, idx) => {
						// console.log("加上去")
						tagContents.push(
							<div className="custom-question-tag" key={'custom-question-tag-' + idx}>
								{key}
							</div>
						);
					});
					customQuestion.push(
						<div>
							<h3>
								{question.title}
								{question.mutiple_select ? '(複選)' : ''}
							</h3>
							{tagContents}
						</div>
					);
					break;
				case 'quantity':
					let optionObject = JSON.parse(question.content);
					let optionContents = [];
					Object.keys(optionObject).forEach((key, idx) => {
						optionContents.push(
							<div className="custom-question-option-container">
								<span className="custom-question-option-name">{key}</span>
								<select className="custom-question-option" defaultValue={1}>
									<option value={1}>{2}</option>
								</select>
							</div>
						);
					});
					customQuestion.push(
						<div>
							<h3>
								{question.title}
								{question.mutiple_select ? '(複選)' : ''}
							</h3>
							{optionContents}
						</div>
					);
					break;
				case 'question':
					customQuestion.push(
						<div>
							<h3>
								{question.title}
								{question.mutiple_select ? '(複選)' : ''}
							</h3>
							<input type="text" className="custom-question-input" />
						</div>
					);
					break;
				default:
					break;
			}
		});

		switch (this.state.currentState) {
			case 'settings':
				return (
					<div
						className={classNames('queue-dashboard-options', { hideArea: this.props.hideOptionArea })}
						style={{ border: '1px solid #3FBA87' }}
					>
						<div className="queue-dashboard-options-header">
							<h3>開始排隊</h3>
						</div>
						<div className="queue-dashboard-options-area empty">
							<p>
								尚未新增排隊組別<br />前往
								<Link
									to={{ pathname: '/dashboard/setting/group', state: { source: 'queue' } }}
									style={{ color: '#404040', textDecoration: 'none' }}
								>
									<span>設定</span>
								</Link>
							</p>
						</div>
					</div>
				);
			case 'addFailed':
				return (
					<div className={classNames('queue-dashboard-options', { hideArea: this.props.hideOptionArea })}>
						<div className="option-failed-container">
							<img src={require('../../images/queue/img_queue_failed.png')} alt="" />
							<h3>排隊未成功</h3>
							<p>請檢查是否重複排隊</p>
							<button
								className="option-action-button"
								onClick={(e) => {
									this.props.updateOptionAreaState('add', e);
								}}
							>
								重新送出
							</button>
							<button
								className="option-action-button complete"
								onClick={(e) => {
									this.props.updateOptionAreaState('normal', e);
								}}
							>
								回首頁
							</button>
						</div>
					</div>
				);
			case 'addSuccess':
				return (
					<div className={classNames('queue-dashboard-options', { hideArea: this.props.hideOptionArea })}>
						<div className="option-success-container">
							<h3>已加入排隊</h3>
							<p>
								{this.props.currentGroupType.name} 是
								<span>{this.props.currentGroupType.title}</span> 組的
							</p>
							<h2>{this.state.createdResponse.waiting_number}</h2>
							<p>
								前面還有 <span>{this.state.createdResponse.waiting_groups}組</span>，約
								<span>
									{getEstimatedWaitingTime(
										this.props.timeCheckingCondition,
										this.state.createdResponse.waiting_groups
									)}分鐘
								</span>
							</p>
							<button
								className="option-action-button"
								onClick={(e) => {
									this.props.updateOptionAreaState('add', e);
								}}
							>
								再排一位
							</button>
							<button
								className="option-action-button complete"
								onClick={(e) => {
									this.props.updateOptionAreaState('normal', e);
								}}
							>
								回首頁
							</button>
						</div>
					</div>
				);
			case 'add':
				return (
					<div className={classNames('queue-dashboard-options', { hideArea: this.props.hideOptionArea })}>
						{ this.state.addDisabled && <div className='status_loading'><LoadingOutlined /></div> }
						<div className="queue-dashboard-options-header">
							<h3>組別：{this.props.currentGroupType.title}</h3>
						</div>
						<div className="option-add-container">
							<p>*人數</p>
							<input
								type="text"
								placeholder={
									this.props.currentGroupType.min_people_count ===
									this.props.currentGroupType.max_people_count ? (
										this.props.currentGroupType.min_people_count
									) : (
										this.props.currentGroupType.min_people_count +
										'~' +
										this.props.currentGroupType.max_people_count
									)
								}
								onChange={(e) => {
									this.setState({
										peopleCount: e.target.value
									});
								}}
								value={this.state.peopleCount !== 0 ? this.state.peopleCount : ''}
							/>
						</div>
						<div className="option-add-container">
							<p>*姓名</p>
							<input
								type="text"
								onChange={(e) => {
									this.setState({
										name: e.target.value
									});
								}}
								value={this.state.name}
							/>
							<select
								className="custom-select"
								id="inlineFormCustomSelect"
								value={this.state.gender === 'other' ? 'other' : this.state.gender === 'male' ? 1 : 2}
								onChange={(e) => {
									if (e.target.value === '1') {
										this.setState({
											gender: 'male'
										});
									} else if (e.target.value === '2') {
										this.setState({
											gender: 'female'
										});
									} else if (e.target.value === 'other') {
										this.setState({
											gender: 'other'
										});
									}
								}}
							>
								<option value="other">性別</option>
								<option value="1">先生</option>
								<option value="2">小姐</option>
							</select>
						</div>
						<div className="option-add-container">
							<p>*手機號碼</p>
							<input
								type="text"
								onChange={(e) => {
									this.setState({
										phone: e.target.value
									});
								}}
								value={this.state.phone}
							/>
						</div>
						<div className="option-add-container">
							<p>Email</p>
							<input
								type="text"
								onChange={(e) => {
									this.setState({
										email: e.target.value
									});
								}}
							/>
						</div>
						{this.isNextButtonAvailable() && !this.state.addDisabled ? (
							<button className="option-add-button" onClick={(e) => this.createQueueRecord()}>
								排隊
							</button>
						) : (
							<button
								className="option-add-button"
								disabled
								onClick={(e) => {
									this.props.updateOptionAreaState('addSuccess', e);
								}}
							>
								排隊
							</button>
						)}
						<button
							className="option-prev-button"
							onClick={(e) => this.props.updateOptionAreaState('normal', e)}
						>
							上一步
						</button>
					</div>
				);
			case 'customQuestion':
				return (
					<div className={classNames('queue-dashboard-options', { hideArea: this.props.hideOptionArea })}>
						{customQuestion}
						{this.isNextButtonAvailable() && !this.state.addDisabled ? (
							<button className="option-add-button" onClick={(e) => this.createQueueRecord()}>
								排隊
							</button>
						) : (
							<button
								className="option-add-button"
								disabled
								onClick={(e) => {
									this.props.updateOptionAreaState('addSuccess', e);
								}}
							>
								排隊
							</button>
						)}
						<button
							className="option-prev-button"
							onClick={(e) => this.props.updateOptionAreaState('normal', e)}
						>
							上一步
						</button>
					</div>
				);
			case 'pause':
				this.props.groupTypes.forEach((groupType, index) => {
					queueOptionArea.push(
						<QueueOptionSelect
							key={index}
							optionParam={groupType}
							updateSelectStatus={this.updateSelectStatus}
							state="unavailable"
						/>
					);
				});

				return (
					<div className={classNames('queue-dashboard-options', { hideArea: this.props.hideOptionArea })}>
						<div className="queue-dashboard-options-header">
							<h3>
								<span className="queue-dashboard-expand">
									<i className="fa fa-angle-double-down" aria-hidden="true" />
								</span>
								開始排隊
							</h3>
						</div>
						<div className="queue-dashboard-options-area">{queueOptionArea}</div>
					</div>
				);
			case 'normal':
				this.props.groupTypes.forEach((param, index) => {
					let enableSelectAction = param['status'] === 'start' || param['status'] === 'pause';
					let optionState = 'normal';

					if (this.state.isOptionEditing) {
						optionState = 'editing';
					} else if (param['status'] === 'start') {
						optionState = 'normal';
					} else if (param['status'] === 'pause') {
						if (param['auto_pause'] === false) {
							optionState = 'pause';
						} else {
							optionState = 'autopause';
						}
					} else {
						optionState = 'pause';
					}

					queueOptionArea.push(
						<QueueOptionSelect
							key={index}
							optionParam={param}
							updateSelectStatus={this.updateSelectStatus}
							selectAction={(ev) => {
								return enableSelectAction ? this.props.startAddCustomers(param.id) : console.log('不能用GG');
							}}
							state={optionState}
						/>
					);
				});

				return (
					<div className={classNames('queue-dashboard-options', { hideArea: this.props.hideOptionArea })}>
						<div className="queue-dashboard-options-header">
							<h3>
								<span className="queue-dashboard-expand" onClick={(e) => this.expandOptionArea(e)}>
									<i className="fa fa-angle-double-down" aria-hidden="true" />
								</span>
								{this.state.isOptionEditing ? '線上排隊開關' : '開始排隊'}
							</h3>
							<button onClick={this.toggleSwitch}>{this.state.isOptionEditing ? '完成' : '編輯'}</button>
						</div>
						<div className="queue-dashboard-options-area">{queueOptionArea}</div>
					</div>
				);
			default:
				console.log(' ');
				break;
		}

		return <div />;
	}
}
