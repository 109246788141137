import React, { useState, useContext } from 'react';
import { browserHistory } from 'react-router';

import MenuUserDeleteModal from '../../shops/menu_user/MenuUserDelete';
import MenuAppIntroModal from '../../shops/menu_user/MenuAppIntro';

import { CustomerContext } from '../crmContext/CustomerContext';
import { MessageContext } from '../crmContext/MessageContext';

import { unbindMenuUser } from '../../shops/menu_user/MenuUserApi';

const CrmFollower = () => {
	const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
    menuUserBindStatus,
    shopName,
    shopLogo,
    followers
  } = CustomerState;
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { showMenuAppIntroModal } = MessageState;

  const [ checkDelete, setCheckDelete ] = useState(false);

  const cancelMenuAppIntroModal = () => {
    MessageStateDispatch({ type: 'closeMenuAppIntroModal' });
  }

  const handleCheckDeleteModal = () => {
    const checkDeleteModalVisible = !checkDelete;
    setCheckDelete(checkDeleteModalVisible)
  };

  const deleteMenuUser = () => {
    unbindMenuUser()
      .then(res => {
        setCheckDelete(false);
        CustomerStateDispatch({ type: 'getMenuUserBindStatus', menuUserBindStatus: false, menuUserAvatar: '' });
      }).catch(err => {
          console.log('crm unbind menu user err ----------', err);
      })
  };

  const addShopDefaultSrc = (e) => {
		e.onerror = null;
		e.target.src = require('../../../images/menuUser/Profile_Picture.svg');
  };

  const sendMenuTalk = () => {
    browserHistory.push({
      pathname: '/dashboard/messages',
      state: {
        source: 'send_menuTalk'
      }
    });
  };

  const goToBindMenuUser = () => {
    browserHistory.push({
      pathname: '/dashboard/menu_user',
      state: {
        source: 'crm_customer_menu'
      }
    });
  };

  const renderBindStatus = () => {
    if(menuUserBindStatus) {
      return (
        <div className='crmFollowerContent'>
          <img
            className='shopLogo'
            src={shopLogo}
            onError={(e) => addShopDefaultSrc(e)}
          />
          <div className='shopName'>{ shopName }</div>
          <div>粉絲人數 { followers }</div>
          <button onClick={() => sendMenuTalk()}>傳送MENU私訊</button>
          <div className='unbind' onClick={() => handleCheckDeleteModal()}>解除綁定</div>
        </div>
      )
    } else {
      return (
        <div className='crmBindContent'>
          <img
            className='menuLogo'
            src={require('../../../images/menuCoupon/menuAppIcon.png')}
          />
          <div>
            <strong>MENU美食誌</strong>
            <br />
            全台最熱門的美食社群 app
          </div>
          <button onClick={() => goToBindMenuUser()}>綁定 MENU 美食誌</button>
          <div>綁定MENU帳號，傳送訊息給你的粉絲</div>
          <div
            className='text'
            onClick={() => MessageStateDispatch({ type: 'showMenuAppIntroModal' })}
          >
            綁定MENU帳號可以做什麼？
          </div>
        </div>
      )
    }
  }

  return (
    <div className='crmFollowerContainer'>
      { renderBindStatus() }

      <MenuUserDeleteModal
        checkDelete={checkDelete}
        handleCheckDelete={handleCheckDeleteModal}
        deleteMenuUser={deleteMenuUser}
      />
      <MenuAppIntroModal
        visible={showMenuAppIntroModal}
        handleMenuAppIntroModal={cancelMenuAppIntroModal}
        source='crm_customer_menu'
      />
    </div>
  )
}

export default CrmFollower;