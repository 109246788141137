import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import moment from 'moment';
import { Modal } from 'antd';

import { MessageContext } from '../../crmContext/MessageContext';
import { MessageAddStateContext } from '../../crmContext/MessageAddContext';

const PreviewMenuTalkPopup = () => {
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { previewInfo, menuUserAvatar } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { shopName, messageSetting, showPreviewPopup, originCouponLists } = MessageAddState;
  const { status, menuTalkContent, scheduleTime } = messageSetting;

  useEffect(() => {
    // 編輯時預覽 modal 沒有限制最小高度
    const previewModalHeight = document.getElementsByClassName('ant-modal-body')[0];

    if(showPreviewPopup && previewModalHeight) {
      previewModalHeight.style.minHeight = 0;
    }
  },[ showPreviewPopup ]);

  const renderDate = () => {
    if(showPreviewPopup) {
      // 編輯時預覽
      if(
        status === '' ||
        status === 'real_time' ||
        status === 'draft' ||
        (status === 'scheduled' && !scheduleTime)
      ) {
        const date = moment(new Date()).format('YYYY年MM月DD日');
        return date;
      } else if(status === 'scheduled' && scheduleTime) {
        const date = moment(new Date(scheduleTime)).format('YYYY年MM月DD日');
        return date;
      }
    } else if(previewInfo.type === 'menuTalk') {
      // 訊息列表預覽
      const date = moment(new Date(previewInfo.schedule_time)).format('YYYY年MM月DD日');
      return date;
    }
  };

  const renderTime = () => {
    if(showPreviewPopup) {
      // 編輯時預覽
      if(
        status === '' ||
        status === 'real_time' ||
        status === 'draft' ||
        (status === 'scheduled' && !scheduleTime)
      ) {
        const hour = new Date().getHours();
        const minutes = new Date().getMinutes();
        let nowTime = '';
        let newMinutes = ''

        if(minutes === 0) {
          newMinutes = '00';
        } else if(minutes < 10) {
          newMinutes = `0${minutes}`;
        } else {
          newMinutes = minutes;
        }

        if(hour >= 12) {
          nowTime = `下午${ hour === 12 ? hour : hour - 12 }:${ newMinutes }`
        } else {
          nowTime = `上午${ hour === 0 ? '0' : hour }:${ newMinutes }`
        }

        return nowTime;
      } else if(status === 'scheduled' && scheduleTime) {
        const hour = new Date(scheduleTime).getHours();
        const minutes = new Date(scheduleTime).getMinutes();
        let setTime = '';
        let newMinutes = ''

        if(minutes === 0) {
          newMinutes = '00';
        } else if(minutes < 10) {
          newMinutes = `0${minutes}`;
        } else {
          newMinutes = minutes;
        }

        if(hour >= 12) {
          setTime = `下午${ hour === 12 ? hour : hour - 12 }:${ newMinutes }`
        } else {
          setTime = `上午${ hour === 0 ? '0' : hour }:${ newMinutes }`
        }

        return setTime;
      }
    } else if(previewInfo.type === 'menuTalk') {
      // 訊息列表預覽
      const hour = new Date(previewInfo.schedule_time).getHours();
      const minutes = new Date(previewInfo.schedule_time).getMinutes();
      let setTime = '';
      let newMinutes = ''

      if(minutes === 0) {
        newMinutes = '00';
      } else if(minutes < 10) {
        newMinutes = `0${minutes}`;
      } else {
        newMinutes = minutes;
      }

      if(hour >= 12) {
        setTime = `下午${ hour === 12 ? hour : hour - 12 }:${ newMinutes }`
      } else {
        setTime = `上午${ hour === 0 ? '0' : hour }:${ newMinutes }`
      }

      return setTime;
    }
  };

  const renderContent = () => {
    if(showPreviewPopup) {
      return menuTalkContent.map((item) => {
        let voucherInfo = '';
        let endDateTime = '';

        if(item.type === 'voucher') {
          const couponIndex = originCouponLists.map((coupon) => coupon.id).indexOf(item.voucherId);

          voucherInfo = originCouponLists[couponIndex];
          endDateTime = moment(new Date(voucherInfo.endDateTime)).format('YYYY年MM月DD日HH:mm');
        };

        return (
          <Fragment>
            { item.type === 'text' &&
              <div className='msg'>
                <div className='text'>{ item.text }</div>
                <div className='time'>{ renderTime() }</div>
              </div>
            }
            { (item.type === 'new_image' || item.type === 'image') &&
              <div className='msg'>
                <div className='img'>
                  <img src={item.url} alt="" />
                </div>
                <div className='time'>{ renderTime() }</div>
              </div>
            }
            { item.type === 'voucher' &&
              <div className='msg'>
                <div className='coupon'>
                  <div className='coupon_thumbnail'>
                    { voucherInfo.thumbnailURL !== '' && <img src={voucherInfo.thumbnailURL} alt='' /> }
                  </div>
                  <div className='coupon_content'>
                    <div className='coupon_content_title'>
                      <div>{voucherInfo.title}</div>
                      <div>{voucherInfo.item}</div>
                    </div>
                    <div className='coupon_content_time'>
                      <div>{shopName}</div>
                      <div>{endDateTime} 截止</div>
                    </div>
                    <button>查看優惠券</button>
                  </div>
                </div>
                <div className='time'>{ renderTime() }</div>
              </div>
            }
          </Fragment>
        )
      })
    } else if(previewInfo.type === 'menuTalk') {
      const previewContent = JSON.parse(previewInfo.contents);
      return previewContent.map((item, index) => {
        let voucherInfo = '';
        let endDateTime = '';

        if(item.type === 'voucher') {
          const couponIndex = originCouponLists.map((coupon) => coupon.id).indexOf(item.voucherId);

          voucherInfo = originCouponLists[couponIndex];
          endDateTime = moment(new Date(voucherInfo.endDateTime)).format('YYYY年MM月DD日HH:mm');
        };

        return (
          <Fragment key={index}>
            { item.type === 'text' &&
              <div className='msg'>
                <div className='text'>{ item.text }</div>
                <div className='time'>{ renderTime() }</div>
              </div>
            }
            { item.type === 'image' &&
              <div className='msg'>
                <div className='img'>
                  <img src={item.url} alt="" />
                </div>
                <div className='time'>{ renderTime() }</div>
              </div>
            }
            { item.type === 'voucher' &&
              <div className='msg'>
                <div className='coupon'>
                  <div className='coupon_thumbnail'>
                    { voucherInfo.thumbnailURL !== '' && <img src={voucherInfo.thumbnailURL} alt='' /> }
                  </div>
                  <div className='coupon_content'>
                    <div className='coupon_content_title'>
                      <div>{voucherInfo.title}</div>
                      <div>{voucherInfo.item}</div>
                    </div>
                    <div className='coupon_content_time'>
                      <div>{shopName}</div>
                      <div>{endDateTime} 截止</div>
                    </div>
                    <button>查看優惠券</button>
                  </div>
                </div>
                <div className='time'>{ renderTime() }</div>
              </div>
            }
          </Fragment>
        )
      })
    }
  };

  const renderSentDateTime = () => {
    const dateTime = moment(new Date(previewInfo.schedule_time)).format('YYYY年MM月DD日 HH:mm:ss');
    return dateTime;
  };

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: 'cancelPopup' });
    MessageAddDispatch({ type: 'cancelPopup' });
	}, [ MessageStateDispatch, MessageAddDispatch ]);

  return (
    <Modal
      title={previewInfo.type === 'menuTalk' ? previewInfo.title : '預覽'}
      visible
      centered
      className="previewMenuTalkPopup"
      onCancel={cancelPopup}
      footer={null}
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className='previewBlock'>
        <div className='dateTime'>{ renderDate() }</div>
        <div className='main'>
          <div className='avatar'>
            {
              menuUserAvatar ?
              <img src={menuUserAvatar} alt="" /> : ''
            }
          </div>
          <div className='content'>
            { renderContent() }
          </div>
        </div>
      </div>

      {
        previewInfo.type === 'menuTalk' &&
        (<Fragment>
          <hr />
          <div className="block">
            {
              previewInfo.schedule_time &&
              <div className="text">傳送日期：{ renderSentDateTime() }</div>
            }
            { previewInfo.filter_tag !== '' && 
              <div className="text">傳送對象：{ previewInfo.filter_tag }</div>
            }
            <div className="tags">
              <div className="tag type">MENU 私訊</div>
              <div className="tag">{ previewInfo.sending_counts }則</div>
            </div>
          </div>
        </Fragment>)
      }
    </Modal>
  );
};

export default PreviewMenuTalkPopup;