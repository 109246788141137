import React from 'react'
var createReactClass = require('create-react-class');
export default createReactClass({
  handleClick(e) {
    this.props.onEditClick(this.props.data);
  },
  render() {
    return (
      <tr>
        <td style={{width: '200px'}}>{this.props.data.created_at}</td>
        <td>{this.props.data.value}</td>
        <td style={{textAlign: 'right'}}>
          <button className="btn btn-color-white btn-style3"
                  data-toggle="modal"
                  data-target=".edithistory"
                  onClick={this.handleClick} >
            <i className="fa fa-pencil" /> 編輯
          </button>
        </td>
      </tr>
    );
  }
});
