import React, { Fragment } from "react";
import _ from 'lodash';
import { Pagination } from "antd";

import { bookingRecordStatus, queueRecordStatus } from '../../utils/data';
import { CrmCustomerBookingRecord, CrmCustomerQueueRecord, CrmCustomerRecord } from './CrmCustomerRecord';

const Tabs = {
	booking: '預約',
	queue: '排隊',
  customer: '顧客'
};

const queueStatus = {
  waiting: '等候中',
  seated: '已報到',
  canceled: '已經取消', // 已取消
  passed: '過號',
  inactive: '失效'
};

const EmptyList = () => {
  return (
    <div className="lists_empty">
      目前暫無紀錄。
    </div>
  )
};

const CrmCustomerRecordList = ({
  customQ,
  bookingRecords,
  queueRecords,
  customerRecords,
  startIdx,
  endIdx,
  page,
  count,
  pageSize,
  pageClick,
  setCount,
  resetPageSetting,
  activeTab,
  setActiveTab,
  setSelectRecord,
  setShowRecordEditorModal
}) => {
  const handleRecordTab = (tab) => {
    if(tab === activeTab) return;

    if(tab === 'booking') setCount(bookingRecords.length);
    if(tab === 'queue') setCount(queueRecords.length);
    if(tab === 'customer') setCount(customerRecords.length);

    setActiveTab(tab);
    resetPageSetting();
  };

  const renderRecordTab = () => {
    return (
      <div className="tabs">
        {Object.keys(Tabs).map((tab, index) => {
          let counts = '';

          if(tab === 'booking') counts = bookingRecords.length;
          if(tab === 'queue') counts = queueRecords.length;
          if(tab === 'customer') counts = customerRecords.length;

          return (
            <div
              key={index}
              className={tab === activeTab ? 'active' : ''}
              onClick={() => handleRecordTab(tab)}
            >
              {Tabs[tab]}紀錄 {`(${counts})`} 
            </div>
          );
        })}
      </div>
    )
  };

  const renderRecordStatus = () => {
    if(activeTab === 'booking' && bookingRecords.length != 0) {
      return (
        <div className="statusBar">
          {Object.keys(bookingRecordStatus).map((option, index) => {
            let statusBookings = [];

            if(option === 'effective') {
              statusBookings = bookingRecords.filter((r) => r.booking.status !== 'no_show' && r.booking.status !== 'cancel');
            } else if(option === 'no_show' || option === 'cancel') {
              statusBookings = bookingRecords.filter((r) => r.booking.status === option);
            }

            return (
              <div
                key={index}
              >
                <div className="num">{statusBookings.length}</div>
                {bookingRecordStatus[option]}
              </div>
            );
          })}
        </div>
      )
    }

    if(activeTab === 'queue' && queueRecords.length != 0) {
      return (
        <div className="statusBar">
          {Object.keys(queueRecordStatus).map((option, index) => {
            let statusQueues = queueRecords.filter((r) => r.status_t === queueStatus[option]);

            return (
              <div
                key={index}
              >
                <div className="num">{statusQueues.length}</div>
                {queueRecordStatus[option]}
              </div>
            );
          })}
        </div>
      )
    }
  };

  const renderRecords = () => {
    if(activeTab === 'booking' && bookingRecords.length !== 0) {
      return (
        <Fragment>
          {bookingRecords.slice(startIdx, endIdx).map((item, idx) => {
            return (
              <CrmCustomerBookingRecord
                key={item.booking.id}
                data={item.booking}
                customQ={customQ}
              />
            )
          })}
        </Fragment>
      )
    }

    if(activeTab === 'queue' && queueRecords.length !== 0) {
      return (
        <Fragment>
          {queueRecords.slice(startIdx, endIdx).map((item, idx) => {
            return (
              <CrmCustomerQueueRecord
                data={item}
                key={item.id}
                customQ={customQ}
              />
            )
          })}
        </Fragment>
      )
    }

    if(activeTab === 'customer' && customerRecords.length !== 0) {
      return (
        <Fragment>
          {customerRecords.slice(startIdx, endIdx).map((item, idx) => {
            return (
              <CrmCustomerRecord
                data={item}
                key={item.id}
                setSelectRecord={setSelectRecord}
                setShowRecordEditorModal={setShowRecordEditorModal}
              />
            )
          })}
        </Fragment>
      )
    }

    return <EmptyList />;
  }

  const renderPagination = () => {
    if(activeTab === 'booking' && bookingRecords.length === 0) return;
    if(activeTab === 'queue' && queueRecords.length === 0) return;
    if(activeTab === 'customer' && customerRecords.length === 0) return;

    return (
      <Pagination
        className="crm_record_page_picker"
        current={page}
        total={count}
        pageSize={pageSize}
        showSizeChanger={false}
        showPrevNextJumpers={false}
        onChange={pageClick}
        itemRender={(_, type, originalElement) => {
          if (type === "prev") {
            return <span>上一頁</span>;
          }
          if (type === "next") {
            return <span>下一頁</span>;
          }
          return originalElement;
        }}
      />
    )
  };

  return (
    <div className="crmCustomerRecordList">
      {renderRecordTab()}
      <div className="lists">
        {renderRecordStatus()}
        <div className="records">
          {renderRecords()}
        </div>
        {renderPagination()}
      </div>
    </div>
  )
}

export default CrmCustomerRecordList;