import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import { Stage, Layer, Group, Image } from 'react-konva';
import useImage from 'use-image';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import moment from 'moment';

import NewAddBookingView from '../../AddNewBooking/NewAddBooking';
import Clocker from './Clocker';
import RectShape from './RectShape';
import CircleShape from './CircleShape';
import BookingStatus from './BookingStatus';
import BookingListInfo from './BookingListInfo';
import ShortCutStatusBtn from './ShortCutStatusBtn';

import AnnouncementListModal from '../../announcement/announcementPopup/AnnouncementListModal';
import AddAnnouncementModal from '../../announcement/announcementPopup/AnnouncementAddModal';
import AnnouncementCheckModal from '../../announcement/announcementPopup/AnnouncementDeleteModal';
import AnnouncementCancelModal from '../../announcement/announcementPopup/AnnouncementCancelModal';

import BookingSystemApi from '../../booking_settings/BookingSystemApi';
import { handleWebsocketEventRegistry, cancelWebsocketSubscription } from '../../../libs/handler';
import { WarningTemplate } from '../../new_module/NewModuleComponent/NewModuleWarning';
import { seatAlertTitle } from '../../new_module/utils/data';

import '../../../stylesheets/slick.css';
import '../../../stylesheets/slick/slick-theme.css';
import '../../../stylesheets/tableMap.css';

import wall_1 from '../../../images/tablemap/wall-1.svg';
import wall_2 from '../../../images/tablemap/wall-2.svg';
import wall_3 from '../../../images/tablemap/wall-3.svg';
import wall_4 from '../../../images/tablemap/wall-4.svg';
import wall_5 from '../../../images/tablemap/wall-5.svg';
import wall_6 from '../../../images/tablemap/wall-6.svg';
import toilet from '../../../images/tablemap/toilet.svg';
import exit from '../../../images/tablemap/exit.svg';
import settingWhite from '../../../images/component/setting-white.svg';

import bookingListEmpty from '../../../images/empty_booking.png';

import {
	handleAnnouncementListModal,
	updateDayAnnouncements,
	resetAnnouncementList
} from '../../../actions/announcementAction';
import { Fragment } from 'react';

const API = new BookingSystemApi();
const QUESTION = 'question';
const eleList = {
	wall_1,
	wall_2,
	wall_3,
	wall_4,
	wall_5,
	wall_6,
	toilet,
	exit
};
const bookingStatusTabJson = {
	1: '本日預約',
	2: '結帳完成',
	3: '未到/取消'
};

let tablemapTableGroup = {}; //記錄桌子group在哪個tab
const tabsWrapHeight = 55,
	settingToolsHeight = 53,
	timeSelectHeight = 34;

let canvasEnabled = false;

const bookingInfos = {
	currentBooking: {},
	nextBooking: {},
	nextNextBooking: {}
};


const BookingTableMap = () => {
	let urlDate = browserHistory.getCurrentLocation().query.date;
	let queryDate = urlDate ? moment(new Date(urlDate)).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
	let todayDate = moment().format('YYYY/MM/DD');
	const datepickerRef = React.useRef();
	const [ date, setDate ] = useState(queryDate);
	const [ today ] = useState(todayDate);
	const [ screenVisible, setScreenVisible ] = useState(true);
	const [ bookingTimeCount, setBookingTimeCount ] = useState({});
	const [ tabs, setTabs ] = useState([]);
	const [ activeTab, setActiveTab ] = useState('');
	const [ autoTable, setAutoTable ] = useState(false);
	const [ diningTime, setDiningTime ] = useState(0);
	const [ serviceTimeOptionEnabled, setServiceTimeOptionEnabled ] = useState(false)
	const [ bookings, setBookings ] = useState([]);
	const [ bookingsAttendance, setBookingsAttendance ] = useState([]);
	const [ originBookings, setOriginBookings ] = useState([]);
	const [ customQ, setCustomQ ] = useState([]);
	const [ customQAns, setCustomQAns ] = useState({});
	const [ tempCustomQAns, setTempCustomQAns ] = useState({});
	const [ showAddBookingView, setShowAddBookingView ] = useState(false);
	const [ modalType, setModalType ] = useState('addNew');
	const [ newSelected, setNewSelected ] = useState({});
	const [ tableCanvas, setTableCanavs ] = useState([]);
	const [ activeBookingStatusTab, setActiveBookingStatusTab ] = useState(1);
	const [ eleImgs, setEleImgs ] = useState([]);
	const [ scaleRatio, setScaleRatio ] = useState(1);
	const [ canvasWidth ] = useState(1024);
	const [ canvasHeight ] = useState(700);
	const [ selectedCanvasTableId, setSelectedCanvasTableId ] = useState(null);
	const [ bookingListInfoVisible, setBookingListInfoVisible ] = useState(false);
	const [ selectedBookingTime, setSelectedBookingTime ] = useState(0);
	const [ timerPaused, setTimerPaused ] = useState(todayDate !== queryDate ? true : false);
	const [ canvasBackgound, setCanvasBackgound ] = useState(null);
	const [ bookingInfoId, setBookingInfoId ] = useState(null);
	const [ bookingListInfoBookings, setBookingListInfoBookings ] = useState(bookingInfos);
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);
	const [ seatedBooking, setSeatedBooking ] = useState({});

	const [ msgPoint, setMsgPoint ] = useState(0);
	const [ smsNotificationSettings, setSmsNotificationSettings ] = useState({});
	const [ emailNotificationSettings, setEmailNotificationSettings ] = useState({});
	const [ spgHashIv, setSpgHashIv ] = useState(null);
	const [ spgHashKey, setSpgHashKey ] = useState(null);
	const [ spgMerchantId, setSpgMerchantId ] = useState(null);
	const [ creditEnabled, setCreditEnabled ] = useState('');

	const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
		showAnnouncementListModal,
		showAnnouncementDeleteModal,
		showAnnouncementCancelModal
	} = announcementListReducer;
	const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    showAddAnnouncementModal
  } = announcementInfo;

	const dispatch = useDispatch();

	const resizeSreen = useCallback(
		() => {
			let timer;
			closeSelectTable();
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				let w_w = window.innerWidth;

				if (w_w >= 500) {
					setScreenVisible(true);

					let $bookingTableMaPanelWidth = document.getElementsByClassName('bookingTableMap__panel')[0]
						.clientWidth;
					let actual_w_w = window.innerWidth - $bookingTableMaPanelWidth;
					let scaleRatio =
						JSON.parse((actual_w_w / canvasWidth).toFixed(2)) > 1
							? 1
							: JSON.parse((actual_w_w / canvasWidth).toFixed(2));

					setScaleRatio(scaleRatio);
				} else {
					setScreenVisible(false);
				}
			}, 200);
		},
		[ canvasWidth ]
	);

	useEffect(
		() => {
			let nav = document.getElementsByClassName('menushop-nav')[0];
			let bodyEle = document.getElementsByTagName('body')[0];
			nav.style.display = 'none';
			resizeSreen();
			window.addEventListener('resize', resizeSreen);

			browserHistory.replace({
				pathname: '/dashboard/bookings/booking_table_map',
				query: {
					date: moment(new Date(today)).format('YYYY-MM-DD')
				}
			});

			return () => {
				window.removeEventListener('resize', resizeSreen);

				if (nav) {
					nav.removeAttribute('style');
					nav = null;
				}
				if (bodyEle) {
					bodyEle.classList.remove('modal-open');
					bodyEle = null;
				}
			};
		},
		[ resizeSreen, today ]
	);

	useEffect(
		() => {
			$(datepickerRef.current)
				.datepicker({
					format: 'yyyy/mm/dd',
					minViewMode: 0,
					maxViewMode: 0,
					todayBtn: 'linked',
					todayHighlight: true,
					language: 'zh-TW'
				})
				.on('changeDate', (e) => {
					let selectedDate = e.format();

					setDate(selectedDate);
					if (today === selectedDate) {
						setTimerPaused(false);
						setCanvasBackgound(null);
					} else {
						setTimerPaused(true);
						// setSelectedBookingTime(0);
					}
				});
		},
		[ today, selectedBookingTime ]
	);

	useEffect(() => {
		return () => dispatch(resetAnnouncementList());
	}, [])

	const getSetting = useCallback(async () => {
		try {
			let customQAns = {},
				customQ = [];
			let autoTableSetting = false,
				diningTime = 0;
			let serviceTimeOptionEnabled;

			await API.getBookingSetting().then((setting) => {
				canvasEnabled = setting.canvas_enabled;
				autoTableSetting = setting.auto_table_distribution;
				diningTime = setting.dining_time;
				serviceTimeOptionEnabled = setting.service_time_option_enabled

				setMsgPoint(setting.shop.msg_point);
				setSmsNotificationSettings(setting.sms_notification_settings);
				setEmailNotificationSettings(setting.email_notification_settings);
				setSpgHashIv(setting.spg_hash_iv);
				setSpgHashKey(setting.spg_hash_key);
				setSpgMerchantId(setting.spg_merchant_id);
			});

			await API.getQuestions().then((data) => {
				data.map((q) => {
					if (q.question_type === QUESTION) {
						customQAns[q.id] = '';
					} else {
						customQAns[q.id] = {};
					}
					return true;
				});

				customQ = data;
			});

			setAutoTable(autoTableSetting);
			setDiningTime(diningTime);
			setServiceTimeOptionEnabled(serviceTimeOptionEnabled);

			setCustomQ(customQ);
			setCustomQAns(customQAns);
			setTempCustomQAns(JSON.parse(JSON.stringify(customQAns)));
		} catch (err) {
			console.log(err);
		}
	}, []);

	const getCreditEnabled = useCallback(
		() => {
			$.ajax({url: window.domain + '/dashboard/booking_settings/spg_data',
				method: 'GET',
				xhrFields: { withCredentials: true }})
			.done(function(data){
				setCreditEnabled(data.credit_enabled)
			}.bind(this))
			.fail(function(xhr){
				console.log('get credit enabled error ----------')
			});
		}, [])

	const getBookings = useCallback(
		async (activeTab) => {
			try {
				let bookings = [],
					originBookings = [];
				let activeTabName = '',
					activeTableCanvas = [],
					activeEleCanvas = [];

				await API.getBookingsByDate(date).then((data) => {
					originBookings = data;

					bookings = data.filter((booking) => {
						return (
							booking.status !== 'no_show' && booking.status !== 'cancel' && booking.status !== 'finish'
						);
					});
				});

				tablemapTableGroup = {};
				const canvas = await API.getTableMap(date).then((data) => {
					data.table_map_list.map((tab) => {
						tab.table.map((t) => {
							let sortTimeline = _.sortBy(t.timeline, 'booking_datetime');
							t.timeline = sortTimeline;
							if (!tablemapTableGroup.hasOwnProperty(tab.name)) {
								tablemapTableGroup[tab.name] = [];
								tablemapTableGroup[tab.name].push(t.name);
							} else {
								tablemapTableGroup[tab.name].push(t.name);
							}
							return null;
						});
						return null;
					});

					return data;
				});

				if (canvas.table_map_list.length !== 0) {
					if (activeTab === '' || !activeTab) {
						activeTabName = canvas.table_map_list[0].name;
						activeTableCanvas = canvas.table_map_list[0].table;
						activeEleCanvas = canvas.table_map_list[0].ele;
					} else {
						activeTabName = activeTab;
						for (let i = 0; i < canvas.table_map_list.length; i++) {
							if (canvas.table_map_list[i].name === activeTabName) {
								activeTableCanvas = canvas.table_map_list[i].table;
								activeEleCanvas = canvas.table_map_list[i].ele;
								break;
							}
						}
					}

					setBookingsAttendance(bookings);
					setBookings(bookings);
					setOriginBookings(originBookings);

					setTabs(canvas.table_map_list);
					setActiveTab(activeTabName);
					setTableCanavs(activeTableCanvas);
					setEleImgs(activeEleCanvas);
				}

				window.app.alert.setMessage('', 'done');

				closeSelectTable();
			} catch (err) {
				console.log(err);
			}
		},
		[ date ]
	);

	const allSetting = useCallback(
		async (activeTab) => {
			try {
				await getSetting();
				await getBookings(activeTab);
				await getCreditEnabled();

				if (!canvasEnabled) {
					browserHistory.push({
						pathname: '/dashboard'
					});

					return;
				}
			} catch (err) {
				window.app.alert.setMessage('請稍後再試', 'error');
			}
		},
		[ getSetting, getBookings, getCreditEnabled ]
	);

	useEffect(
		() => {
			let bookingTimeCount = {};
			setBookingTimeCount({});
			let timeZone = null;

			bookingsAttendance.map((booking) => {
				if (booking.status !== 'no_show' && booking.status !== 'cancel' && booking.status !== 'finish') {
					let hour = booking.time.slice(0, 2),
						min = parseInt(booking.time.slice(3), 10);
					let bookingTime = min < 30 && min >= 0 ? `${hour}:00` : `${hour}:30`;

					if (timeZone === bookingTime) {
						bookingTimeCount[bookingTime] = bookingTimeCount[bookingTime] + booking.attendance;
					} else {
						timeZone = bookingTime;
						bookingTimeCount[bookingTime] = booking.attendance;
					}
				}

				return null;
			});

			setBookingTimeCount(bookingTimeCount);
		},
		[ bookingsAttendance ]
	);

	useEffect(
		() => {
			const load = async () => {
				window.app.alert.setMessage('讀取中..', 'done');
				allSetting();
				// getCreditEnabled();
			};

			load();
		},
		[ allSetting ]
	);

	// const changeBookingStatus = useCallback((data, bookings) =>{
	// 	let newBookings = [...bookings];
	// 	let booking_id = data.booking.booking.id;
	// 	let booking_index = newBookings.map(booking=> {return booking.id}).indexOf(booking_id);

	// 	newBookings.splice(booking_index, 1, data.booking.booking);
	// 	setBookings(newBookings);
	// }, [])

	useEffect(
		() => {
			browserHistory.replace({
				pathname: '/dashboard/bookings/booking_table_map',
				query: {
					date: moment(new Date(date)).format('YYYY-MM-DD')
				}
			});
			//預約更新狀態
			handleWebsocketEventRegistry('refresh_booking_list_status_changed', (data) => {
				setTimeout(() => {
					getBookings(activeTab);
					// getCanvas(activeTab, date)
					// changeBookingStatus(data, bookings)
				}, 3000);
			});

			//修改座位、座位圖
			handleWebsocketEventRegistry('sync_canvas', () => {
				setTimeout(() => {
					allSetting(activeTab);
				}, 3000);
			});

			//有新預約、修改預約
			handleWebsocketEventRegistry('refresh_booking_list', () => {
				setTimeout(() => {
					getBookings(activeTab);
				}, 3000);
			});

				// 公告: 建立/更新/過期/刪除 -> update 該月公告
			handleWebsocketEventRegistry('sync_announcements', () => {
				setTimeout(() => {
					let urlDate = browserHistory.getCurrentLocation().query.date;
					dispatch(updateDayAnnouncements(urlDate));
				}, 3000);
			});

			return () => {
				cancelWebsocketSubscription([ 'sync_canvas' ]);
				cancelWebsocketSubscription([ 'notify' ]);
				cancelWebsocketSubscription([ 'refresh_booking_list' ]);
				cancelWebsocketSubscription([ 'refresh_booking_list_status_changed' ]);
				cancelWebsocketSubscription([ 'sync_announcements' ]);
			};
		},
		[ allSetting, getBookings, activeTab, date, bookings, dispatch ]
	);

	useEffect(
		() => {
			let bookings = [];

			if (activeBookingStatusTab === 1) {
				bookings = originBookings.filter((booking) => {
					return booking.status !== 'no_show' && booking.status !== 'cancel' && booking.status !== 'finish';
				});
			} else if (activeBookingStatusTab === 2) {
				bookings = originBookings.filter((booking) => {
					return booking.status === 'finish';
				});
			} else if (activeBookingStatusTab === 3) {
				bookings = originBookings.filter((booking) => {
					return booking.status === 'no_show' || booking.status === 'cancel';
				});
			}

			setBookings(bookings);
		},
		[ activeBookingStatusTab, originBookings ]
	);

	const renderSystemTools = () => {
		return <div className="bookingSystem__tools">{bookingStatusTabMemo}</div>;
	};

	const switchTab = useCallback(
		(activeTab) => {
			tabs.map((t) => {
				if (t.name === activeTab) {
					setTableCanavs(t.table);
					setEleImgs(t.ele);
				}
				return null;
			});
			closeSelectTable();
			setActiveTab(activeTab);
		},
		[ tabs ]
	);

	const renderTabs = useCallback(
		(activeTab) => {
			return tabs.map((tab, index) => {
				let active = tab.name === activeTab ? 'active' : '';
				return <Tab key={index} tab={tab} active={active} switchTab={(activeTab) => switchTab(activeTab)} />;
			});
		},
		[ switchTab, tabs ]
	);

	const renderBookingStatusTab = useCallback(
		(bookingStatusTabJson) => {
			let confirmedLength = 0,
				finishLength = 0,
				cancelLength = 0;
			originBookings.map((booking) => {
				if (booking.status === 'finish') {
					finishLength += 1;
				} else if (booking.status === 'no_show' || booking.status === 'cancel') {
					cancelLength += 1;
				} else {
					confirmedLength += 1;
				}
				return null;
			});
			return (
				<div className="bookingStatusTab">
					{Object.keys(bookingStatusTabJson).map((tab) => {
						let active =
							bookingStatusTabJson[tab] === bookingStatusTabJson[activeBookingStatusTab] ? 'active' : '';

						if (tab === '1') {
							return (
								<div
									key={tab}
									className={`bookingStatusTab__tab ${active}`}
									onClick={() => chageBookingStatusTab(tab)}
								>
									{bookingStatusTabJson[tab]}({confirmedLength})
								</div>
							);
						} else if (tab === '2') {
							return (
								<div
									key={tab}
									className={`bookingStatusTab__tab ${active}`}
									onClick={() => chageBookingStatusTab(tab)}
								>
									{bookingStatusTabJson[tab]}({finishLength})
								</div>
							);
						} else {
							return (
								<div
									key={tab}
									className={`bookingStatusTab__tab ${active}`}
									onClick={() => chageBookingStatusTab(tab)}
								>
									{bookingStatusTabJson[tab]}({cancelLength})
								</div>
							);
						}
					})}
				</div>
			);
		},
		[ activeBookingStatusTab, originBookings ]
	);

	const setStatus = useCallback(
		(booking, newStatus) => {
			const customerName = booking.last_name,
				customerGender = booking.gender_desc;
			const status_time = moment().format().slice(11, 16);
			const date = moment(booking.booking_datetime).format('YYYY-MM-DD');
			closeSelectTable();

			API.setBookingStatus(booking.id, newStatus, date, status_time)
				.then(() => {
					getBookings(activeTab);

					if (newStatus === 'confirmed') {
						window.app.alert.setMessage(customerName + customerGender + ' 確認預約', 'done');
					} else if (newStatus === 'no_show') {
						window.app.alert.setMessage(customerName + customerGender + ' 未到店', 'done');
					} else if (newStatus === 'show') {
						window.app.alert.setMessage(customerName + customerGender + ' 已到店', 'done');
					} else if (newStatus === 'seated') {
						window.app.alert.setMessage(customerName + customerGender + ' 已入座', 'done');
					} else if (newStatus === 'finish') {
						window.app.alert.setMessage(customerName + customerGender + ' 完成消費', 'done');
					} else {
						window.app.alert.setMessage('更新狀態成功', 'done');
					}
				})
				.catch(() => {
					window.app.alert.setMessage('請重新再試', 'error');
				});
		},
		[ getBookings, activeTab ]
	);

	const renderBookings = useCallback(
		(bookings, bookingInfoId) => {
			let timeZone = null;

			if (bookings.length === 0) {
				return (
					<div className="bookingListEmpty">
						<img src={bookingListEmpty} alt="bookingListEmpty" />
					</div>
				);
			};

			return bookings.map((booking, index) => {
				let hour = booking.time.slice(0, 2);
				let goBookingListCard = booking.id === bookingInfoId ? 'goBookingListCard' : '';

				if (timeZone === hour) {
					return (
						<BookingListCell
							goBookingListCard={goBookingListCard}
							key={index}
							booking={booking}
							customQ={customQ}
							setStatus={(booking, newStatus) => setStatus(booking, newStatus)}
							editBooking={(booking) => editBooking(booking)}
							setBookingInfoId={setBookingInfoId}
							setShowWarning={setShowWarning}
							setSeatedBooking={setSeatedBooking}
							setWarningTales={setWarningTales}
							serviceTimeOptionEnabled={serviceTimeOptionEnabled}
						/>
					);
				} else {
					timeZone = hour;
					let parseTimeZone = parseInt(hour, 10);
					return (
						<React.Fragment key={index}>
							{activeBookingStatusTab === 1 ? (
								<p className="bookingSectionTitle" name={parseTimeZone}>
									<span>{timeZone} : 00</span>
								</p>
							) : null}

							<BookingListCell
								goBookingListCard={goBookingListCard}
								booking={booking}
								customQ={customQ}
								setStatus={(booking, newStatus) => setStatus(booking, newStatus)}
								editBooking={(booking) => editBooking(booking)}
								setBookingInfoId={setBookingInfoId}
								setShowWarning={setShowWarning}
								setSeatedBooking={setSeatedBooking}
								setWarningTales={setWarningTales}
								serviceTimeOptionEnabled={serviceTimeOptionEnabled}
							/>
						</React.Fragment>
					);
				}
			});
		},
		[ activeBookingStatusTab, customQ, serviceTimeOptionEnabled, setStatus ]
	);

	const selectTable = useCallback(
		(shapeProps, timeline) => {
			setBookingListInfoVisible(true);
			let $bookingListWrapWidth = document.getElementsByClassName('bookingTableMap__canvas__tables')[0]
				.offsetWidth;
			let $canvasTableBookingInfoWrapWidth = document.getElementsByTagName('canvas')[0].offsetWidth;
			let $restGap = ($bookingListWrapWidth - $canvasTableBookingInfoWrapWidth) / 2;
			let $tablesBookingListCardWidth = 300;
			let $bookingList = document.getElementsByClassName('tables_bookingList__wrap')[0];
			let listX = 0,
				listY = 0;

			if (shapeProps.table_type === 'circle') {
				if (
					$bookingListWrapWidth - $restGap - (shapeProps.positionX + shapeProps.width / 2) * scaleRatio <
					$tablesBookingListCardWidth + 10
				) {
					$bookingList.classList.add('display_left');
					listX =
						(shapeProps.positionX - 30 - shapeProps.width / 2) * scaleRatio - $tablesBookingListCardWidth;
				} else {
					$bookingList.classList.remove('display_left');
					listX = (shapeProps.positionX + shapeProps.width / 2 + 5) * scaleRatio;
				}
				listY = (shapeProps.positionY - 70) * scaleRatio;
			} else {
				if (
					$bookingListWrapWidth - $restGap - (shapeProps.positionX + shapeProps.width) * scaleRatio <
					$tablesBookingListCardWidth + 10
				) {
					$bookingList.classList.add('display_left');
					listX = (shapeProps.positionX - 30) * scaleRatio - $tablesBookingListCardWidth;
				} else {
					$bookingList.classList.remove('display_left');
					listX = (shapeProps.positionX + shapeProps.width + 5) * scaleRatio;
				}
				listY = (shapeProps.positionY - 20) * scaleRatio;
			}

			$bookingList.style.left = listX + 'px';
			$bookingList.style.top = listY + 'px';

			$bookingList.classList.remove('hide');

			setSelectedCanvasTableId(shapeProps.id);
		},
		[ scaleRatio ]
	);

	const closeSelectTable = () => {
		let $bookingList = document.getElementsByClassName('tables_bookingList__wrap')[0];
		if ($bookingList) {
			$bookingList.classList.add('hide');
			setBookingListInfoVisible(false);
		}
	};

	const renderTableCanvas = useCallback(
		() => {
			return tableCanvas.map((table, index) => {
				if (table.table_type !== 'circle') {
					return (
						<RectShape
							key={table.name}
							shapeProps={table}
							timeline={table.timeline}
							isSelected={table.id === selectedCanvasTableId}
							selectedBookingTime={selectedBookingTime}
							timerPaused={timerPaused}
							date={date}
							selectTable={(shapeProps, timeline) => selectTable(shapeProps, timeline)}
							setBookingListInfoBookings={setBookingListInfoBookings}
						/>
					);
				} else {
					return (
						<CircleShape
							key={table.name}
							shapeProps={table}
							timeline={table.timeline}
							isSelected={table.id === selectedCanvasTableId}
							selectedBookingTime={selectedBookingTime}
							timerPaused={timerPaused}
							date={date}
							selectTable={(shapeProps, timeline) => selectTable(shapeProps, timeline)}
							setBookingListInfoBookings={setBookingListInfoBookings}
						/>
					);
				}
			});
		},
		[ selectTable, selectedCanvasTableId, tableCanvas, selectedBookingTime, timerPaused, date ]
	);

	const renderEleCanvas = (eleImgs) => {
		return eleImgs.map((ele, index) => {
			return <URLImage key={index} img={ele} />;
		});
	};

	const goBookingInfo = (id) => {
		setActiveBookingStatusTab(1);
		setBookingInfoId(id);
		let cavasSetting = document.getElementsByClassName('bookingTableMap__panel')[0];

		let close = cavasSetting.classList.contains('hidePanel');
		let toggleIcon = document.getElementById('toggleIcon');
		let addBookingBtn = document.getElementsByClassName('addBookingBtn')[0];

		if (close) {
			cavasSetting.classList.remove('hidePanel');
			toggleIcon.classList.add('fa-angle-double-right');
			toggleIcon.classList.remove('fa-angle-double-left');
			addBookingBtn.removeAttribute('style');

			resizeSreen();
		}

		closeSelectTable();
		setSelectedCanvasTableId(null);
	};

	const renderSystemList = () => {
		return (
			<div className="systemList systemList-booking">
				<div className="bookingTableMap__bookingList">{bookingsMemo}</div>
			</div>
		);
	};

	const bookingStatusTabMemo = useMemo(() => renderBookingStatusTab(bookingStatusTabJson), [
		renderBookingStatusTab
	]);

	const bookingsMemo = useMemo(() => renderBookings(bookings, bookingInfoId), [
		renderBookings,
		bookings,
		bookingInfoId
	]);

	const tabsMemo = useMemo(() => renderTabs(activeTab), [ renderTabs, activeTab ]);

	const eleImgsCanvasMemo = useMemo(() => renderEleCanvas(eleImgs), [ eleImgs ]);

	const tableCanvasMemo = useMemo(() => renderTableCanvas(), [ renderTableCanvas ]);

	const chageBookingStatusTab = (tab) => {
		let bookingTableMapBookingList = document.getElementsByClassName('bookingTableMap__bookingList')[0];
		bookingTableMapBookingList.scrollTop = 0;

		setActiveBookingStatusTab(parseInt(tab, 10));
	};

	const addNewBooking = () => {
		let bodyEle = document.getElementsByTagName('body')[0];
		bodyEle.classList.add('modal-open');
		setModalType('addNew');
		setShowAddBookingView(true);
	};

	const editBooking = (booking) => {
		let bodyEle = document.getElementsByTagName('body')[0];
		bodyEle.classList.add('modal-open');
		setNewSelected(booking);
		setModalType('editor');
		setShowAddBookingView(true);
	};

	const hideNewAddBookingModal = () => {
		let bodyEle = document.getElementsByTagName('body')[0];
		bodyEle.classList.remove('modal-open');
		setShowAddBookingView(false);
		setCustomQAns(JSON.parse(JSON.stringify(tempCustomQAns)));
	};

	const togglePanel = () => {
		let cavasSetting = document.getElementsByClassName('bookingTableMap__panel')[0];
		let addBookingBtn = document.getElementsByClassName('addBookingBtn')[0];
		let toggleIcon = document.getElementById('toggleIcon');
		let open = cavasSetting.classList.contains('hidePanel');

		if (!open) {
			cavasSetting.classList.add('hidePanel');
			toggleIcon.classList.remove('fa-angle-double-right');
			toggleIcon.classList.add('fa-angle-double-left');
			addBookingBtn.style.right = '10px';
			resizeSreen();
		} else {
			cavasSetting.classList.remove('hidePanel');
			toggleIcon.classList.add('fa-angle-double-right');
			toggleIcon.classList.remove('fa-angle-double-left');
			addBookingBtn.removeAttribute('style');

			resizeSreen();
		}

		closeSelectTable();
	};

	const renderBookingList = () => {
		if (bookingListInfoVisible) {
			return (
				<BookingListInfo
					bookingListInfoBookings={bookingListInfoBookings}
					timerPaused={timerPaused}
					editBooking={editBooking}
					setStatus={setStatus}
					goBookingInfo={goBookingInfo}
					selectedBookingTime={selectedBookingTime}
					date={date}
				/>
			);
		} else {
			return null;
		}
	};

	const switchMode = (mode) => {
		let queryDate = moment(new Date(date)).format('YYYY-MM-DD');
		browserHistory.push({
			pathname: '/dashboard/bookings',
			query: {
				date: queryDate,
				mode
			}
		});
	};

	const renderDatepicker = useCallback(
		() => {
			let dateFormat = moment(new Date(date)).format('YYYY/MM/DD');
			return (
				<div ref={datepickerRef} className="input-group date">
					<input type="text" className="form-control dateInfo__input" value={dateFormat} readOnly />
					<span className="input-group-addon">
						<i className="fa fa-calendar" aria-hidden="true" />
					</span>
				</div>
			);
		},
		[ date ]
	);

	const datepickerMemo = useMemo(() => renderDatepicker(), [ renderDatepicker ]);

	const goBookingSetting = () => {
		browserHistory.push({
			pathname: '/dashboard/setting'
		});
	};

	const cancelWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
		setSeatedBooking({});
	};

	const confirmWarning = () => {
		setStatus(seatedBooking, 'seated');
		setShowWarning(false);
		setWarningTales([]);
		setSeatedBooking({});
	};

	if (!screenVisible) {
		return (
			<Fragment>
				<NotVisibleView switchMode={switchMode} goBookingSetting={goBookingSetting} />
				{ showAnnouncementListModal && <AnnouncementListModal/> }
			</Fragment>
		);
	}

	return (
		<div className="bookingTableMapWrap">
			<section className="settingTools">
				<div className='tools_flex'>
					<div className="dateInfo">{datepickerMemo}</div>
					<ViewBtns switchMode={switchMode} />
				</div>
				<dir className='tools_flex'>
					<AnnouncementBtn />
					<GoBookingSetting goBookingSetting={goBookingSetting} />
				</dir>
			</section>
			<Clocker
				datepickerRef={datepickerRef}
				bookingTimeCount={bookingTimeCount}
				setSelectedBookingTime={setSelectedBookingTime}
				setCanvasBackgound={setCanvasBackgound}
				setTimerPaused={setTimerPaused}
				timerPaused={timerPaused}
				setDate={setDate}
				closeSelectTable={closeSelectTable}
			/>
			<div className="bookingTableMap">
				<div className="bookingTableMap__canvas">
					<div className="bookingTableMap__canvas_tools">
						<div className="bookingTableMap__canvas__tabs">
							<div className="tabsWrap">{tabsMemo}</div>
						</div>
					</div>
					<div
						className="bookingTableMap__canvas__tables"
						style={{ height: window.outerHeight - tabsWrapHeight - settingToolsHeight - timeSelectHeight }}
					>
						<div style={{ height: canvasHeight * scaleRatio + 140, padding: '10px 0 20px' }}>
							<div className="tables_bookingList" style={{ width: canvasWidth * scaleRatio }}>
								{renderBookingList()}
							</div>

							<Stage
								className={canvasBackgound}
								width={canvasWidth * scaleRatio}
								height={canvasHeight * scaleRatio}
								scaleX={scaleRatio}
								scaleY={scaleRatio}
								onClick={(e) => {
									const clickedOnEmpty = e.target === e.target.getStage();
									if (clickedOnEmpty) {
										closeSelectTable();
										setSelectedCanvasTableId(null);
									}
								}}
								onTouchStart={(e) => {
									const clickedOnEmpty = e.target === e.target.getStage();
									if (clickedOnEmpty) {
										closeSelectTable();
										setSelectedCanvasTableId(null);
									}
								}}
							>
								<Layer>
									{eleImgsCanvasMemo}
									{tableCanvasMemo}
								</Layer>
							</Stage>
						</div>
					</div>
				</div>
				<div className="bookingTableMap__panel">
					<span className="bookingTableMap__panel__toggleSetting" onClick={() => togglePanel()}>
						<i id="toggleIcon" className="fa fa-angle-double-right" aria-hidden="true" />
					</span>

					<div className="fixTop">{renderSystemTools()}</div>

					{renderSystemList()}
				</div>
			</div>

			<button className="addBookingBtn" onClick={addNewBooking}>
				<span>＋</span>
			</button>

			{showAddBookingView && (
				<NewAddBookingView
					autoTable={autoTable}
					hideNewAddBookingModal={hideNewAddBookingModal}
					updateData={() => getBookings(activeTab)}
					modalType={modalType}
					date={date}
					diningTime={diningTime}
					newSelected={newSelected}
					customQAns={customQAns}
					customQ={customQ}
					msgPoint={msgPoint}
					smsNotificationSettings={smsNotificationSettings}
					emailNotificationSettings={emailNotificationSettings}
					spg_hash_iv={spgHashIv}
					spg_hash_key={spgHashKey}
					spg_merchant_id={spgMerchantId}
					credit_enabled={creditEnabled}
				/>
			)}

			{showWarning && (
				<WarningTemplate cancelWarning={cancelWarning} confirmWarning={confirmWarning} title={seatAlertTitle}>
					<p>
						桌次
						{warningTales.map((table) => {
							return (
								<span key={table} className="tableName">
									{table}
								</span>
							);
						})}
						有尚未結束的預約
					</p>
					<p>是否確定入座？</p>
				</WarningTemplate>
			)}

			{ showAnnouncementListModal && <AnnouncementListModal/> }

			{
				showAddAnnouncementModal &&
				<AddAnnouncementModal />
			}

			{ showAnnouncementDeleteModal &&
				<AnnouncementCheckModal/>
			}

			{ showAnnouncementCancelModal &&
				<AnnouncementCancelModal/>
			}
			<div id="modalPortal" />
		</div>
	);
};


const Tab = ({ tab, active, switchTab }) => {
	return (
		<section className={`tabSection ${active}`} onClick={() => switchTab(tab.name)}>
			<h2 className={active}>{tab.name}</h2>
			{/* <span>8桌</span> */}
		</section>
	);
};

const ViewBtns = ({ switchMode }) => {
	return (
		<div>
			<div className="viewBtns">
				<i className="calendar" onClick={() => switchMode('calendar')} />
			</div>
			<div className="viewBtns">
				<i className="list" onClick={() => switchMode('list')} />
			</div>
			<div className="viewBtns">
				<i className="table" onClick={() => switchMode('table')} />
			</div>
			<div className="viewBtns">
				<i className="tablemap" />
			</div>
		</div>
	);
};

const AnnouncementBtn = () => {
	let urlDate = browserHistory.getCurrentLocation().query.date;
	let queryDate = urlDate ? moment(new Date(urlDate)).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
	let $queryDate = urlDate ? moment(new Date(urlDate)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

	const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
		dayAnnouncements
	} = announcementListReducer;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updateDayAnnouncements($queryDate));
	}, [ $queryDate ]);

	if(dayAnnouncements.length === 0) {
		return (
			<span
				className='announcement_month disabled'
			>
				<span className='announcement_day_none' />
				<span className='txt'>本日公告</span>
			</span>
		)
	} else {
		return (
			<span
				className='announcement_month'
				onClick={() => dispatch(handleAnnouncementListModal('day', queryDate))}
			>
				<span className='announcement_month_remind' />
				<span className='txt'>本日公告</span>
			</span>
		)
	}
};

const GoBookingSetting = ({ goBookingSetting }) => {
	return (
		<React.Fragment>
			<label className="bookingSettingBtn" onClick={goBookingSetting}>
				<img src={settingWhite} alt="setting" />
				<span>設定</span>
			</label>
		</React.Fragment>
	);
};

const NotVisibleView = ({ switchMode, goBookingSetting }) => {
	return (
		<div className="notVisible">
			<div className="settingTools">
				<ViewBtns switchMode={switchMode} />
				<div>
					<AnnouncementBtn />
					<GoBookingSetting goBookingSetting={goBookingSetting} />
				</div>
			</div>
			<div className="notVisibleOOps">
				<i className="oops" />
				<i className="oopsTxt" />
				<h2>請以寬度大於 500 px的裝置瀏覽座位圖模式，以符合最佳體驗。</h2>
			</div>
		</div>
	);
};

const URLImage = ({ img }) => {
	const imgRef = React.useRef();
	const [ image ] = useImage(eleList[img.name]);

	return (
		<Group preventDefault={false} x={img.positionX} y={img.positionY}>
			<Image ref={imgRef} width={img.width} height={img.height} image={image} preventDefault={false} />
		</Group>
	);
};

const CustomerTag = (booking) => {
	let note = booking.customer_info.note,
		tags = booking.customer_info.tags;

	if (note.length !== 0 || tags.length !== 0) {
		return (
			<div className="customerTag">
				<span className="customerTagIcon" />
				<div className="dropdown-menu">
					<div>
						{tags.length !== 0 &&
							tags.map((tag, index) => {
								return <span key={index} className={`c-tag ${tag}`} />;
							})}
					</div>
					<div>{note.length !== 0 && <p>{note}</p>}</div>
				</div>
			</div>
		);
	}
};

const statusBtn = (booking, setStatus, seatedWarning, editBooking) => {
	let statusTime = JSON.parse(booking.status_time);
	let stautsBtn = null,
		stautsBtnTime = null;
	let garyBackground = '';
	let statusEdit = null;

	switch (booking.status) {
		case 'confirmed':
			stautsBtn = (
				<React.Fragment>
					<button className="btn noShowBtn" onClick={() => setStatus(booking, 'no_show')}>
						未到店
					</button>
					<button className="btn showBtn" onClick={() => setStatus(booking, 'show')}>
						已到店
					</button>
				</React.Fragment>
			);
			break;
		case 'show':
			stautsBtn = (
				<button className="btn seatedBtn" onClick={() => seatedWarning(booking)}>
					入座
				</button>
			);
			stautsBtnTime = <span className="stautsBtnTime">報到時間: {statusTime['show']}</span>;
			break;
		case 'seated':
			stautsBtn = (
				<button className="btn checkBtn" onClick={() => setStatus(booking, 'finish')}>
					結帳
				</button>
			);
			stautsBtnTime = <span className="stautsBtnTime">入座時間: {statusTime['seated']}</span>;

			break;
		case 'finish':
			stautsBtn = <span className="doneBtn">{statusTime['finish']}完成消費</span>;
			statusEdit = 
			<span>
				<span className="status__edit__bar">|</span>
				<span className="status__edit__icon">
					<img src={require('../../../images/edit.svg')} onClick={() => editBooking(booking)}/>
				</span>
			</span>;
			break;
		case 'no_show':
			garyBackground = 'garyBackground';
			stautsBtn = <span className="noShowBtnTxt">{statusTime['no_show']}未到店</span>;
			statusEdit = 
			<span>
				<span className="status__edit__bar">|</span>
				<span className="status__edit__icon">
					<img src={require('../../../images/edit.svg')} onClick={() => editBooking(booking)}/>
				</span>
			</span>;
			break;
		case 'cancel':
			garyBackground = 'garyBackground';
			stautsBtn = <span className="cancelBtnTxt">{statusTime['cancel']}取消預約</span>;
			statusEdit = 
			<span>
				<span className="status__edit__bar">|</span>
				<span className="status__edit__icon">
					<img src={require('../../../images/edit.svg')} onClick={() => editBooking(booking)}/>
				</span>
			</span>;
			break;
		default:
			stautsBtn = (
				<React.Fragment>
					<button className="btn cancelBtn" onClick={() => setStatus(booking, 'cancel')}>
						取消預約
					</button>
					<button className="btn confirmedBtn" onClick={() => setStatus(booking, 'confirmed')}>
						確認預約
					</button>
				</React.Fragment>
			);
			stautsBtnTime = null;
			break;
	}

	return {
		stautsBtn,
		stautsBtnTime,
		statusEdit,
		garyBackground
	};
};


const BookingListCell = ({
	booking,
	customQ,
	setStatus,
	editBooking,
	goBookingListCard,
	setBookingInfoId,
	setShowWarning,
	setWarningTales,
	setSeatedBooking,
	serviceTimeOptionEnabled
}) => {
	const bookingListCellRef = React.useRef();
	let serviceTagBlock = [],
		quantityBlock = [],
		questionsBlock = [];
	let existQuestion = customQ.map((q) => {
		return q.id.toString();
	});
	let serviceMark = JSON.parse(booking['customized_questions']);
	let keys = Object.keys(serviceMark);

	customQ.forEach((q) => {
		keys.forEach((el) => {
			if (q['id'].toString() === el && typeof serviceMark[el] === 'object' && existQuestion.indexOf(el) !== -1) {
				let content = JSON.parse(q.content);
				let ansObj = serviceMark[el];

				Object.keys(ansObj).forEach((index) => {
					let optionAnsw = {};
					if (content[index] && ansObj[index] === -1) {
						serviceTagBlock.push(content[index].name);
					} else if (content[index] && ansObj[index] !== -1 && ansObj[index] !== 0) {
						optionAnsw['name'] = content[index].name;
						optionAnsw['value'] = ansObj[index];
						quantityBlock.push(optionAnsw);
					}
				});
			} else if (
				q['id'].toString() === el &&
				q['question_type'] === 'question' &&
				existQuestion.indexOf(el) !== -1 &&
				serviceMark[el] !== ''
			) {
				questionsBlock.push(serviceMark[q.id]);
			}
		});
	});

	useEffect(() => {
		$('.bookingStatus-dropdown').popover({
			html: true,
			trigger: 'click',
			container: '.bookingTableMap__bookingList__card'
		});
	}, []);

	useEffect(
		() => {
			if (goBookingListCard !== null && goBookingListCard !== '') {
				let bookingTableMapBookingList = document.getElementsByClassName('bookingTableMap__bookingList')[0];
				let $booking = bookingListCellRef.current;
				$booking.classList.add('goBookingListCard');
				bookingTableMapBookingList.scrollTop = $booking.offsetTop - 190;
				setTimeout(() => {
					$booking.classList.remove('goBookingListCard');
					setBookingInfoId(null);
				}, 1000);
			}
		},
		[ goBookingListCard, setBookingInfoId ]
	);

	const renderGroupSeat = () => {
		let seatGroup = {},
			notHaveSeatGroup = [];

		JSON.parse(booking.seating_json).map((seat) => {
			let emptyGroupSeat = true;
			Object.keys(tablemapTableGroup).map((group) => {
				let findSeatIndx = tablemapTableGroup[group].indexOf(seat.table_number);
				if (findSeatIndx !== -1) {
					emptyGroupSeat = false;
					if (!seatGroup.hasOwnProperty(group)) {
						seatGroup[group] = [];
						seatGroup[group].push(seat.table_number);
					} else {
						seatGroup[group].push(seat.table_number);
					}
				}
				return null;
			});
			if (emptyGroupSeat) notHaveSeatGroup.push(seat.table_number);
			return null;
		});

		return (
			<React.Fragment>
				{Object.keys(seatGroup).map((group, index) => {
					return (
						<div key={index}>
							<span>{group}</span>
							{seatGroup[group].map((seat, seatIndex) => {
								return <span key={seatIndex}>{seat}</span>;
							})}
						</div>
					);
				})}
				<div className="normalSeats">
					{notHaveSeatGroup.map((seat, index) => {
						return <span key={index}>{seat}</span>;
					})}
				</div>
			</React.Fragment>
		);
	};

	const renderBookingSource = () => {
		if (booking.source === 'online') {
			if (booking.source_url_t === 'google') {
				return <span className="bookingSourceIcon bookingSourceIcon-google" />;
			} else if (booking.source_url_t === 'facebook') {
				return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
			} else if (booking.source_url_t === 'instagram') {
				return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
			} else if (booking.source_url_t === 'menuApp') {
				return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
			} else if (booking.source_url_t === 'other') {
				return <span className="bookingSourceIcon bookingSourceIcon-other" />;
			}
		}

		if(booking.source === 'google_booking' && booking.source_url_t === 'google_reservation') {
			return <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />;
		}
	};

	const renderOnsiteTag = () => {
		if (booking.on_site) {
			return <div className="onSiteTag">現場</div>;
		}
	};

	const seatedWarning = useCallback(
		(booking) => {
			API.checkSeated(booking.id).then((data) => {
				if (data.overlay_table.length !== 0) {
					setShowWarning(true);
					setWarningTales(data.overlay_table);
					setSeatedBooking(booking)
				} else {
					setStatus(booking, 'seated');
				}
			});
		},
		[ setStatus, setShowWarning, setWarningTales, setSeatedBooking ]
	);

	let statusBtnRes = statusBtn(booking, setStatus, seatedWarning, editBooking);

	const renderStatusBtn = () => {
		const today = moment().format('YYYY-MM-DD');
		const date = browserHistory.getCurrentLocation().query.date;
		const isToday = today === moment(new Date(date)).format('YYYY-MM-DD');

		if (isToday) {
			return <ShortCutStatusBtn booking={booking} statusBtnRes={statusBtnRes} />;
		} else if (!isToday && (booking.status === 'cancel' || booking.status === 'finish')) {
			//非當日：取消預約和完成消費才需要出現
			return <ShortCutStatusBtn booking={booking} statusBtnRes={statusBtnRes} />;
		}
	};

	const renderSeatArea = () => {
		const { source, space_group_selection_t } = booking;
		if (source === 'online' && space_group_selection_t !== '') {
			return (
				<span className="seatArea">
					<span>{space_group_selection_t}</span>
				</span>
			);
		}
	};

	const renderDiningTime = () => {
		let diningTimeContent = booking.service_time_str;
		let diningTime = booking.dining_time;
		let diningTime_hour = Math.floor(parseInt(diningTime, 10) / 60);
		let diningTime_min = Math.floor(parseInt(diningTime, 10) % 60);

		if(serviceTimeOptionEnabled && booking.source !== 'offline') {
			return (
				<div className="diningTime">
					<div className="diningTime_time">{diningTimeContent}</div>
					<div className="diningTime_time">({diningTime_hour} 小時 {diningTime_min} 分)</div>
				</div>
			)
		} else {
			return (
				<div className="diningTime">
					<div className="diningTime_time">({diningTime_hour} 小時 {diningTime_min} 分)</div>
				</div>
			)
		};
	};

	return (
		<section
			ref={bookingListCellRef}
			id={booking.id}
			className={`bookingTableMap__bookingList__card ${statusBtnRes.garyBackground}`}
		>
			<div className="listInfo">
				<div className="listInfo__cell">
					<span>{booking.time}</span>
					{renderBookingSource()}
					{renderDiningTime()}
				</div>
				<div className="listInfo__cell">
					<p>
						{renderOnsiteTag()}
						<div className="listInfo__cell__name">
							<span>{booking.last_name}</span>&nbsp;
							<span>{booking.gender_desc}</span>
						</div>
					</p>
					<p className="listInfo__cell__tel">{booking.phone_number}</p>
					{CustomerTag(booking)}
				</div>
				<div className="listInfo__cell">
					<span>{booking.attendance}</span>
					<span>位</span>
				</div>
			</div>
			<div className="tableInfo">{renderGroupSeat()}</div>
			<div className="seatAreaInfo">{renderSeatArea()}</div>
			<div className="questionInfo">
				<div className="tagsWrap">
					{serviceTagBlock.map((tag, index) => {
						return <span key={index}>{tag}</span>;
					})}
					{quantityBlock.map((ele, index) => {
						return (
							<span key={index}>
								{ele.name}x{ele.value}
							</span>
						);
					})}
				</div>
				<div className="noteWRap">
					<ul>
						{questionsBlock.map((q, index) => {
							return <li key={index}>{q}</li>;
						})}
					</ul>
				</div>
			</div>
			{booking.shop_memo && booking.shop_memo.trim().length !== 0 ? (
				<div className="shopMemo">{booking.shop_memo}</div>
			) : null}
			{booking.memo && booking.memo.trim().length !== 0 ? <div className="memo">{booking.memo}</div> : null}

			{renderStatusBtn()}
			{
				booking.status !== 'finish' &&
				booking.status !== 'cancel' &&
				booking.status !== 'no_show' && (
					<span className="status__edit">
					<BookingStatus setStatus={setStatus} booking={booking} seatedWarning={seatedWarning} />
					<span className="status__edit__bar">|</span>
					<span style={{ cursor: 'pointer', transform: 'scale(0.8)'}}><img src={require('../../../images/edit.svg')} onClick={() => editBooking(booking)}/></span>
				</span>
				)
			}

			<div className="updateTime">
				{booking.source_t}
				<span>
					{booking.updated_at !== booking.created_at ? (
					` ｜ 最後更新: ${new moment(booking.updated_at).format('YYYY/MM/DD HH:mm')}`
					) : null}
				</span>
				<div>
					建立: {new moment(booking.created_at).format('YYYY/MM/DD HH:mm')} ｜ 查詢代碼:{' '}
					{booking.active_code}
				</div>
			</div>
		</section>
	);
};

export default BookingTableMap;
