import React, { Fragment, useEffect, useContext } from 'react';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { MessageContext } from '../../crmContext/MessageContext';
import { MessageAddStateContext } from '../../crmContext/MessageAddContext';

import MenuTalkItem from './crmMenuTalk/CrmMenuTalkItem';

const MessageInfoSection = ({ children }) => {
	return <section className="crmMessageAdd_block">{React.Children.map(children, (child) => child)}</section>;
};

const InfoSectionTitle = () => {
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;

  if(editType === 'sms') {
    return <h3>簡訊內容</h3>;
  } else if(editType === 'menuTalk') {
    return <h3>MENU私訊內容</h3>;
  }
};

const MessageTitle = () => {
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { messageSetting, error } = MessageAddState;
  const { title } = messageSetting;

  const inputText = editType === 'sms' ? '請輸入簡訊標題' : '請輸入MENU私訊標題';
  const sendObject = editType === 'sms' ? '顧客' : '粉絲';

  useEffect(() => {
    const titleInput = document.getElementsByClassName('titleInput')[0];

    if(error.includes('title')) {
      titleInput.style.border = '1px solid #EF6868';
    } else {
      titleInput.style.border = '1px solid #C8C8C8';
    }
  }, [ error ]);

  const handleTile = (e) => {
		const title = e.target.value;
		MessageAddDispatch({ type: 'setMessageTitle', title });
	};

  return (
    <div className="crmMessageAdd_input" style={{ marginTop: '24px', marginBottom: '24px' }}>
      <h5>標題</h5>
      <input
        type="text"
        value={title}
        onChange={(e) => handleTile(e)}
        placeholder={inputText}
        className="titleInput"
      />
      { error.includes('title') ?
        <div className="error_text">{ inputText }。</div> :
        <div>標題文字將不會顯示給{ sendObject }。</div>
      }
    </div>
  )
}

const MessageContent = () => {
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { messageSetting, error, errorCoupon, insertCoupons, originCouponLists } = MessageAddState;
  const { content, menuTalkContent, uploadImg } = messageSetting;

  useEffect(() => {
    const textarea = document.getElementsByClassName('textarea')[0];

    if(textarea) {
      if(error.includes('content') || error.includes('content_invalid')) {
        textarea.style.border = '1px solid #EF6868';
      } else {
        textarea.style.border = '1px solid #C8C8C8';
      }
    }
  }, [ error ]);

  useEffect(() => {
    if(editType === 'sms') {
      MessageAddDispatch({ type: 'setSmsContentCursorPosition', cursorPosition: content.length });
    }
  }, [ editType, MessageAddDispatch, content ]);

  useEffect(() => {
    let insertCoupons = [];

    if(editType === 'menuTalk') {
      menuTalkContent.forEach((item, index) => {
        if(item.type === 'voucher') {
          let coupon = menuTalkContent[index];

          const couponIndex = originCouponLists.findIndex((coupon) => {
            return coupon.id === item.voucherId;
          })

          coupon.endDateTime = originCouponLists[couponIndex].endDateTime;

          insertCoupons.push(coupon);
        }
      })

      MessageAddDispatch({ type: 'setMenuTalkInsertCoupons', insertCoupons });
    }
  }, [ editType ]);

  const handleContent = (e) => {
		const content = e.target.value;
		MessageAddDispatch({ type: 'setMessageContent', content });
	};

  const handleCursorPosition = () => {
    let cursorPosition = document.getElementById('crm_sms_content_textarea').selectionStart;
		MessageAddDispatch({ type: 'setSmsContentCursorPosition', cursorPosition });
  };

  const showInsertMenuCouponModal = () => {
    MessageAddDispatch({ type: 'showInsertMenuCouponModal' });
  };

  const addContent = () => {
    const newMenuTalkContent = _.cloneDeep(menuTalkContent);
    const newText = {
      type: 'text',
      text: ''
    };

    newMenuTalkContent.push(newText);
    MessageAddDispatch({ type: 'setMenuTalkContent', menuTalkContent: newMenuTalkContent });
  }

  const handleMenuTalkImage = (e) => {
		e.preventDefault();

    let reader = new FileReader();
		let file = e.target.files[0];
		if (file.size > 2097152) {
			window.app.alert.setMessage('檔案大小不可超過 2MB', 'error');
		} else {
			reader.onloadend = () => {
        const newMenuTalkContent = _.cloneDeep(menuTalkContent);
        const newUploadImg = _.cloneDeep(uploadImg);

        newMenuTalkContent.push({
          type: 'new_image',
          url: reader.result,
          imageName: file.name
        });

        newUploadImg.push(file);

        MessageAddDispatch({ type: 'setMenuTalkContent', menuTalkContent: newMenuTalkContent });
        MessageAddDispatch({ type: 'setMenuTalkUploadImg', uploadImg: newUploadImg });
			};

			reader.readAsDataURL(file);
		}
	};

  const renderActionBtn = () => {
    if(editType === 'sms') {
      return (
        <button onClick={() => showInsertMenuCouponModal()}>
          <img src={require('../../../../images/crm/coupon_icon.svg')} alt='' />
          嵌入優惠券連結
        </button>
      )
    } else if(editType === 'menuTalk') {
      return (
        <Fragment>
          <div className="menuTalk_actionBtn_text">點擊 Icon 新增文字內容、分享優惠券及圖片。</div>
          { error.includes('content') ? <div className="error_text">請新增MENU私訊內容，內容不可為空白。</div> : null }
          <div className="menuTalk_actionBtn">
            <button onClick={() => addContent()}>
              <img src={require('../../../../images/crm/edit_content_icon.svg')} alt='' />
              <span className="txt">新增文字</span>
            </button>
            <button onClick={() => showInsertMenuCouponModal()}>
              <img src={require('../../../../images/crm/coupon_icon.svg')} alt='' />
              <span className="txt">分享優惠券</span>
            </button>
            <button>
              <label htmlFor="upload-menuTalk-img">
                <img src={require('../../../../images/crm/edit_img_icon.svg')} alt='' />
                <span className="txt">插入圖片</span>
							</label>
							<input
								id="upload-menuTalk-img"
								onChange={(e) => handleMenuTalkImage(e)}
								onClick={(e) => {
									e.target.value = null;
								}}
								className="file-upload__input"
								type="file"
								name="file-upload-menuTalk"
								accept="image/x-png,image/gif,image/jpeg"
							/>
            </button>
          </div>
        </Fragment>
      )
    }
  };

  const handleMENUContent = (index, e) => {
    const newMenuTalkContent = _.cloneDeep(menuTalkContent);

    newMenuTalkContent[index].text = e.target.value;
    MessageAddDispatch({ type: 'setMenuTalkContent', menuTalkContent: newMenuTalkContent });
  };

  const deleteMENUContent = (index, item) => {
    const newMenuTalkContent = _.cloneDeep(menuTalkContent);
    const newInsertCoupons = _.cloneDeep(insertCoupons);
    const newUploadImg = _.cloneDeep(uploadImg);

    newMenuTalkContent.splice(index, 1);
    MessageAddDispatch({ type: 'setMenuTalkContent', menuTalkContent: newMenuTalkContent });

    if(item.type === 'voucher') {
      newInsertCoupons.forEach((coupon, index) => {
				if (coupon['voucherId'] === item['voucherId']) {
					newInsertCoupons.splice(index, 1);
				}
			});

      MessageAddDispatch({ type: 'setMenuTalkInsertCoupons', insertCoupons: newInsertCoupons });
    } else if(item.type === 'new_image') {
      newUploadImg.forEach((img, index) => {
				if (img['imageName'] === item['imageName']) {
					newUploadImg.splice(index, 1);
				}
			});

      MessageAddDispatch({ type: 'setMenuTalkUploadImg', uploadImg: newUploadImg });
    }
  };

  const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		changeContentOrder({ result });
  };

  const changeContentOrder = ({ result }) => {
		const startIndex = result.source.index;
		const endIndex = result.destination.index;

		const newContent = reorderContent({ menuTalkContent, startIndex, endIndex });
    MessageAddDispatch({ type: 'setMenuTalkContent', menuTalkContent: newContent });
	};

  const reorderContent = ({ menuTalkContent, startIndex, endIndex }) => {
    let result = JSON.parse(JSON.stringify(menuTalkContent));
    const [ removed ] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const renderContent = () => {
    const renderNumberOfCharacters = () => {
      let contentLength = content.trim().length;
      return <div className="countText">{contentLength} 字</div>;
    };

    if(editType === 'sms') {
      return (
        <Fragment>
          <div className="textarea">
            <textarea
              id='crm_sms_content_textarea'
              value={content}
              onChange={(e) => handleContent(e)}
              placeholder="請輸入簡訊內文"
              maxLength="335"
              onClick={() => handleCursorPosition()}
              onKeyUp={() => handleCursorPosition()}
            />
            { renderNumberOfCharacters() }
          </div>
          { error.includes('content') ? <div className="error_text">內文不可為空白。</div> : null }
          { error.includes('content_invalid') ? <div className="error_text">內容含有無法使用簡訊傳送的字元。</div> : null }
        </Fragment>
      )
    } else if(editType === 'menuTalk') {
      return (
        <Fragment>
          <DragDropContext
            onDragEnd={onDragEnd}
          >
            <Droppable
              droppableId="contentDroppable"
            >
              {(provided, _) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  { menuTalkContent.map((item, index) => {
                    let blockStyle = '';

                    if(item.type === 'text') {
                      blockStyle = 'edit_block edit_content';
                    } else if(item.type === 'new_image' || item.type === 'image') {
                      blockStyle = 'edit_block image_block';
                    } else {
                      if(errorCoupon.length > 0) {
                        // 檢查優惠券有效期限是否在排程時間內
                        const findIndex = errorCoupon.map((coupon) => coupon.voucherId).indexOf(item.voucherId);
                        
                        if(findIndex !== -1) {
                          blockStyle = 'edit_block voucher_block';
                        } else {
                          blockStyle = 'edit_block';
                        }
                      } else {
                        blockStyle = 'edit_block';
                      }
                    };

                    return (
                      <Draggable
                        key={index}
                        index={index}
                        draggableId={"contentDraggable_" + index}
                      >
                        {(provided, snapshot) => (
                          <div 
                            key={index}
                            className={blockStyle}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              border: snapshot.isDragging ?
                              '1px solid #676767' : '1px solid #c8c8c8'
                            }}
                          >
                            <MenuTalkItem>
                              <MenuTalkItem.Header
                                index={index}
                                item={item}
                                deleteMENUContent={deleteMENUContent}
                              />
                              <MenuTalkItem.Content
                                index={index}
                                item={item}
                                couponLists={originCouponLists}
                                handleMENUContent={handleMENUContent}
                              />
                            </MenuTalkItem>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  { provided.placeholder }
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Fragment>
      )
    }
  };

  return (
    <div className="crmMessageAdd_input">
      <h5>內文</h5>
      { renderActionBtn() }
      { renderContent() }
    </div>
  )
}

MessageInfoSection.Title = InfoSectionTitle;
MessageInfoSection.MessageTitle = MessageTitle;
MessageInfoSection.MessageContent = MessageContent;

export default MessageInfoSection;
