import React from 'react'
//import Dropzone from 'react-dropzone'
//import { handleError } from '../../libs/handler'
// import { config } from '../../utils/config'

var createReactClass = require('create-react-class');
// console.log(config)
export default createReactClass({
  renderLogo() {
    if(this.props.imagePreviewUrl) {
      return this.props.imagePreviewUrl;
    } else if(this.props.data.logo) {
      return this.props.data.logo;
    } else {
      return require("../../images/menuUser/Profile_Picture.svg");
    }
  },
  addShopDefaultSrc(e) {
		e.onerror = null;
		e.target.src = require('../../images/menuUser/Profile_Picture.svg');
	},
  render() {
    return (
      <div id="shopForm">
        <div className="shopForm shopForm_edit">
          <div className="shopForm_block">
            <div className='shopForm_info_logo_block'>
              <div className="form-group">
                <img
                  className="shopForm_info_logo"
                  width="100"
                  src={this.renderLogo()}
                  onError={this.addShopDefaultSrc}
                  alt=""
                />
                <div>
                  <label htmlFor="upload" className="shopForm_info_logo_change">更換頭貼</label>
                  <input
                    id="upload"
                    onChange={this.props.onImageChange}
                    className="file-upload__input"
                    type="file"
                    name="file-upload"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
              </div>
            </div>

            <div className='shopForm_info_block'>
              <div className="shopForm_info">
                <div className="shopForm_info_title">店家名稱</div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder="店家名稱"
                  name="display_name_update"
                  value={this.props.updateData.display_name_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="shopForm_info">
                <div className="shopForm_info_title">分店名稱（選填）</div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder="分店名稱（選填）"
                  name="branch_update"
                  value={this.props.updateData.branch_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="shopForm_info">
                <div className="shopForm_info_title">聯絡電話</div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder="聯絡電話"
                  name="phone_number_update"
                  value={this.props.updateData.phone_number_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="shopForm_info">
                <div className="shopForm_info_title">店家地址</div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder="店家地址"
                  name="address_update"
                  value={this.props.updateData.address_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="shopForm_info">
                <div className="shopForm_info_title">店家網址</div>
                <input
                  type="text" 
                  className="form-control shopForm_info_input"
                  placeholder="店家網址"
                  name="website_url_update"
                  value={this.props.updateData.website_url_update}
                  onChange={this.props.onInputChange}
                />
              </div>
              <div className="text-right">
                <button className="shopForm_btn btn_cancel" onClick={this.props.cancelEdit}>取消</button>
                <button className="shopForm_btn btn_save" type="submit" onClick={this.props.onSubmit}>儲存</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});
