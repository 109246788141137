import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Modal } from 'antd';
import Datetime from 'react-datetime';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

import { getCrmMessagesSms, updateCrmSmsSchedule } from '../api/CrmMessageSmsApi';
import { getCrmMessagesMenuTalk, updateCrmMenuTalkSchedule } from '../api/CrmMessageMenuTalkApi';

import { MessageContext } from '../crmContext/MessageContext';
import { MessageAddStateContext } from '../crmContext/MessageAddContext';

const EditDateTimeModal = () => {
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { messageId, messageType, originMessages } = MessageState;

  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { messageSetting, originCouponLists } = MessageAddState;
  const { status, scheduleTime } = messageSetting;

  const [ menuTalkInfo, setMenuTalkInfo ] = useState(null);
  const [ errorType, setErrorType ] = useState('');

  const dateText = status === 'real_time' ? '今日' : '此日';

  // useEffect(() => {
  //   const dateTime = document.getElementById('editDateTime');
  //   const error = document.getElementsByClassName('error_text')[0];

  //   if(errorType) {
  //     error.style.display = 'block';

  //     if(dateTime) {
  //       dateTime.getElementsByClassName('form-control')[0].style.border = '1.5px solid #EF6868';
  //     }
  //   } else {
  //     error.style.display = 'none';

  //     if(dateTime) {
  //       dateTime.getElementsByClassName('form-control')[0].border = '1px solid #C8C8C8';
  //     }
  //   }
  // }, [ errorType, status ]);

  useEffect(() => {
    const error = document.getElementsByClassName('error_coupon_expired')[0];

    if(errorType === 'expired_coupon') {
      error.style.display = 'block';
    } else {
      error.style.display = 'none';
    }
  }, [ errorType ]);

  useEffect(() => {
    if(messageType === 'menuTalk') {
      const menuTalkIndex = originMessages.findIndex((msg) => {
        return msg.id === messageId;
      })

      const menuTalkInfo = originMessages[menuTalkIndex];

      setMenuTalkInfo(menuTalkInfo);
    }
  }, [ messageType, originMessages, messageId ]);

  const getCrmMessageList = async () => {
    try {
      const smsMessagesResult = await getCrmMessagesSms(),
        smsMessages = smsMessagesResult.data.crm_sms_body;
      const menuTalkMessagesResult = await getCrmMessagesMenuTalk(),
        menuTalkMessages = menuTalkMessagesResult.data.crm_menu_body;

      for(let i = 0; i < smsMessages.length; i++) {
        smsMessages[i].type = 'sms';
      };

      for(let j = 0; j < menuTalkMessages.length; j++) {
        menuTalkMessages[j].type = 'menuTalk';
      };

      const allMessages = smsMessages.concat(menuTalkMessages);

      MessageStateDispatch({ type: 'setInitMessages', originMessages: allMessages });
      MessageStateDispatch({ type: 'setStatusMessagesNum' });
    } catch (err) {
      console.log(err)
    }
  };

  const cancelPopup = useCallback(() => {
    MessageStateDispatch({ type: 'cancelPopup' });
	}, [ MessageStateDispatch ]);

	const handleMessageStatus = (type) => {
    MessageAddDispatch({ type: 'setMessageStatus', status: type });
	};

  const submit = async () => {
    const nowDateTime = new Date();
    
    if(messageType === 'menuTalk') {
      const menuTalkContent = JSON.parse(menuTalkInfo.contents);
      let insertCoupons = [];
      let errorCoupon = []; // 在排程日期前過期的優惠券
  
      menuTalkContent.forEach((item, index) => {
        if(item.type === 'voucher') {
          let coupon = menuTalkContent[index];
  
          const couponIndex = originCouponLists.findIndex((coupon) => {
            return coupon.id === item.voucherId;
          })
  
          coupon.endDateTime = originCouponLists[couponIndex].endDateTime;
  
          insertCoupons.push(coupon);
        }
      })
      
      for (let i = 0; i < insertCoupons.length; i++) {
        if(new Date(insertCoupons[i].endDateTime) < new Date(scheduleTime)){
          errorCoupon.push(insertCoupons[i])
        }
      };
  
      if(errorCoupon.length > 0) {
        return setErrorType('expired_coupon');
      };
    }

    if(nowDateTime > new Date(scheduleTime)) {
      window.app.alert.setMessage('排程時間應晚於現在時間', 'error');
      return;
    };

    const newScheduleTime = {
      status: status,
      schedule_time: scheduleTime
    };

    if(messageType === 'sms') {
      try {
        await updateCrmSmsSchedule(messageId, newScheduleTime);
        getCrmMessageList();
        cancelPopup();
      } catch(err) {
        console.log('update crm sms schedule err ------', err);
      }
    } else {
      try {
        await updateCrmMenuTalkSchedule(messageId, newScheduleTime);
        getCrmMessageList();
        cancelPopup();
      } catch(err) {
        console.log('update crm menuTalk schedule err ------', err);
      }

      // 檢查該日有無超過三則
      // setErrorType(true);
    }
  };

  const renderDateTime = () => {
    let inputProps = {
      placeholder: '自訂時間'
    };

    return (
      <div id='editDateTime' className="datetime">
        <Datetime
          inputProps={inputProps}
          value={scheduleTime}
          onChange={(datetime) => {
            MessageAddDispatch({ type: 'setScheduleTime', scheduleTime: datetime });
          }}
          dateFormat="YYYY-MM-DD"
          timeFormat="HH:mm"
          isValidDate={(currentDate) => {
            return currentDate.isAfter(moment().subtract(1, 'day'));
          }}
        />
        <CalendarOutlined />
      </div>
    )
  }

  return (
    <Modal
      title="修改排程時間"
      visible
      centered
      className="editDateTimeModal"
      onCancel={cancelPopup}
      footer={
        <button onClick={() => submit()}>確定修改</button>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {/* <div className='error_text '>提醒：{ dateText }傳送MENU私訊已達三則上限，請更改排程時間。</div> */}
      <div className='error_text error_coupon_expired' >此排程日期已超過優惠券結束日，請重新選擇日期。</div>
      <div className="crmMessageAdd_radio">
        <label>
          <input
            type="radio"
            name="sendTime"
            onChange={() => handleMessageStatus('real_time')}
            checked={status === 'real_time'}
          />
          立即傳送
        </label>
      </div>
      <div className="crmMessageAdd_radio" style={{ marginTop: '16px' }}>
        <label>
          <input
            type="radio"
            name="sendTime"
						onChange={() => handleMessageStatus('scheduled')}
						checked={status === 'scheduled'}
          />
          自訂時間
        </label>
      </div>
      { status === 'scheduled' ? renderDateTime() : null }
    </Modal>
  );
};

export default EditDateTimeModal;