import React, { Fragment } from "react";
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const BlockedWarning = ({
  modalType = 'addNew',
  addBookingSubmit,
  cancelBlockedWarning,
  isLoading
}) => {
  const content = modalType === 'editor' ? '確定修改此筆預約嗎？' : '確定新增此筆預約嗎？';

  return (
    <Modal
      title={`確定${modalType === 'editor' ? '修改' : '新增'}嗎？`}
      visible
      centered
      className="blockedWarningPopup"
      footer={
        <Fragment>
          <button
            className="btn_cancel"
            disabled={isLoading}
            onClick={cancelBlockedWarning}
          >
            取消
          </button>
          <button
            className="btn_save"
            disabled={isLoading}
            onClick={addBookingSubmit}
          >
            {isLoading ? <LoadingOutlined /> : '確認'}
          </button>
        </Fragment>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      此組號碼已被加入封鎖名單，{content}
    </Modal>
  )
}

export default BlockedWarning;