import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import { Link, browserHistory } from "react-router";
import moment from "moment";
import _ from 'lodash';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons'

import { CustomerContext } from '../crmContext/CustomerContext';
import { getCustomerData } from '../api/CrmCustomerApi';

import CustomerRecordInfo from '../crmCustomer/crmCustomerRecord/CrmCustomerRecordInfo';
import CustomerRecordList from '../crmCustomer/crmCustomerRecord/CrmCustomerRecordList';

import CustomerDeleteModal from '../crmCustomer/crmCustomerRecord/CrmCustomerDeleteModal';
import CustomerEditModal from './crmCustomerPopup/CrmCustomerEditorModal';
import RecordEditorModal from "./crmCustomerPopup/RecordEditorModal";

import NewAddBookingView from "../../AddNewBooking/NewAddBooking";
import BookingSystemApi from "../../booking_settings/BookingSystemApi";

import './crmCustomerRecordBox.scss';

const QUESTION = "question";
const API = new BookingSystemApi();

const CrmCustomerRecordBox = (props) => {
  const { CustomerState, CustomerStateDispatch } = useContext(CustomerContext);
  const {
    showAddCustomerModal,
    loading
  } = CustomerState;

  const [ isHiddenNewBookingAddView, setIsHiddenNewBookingAddView ] = useState(true);
  const [ data, setData ] = useState({
    records: [],
    calls: [],
    bookings: [],
    customer_tags: []
  });
  const [ isSaved, setIsSaved ] = useState(true);
  const [ edit, setEdit ] = useState({});
  const [ date, setDate ] = useState(new moment().format("YYYY-MM-DD"));
  const [ modalType, setModalType ] = useState('customer');
  const [ newSelected, setNewSelected ] = useState({});
  const [ customerInfo, setCustomerInfo ] = useState({});
  const [ customQ, setCustomQ ] = useState([]);
  const [ customQAns, setCustomQAns ] = useState({});
  const [ tempCustomQAns, setTempCustomQAns ] = useState({});

  const [ msgPoint, setMsgPoint ] = useState(0);
  const [ smsNotificationSettings, setSmsNotificationSettings ] = useState({});
  const [ emailNotificationSettings, setEmailNotificationSettings ] = useState({});

  const [ spg_hash_iv, setSpg_hash_iv ] = useState(null);
  const [ spg_hash_key, setSpg_hash_key ] = useState(null);
  const [ spg_merchant_id, setSpg_merchant_id ] = useState(null);
  const [ credit_enabled, setCredit_enabled ] = useState('');

  const [ showCustomerDeleteModal, setShowCustomerDeleteModal ] = useState(false);
  const [ showRecordEditorModal, setShowRecordEditorModal ] = useState(false);

  // record list
  const [ bookingRecords, setBookingRecords ] = useState([]);
  const [ queueRecords, setQueueRecords ] = useState([]);
  const [ customerRecords, setCustomerRecords ] = useState([]);
  const [ page, setPage ] = useState(1);
  const [ count, setCount ] = useState(0); // 預設為預約筆數
  const [ startIdx, setStartIdx ] = useState(0);
  const [ endIdx, setEndIdx ] = useState(10);
  const [ activeTab, setActiveTab ] = useState('booking'); // booking 預約, queue 排隊
  const [ selectRecord, setSelectRecord ] = useState(null);

  const diningTime = 120;
  const pageSize = 10;

  useEffect(() => {
		load(true);
		getCustomQuestion();
		getBookingSetting();
		getCreditEnabled();
  }, []);

  useEffect(() => {
    let newStartIdx = (page - 1) * pageSize;
    let newEndIdx = page * pageSize;

    setStartIdx(newStartIdx);
    setEndIdx(newEndIdx);
  }, [ page ]);

  const getParentPath = () => {
    const pathState = browserHistory.getCurrentLocation().state;

    if(pathState?.customerList) {
      return `${window.domain}/dashboard/customers${pathState.customerList}`;
    } else {
      return window.location.pathname
      .split("/")
      .slice(0, -1)
      .join("/");
    }
  };

  const load = (setLoading) => {
    let newSelected = {};
    let customerInfo = {};

    if(setLoading) CustomerStateDispatch({ type: 'setLoading', loading: true })

    getCustomerData(props.params.id)
      .then(res => {
        const { data } = res;
        let newBookingRecords = data.customer.booking_statics.bookings
          .filter((record) => {
            return record.booking.source !== 'line_up';
          })
          .sort((a, b) => {
            return new Date(b.booking.booking_datetime) - new Date(a.booking.booking_datetime);
          });
        let newQueueRecords = data.customer.queue_records
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });

        newSelected["id"] = data.customer.id;
        newSelected["bookingDate"] = new moment().format("YYYY-MM-DD");
        newSelected["time"] = "";
        newSelected["dining_time"] = 120;

        customerInfo["phone"] = data.customer.phone_number;
        customerInfo["name"] = data.customer.last_name;
        customerInfo["gender"] = data.customer.gender;
        customerInfo["email"] = data.customer.email;
        customerInfo["tags"] = data.customer.customer_tags;
				customerInfo["note"] = data.customer.note;

        setData(data.customer);
        // add new booking
        setNewSelected(newSelected);
        setCustomerInfo(customerInfo);

        let newChecked = data.customer.customer_tags.map(function(tag) {
					return tag.id;
				});
        CustomerStateDispatch({ type: 'setCustomerInfo',
          id: data.customer.id,
          last_name: data.customer.last_name,
          birthday: data.customer.birthday,
          gender: data.customer.gender,
          phone_number: data.customer.phone_number,
          company: data.customer.company,
          company_id: data.customer.company_id,
          company_tele: data.customer.company_tele,
          email: data.customer.email,
          note: data.customer.note,
          tags_checked: newChecked
        });

        CustomerStateDispatch({ type: 'setLoading', loading: false })

        setBookingRecords(newBookingRecords);
        setQueueRecords(newQueueRecords);
        setCustomerRecords(data.customer.records);

        if(activeTab === 'booking') setCount(newBookingRecords.length);
        if(activeTab === 'queue') setCount(newQueueRecords.length);
        if(activeTab === 'customer') setCount(data.customer.records.length);
      })
      .catch(error => {
        console.log("error----", error);
      });
  };

  const getCustomQuestion = () => {
    let customQAns = {};

    API.getQuestions().then(data => {
      data.map(q => {
        if (q.question_type === QUESTION) {
          customQAns[q.id] = "";
        } else {
          customQAns[q.id] = {};
        }
        return true;
      });

      setCustomQ(data);
      setCustomQAns(customQAns);
      setTempCustomQAns(JSON.stringify(customQAns));
    });
  };

  const getBookingSetting = () => {
    try {
      API.getBookingSetting().then(data => {
        setMsgPoint(data.shop.msg_point);
        setSmsNotificationSettings(data.sms_notification_settings);
        setEmailNotificationSettings(data.email_notification_settings);
        setSpg_hash_iv(data.spg_hash_iv);
        setSpg_hash_key(data.spg_hash_key);
        setSpg_merchant_id(data.spg_merchant_id);
      })
    } catch(err) {
      console.log('customer get booking setting err ----------', err);
    }
  };

  const getCreditEnabled = () => {
    $.ajax({url: window.domain + '/dashboard/booking_settings/spg_data',
      method: 'GET',
      xhrFields: { withCredentials: true }})
    .done(function(data){
      setCredit_enabled(data.credit_enabled);
    }.bind(this))
    .fail(function(xhr){
      console.log('get credit enabled err ---------')
    });
  };

  const hideNewAddBookingModal = () => {
		let bodyEle = document.getElementsByTagName("body");
		bodyEle[0].classList.remove("modal-open");
    
    setIsHiddenNewBookingAddView(true);
    setCustomQAns(JSON.parse(tempCustomQAns));
	};

  const handleBookingClick = () => {
		let bodyEle = document.getElementsByTagName("body");
		bodyEle[0].classList.add("modal-open");

    setIsHiddenNewBookingAddView(false);
	};

  const handleEditCustomer = () => {
    CustomerStateDispatch({ type: 'setAddCustomerModal', show: false });
  };

  const resetPageSetting = () => {
    setPage(1);
    setStartIdx(0);
    setEndIdx(10);
  };

  const updateData = () => {
    load();
    setActiveTab('booking');
  };

  const pageClick = (page) => {
    setPage(page);
  };

  const handleRecordSubmit = async(data, type, url) => {
    try {
      await axios({
        method: type,
        url: window.domain + url,
        data: data,
        withCredentials: true
      });

      let msg = '';

      if(type === 'post') msg = '已新增紀錄';
      if(type === 'put') msg = '已編輯紀錄';
      if(type === 'delete') msg = '已刪除紀錄';

      load();

      window.app.alert.setMessage(msg, "done");
    } catch(error) {
      console.log('customer record update error ----', error);
    }
  }

  return (
    <div className={`crmCustomerRecordBox ${loading ? 'overflow_hidden' : ''}`}>
      {
        loading &&
        <div className="mask_loading" >
          <LoadingOutlined style={{ fontSize: 30 }} />
          <p>Loading ...</p>
        </div>
      }
      <div className="btn_return">
        <Link to={getParentPath()}>
          <img
            src={require("../../../images/crm/return_icon.svg")}
            alt=''
          />
          返回顧客列表
        </Link>
      </div>

      <CustomerRecordInfo
        data={data}
        load={load}
        setIsHiddenNewBookingAddView={setIsHiddenNewBookingAddView}
        setShowRecordEditorModal={setShowRecordEditorModal}
      />
      <CustomerRecordList
        customQ={customQ}
        bookingRecords={bookingRecords}
        queueRecords={queueRecords}
        customerRecords={customerRecords}
        startIdx={startIdx}
        endIdx={endIdx}
        page={page}
        count={count}
        pageSize={pageSize}
        activeTab={activeTab}
        pageClick={pageClick}
        setCount={setCount}
        resetPageSetting={resetPageSetting}
        setActiveTab={setActiveTab}
        setSelectRecord={setSelectRecord}
        setShowRecordEditorModal={setShowRecordEditorModal}
      />

      {
        showAddCustomerModal &&
        <CustomerEditModal
          load={load}
          setShowCustomerDeleteModal={setShowCustomerDeleteModal}  
        />
      }

      {
        showCustomerDeleteModal &&
        <CustomerDeleteModal
          id={data.id}
          setShowCustomerDeleteModal={setShowCustomerDeleteModal}
          handleEditCustomer={handleEditCustomer}
        />
      }

      {
        showRecordEditorModal &&
        <RecordEditorModal
          id={data.id}
          url={props.record_url}
          selectRecord={selectRecord}
          setSelectRecord={setSelectRecord}
          handleRecordSubmit={handleRecordSubmit}
          setShowRecordEditorModal={setShowRecordEditorModal}
        />
      }

      {!isHiddenNewBookingAddView && (
        <NewAddBookingView
          hideNewAddBookingModal={hideNewAddBookingModal}
          updateData={updateData}
          modalType={modalType}
          date={date}
          diningTime={diningTime}
          newSelected={newSelected}
          customerInfo={customerInfo}
          customQAns={customQAns}
          customQ={customQ}
          msgPoint={msgPoint}
          smsNotificationSettings={smsNotificationSettings}
          emailNotificationSettings={emailNotificationSettings}
          spg_hash_iv={spg_hash_iv}
          spg_hash_key={spg_hash_key}
          spg_merchant_id={spg_merchant_id}
          credit_enabled={credit_enabled}
        />
      )}
    </div>
  );
}

CrmCustomerRecordBox.defaultProps = {
	record_url: "/dashboard/records",
	tag_url: "/dashboard/customer_tags.json"
};

export default CrmCustomerRecordBox;