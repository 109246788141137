import React from "react";
import $ from "jquery";
import { browserHistory } from "react-router";
import { Pagination } from "antd";

import CustomerToolbar from "./CustomerToolbar";
import CustomerForm from "./CustomerForm";
import CustomerList from "./CustomerList";

import '../../sass/customers.scss';

import { handleError } from "../../libs/handler";
var createReactClass = require("create-react-class");
export default createReactClass({
	load() {
		$.ajax({
			url: window.domain + this.props.url,
			dataType: "json",
			data: { page: this.state.page, per: this.state.per },
			xhrFields: { withCredentials: true },
			success: function (res) {
				this.setState({
					data: res.customers,
					page_num: Math.ceil(res.meta.size / this.state.per),
				});
			}.bind(this),
			error: function (xhr) {
				handleError(xhr);
			},
		});

		$.ajax({
			url: window.domain + "/dashboard/customers/count.json",
			dataType: "json",
			xhrFields: { withCredentials: true },
			success: function (res) {
				this.setState({ count: res.count });
			}.bind(this),
			error: function (xhr) {
				handleError(xhr);
			},
		});
	},
	handlePageClick(value, callback) {
		console.log("handlePageClick value", value);

		this.setState({ page: value }, function () {
			callback();
			browserHistory.push(
				`${window.location.pathname}?page=${this.state.page}&per=${this.state.per}`
			);
			this.load();
		});
	},
	pageClick(value) {
		this.setState({ page: value }, function () {
			browserHistory.push(
				`${window.location.pathname}?page=${this.state.page}&per=${this.state.per}`
			);
			this.load();
		});
	},
	handlePerSelect(current, size) {
		this.setState({ per: size }, function () {
			browserHistory.push(
				`${window.location.pathname}?page=${this.state.page}&per=${this.state.per}`
			);
			this.load();
		});
	},
	clearMessage() {
		this.setState({ messages: "" });
	},
	getDefaultProps() {
		return {
			url: "/dashboard/customers",
		};
	},
	getInitialState() {
		return {
			data: [],
			page: 1,
			per: 10,
			page_num: 0,
		};
	},
	componentDidMount() {
		this.load();
		$("#addMember").on('click',
			function () {
				$(this.refs.form.refs.modal).modal("show");

				const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
				const modalDialog = document.getElementsByClassName('modal-dialog')[0];
				if(modalBackdrop) {
					modalBackdrop.style.display = 'none';
					modalDialog.style.top = '15%';
				}
			}.bind(this)
		);

		window.location.search.split("&").forEach(
			function (q) {
				if (q.search("page") >= 0) {
					var p = parseInt(q.split("=").pop(), 10);
					if (p > 0) {
						this.setState({ page: p });
					}
				} else if (q.search("per") >= 0) {
					var e = parseInt(q.split("=").pop(), 10);
					if (e > 0) {
						this.setState({ per: e });
					}
				} else {
				}
			}.bind(this)
		);
	},
	renderCount() {
		if (window.app.current_user.crm_limit < 5001) {
			return (
				<div className="customer_count">
					顧客總數：{this.state.count} / {window.app.current_user.crm_limit}
				</div>
			);
		} else {
			return <div className="customer_count">顧客總數：{this.state.count}</div>;
		}
	},
	render() {
		return (
			<div>
				<div className="container container-shadow customers_container">
					<h2>顧客列表</h2>
					<CustomerToolbar domain={window.domain} />
					<Pagination
						className="page-picker"
						current={this.state.page}
						pageSize={this.state.per}
						total={this.state.count}
						pageSizeOptions={[10, 20, 30, 40, 50]}
						locale={{ items_per_page: "筆/頁" }}
						onChange={this.pageClick}
						onShowSizeChange={this.handlePerSelect}
						showSizeChanger={true}
						itemRender={(_, type, originalElement) => {
							if (type === "prev") {
								return <span className="btn-pill">上一頁</span>;
							}
							if (type === "next") {
								return <span className="btn-pill">下一頁</span>;
							}
							return originalElement;
						}}
					/>

					<div className="menu-section">
						<table className="table table-hover table-style-select">
							<thead>
								<tr>
									<th style={{ width: 30 }} />
									<th>姓名</th>
									<th>性別</th>
									<th>電話</th>
									<th colSpan={2} />
								</tr>
							</thead>
							<tfoot>
								<tr>
									<td colSpan={6} />
								</tr>
							</tfoot>
							<CustomerList data={this.state.data} />
						</table>

						<Pagination
							className="page-picker"
							current={this.state.page}
							pageSize={this.state.per}
							total={this.state.count}
							pageSizeOptions={[10, 20, 30, 40, 50]}
							locale={{ items_per_page: "筆/頁" }}
							onChange={this.pageClick}
							onShowSizeChange={this.handlePerSelect}
							showSizeChanger={true}
							itemRender={(_, type, originalElement) => {
								if (type === "prev") {
									return <span className="btn-pill">上一頁</span>;
								}
								if (type === "next") {
									return <span className="btn-pill">下一頁</span>;
								}
								return originalElement;
							}}
						/>

						{this.renderCount()}
					</div>
				</div>
				<CustomerForm
					ref="form"
					domain={window.domain}
					onSubmitCallback={this.load}
					alert={this.props.alert}
				/>
			</div>
		);
	},
});
