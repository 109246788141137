import React, { useCallback, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';

import { updateCrmBlockedList } from '../api/CrmSettingsApi';
import useDebounce from '../utils/useDebounce';
import useBlockedList from '../utils/useBlockedList';
import DeleteConfirmModal from './crmSettingsPopup/DeleteConfirmModal';

const CrmBlockedList = (props) => {
  const [ isSaved, setIsSaved ] = useState(true);
  const [ isEditing, setIsEditing ] = useState(false);
	const [ blockedList, setBlockedList ] = useState([]);
	const [ blockedListNumber, setBlockedListNumber ] = useState('');
	const [ blockedListError, setBlockedListError ] = useState(''); // empty 未填, duplicate 重複的封鎖號碼
	const [ searchBlockedListNumber, setSearchBlockedListNumber ] = useState('');
  const [ isSearching, setIsSearching ] = useState(false);
  const [ currentWindowWidth, setCurrentWindowWidth ] = useState(window.innerWidth);

  const [ currentList, setCurrentList ] = useState([]);
  const [ selectNumbers, setSelectNumbers ] = useState([]);
  const [ showDeleteConfirmModal, setShowDeleteConfirmModal ] = useState(false);

  const debouncedSearchNum = useDebounce(searchBlockedListNumber, 300);
  const { response, loading, getBlockedList } = useBlockedList();
  const inputRef = useRef(null);

  const routerWillLeave = useCallback(
		() => {
			if (!isSaved && isEditing) return '您剛剛做的更動尚未儲存，請問您是否要離開？';
		},
		[ isSaved, isEditing ]
	);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, routerWillLeave);
		},
		[ props, routerWillLeave ]
	);

  useEffect(() => {
    if (response) {
      if (searchBlockedListNumber) {
        handleDebounceSearch(response);
      } else {
        setCurrentList(response);
      }

      setBlockedList(response);
    }
  }, [response, searchBlockedListNumber]);

  useEffect(() => {
    if(debouncedSearchNum || searchBlockedListNumber) handleDebounceSearch();
  }, [ debouncedSearchNum ]);

  useEffect(() => {
    if (currentWindowWidth < 768 && isSearching) {
      inputRef.current.focus();
    }
  }, [currentWindowWidth, isSearching]);

  const resizeScreen = useCallback(() => {
    let w_w = window.innerWidth;
    setCurrentWindowWidth(w_w);

    // if(w_w < 767 && searchBlockedListNumber) {
    //   setIsSearching(true);
    // } else {
    //   setIsSearching(false);
    // }
	}, []);

  useEffect(() => {
    window.addEventListener('resize', resizeScreen);
    return () => {
      window.removeEventListener('resize', resizeScreen);
    }
  }, [ resizeScreen ]);

  const handleDebounceSearch = (list = blockedList) => {
    let newBlockedList = [];

    list.forEach((num) => {
      let matchNumber = true;

      for (let i = 0; i < debouncedSearchNum.length; i++) {
        if (debouncedSearchNum[i] !== num[i]) matchNumber = false;
      }

      if (matchNumber) newBlockedList.push(num);
    });

    setCurrentList(newBlockedList);
  };

  const handleBlockedListNum = (e) => {
		const value = e.target.value;
		const reg = /^[0-9]*$/;
		const regTest = reg.test(value);
		
		if (regTest) {
			setBlockedListNumber(value);
		}
	};

  const updateBlockedList = async(type) => {
    try {
      let newBlockedList = [...currentList];

      if(type === 'add') {
        await updateCrmBlockedList({
          phone_numbers: [blockedListNumber],
          operation: 'add'
        }); 

        newBlockedList.push(blockedListNumber);
        setBlockedListError('');
        setBlockedListNumber('');
        window.app.alert.setMessage('已將號碼加入封鎖名單', 'done');
      }

      if(type === 'delete') {
        await updateCrmBlockedList({
          phone_numbers: selectNumbers,
          operation: 'delete'
        }); 

        newBlockedList = newBlockedList.filter(number=> !selectNumbers.includes(number));
        setSelectNumbers([]);
        setIsEditing(false);
        setIsSaved(true);
        setShowDeleteConfirmModal(false);
        window.app.alert.setMessage('已完成編輯', 'done');
      }

      setCurrentList(newBlockedList);
      getBlockedList();
    } catch(err) {
      console.log('crm settings update blocked list err -----', err);
    }
  }

  const addBlockedList = async() => {
    // error: empty 號碼為空, duplicate 重複號碼
    if(blockedListNumber.trim().length === 0) {
			return setBlockedListError('empty');
		}

		if(blockedList.indexOf(blockedListNumber) !== -1) {
			setBlockedListError('duplicate');
			return;
		}

    updateBlockedList('add');
	};

	const handleSearchBlockedList = (value) => {
    const reg = /^[0-9]*$/;
    const regTest = reg.test(value);

		if(value.length === 0) {
			setSearchBlockedListNumber('');
      setCurrentList(blockedList);
      setIsSearching(false);

			return;
		}

    if(regTest) {  
      setSearchBlockedListNumber(value);
    }
	};

  const handleEditToggle = () => {
    if(!isEditing) setIsSaved(false);
    setIsEditing(!isEditing);
    setSelectNumbers([]);

  };

  const handleCheckboxChange = (phoneNumber) => {
    setSelectNumbers((prevSelected) => {
      if (prevSelected.includes(phoneNumber)) {
        // 如果已經包含，則移除
        return prevSelected.filter((num) => num !== phoneNumber);
      } else {
        // 如果不包含，則加入
        return [...prevSelected, phoneNumber];
      }
    });
  };

  const handleShowDeleteBlockedModalToggle = () => {
    setShowDeleteConfirmModal(!showDeleteConfirmModal);
  };

  const handleRemoveSelected = () => {
    // 清空狀態
    updateBlockedList('delete')
  };

  const renderEditButton = () => {
    if(!isEditing) {
      return (
        <button
          onClick={handleEditToggle}
        >
          <img src={require('../../../images/crm/edit_pen_icon.svg')} alt="" />
          編輯名單
        </button>
      )
    } else {
      return (
        <>
          <button
            className='btn_delete'
            onClick={handleShowDeleteBlockedModalToggle}
            disabled={selectNumbers.length === 0}
          >
            <div className='delete_icon' />
            刪除
          </button>
          <button
            className='btn_cancel'
            onClick={handleEditToggle}
          >
            <div className='cross_icon'></div>
            取消
          </button>
        </>
      )
    }
  };

  const renderCurrentList = () => {
		return (
			<div className='crmSettings__block'>
        <div className={`crmSettings__block_list ${isEditing ? 'edit_list' : ''}`}>
          {
            currentList.map((num) => {
              return (
                <div className='crmSettings__list_num' key={num}>
                  <label>
                    {
                      isEditing ?
                      <input
                        type="checkbox"
                        value={num}
                        onClick={() => handleCheckboxChange(num)}
                      /> : <div className='placeholderDiv' />
                    }
                    {
                      num.length > 10 && !isEditing ?
                      <Tooltip
                        placement="bottomLeft"
                        title={num}
                        trigger='click'
                        color='#333'
                        overlayClassName='crmSettings__list_num_tooltip'
                      >
                        <span style={{ cursor: 'pointer' }}>{num}</span>
                      </Tooltip> :
                      <span>{num}</span>
                    }
                  </label>
                </div>
              )
            })
          }
        </div>
			</div>
		)
	};

  const renderListEmpty = () => {
    if(searchBlockedListNumber.length !== 0) {
      return (
        <div className='crmSettings__block'>
          <div className='crmSettings__search-empty'>無相符的手機號碼。</div>
        </div>
      )
    } else {
      return (
        <div className='crmSettings_empty'>
          {loading ? <LoadingOutlined style={{fontSize: '36px'}} /> : '您尚未封鎖任何電話號碼。'}
        </div>
      )
    }
  };

  const renderBlockedListError = () => {
    if(blockedListError === 'empty') return <div className='settingList__error'>請輸入完整的號碼</div>
    if(blockedListError === 'duplicate') return <div className='settingList__error'>此組號碼已存在於封鎖名單中</div>
	};

  const handleExpendSearch = () => {
    if(currentWindowWidth <= 768) {
      setIsSearching(true);
    }
  };

  return (
    <div className='crmSettings crm_setting_sticky'>
      <div className={`crmSettings_bar ${isSearching ? 'in_search_m' : ''}`}>
        <h2>封鎖名單</h2>
        <div className={`crmSettings_search ${searchBlockedListNumber ? 'in_search' : ''}`}>
          <SearchOutlined
            onClick={handleExpendSearch}
          />
          <input
            type="text"
            ref={inputRef}
            value={searchBlockedListNumber}
            placeholder='搜尋號碼'
            onChange={(e) => handleSearchBlockedList(e.target.value)}
            disabled={isEditing}
          />
          { searchBlockedListNumber.length > 0 && 
						<img
							src={require('../../../images/announcement/icon_close.svg')}
							onClick={() => handleSearchBlockedList('')}
							alt=''
						/>
          }
        </div>
      </div>
      <div className='crmSettings_list'>
        <div className='crmSettings_input'>
          <>
            <input
              type="text"
              className={`${blockedListError ? 'crmSettings_input-error' : ''} form-control`}
              value={blockedListNumber}
              onChange={(e) => handleBlockedListNum(e)}
              disabled={isEditing || searchBlockedListNumber.length !== 0}
              placeholder='新增封鎖號碼'
            />
            <button
              className='crmSettings_btn_add'
              onClick={addBlockedList}
              disabled={isEditing || searchBlockedListNumber.length !== 0}
            >
              新增
            </button>
          </>
        { blockedListError && renderBlockedListError() }
        </div>
        { currentList.length > 0  &&
          <div className='crmSettings__block_edit'>
            {renderEditButton()}
          </div>
        }
        { currentList.length > 0 ? renderCurrentList() : renderListEmpty() }
      </div>

      { showDeleteConfirmModal &&
        <DeleteConfirmModal
          type='blockedList'
          submit={handleRemoveSelected}
          cancel={handleShowDeleteBlockedModalToggle}
          selectNumbers={selectNumbers}
        />
      }
    </div>
  )
}

export default CrmBlockedList;