import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';

const RecordModalEditInfo_new = ({
  onInfoSubmit,
  onInfoChange,
  cancelEdit,
  handleTagInputChange,
  data,
  checked,
  url
}) => {
  const [ tags, setTags ] = useState([]);

  const modalRef = useRef();

  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
		$.ajax({
			url: window.domain + '/dashboard/customer_tags.json',
			dataType: 'json',
			xhrFields: { withCredentials: true },
			success: function(data) {
        setTags(data.customer_tags);
			},
			error: function() {}
		});
  };

  const handleSubmit = (e) => {
		e.preventDefault();
		let info = { customer: $.extend({}, data) };
		delete info['customer']['records'];
		onInfoSubmit(
			info,
			'PUT',
			url,
			function() {
        $(modalRef.current).modal('toggle');
				handleTagSubmit(data.id);
			}
		);
	};

  const handleTagSubmit = (id) => {
		let tags = { checked, id: id };
		$.ajax({
			url: window.domain + '/dashboard/customer_tags.json',
			dataType: 'json',
			data: tags,
			type: 'POST',
			xhrFields: { withCredentials: true },
			success: function(d) {},
			error: function(xhr) {
				window.app.alert.setMessage(xhr.responseJSON.message, 'error');
			}
		});
	}

  return (
    <div
      className='modal fade editmodal'
      ref={modalRef}
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={cancelEdit}
            >
              <span aria-hidden='true'>×</span>
            </button>
            <h4 className='modal-title'>編輯顧客資訊</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='modal-body'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-xs-5'>
                    <div className='form-group'>
                      <label>姓名</label>
                      <input
                        className='form-control'
                        placeholder='輸入姓'
                        name='last_name'
                        value={data.last_name || ''}
                        onChange={onInfoChange}
                      />
                    </div>
                  </div>
                  <div className='col-xs-5'>
                    <div className='form-group select required member_gender'>
                      <label
                        className='select required control-label'
                        htmlFor='member_gender'>
                        性別
                      </label>
                      <select
                        className='select required form-control'
                        required='required'
                        aria-required='true'
                        id='member_gender'
                        name='gender'
                        value={data.gender}
                        onChange={onInfoChange}
                        >
                        <option value='other'>
                          其他
                        </option>
                        <option value='male'>
                          男性
                        </option>
                        <option value='female'>
                          女性
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-5'>
                    <div className='form-group'>
                      <label>手機電話</label>
                      <input
                        className='form-control'
                        placeholder='輸入手機電話'
                        name='phone_number'
                        value={data.phone_number || ''}
                        onChange={onInfoChange}
                      />
                    </div>
                  </div>
                  <div className='col-xs-5'>
                    <div className='form-group'>
                      <label>市話</label>
                      <input
                        className='form-control'
                        placeholder='輸入市話'
                        name='tele_number'
                        value={data.tele_number || ''}
                        onChange={onInfoChange}
                      />
                    </div>
                  </div>
                  <div className='col-xs-5'>
                    <div className='form-group'>
                      <label>email</label>
                      <input
                        className='form-control'
                        placeholder='輸入email'
                        name='email'
                        value={data.email || ''}
                        onChange={onInfoChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <label>標記</label>
                    <div
                      className='checkbox btn-group customer_tag'
                      style={{ marginTop: '-10px' }}>
                      {tags.map((tag) => {
                          return (
                            <label key={tag.id}>
                              <input
                                className='checkbox_tag'
                                type='checkbox'
                                name='tag'
                                value={
                                  tag.id
                                }
                                checked={checked.includes(
                                  tag.id
                                )}
                                onChange={handleTagInputChange}
                              />
                              <img
                                src={
                                  tag.image
                                }
                                alt=''
                              />
                              {
                                tag.description
                              }
                            </label>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <div className='form-group'>
                      <label>備註</label>
                      <textarea
                        className='text optional form-control'
                        name='note'
                        value={data.note}
                        onChange={onInfoChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-color-white btn-style4'
                data-dismiss='modal'
                onClick={cancelEdit}
                >
                取消
              </button>
              <input
                type='submit'
                className='btn btn-color-black btn-style4'
                value='儲存'
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default RecordModalEditInfo_new;