import React from 'react'
import { browserHistory } from 'react-router'
var createReactClass = require('create-react-class');
export default createReactClass({
  handleSubmit(e) {
    e.preventDefault();
    var url = window.location.pathname;
    var parent_url = url.split('/').slice(0, -1).join('/');
    this.props.onRecordSubmit({}, 'DELETE', url, function(){browserHistory.push(parent_url)});
  },
  render() {
    return (
      <div className="modal fade deleteitem" tabIndex={-1} role="dialog" aria-labelledby="mySmallModalLabel">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body-center">
              <h4 className="modal-title" id="myModalLabel">確定要刪除?</h4>
              <br />
              <button type="button" className="btn-length-180 btn-color-white btn-style4" data-dismiss="modal">取消</button>
              <button type="button" className="btn-length-180 btn-color-red btn-style4" data-dismiss="modal" onClick={this.handleSubmit} >刪除</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
});
