import React, { useState } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import CheckPopup from '../../../popup/CheckPopup';

const RecordEditorModal = ({
  id,
  url,
  selectRecord,
  setSelectRecord,
  setShowRecordEditorModal,
  handleRecordSubmit
}) => {
  const [ showCancelEditCheckModal, setShowCancelEditCheckModal ] = useState(false);
  const [ showDeleteCheckModal, setShowDeleteCheckModal ] = useState(false);

  const { handleSubmit, register, formState: { errors }, watch } = useForm({
    defaultValues: {
      record: selectRecord ? selectRecord.value : ''
    }
  });
  const recordValue = watch('record', selectRecord ? selectRecord.value : '');

  const cancelPopup = (type = 'cancel') => {
    let isSaved = true;

    if(selectRecord) {
      if(selectRecord.value !== recordValue) isSaved = false;
    } else {
      if(recordValue !== '') isSaved = false;
    }

    if(!isSaved && type !== 'submit') {
      setShowCancelEditCheckModal(true);
    } else {
      setShowRecordEditorModal(false);
    }

    setSelectRecord(null);
  };

  const renderDate = () => {
    if(selectRecord) {
      return moment(selectRecord.created_at).format('YYYY-MM-DD');
    } else {
      return moment().format('YYYY-MM-DD');
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    let newRecord = {};

    if(selectRecord && selectRecord.id) {
      newRecord = {record: Object.assign({}, selectRecord, { value: data.record })};

      handleRecordSubmit(newRecord, 'put', url + '/' + selectRecord.id + '.json');
    } else {
      newRecord = {
        customer_id: id,
        record: {
          value: data.record
        }
      };

      handleRecordSubmit(newRecord, 'post', url + '.json');
    }
    cancelPopup('submit');
  };

  const handleDelete = () => {
    handleRecordSubmit('', 'delete', url + '/' + selectRecord.id);
    cancelPopup('submit');
  };

  const handleCanceled = () => {
    setShowRecordEditorModal(false);
    setShowCancelEditCheckModal(false);
  };

  return (
    <Modal
      title={`${selectRecord ? '編輯' : '新增'}顧客紀錄`}
      width={640}
      visible
      centered
      closable={false}
      className="recordEditorModal"
      footer={
        <div className='btn_action'>
          {
            selectRecord &&
            <button
              type="button"
              className='btn_delete'
              onClick={() => setShowDeleteCheckModal(true)}
            >
              刪除
            </button>
          }
          <div>
            <button
              type="button"
              className='btn_cancel'
              onClick={cancelPopup}
            >
              取消
            </button>
            <button
              className={errors.message?.record || recordValue.length === 0 ? 'btn_disabled' : 'btn_confirm'}
              type='submit'
              disabled={errors.message?.record || recordValue.length === 0}
              onClick={handleSubmit(onSubmit)}
            >
              {selectRecord ? '儲存' : '新增'}
            </button>
          </div>
        </div>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='date'>{renderDate()}</div>
        <div className='title'>紀錄內容</div>
        <textarea
          placeholder='請輸入紀錄內容'
          {...register('record', {required: true})}
        />
      </form>

      {
        showCancelEditCheckModal &&
        <CheckPopup
          title={`放棄${selectRecord ? '編輯' : '新增'}嗎？`}
          content={`退出將不會保留${selectRecord ? '已變更的' : '紀錄'}內容。確定退出嗎？`}
          submit={handleCanceled}
          cancel={() => setShowCancelEditCheckModal(false)}
        />
      }

      {
        showDeleteCheckModal &&
        <CheckPopup
          title='刪除紀錄嗎？'
          content='確定刪除嗎？'
          submit={handleDelete}
          cancel={() => setShowDeleteCheckModal(false)}
        />
      }
    </Modal>
  );
};

export default RecordEditorModal;