import React from 'react';
import { Modal } from 'antd';

import './shopFormNewModal.scss';

export default ({ data, onInputChange, onImageChange, imagePreviewUrl, onFormSwitch, onSubmit}) => {
  const renderLogo = () => {
    if(imagePreviewUrl) {
      return imagePreviewUrl;
    } else if(data.logo) {
      return data.logo;
    } else {
      return require("../../images/menuUser/Profile_Picture.svg");
    }
  };

  const addShopDefaultSrc = (e) => {
		e.onerror = null;
		e.target.src = require('../../images/menuUser/Profile_Picture.svg');
  };

	return (
		<Modal
			destroyOnClose
			centered
			visible
			wrapClassName="shopNewModal"
      title="在 MENU 美食誌建立店家資訊"
      footer={
        <button onClick={onSubmit}>建立</button>
      }
      onCancel={onFormSwitch}
      transitionName="ant-move-down"
		>
			<div className="shopNewModalBody">
        <div className="logo">
          <img
            className="shopForm_info_logo"
            src={renderLogo()}
            onError={(e) => addShopDefaultSrc(e)}
          />
          <div>
            <label htmlFor="upload" className="shopForm_info_logo_change">更換頭貼</label>
            <input
              id="upload"
              onChange={onImageChange}
              className="file-upload__input"
              type="file"
              name="file-upload"
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
        </div>
        <div className="info">
          <div className="info_block">
            <div className="info_block_title">店家名稱</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder="店家名稱"
              name="display_name"
              value={data.display_name || ''}
              onChange={onInputChange}
            />
          </div>
          <div className="info_block">
            <div className="info_block_title">分店名稱（選填）</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder="分店名稱（選填）"
              name="branch"
              value={data.branch || ''}
              onChange={onInputChange}
            />
          </div>
          <div className="info_block">
            <div className="info_block_title">聯絡電話</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder="聯絡電話"
              name="phone_number"
              value={data.phone_number || ''}
              onChange={onInputChange}
            />
          </div>
          <div className="info_block">
            <div className="info_block_title">店家地址</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder="店家地址"
              name="address"
              value={data.address || ''}
              onChange={onInputChange}
            />
          </div>
          <div className="info_block">
            <div className="info_block_title">店家網址</div>
            <input
              type="text" 
              className="form-control shopForm_info_input"
              placeholder="店家網址"
              name="website_url"
              value={data.website_url || ''}
              onChange={onInputChange}
            />
          </div>
        </div>
      </div>
		</Modal>
	);
};
