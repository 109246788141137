import React, { Component } from 'react';
import $ from 'jquery';
import QueueSystemAPI from '../queue_system_settings/QueueSystemAPI';

export default class QueueSettingGroupType extends Component {


    state = {
		groupTypes: [],
    }
    
    constructor(props) {
        super(props);
        this.addNewGroupType = this.addNewGroupType.bind(this);
        this.editGroupType = this.editGroupType.bind(this);
        this.deleteGroupType = this.deleteGroupType.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
        })      
    }

    componentDidMount() {
        this.API = new QueueSystemAPI()
        this.loadGroupContent()
    }

    loadGroupContent = () => {
        this.API.getListedQueueGroup(
            data => {
                if (data !== undefined) {
                    this.setState({
                        groupTypes: data
                    })
                }
            }
        )

    }

    addNewGroupType = () => {
        this.groupTypeModal.show(-1);
    }

    editGroupType = (groupType) => {
        this.groupTypeModal.show(groupType);
    }

    deleteGroupType = (idx) => {
        this.API.deleteQueueGroup(idx, data => {
            if (data["id"] !== undefined) {
                let currentGroupTypes = this.state.groupTypes
                currentGroupTypes = currentGroupTypes.filter( element => {
                    return element.id !== data["id"]
                })
                this.setState({
                    groupTypes: currentGroupTypes
                })
            }
        });
    }
    
    render() {

        let groupTypes = this.state.groupTypes.map((groupType, idx) => {
			return (
				<div key={idx}>
					<div className="rangeTitle">
						<img src={require("../../images/queue/queue_group_item.png")} alt="" />
						<h4 style={{width: '50%'}}>{groupType.title}</h4>
						<div>
                            <a className="msLink" 
                               onClick={() => {this.editGroupType(groupType) }}
                            >
                                編輯
                            </a>
                            <a className={ 
                                groupType.status !== 'end' ? 
                                "msLink disabled" :
                                "msLink" }
                               onClick={() => {   
                                if (groupType.status !== 'end') {
                                    alert("排隊進行中無法刪除組別");
                                } else {  
                                    this.deleteGroupType(groupType.id) 
                                }
                            }}
                            >
                                刪除
                            </a>
						</div>
					</div>
					<div className="settingTbl">
						<div className="settingTblRow">
							<div className="header">每組人數範圍</div>
							<div className="text">{groupType.min_people_count}~{groupType.max_people_count}</div>
						</div>
                        <div className="settingTblRow">
							<div className="header">上限組數</div>
							<div className="text">{groupType.count_limit}</div>
						</div>
                        <div className="settingTblRow">
							<div className="header">起始號碼</div>
							<div className="text">{groupType.begin_number}</div>
						</div>
                        {/* <div className="settingTblRow">
							<div className="header">開放時間</div>
							<div className="text">
                                <div>星期一 11:00~14:00、17:00~22:00</div>
                                <div>星期二 9:00~14:00、17:00~22:00</div>
                                <div>星期五 11:00~14:00</div>
                            </div>
						</div> */}
						{/* <div className="settingTblRow">
							<div className="header"
								data-toggle="tooltip"
								data-placement="bottom"
								title="如為必填，顧客必須至少選擇一項標記才可建立預約">
								必填與否
								<i className="fa fa-question-circle-o" aria-hidden="true"></i>
							</div>
							<div className="text">{service.required ? "必填" : "選填"}</div>
						</div> */}
					</div>

					<hr />
				</div>
			)
        });
        
        return (
            <div className="main overflow">
                <div style={{display: 'flex'}}>
                    <div style={{flexGrow: 1}}>
                        <h3>排隊組別設定</h3>
                        <h5>您可以依據不同的服務類型或人數範圍，設定不同的排隊組別。在達到排隊組別的上限組數後，顧客將無法在該組別進行線上排隊。</h5>
                    </div>
                </div>
                {/* <hr />
                <div className="settingTbl" style={{marginLeft: 0}}>
                    <div className="settingTblRow">
                        <div className="header"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="題目是否於線上預約及自建預約中啟用顯示">
                            預估常用時間
                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                        </div>
                        <div className="text">
                            <input ref="defaultStartTime" 
                                className="form-control input-lg" 
                                style={{width: '80px', display: 'inline', marginRight: '12px'}}  
                                name="booking_notification_email"/>
                                ~
                            <input ref="defaultEndTime" 
                                className="form-control input-lg" 
                                style={{width: '80px', display: 'inline', marginLeft: '12px'}}  
                                name="booking_notification_email"/>
                        </div>
                    </div>
                </div>  */}
                <hr />
                {groupTypes}
                <GroupTypeModal 
                    ref={(ref) => { this.groupTypeModal = ref }}
                    closeHandler = {this.loadGroupContent} />
                <button className="msBtn" style={{marginRight: '12px'}} onClick={ () => this.addNewGroupType()}>＋ 新增組別</button>
                {/* <div className="msBtn msBtnMain" style={{width: '180px'}} onClick={this.submit}>儲存</div> */}
                <hr/>
            </div>
        )
    }
}


class GroupTypeModal extends Component {

	state = {
        title: "",
        status: "",
        countLimit: 99,
        beginNumber: 1,
        minPeopleCount: 1,
        maxPeopleCount: 2,
        selectedGroups: [],
        timeGroups: [],
		id: -1
	}

	componentDidUpdate(prevProps, prevState) {
		
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		})  

		$(document).ready(function () {

		});
	}


	show = (groupType) => {

        this.API = new QueueSystemAPI()
        

        if (groupType !== -1) {
            // 編輯
            this.setState({
                status: groupType.status,
                title: groupType.title,
                countLimit: groupType.count_limit,
                beginNumber: groupType.begin_number,
                minPeopleCount: groupType.min_people_count,
                maxPeopleCount: groupType.max_people_count,
                id: groupType.id
            }, () => {
				$(this.refs.modal).modal({ show: true, backdrop: 'static' });
				$('[data-toggle="tooltip"]').tooltip()
            })
        } else {
            // 建立
            this.setState({
                title: "",
                countLimit: 99,
                beginNumber: 1,
                minPeopleCount: 1,
                maxPeopleCount: 2,
                id: -1
            }, () => {
				$(this.refs.modal).modal({ show: true, backdrop: 'static' });
				$('[data-toggle="tooltip"]').tooltip()
			})
        }
	}

	close = () => {
		$(this.refs.modal).modal('hide');
    }
    
    updateGroupType = (e) => {

        if (this.state.title === "") {
            alert("組名為必填欄位")
        }


        if (this.state.id === -1) {
            this.API.createQueueGroup({
                title: this.state.title,
                available: true,
                max_people_count: Math.max(this.state.maxPeopleCount, this.state.minPeopleCount),
                min_people_count: Math.min(this.state.maxPeopleCount, this.state.minPeopleCount),
                count_limit: this.state.countLimit,
                begin_number: this.state.beginNumber
            }, data => {
                if (data !== undefined) {
                    // console.log("建立成功")
                    // console.log(data);
                    this.props.closeHandler()
                    $(this.refs.modal).modal('hide');
                }
            });
        } else {
            this.API.editQueueGroup({
                id: this.state.id,
                title: this.state.title,
                available: true,
                max_people_count: Math.max(this.state.maxPeopleCount, this.state.minPeopleCount),
                min_people_count: Math.min(this.state.maxPeopleCount, this.state.minPeopleCount),
                count_limit: this.state.countLimit,
                begin_number: this.state.beginNumber
            }, data => {
                if (data !== undefined) {
                    // console.log("更新成功")
                    // console.log(data);
                    this.props.closeHandler()
                    $(this.refs.modal).modal('hide');
                }
            });
        }
    }

	inputChange = (e) => {
        if (e.target.value.length <= 20) {
            this.setState({
                title: e.target.value
            })
        }
    }
    
    toggleTimePicker = (selectedIndexPath) => {
        this.setState(prevState => ({
            selectedIndexPath: selectedIndexPath,
            timePickerOpened: !prevState.timePickerOpened
        }));
    }

	render() {
        // let selectedHour = "00";
        // let selectedMinute = "00";
        // let timeGroupsElement = [];
        // let lineCount = 0;
        // this.state.timeGroups.forEach((element, index) => {
        //     const mappingDay = ["星期一","星期二","星期三","星期四","星期五","星期六","星期日"]
        //     let timeGroupsSubElement = [];
        //     element.forEach((subElement, subIndex) => {
        //         timeGroupsSubElement.push((
        //             <div className="availableTime-group">
        //                 <div className="custum-input"  
        //                     onClick={ e => {this.toggleTimePicker(index+"-"+subIndex+"-1")}}
        //                     style={{"width": "140px", 
        //                             "height": "44px",
        //                             "border": "1px solid #ccc",
        //                             "borderRadius": "4px",
        //                             "lineHeight": "44px",
        //                             "paddingLeft": "4px",
        //                             "marginRight": "12px",
        //                             "display": "inline-block"
        //                             }}>
        //                             {/* {booking_date_time !== "" ? booking_date_time : this.state.formData.booking_datetime}  */}
        //                     </div>
        //                     { (this.state.timePickerOpened && this.state.selectedIndexPath === (index+"-"+subIndex+"-1")) ?
        //                     <div 
        //                     style={{"position": "absolute",
        //                             "zIndex": "999",
        //                             "top":  16 + index*28 + lineCount*58+"px"
        //                             }}>      
        //                     <TimePicker 
        //                     selectedHour = {selectedHour}
        //                     selectedMinute = {selectedMinute}
        //                     closeHandler={this.toggleTimePicker}
        //                     confirmHandler={this.updateBookingDateTime}></TimePicker>
        //                     </div> : null
        //                     }
        //                     ~
        //                 <div className="custum-input"  
        //                     onClick={ e => {this.toggleTimePicker(index+"-"+subIndex+"-2")}}
        //                     style={{"width": "140px", 
        //                             "height": "44px",
        //                             "border": "1px solid #ccc",
        //                             "borderRadius": "4px",
        //                             "lineHeight": "44px",
        //                             "paddingLeft": "4px",
        //                             "marginLeft": "12px",
        //                             "display": "inline-block"
        //                             }}>
        //                             {/* {booking_date_time !== "" ? booking_date_time : this.state.formData.booking_datetime}  */}
        //                     </div>
        //                     { (this.state.timePickerOpened && this.state.selectedIndexPath === (index+"-"+subIndex+"-2")) ?
        //                     <div 
        //                     style={{"position": "absolute",
        //                             "zIndex": "999",
        //                             "top":  16 + index*28 + lineCount*58+"px",
        //                             "left":"80px"
        //                             }}>      
        //                     <TimePicker 
        //                     selectedHour = {selectedHour}
        //                     selectedMinute = {selectedMinute}
        //                     closeHandler={this.toggleTimePicker}
        //                     confirmHandler={this.updateBookingDateTime}></TimePicker>
        //                     </div> : null
        //                     }
        //                 {/* <input ref="defaultStartTime" 
        //                     className="form-control input-lg" 
        //                     style={{width: '120px', display: 'inline', marginRight: '12px'}}  
        //                     name="booking_notification_email"/>
        //                     ~
        //                 <input ref="defaultEndTime" 
        //                     className="form-control input-lg" 
        //                     style={{width: '120px', display: 'inline', marginLeft: '12px'}}  
        //                     name="booking_notification_email"/> */}
        //                 <button>
        //                     <img className="dismiss-btn-image" src={require("../../images/icon_dismiss.png")} />
        //                 </button>
        //                 <button>
        //                     <img className="dismiss-btn-image" src={require("../../images/icon_dismiss.png")} />
        //                 </button>
        //             </div>
        //         ))
        //         lineCount++;
        //     });

        //     timeGroupsElement.push((
        //         <div className="timeGroup-container">
        //             <div className="checkbox-container">
        //                 <input type="checkbox" defaultChecked />
        //                 <span className="checkmark"></span>
        //             </div>
        //             <span className="day">{mappingDay[index]}</span>
        //             <div className="availableTime-container">
        //                 {timeGroupsSubElement}
        //             </div>
        //         </div>
        //     ))
        // });

		return (
			<div className="modal fade timeSetting" ref="modal" >
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
							<h4 className="modal-title">新增排隊組別</h4>
						</div>
						<div className="modal-body">
							<div className="container-fluid">
								<div className="row form-inline">
									<div className="col-sm-3">
										組名
									</div>
									<div className="col-sm-9">
										<div className="text">
											<input ref = { ref => this.titleRef = ref}
												className="form-control input-lg"
												style={{ width: '60%', display: 'inline'}} 
												onChange={this.inputChange}
                                                value={this.state.title}
                                                placeholder="輸入組名" 
                                                disabled={(this.state.status === 'start' || this.state.status === 'pause') && this.state.id !== -1}
                                                />
										</div>
									</div>
									<div className="col-sm-12">
										<div style={{ width: '100%', height: '16px' }} />
									</div>
								</div>
                                <div className="row form-inline">
									<div className="col-sm-3" data-toggle="tooltip" data-placement="bottom" title="每組排隊可登記的人數範圍">
										每筆人數範圍
                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
									</div>
									<div className="col-sm-9">
										<div className="text">
											<input ref = { ref => this.minRef = ref}
                                                className="form-control input-lg"
                                                type="number"
                                                style={{ width: '12%', display: 'inline', marginRight: '12px', 
                                                    paddingLeft: '10px',
                                                    paddingRight: '6px'}} 
                                                disabled={(this.state.status === 'start' || this.state.status === 'pause') && this.state.id !== -1}
												onChange={(e) => {
                                                    if (this.minRef.value < 1) {
                                                        this.setState({
                                                            minPeopleCount: 1
                                                        })
                                                    } else {
                                                        this.setState({
                                                            minPeopleCount: e.target.value
                                                        })
                                                    }
                                                }}
												value={this.state.minPeopleCount} 
                                                />
                                            ~
                                            <input ref = { ref => this.maxRef = ref}
                                                className="form-control input-lg"
                                                type="number"
                                                style={{ width: '12%', display: 'inline', marginLeft: '12px', 
                                                    paddingLeft: '10px',
                                                    paddingRight: '6px'}} 
                                                disabled={(this.state.status === 'start' || this.state.status === 'pause') && this.state.id !== -1}
												onChange={(e) => {
                                                    if (this.maxRef.value > 99) {
                                                        this.setState({
                                                            maxPeopleCount: 99
                                                        })
                                                    } else {
                                                        this.setState({
                                                            maxPeopleCount: e.target.value
                                                        })
                                                    }
                                                }}
												value={this.state.maxPeopleCount} 
                                                />
										</div>
									</div>
									<div className="col-sm-12">
										<div style={{ width: '100%', height: '16px' }} />
									</div>
								</div>
                                <div className="row form-inline">
                                    <div className="col-sm-3" data-toggle="tooltip" data-placement="bottom" title="排隊到達上限則自動暫停線上排隊">
                                        上限組數
                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
									</div>
									<div className="col-sm-9">
										<div className="text">
											<input ref = { ref => this.titleRef = ref}
                                                className="form-control input-lg"
                                                type="number"
                                                placeholder="100"
												style={{ width: '30%', display: 'inline'}} 
												onChange={(e) => {
                                                    this.setState({
                                                        countLimit: e.target.value
                                                    })
                                                }}
												value={this.state.countLimit}
                                                />
										</div>
									</div>
									<div className="col-sm-12">
										<div style={{ width: '100%', height: '16px' }} />
									</div>
								</div>
                                <div className="row form-inline">
                                    <div className="col-sm-3" data-toggle="tooltip" data-placement="bottom" title="顧客排隊的起始號碼">
                                        起始號碼
                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
									</div>
									<div className="col-sm-9">
										<div className="text">
											<input ref = { ref => this.titleRef = ref}
                                                className="form-control input-lg"
                                                type="number"
                                                placeholder="100"
                                                style={{ width: '30%', display: 'inline'}} 
                                                disabled={(this.state.status === 'start' || this.state.status === 'pause') && this.state.id !== -1}
												onChange={(e) => {
                                                    this.setState({
                                                        beginNumber: e.target.value
                                                    })
                                                }}
												value={this.state.beginNumber}
                                                />
										</div>
									</div>
									<div className="col-sm-12">
										<div style={{ width: '100%', height: '16px' }} />
									</div>
								</div>
                                {/* <hr />
                                <div className="row form-inline">
									<div className="col-sm-3">
										開放時間
									</div>
									<div className="col-sm-9" style={{minHeight: '500px'}}>
										<div className="text">
                                            {timeGroupsElement}
										</div>
									</div>
									<div className="col-sm-12">
										<div style={{ width: '100%', height: '16px' }} />
									</div>
								</div> */}
							</div>
						</div>
						<div className="modal-footer">
                            <button 
                                style={{ padding: '0 55px' }} 
								onClick={() => { this.updateGroupType() }} 
								type="button" 
								className="msBtn msBtnMain">儲存</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
