import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router';
import $ from 'jquery';
import _ from 'lodash';
import { Switch } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeSettingWeek,
	changeSettingTime,
	checkNumpadTime,
	changeDiningTimeFixed,
	changeDiningTimeIntervalTime,
	changeDiningTimeUserSetting,
	cancelSettingEdit,
	saveSettingEdit,
	changeEnable,
	changeFixedDiningTime,
	changeDiningTimeMode,
	addTimeRange,
	addDiningTimeRange,
	diningTimeShowTime,
	updateDiningTimeName,
	addTime,
	removeTime,
	changeIntervalTime,
	delTimeRange,
	delDiningTimeRange
} from '../../../actions/bookingSettingAreaAction';
import Numpad from './Numpad';

const weeksTxt = [ '星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六' ];
const intervalTime = [ 5, 10, 15, 20, 30, 50, 60 ];

const SettingEditor = ({ children }) => {
	return <div className="settingEditor">{React.Children.map(children, (child) => child)}</div>;
};

const SettingEditorTitle = () => {
	const dispatch = useDispatch();

	const cancelEdit = () => {
		const addNewWeekSettingBtn = document.getElementById('addNewWeekSettingBtn');
		addNewWeekSettingBtn.style.display = 'block';

		dispatch(cancelSettingEdit());
	};

	const saveEdit = () => {
		dispatch(saveSettingEdit());
	};

	return (
		<div className="settingEditorTop settingRow" style={{marginTop: '0', marginBottom: '32px'}}>
			<div className="settingRow__content">
				<p className="settingRow__Title">開放時段設定</p>
			</div>
			<div className="settingRow__button">
				<button className="button-cancel" onClick={() => cancelEdit()}>
					取消
				</button>
				<button className="button-save" onClick={() => saveEdit()}>
					確定
				</button>
			</div>
		</div>
	);
};

const SettingEditorWeek = ({ activeOption }) => {
	const dipatch = useDispatch();
	const BSareaModalState = useSelector((state) => state.BookingSettingAreaModalReducer);
	const {usedWeeks} = BSareaModalState;

	const handleChoose = (option) => {
		dipatch(changeSettingWeek(option));
	};

	const renderOption = () => {
		return weeksTxt.map((option, index) => {
			const active = _.includes(activeOption, option);
			const disable=_.includes(usedWeeks, index)

			return (
				<div
					key={option}
					className={classNames('groups_table', { active, disable })}
					onClick={() => handleChoose(option)}
				>
					{option}
				</div>
			);
		});
	};

	return (
		<section>
			<div className="sectionTitle">開放營業日</div>
			<div className="sectionContent weeksWrap">{renderOption()}</div>
		</section>
	);
};

const SettingEditorEnable_2 = ({ enable }) => {
	const dispatch = useDispatch();
	const handleEnable = (enable) => {
		dispatch(changeEnable(enable));
	};

	return (
		<section>
			<div className="sectionTitle" style={{marginBottom: '0'}}>提供服務時段</div>
			<div className="sectionSubtitle">選擇營業日提供服務的時間，顧客能在您設定的時間預約服務。</div>
			<div className="enableOption row">
				<label className="col-sm-12 enable_option_marginTop">
					<input type="radio" name="enable" checked={enable === 2} onChange={() => handleEnable(2)} />
					<span className="option_title">固定區間</span>
					<div className="option_subtitle">您可以新增時間區間，顧客能在此範圍的時間建立預約。</div>
				</label>
			</div>
		</section>
	);
};

const SettingEditorEnable_3 = ({ enable }) => {
	const dispatch = useDispatch();
	const handleEnable = (enable) => {
		dispatch(changeEnable(enable));
	};

	return (
		<div className="enableOption row" style={{height: '72px'}}>
			<label  className="col-sm-12">
				<input type="radio" name="enable" checked={enable === 3} onChange={() => handleEnable(3)} />
				<span className="option_title">自訂時段</span>
				<div className="option_subtitle">顧客能在您設定的單一時間建立預約。</div>
			</label>
		</div>
	);
};

const SettingEditorTime = ({ activeOption }) => {
	const dispatch = useDispatch();
	const [ showNumpad, setShowNumpad ] = useState(false);

	const handleRemoveTime = (option) => {
		dispatch(removeTime(option))
	};
	const renderOption = () => {
		return activeOption.map((option) => {
			return (
				<div key={option} className="groups_table active">
					<button className="removeTimeButton" onClick={() => handleRemoveTime(option)} />
					{option}
				</div>
			);
		});
	};

	const handleAddTime = () => {
		setShowNumpad(true);
	};

	const closeNumpad = useCallback(() => {
		setShowNumpad(false);
	}, []);

	const checkNumpad = useCallback(
		(displayTime) => {
			dispatch(addTime(displayTime)).then(() => {
				setShowNumpad(false);
			});
		},
		[ dispatch ]
	);

	return (
		<section>
			{/* <div className="sectionTitle">自訂時段</div> */}
			<div>
				<button className="addButtonStyle" style={{marginTop: '0'}} onClick={() => handleAddTime()}>+ 新增區間</button>
			</div>
			<div className="sectionContent" id="availableTime">
				{renderOption()}
			</div>
			{showNumpad && (
				<Portal>
					<Numpad close={closeNumpad} check={checkNumpad} />
				</Portal>
			)}
		</section>
	);
};

const SettingEditorTimeRange = ({ activeTimeRange }) => {
	const dispatch = useDispatch();
	const { errorTime } = useSelector((state) => state.BookingSettingAreaModalReducer);
	const [ showNumpad, setShowNumpad ] = useState(false);
	const [ timeRangeIndex, setTimeRangeIndex ] = useState(null);
	const [ timeIndex, setTimeIndex ] = useState(null);
	const [ activeTime, setActiveTime ] = useState('');

	const handleTimeRange = (range, timeIndex, index) => {
		setActiveTime(range[timeIndex]);
		setTimeRangeIndex(index);
		setTimeIndex(timeIndex);
		setShowNumpad(true);
	};

	const handleDelTimeRange = (index)=>{
		dispatch(delTimeRange(index))
	}

	const renderTimeRange = () => {
		return activeTimeRange.map((range, index) => {
			let errorIndex = errorTime.indexOf(index);

			const errorStyle = errorIndex !== -1 ? 'errorStyle' : '';

			return (
				<div key={index} className="settingRow" id="timeRange">
					<div className={classNames('settingRow__content', { errorStyle })}>
						從<span className="timeRangeCell" onClick={() => handleTimeRange(range, 0, index)}>
							{range[0]}
						</span>至<span className="timeRangeCell" onClick={() => handleTimeRange(range, 1, index)}>
							{range[1]}
						</span>

						<button className="areaDelButton" onClick={()=> handleDelTimeRange(index)}/>
					</div>
					<div className="settingRow__button">
						<p className="settingRow__Title">區間{index + 1}</p>
					</div>
				</div>
			);
		});
	};

	const closeNumpad = useCallback(() => {
		setShowNumpad(false);
	}, []);

	const checkNumpad = useCallback(
		(displayTime) => {
			dispatch(checkNumpadTime({ displayTime, timeRangeIndex, timeIndex })).then(() => {
				setShowNumpad(false);
			});
		},
		[ dispatch, timeIndex, timeRangeIndex ]
	);

	const handleAddTimeRange = () => {
		dispatch(addTimeRange());
	};

	return (
		<section>
			{/* <div className="sectionTitle">固定區間</div> */}
			{/* <p>＊請設定區間範圍內的時段</p> */}
			<div>
				<button className="addButtonStyle" style={{marginTop: '0', marginBottom: '8px'}} onClick={() => handleAddTimeRange()}>+ 新增區間</button>
			</div>
			<div className="sectionContent timeRange">{renderTimeRange()}</div>
			{showNumpad && (
				<Portal>
					<Numpad close={closeNumpad} check={checkNumpad} activeTime={activeTime} />
				</Portal>
			)}
		</section>
	);
};

const Portal = ({ children }) => {
	return createPortal(children, document.getElementById('areaModalNumpad'));
};

const SettingEditorInterval = ({ interval }) => {
	const dispatch = useDispatch();

	const handleIntervalTime = (e) => {
		const time = e.target.value;
		dispatch(changeIntervalTime(parseInt(time, 10)));
	};

	const renderTimeInterval = () => {
		return intervalTime.map((time) => <option key={time}>{time}</option>);
	};
	return (
		<section style={{marginBottom: '0'}}>
			<div className="row">
				<div className="sectionTitle col-sm-3 sectionTitle_2">預約時段區間</div>
				<div className="sectionContent col-sm-9 sectionContent_2">
					<select value={interval} onChange={(e) => handleIntervalTime(e)}>
						{renderTimeInterval()}
					</select>
					<span>分鐘</span>
				</div>
			</div>
		</section>
	);
};

const SettingEditorDiningTime = ({ fixedDiningTime, diningTimeOptions, serviceTimeOptionEnabled }) => {
	const dispatch = useDispatch();
	const hour = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23 ];
	const min = [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 ];

	let diningHour = hour;

	const handleFixedDiningTime = (type, e ) => {
		const time = e.target.value;
		dispatch(changeFixedDiningTime({ timeType: type, time }));
	};

	const handleDiningTimeMode = (mode) => {
		dispatch(changeDiningTimeMode(mode));
	};

	const handleDiningTimeNameUpdate = (id, e) => {
		const name = e.target.value;
		dispatch(updateDiningTimeName({ id, name}))
	};

	const handleDiningTime_userSetting = (type, id, e) => {
		const time = e.target.value;
		dispatch(changeDiningTimeUserSetting({ timeType: type, id, time }));
	};

	const handleDiningTimeShowTime = () => {
		dispatch(diningTimeShowTime())
	};

	const handleAddDiningTimeRange = () => {
		dispatch(addDiningTimeRange())
	};

	const handleDelDiningTimeRange = (index)=>{
		dispatch(delDiningTimeRange(index))
	};

	const handleDiningTime_fixed = (rangeType, type, e) => {
		const time = e.target.value;
		dispatch(changeDiningTimeFixed({ rangeType:rangeType, timeType: type, time }));
	};

	const renderModalDiningTime_userSetting = () => {
		let idx = 1;

		if (diningTimeOptions.mode === 'user_setting') {
			return (
			<div className="sectionContent diningTime">
				<div className="diningTime_name_switch_block">
					<div className="diningTime_name_switch">
						<div className="diningTime_name_title">命名服務時間</div>
						<Switch
							checked={diningTimeOptions.user_setting.show_name}
							onChange={() => handleDiningTimeShowTime()}
						/>
					</div>
					<div className="diningTime_name_txt">開啟時，您可以設定服務時間名稱並顯示於前台供顧客選取。</div>
				</div>
				<div>
					<button
					className="addButtonStyle"
					style={{marginBottom: '24px'}}
					onClick={() => handleAddDiningTimeRange()}
					>
						+ 新增選項
					</button>
				</div>
				{diningTimeOptions.user_setting.service_time_setting.map((range, id) => {
					return (
						<div key={id} className="diningTime_option">
							<div className="diningTime_index">選項{idx ++}</div>
							{
								diningTimeOptions.user_setting.show_name === true ? (
									<div style={{marginBottom: '8px'}}>
										<input
											className="diningTime_option_name"
											type="text"
											value={diningTimeOptions.user_setting.service_time_setting[id].name}
											onChange={(e) => handleDiningTimeNameUpdate(id, e)}
											maxLength="20"
											placeholder={`請輸入選項名稱`}
											/>
										{
											diningTimeOptions.user_setting.service_time_setting.length > 1 ? (
												<button 
													className="diningTime_areaDelButton"
													onClick={()=> handleDelDiningTimeRange(id)}
												/>
											) : null
										}
										<div className="diningTime_option_name_valid">請輸入該選項名稱。</div>
									</div>
								) : null
							}
							<select 
								className="diningTime_select_userSetting"
								value={Math.floor(parseInt(diningTimeOptions.user_setting.service_time_setting[id].service_time, 10) / 60)}
								onChange={(e) => handleDiningTime_userSetting('hour', id, e)}
							>
								{diningHour.map((hour) => (
									<option key={hour} value={hour}>
										{hour}
									</option>
								))}
							</select>
							<span className="diningTime__txt">小時</span>
							<select
								className="diningTime_select_userSetting"
								value={Math.floor(parseInt(diningTimeOptions.user_setting.service_time_setting[id].service_time, 10) % 60)}
								onChange={(e) => handleDiningTime_userSetting('min', id, e)}
								>
								{min.map((m) => (
									<option key={m} value={m}>
										{m}
									</option>
								))}
							</select>
							<span className="diningTime__txt">分鐘</span>
							{
								(diningTimeOptions.user_setting.show_name !== true) &&
								(diningTimeOptions.user_setting.service_time_setting.length > 1)? (
									<button
									className="diningTime_areaDelButton"
									onClick={()=> handleDelDiningTimeRange(id)}
								/>
								) : null
							}
						</div>
					)
				})}
			</div>
			)
		}
	}

	const renderModalDiningTime_fixed = () => {
		let min_dining_time_hour = Math.floor(parseInt(diningTimeOptions.fixed.min, 10) / 60)
		let min_dining_time_min = Math.floor(parseInt(diningTimeOptions.fixed.min, 10) % 60)
		let max_dining_time_hour = Math.floor(parseInt(diningTimeOptions.fixed.max, 10) / 60)
		let max_dining_time_min = Math.floor(parseInt(diningTimeOptions.fixed.max, 10) % 60)

		if (diningTimeOptions.mode === 'fixed') {
			return (
			<div className="sectionContent diningTime" style={{marginTop: '32px'}}>
				<div className="diningTime_content">
					<span className="diningTime_option diningTime_range">從最短</span>
					<select value={min_dining_time_hour} onChange={(e) => handleDiningTime_fixed('min','hour', e)}>
						{diningHour.map((hour) => (
							<option key={hour} value={hour}>
								{hour}
							</option>
						))}
					</select>
					<span className="diningTime__txt">小時</span>
					<select value={min_dining_time_min} onChange={(e) => handleDiningTime_fixed('min' ,'min', e)}>
						{min.map((m) => (
							<option key={m} value={m}>
								{m}
							</option>
						))}
					</select>
					<span className="diningTime__txt">分鐘</span>
				</div>
				<div className="diningTime_content">
					<span className="diningTime_option diningTime_range">到最長</span>
					<select value={max_dining_time_hour} onChange={(e) => handleDiningTime_fixed('max','hour', e)}>
						{diningHour.map((hour) => (
							<option key={hour} value={hour}>
								{hour}
							</option>
						))}
					</select>
					<span className="diningTime__txt">小時</span>
					<select value={max_dining_time_min} onChange={(e) => handleDiningTime_fixed('max','min', e)}>
						{min.map((m) => (
							<option key={m} value={m}>
								{m}
							</option>
						))}
					</select>
					<span className="diningTime__txt">分鐘</span>
				</div>
			</div>
			)
		}
	}

	const renderTimeInterval_fixed = () => {
		const handleDiningTimeIntervalTime = (e) => {
			const time = e.target.value;
			dispatch(changeDiningTimeIntervalTime(parseInt(time, 10)));
		};

		if(diningTimeOptions.mode === 'fixed') {
			return(
				<div className="row diningTime_interval" style={{marginBottom: '0px', marginTop: '24px'}}>
				<div className="col-sm-3 diningTime_select_title">
						預約時段區間
				</div>
				<div className="col-sm-9 p-0 diningTime_select_margin">
					<select
						className="diningTime__select"
						value={30}
						value={diningTimeOptions.fixed.unit}
						onChange={(e) => handleDiningTimeIntervalTime(e)}
					>
						{intervalTime.map((time) => {
							return <option key={time}>{time}</option>;
						})}
					</select>
					<span className="diningTime__txt">分鐘</span>
				</div>
			</div>
			)
		}
	}
	
	const renderDiningTimeOptions = () => {
		return (
			<div>
				<div className="enableOption row">
					<label className="col-sm-12 enable_option_marginTop">
						<input type="radio" name="enable_2" checked={diningTimeOptions.mode === 'user_setting'} onChange={() => handleDiningTimeMode('user_setting')} />
						<span className="option_title">自訂時間</span>
						<div className="option_subtitle">為顧客提供的服務時間選項。</div>
					</label>
				</div>
				{ renderModalDiningTime_userSetting() }
				<div className="enableOption row">
					<label className="col-sm-12 enable_option_marginTop">
						<input type="radio" name="enable_2" checked={diningTimeOptions.mode === 'fixed'} onChange={() => handleDiningTimeMode('fixed')} />
						<span className="option_title">固定區間</span>
						<div className="option_subtitle">顧客能在此範圍的時間選取服務時間。</div>
					</label>
				</div>
				{ renderModalDiningTime_fixed() }
				{ renderTimeInterval_fixed() }
			</div>
		)
	}

	const renderFixedDiningTime = () => {
		let dining_time_hour = Math.floor(parseInt(fixedDiningTime, 10) / 60)
		let dining_time_min = Math.floor(parseInt(fixedDiningTime, 10) % 60)

		return (
			<div className="fixedDiningTime" style={{ marginTop: '24px' }}>
				<select 
					value={dining_time_hour}
					onChange={(e) => handleFixedDiningTime('hour', e)}
				>
					{hour.map((hour) => (
						<option key={hour} value={hour}>
							{hour}
						</option>
					))}
				</select>
				<span className="diningTime__txt">小時</span>
				<select
					value={dining_time_min}
					onChange={(e) => handleFixedDiningTime('min', e)}
				>
					{min.map((m) => (
						<option key={m} value={m}>
							{m}
						</option>
					))}
				</select>
				<span className="diningTime__txt">分鐘</span>
			</div>
		)
	}

	return (
		<section>
			<div className="sectionTitle" style={{marginBottom: '0'}}>服務時間</div>
			<div className="sectionSubtitle">提供顧客的服務時數，顧客能選擇您所提供的服務時間長度。</div>
			{serviceTimeOptionEnabled ? renderDiningTimeOptions() : renderFixedDiningTime() }
		</section>
	);
};


SettingEditor.Title = SettingEditorTitle;
SettingEditor.Time = SettingEditorTime;
SettingEditor.Week = SettingEditorWeek;
SettingEditor.Enable_2 = SettingEditorEnable_2;
SettingEditor.Enable_3 = SettingEditorEnable_3;
SettingEditor.TimeRange = SettingEditorTimeRange;
SettingEditor.Interval = SettingEditorInterval;
SettingEditor.DiningTime = SettingEditorDiningTime;

export default SettingEditor;
