import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import _ from 'lodash';
import { Modal, DatePicker } from 'antd';
import moment from 'moment';

import { MessageContext } from '../../../crmContext/MessageContext';
import { MessageAddStateContext } from '../../../crmContext/MessageAddContext';

import { filterCustomers } from '../../../api/CrmMessageSmsApi';
import { filterFollowers } from '../../../api/CrmMessageMenuTalkApi';

const genderJson = [
  { genderTxt: '男', value: 'male' },
  { genderTxt: '女', value: 'female' },
  { genderTxt: '其他', value: 'other' }
];

const customerTagsJson = [
  { tagTxt: '貴賓', value: 'vip' },
  { tagTxt: '全素', value: 'vegan' },
  { tagTxt: '蛋奶素', value: 'egg_vegan' },
  { tagTxt: '奧客', value: 'not_friendly' }
];

const SendObjectFilterModal = () => {
  const { MessageState } = useContext(MessageContext);
  const { editType } = MessageState;
  const { MessageAddState, MessageAddDispatch } = useContext(MessageAddStateContext);
  const { messageSetting } = MessageAddState;
  const { filterConditions, filterJson } = messageSetting;
  const {
    dateAfter,
    dateBefore,
    bookingMin,
    bookingMax,
    exchangeMin,
    exchangeMax,
    fetchMin,
    fetchMax,
    gender,
    tags
  } = filterConditions;

  const [ displayGender, setDisplayGender ] = useState('');
  // sms
  const [ displayTags, setDisplayTags ] = useState([]);
  const [ displayDateAfter, setDisplayDateAfter ] = useState(null);
  const [ displayDateBefore, setDisplayDateBefore ] = useState(null);
  const [ displayBookingMin, setDisplayBookingMin ] = useState('');
  const [ displayBookingMax, setDisplayBookingMax ] = useState('');
  // menuTalk
  const [ displayExchangeMin, setDisplayExchangeMin ] = useState('');
  const [ displayExchangeMax, setDisplayExchangeMax ] = useState('');
  const [ displayFetchMin, setDisplayFetchMin ] = useState('');
  const [ displayFetchMax, setDisplayFetchMax ] = useState('');

  const [ displayFilterJson, setDisplayFilterJson ] = useState({});


  useState(() => {
    setDisplayGender(gender);

    setDisplayTags(tags);
    setDisplayDateAfter(dateAfter);
    setDisplayDateBefore(dateBefore);
    setDisplayBookingMin(bookingMin);
    setDisplayBookingMax(bookingMax);

    setDisplayExchangeMin(exchangeMin);
    setDisplayExchangeMax(exchangeMax);
    setDisplayFetchMin(fetchMin);
    setDisplayFetchMax(fetchMax);

    setDisplayFilterJson(filterJson);
  }, []);

  useEffect(() => {
    const record = document.getElementById('record');
    const couponExchange = document.getElementById('couponExchange');
    const couponFetch = document.getElementById('couponFetch');

    if(editType === 'sms') {
      if(bookingMin !== '') {
        record.classList.add('countText_left');
      } else {
        record.classList.remove('countText_left');
      };
  
      if(bookingMax !== '') {
        record.classList.add('countText_right');
      } else {
        record.classList.remove('countText_right');
      };
    } else if(editType === 'menuTalk') {
      if(exchangeMin !== '') {
        couponExchange.classList.add('countText_left');
      } else {
        couponExchange.classList.remove('countText_left');
      };
  
      if(exchangeMax !== '') {
        couponExchange.classList.add('countText_right');
      } else {
        couponExchange.classList.remove('countText_right');
      };
  
      if(fetchMin !== '') {
        couponFetch.classList.add('countText_left');
      } else {
        couponFetch.classList.remove('countText_left');
      };
  
      if(fetchMax !== '') {
        couponFetch.classList.add('countText_right');
      } else {
        couponFetch.classList.remove('countText_right');
      };
    }

  }, [
    editType,
    bookingMin,
    bookingMax,
    exchangeMin,
    exchangeMax,
    fetchMin,
    fetchMax,
  ]);

  useEffect(() => {
    const filterBtn = document.getElementsByClassName('save')[0];

    if(JSON.stringify(displayFilterJson) === '{}') {
      filterBtn.disabled = true;
      filterBtn.style.backgroundColor = '#DCDCDC';
    } else {
      filterBtn.disabled = false;
      filterBtn.style.backgroundColor = '#3FBA88';
    }
  },[ displayFilterJson ]);

  const handleDateAfter = (date) => {
    const newDate = moment(new Date(date)).format('YYYYMMDD');
    const newFilterJson = _.cloneDeep(displayFilterJson);

    if(newDate === '19700101' || newDate === null) {
      delete newFilterJson.date_after;
    } else if(newDate !== null) {
      newFilterJson.date_after = newDate;
    }

    setDisplayDateAfter(newDate);
    setDisplayFilterJson(newFilterJson);
  };

  const handleDateBefore = (date) => {
    const newDate = moment(new Date(date)).format('YYYYMMDD');
    const newFilterJson = _.cloneDeep(displayFilterJson);

    if(newDate === '19700101' || newDate === null) {
      delete newFilterJson.date_before;
    } else if(newDate !== null) {
      newFilterJson.date_before = newDate;
    }

    setDisplayDateBefore(newDate);
    setDisplayFilterJson(newFilterJson);
  };

  const handleFilterGender = (value) => {
    const newFilterJson = _.cloneDeep(displayFilterJson);

    if(displayGender === value) {
      setDisplayGender('');

      if(editType === 'sms') {
        delete newFilterJson.gender;
      } else {
        delete newFilterJson.isMale;
      }
    } else {
      setDisplayGender(value);

      if(editType === 'sms') {
        newFilterJson.gender = value;
      } else {
        if(value === 'male') {
          newFilterJson.isMale = true;
        } else if(value === 'female') {
          newFilterJson.isMale = false;
        } else {
          newFilterJson.isMale = null;
        }
      }
    }

    setDisplayFilterJson(newFilterJson);
  };

  const handleFilterTag = (tag) => {
    const newFilterJson = _.cloneDeep(displayFilterJson);
    const newTags = _.cloneDeep(displayTags);

    let index = newTags.indexOf(tag);
    index === -1 ? newTags.push(tag) : newTags.splice(index, 1);

    if(newTags.length !== 0) {
      newFilterJson.customer_tags = newTags.toString();
    } else {
      delete newFilterJson.customer_tags;
    }

    setDisplayTags(newTags);
    setDisplayFilterJson(newFilterJson);
  };

  const handleFilterConditions = (e, type) => {
    const newFilterJson = _.cloneDeep(displayFilterJson);
    const value = e.target.value;
    const reg = /^\d+$/;
		const regTest = reg.test(value);

		if (value !== '' && !regTest) {
			return window.app.alert.setMessage('請輸入數字', 'error');
		}

    if(type === 'bookingMin') {
      setDisplayBookingMin(value);
  
      if(value !== '0' && value !== '') {
        newFilterJson.booking_min = value;
      } else {
        delete newFilterJson.booking_min;
      }
    } else if(type === 'bookingMax') {
      setDisplayBookingMax(value);
  
      if(value !== '0' && value !== '') {
        newFilterJson.booking_max = value;
      } else {
        delete newFilterJson.booking_max;
      }
    } else if(type === 'exchangeMin') {
      setDisplayExchangeMin(value);
  
      if(value !== '0' && value !== '') {
        newFilterJson.minExchangeCount = value;
      } else {
        delete newFilterJson.minExchangeCount;
      }
    } else if(type === 'exchangeMax') {
      setDisplayExchangeMax(value);
  
      if(value !== '0' && value !== '') {
        newFilterJson.maxExchangeCount = value;
      } else {
        delete newFilterJson.maxExchangeCount;
      }
    } else if(type === 'fetchMin') {
      setDisplayFetchMin(value);

      if(value !== '0' && value !== '') {
        newFilterJson.minFetchCount = value;
      } else {
        delete newFilterJson.minFetchCount;
      }
    } else if(type === 'fetchMax') {
      setDisplayFetchMax(value);
  
      if(value !== '0' && value !== '') {
        newFilterJson.maxFetchCount = value;
      } else {
        delete newFilterJson.maxFetchCount;
      }
    }

    setDisplayFilterJson(newFilterJson);
  };

  const cancelPopup = useCallback(() => {
    MessageAddDispatch({ type: 'cancelPopup' });
	}, [ MessageAddDispatch ]);

  const submitFilterConditions = async () => {
    const $dateAfter = moment(displayDateAfter, 'YYYY-MM-DD');
    const $dateBefore = moment(displayDateBefore, 'YYYY-MM-DD');

    if(JSON.stringify(displayFilterJson) === "{}") {
      return MessageAddDispatch({ type: 'cancelPopup' });
    }

    if(editType === 'sms') {
      if(displayDateAfter !== null && displayDateBefore !== null) {
        if($dateAfter > $dateBefore) {
          return window.app.alert.setMessage('顧客紀錄時間範圍有誤', 'error');
        }
      }

      if(
        (displayBookingMin !== '' && displayBookingMin !== null) &&
        (displayBookingMax !== '' && displayBookingMax !== null)
      ) {
        if(Number(displayBookingMin) > Number(displayBookingMax)) {
          return window.app.alert.setMessage('顧客擁有有效紀錄筆數最大值不可小於最小值', 'error');
        }
      }

      try {
        const customersId = [];
        const { data } = await filterCustomers(displayFilterJson);
  
        data.customers.map((customer) => {
          return customersId.push(customer.id);
        })
  
        MessageAddDispatch({
          type: 'setSelectCustomer',
          selectCustomer: data.customers.length,
          customers: customersId
        })

        MessageAddDispatch({ type: 'setFilterDateRanges', dateAfter: displayDateAfter, dateBefore: displayDateBefore });
        MessageAddDispatch({ type: 'setFilterGender', gender: displayGender, editType });
        MessageAddDispatch({ type: 'setFilterTag', tags: displayTags });
        MessageAddDispatch({ type: 'setFilterConditions', filterType: 'bookingMin', value: displayBookingMin });
        MessageAddDispatch({ type: 'setFilterConditions', filterType: 'bookingMax', value: displayBookingMax });
  
        MessageAddDispatch({ type: 'cancelPopup' });
      } catch(err) {
        console.log('filter customers err ----------', err);
      }
    } else if(editType ==='menuTalk') {
      if(displayExchangeMin !== '' && displayExchangeMax !=='') {
        if(Number(displayExchangeMin) > Number(displayExchangeMax)) {
          return window.app.alert.setMessage('粉絲核銷優惠券次數最大值不可小於最小值', 'error');
        }
      }

      if(displayFetchMin !== '' && displayFetchMax !=='') {
        if(Number(displayFetchMin) > Number(displayFetchMax)) {
          return window.app.alert.setMessage('粉絲領取優惠券次數最大值不可小於最小值', 'error');
        }
      }

      try {
        const followersId = [];
        const { data } = await filterFollowers(displayFilterJson);
  
        data.followers.map((follower) => {
          return followersId.push(follower.followerId);
        })

        MessageAddDispatch({
          type: 'setSelectFollower',
          selectFollower: data.followers.length,
          followers: followersId
        });

        if(displayGender === 'other') {
          MessageAddDispatch({
            type: 'setFilterMenuOtherGender',
            followers: data.followers,
          });
        }

        MessageAddDispatch({ type: 'setFilterGender', gender: displayGender, editType });
        MessageAddDispatch({ type: 'setFilterConditions', filterType: 'exchangeMin', value: displayExchangeMin });
        MessageAddDispatch({ type: 'setFilterConditions', filterType: 'exchangeMax', value: displayExchangeMax });
        MessageAddDispatch({ type: 'setFilterConditions', filterType: 'fetchMin', value: displayFetchMin });
        MessageAddDispatch({ type: 'setFilterConditions', filterType: 'fetchMax', value: displayFetchMax });

        MessageAddDispatch({ type: 'cancelPopup' });
      } catch(err) {
        console.log('filter followers err ----------', err);
      }
    }
	};

  const cleanFilterConditions = async () => {
    MessageAddDispatch({
      type: 'setSelectCustomer',
      selectCustomer: 0,
      customers: []
    });

    MessageAddDispatch({
      type: 'setSelectFollower',
      selectFollower: 0,
      followers: []
    });

    MessageAddDispatch({ type: 'cleanFilterConditions' });
    cancelPopup();
	};

  const renderCustomerRecord = () => {
    if(editType === 'sms') {
      return (
        <Fragment>
          <div style={{ marginBottom: '32px' }}>
            <h5>顧客紀錄時間範圍</h5>
            <DatePicker
              className="dateRangePicker"
              placeholder='該日期之後'
              defaultValue={displayDateAfter && moment(displayDateAfter, 'YYYY-MM-DD')}
              onChange={(date) => handleDateAfter(date)}
            />
            <DatePicker
              placeholder='該日期之前'
              defaultValue={displayDateBefore && moment(displayDateBefore, 'YYYY-MM-DD')}
              onChange={(date) => handleDateBefore(date)}
            />
          </div>

          <div style={{ marginBottom: '32px' }}>
            <h5>顧客擁有有效紀錄筆數</h5>
            <div id="record" className="messageFilter_input">
              <div className="record">
                <input
                  type="text"
                  value={displayBookingMin}
                  onChange={(e) => handleFilterConditions(e, 'bookingMin')}
                  placeholder="最少筆數（選填）"
                />
              </div>
              <div className="record">
                <input
                  type="text"
                  value={displayBookingMax}
                  onChange={(e) => handleFilterConditions(e, 'bookingMax')}
                  placeholder="最多筆數（選填）"
                />
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  };

  const renderGender = () => {
    const title = editType === 'sms' ? '顧客性別' : '粉絲性別';

    return (
      <div style={{ marginBottom: '20px' }}>
        <h5 style={{ marginBottom: '21px' }}>{ title }</h5>
        <div className="messageFilter_layout">
          { genderJson.map((item, index) => {
            return (
              <div className="messageFilter_radio" key={index}>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    checked={displayGender === item.value}
                    value={item.value}
                    onClick={() => handleFilterGender(item.value)}
                    onChange={() => handleFilterGender(item.value)}
                  />
                  { item.genderTxt }
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  };

  const renderCustomerTag = () => {
    if(editType === 'sms') {
      return (
        <div>
          <h5 style={{ marginBottom: '21px' }}>顧客標記</h5>
          <div className="messageFilter_layout">
            { customerTagsJson.map((item, index) => {
              return (
                <div className="messageFilter_check" key={index}>
                  <label>
                    <input
                      type="checkbox"
                      value={item.value}
                      checked={displayTags && displayTags.includes(item.value)}
                      onChange={() => handleFilterTag(item.value)}
                    />
                    { item.tagTxt }
                  </label>
                </div>
              )
            }) }
          </div>
        </div>
      )
    }
  }

  const renderCouponRecord = () => {
    if(editType === 'menuTalk') {
      return (
        <Fragment>
          <div style={{ marginBottom: '32px' }}>
            <h5>粉絲領取優惠券次數</h5>
            <div id="couponExchange" className="messageFilter_input">
              <div className="coupon">
                <input
                  type="text"
                  value={displayFetchMin}
                  onChange={(e) => handleFilterConditions(e, 'fetchMin')}
                  placeholder="最少筆數（選填）"
                />
              </div>
              <div className="coupon">
                <input
                  type="text"
                  value={displayFetchMax}
                  onChange={(e) => handleFilterConditions(e, 'fetchMax')}
                  placeholder="最多筆數（選填）"
                />
              </div>
            </div>
          </div>

          <div style={{ marginBottom: '32px' }}>
            <h5>粉絲核銷優惠券次數</h5>
            <div id="couponFetch" className="messageFilter_input">
              <div className="coupon">
                <input
                  type="text"
                  value={displayExchangeMin}
                  onChange={(e) => handleFilterConditions(e, 'exchangeMin')}
                  placeholder="最少筆數（選填）"
                />
              </div>
              <div className="coupon">
                <input
                  type="text"
                  value={displayExchangeMax}
                  onChange={(e) => handleFilterConditions(e, 'exchangeMax')}
                  placeholder="最多筆數（選填）"
                />
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  }

  const renderCleanFilterConditions = () => {
    if(
      JSON.stringify(filterJson) !== '{}' ||
      JSON.stringify(displayFilterJson) !== '{}'
    ) {
      return <button className="clean" onClick={() => cleanFilterConditions()}>全部清除</button>;
    }
  };

  return (
    <Modal
      title="篩選條件"
      visible
      centered
      className="messageFilterModal"
      onCancel={cancelPopup}
      footer={
        <div>
          { renderCleanFilterConditions() }
          <button className="save" onClick={() => submitFilterConditions()}>套用篩選</button>
        </div>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      { renderCustomerRecord() }
      { renderGender() }
      { renderCustomerTag() }
      { renderCouponRecord() }
    </Modal>
  );
};

export default SendObjectFilterModal;