import React, { useCallback, useContext }  from 'react';

import { messageFilterOption, messageSortOption, messageStatusTab } from '../utils/data';
import { MessageContext } from '../crmContext/MessageContext';

import { Select } from 'antd';
const { Option } = Select;

const CrmMessageTab = () => {
	const { MessageState, MessageStateDispatch } = useContext(MessageContext);
	const {
    messageFilter,
    messageSort,
    messageStatus,
    statusMessages,
    searchInputText,
    searchMode
  } = MessageState;

  const handleMessageFilter = useCallback((value) => {
    let index = Object.values(messageFilterOption).indexOf(value);
    let filter = Object.keys(messageFilterOption)[index];

    if (filter === messageFilter) return;

    if(searchInputText.length > 0 && searchMode) {
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: true });
    } else {
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: false });
    }

		MessageStateDispatch({ type: 'setMessageFilter', messageFilter: filter });
    MessageStateDispatch({ type: 'setStatusMessagesNum' });
	}, [ messageFilter, messageStatus, searchInputText, searchMode, MessageStateDispatch ]);

  const handleMessageSort = useCallback((value) => {
    let index = Object.values(messageSortOption).indexOf(value);
    let sort = Object.keys(messageSortOption)[index];

		if (sort === messageSort) return;

    if(searchInputText.length > 0 && searchMode) {
      MessageStateDispatch({ type: 'setSearchResSort', messageSort: sort });
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: true });
    } else {
      MessageStateDispatch({ type: 'setMessageSort', messageSort: sort });
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus, searchMode: false });
    }

    MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
	}, [ messageSort, messageStatus, messageFilter, searchInputText, searchMode, MessageStateDispatch ]);

  const handleMessageType = useCallback((value) => {
		if (value === messageStatus) return;

    if(searchInputText.length > 0 && searchMode) {
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: value, searchMode: true });
    } else {
      MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: value, searchMode: false });
    }

    MessageStateDispatch({ type: 'setMessageFilter', messageFilter });
	}, [ messageStatus, messageFilter, searchInputText, searchMode, MessageStateDispatch ]);

  return (
    <div className="crmMessage_bar crmMessage_tab">
      <div className='msgSelect'>
        <Select
          bordered={false}
          defaultValue={messageFilterOption[messageFilter]}
          onChange={(value) => handleMessageFilter(value)}
        >
          {Object.keys(messageFilterOption).map((option, index) => {
            return(
              <Option
                key={index}
                value={messageFilterOption[option]}
              >
                {messageFilterOption[option]}
              </Option>
            )
          })}
        </Select>
      </div>

      <div className="crmMessage_type_tab">
        {Object.keys(messageStatusTab).map((option, index) => {
					return (
            <div
              key={index}
              className={option === messageStatus ? 'active' : ''}
              onClick={() => handleMessageType(option)}
            >
              {messageStatusTab[option]} {statusMessages[index] !== 0 ? statusMessages[index] : ''}
            </div>
          );
				})}
      </div>

      <div className='msgSelect'>
        <Select
          bordered={false}
          defaultValue={messageSortOption[messageSort]}
          onChange={(value) => handleMessageSort(value)}
        >
          {Object.keys(messageSortOption).map((option, index) => {
            return(
              <Option
                key={index}
                value={messageSortOption[option]}
              >
                {messageSortOption[option]}
              </Option>
            )
          })}
        </Select>
      </div>
    </div>
  )
}

export default CrmMessageTab;
